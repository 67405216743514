import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { getLeadTotals } from '../../api/leads';

type Props = {
    selNHID: number;
};

export const LeadsEmailLog = (props: Props) => {
    const [ totals, setTotals ] = useState<any>({
        EmailInquiries: 0,
        BrochureInquiries: 0
    });

    const getTotals = async () => {
        const params: any = {};

        if (props.selNHID) {
            params.NHIDs = [props.selNHID];
            params.IsSingleProperty = true;
        }

        const data = await getLeadTotals(params);
        setTotals(data);
    };

    useEffect(() => {
        getTotals();
    }, [props.selNHID]);

    return (
        <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg flex flex-col max-h-64">
            <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 2xl:py-3 min-h-14 flex items-center justify-between gap-2">
                <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                    Email Inquiry &amp; Brochure Download Log
                </h4>
            </div>
            <div className="p-3 sm:p-4 2xl:p-5 lg:pb-0">
                <div className="flex flex-wrap gap-x-3">
                    <div className="flex items-center gap-4">
                        <strong className="text-6xl min-w-10 text-center block">
                            { totals.EmailInquiries }
                        </strong>
                        <span className="block min-w-48">
                            Email Inquiries
                        </span>
                    </div>
                    <div className="flex items-center gap-4">
                        <strong className="text-6xl min-w-10 text-center block">
                            { totals.BrochureInquiries }
                        </strong>
                        <span className="block min-w-48">
                            Brochure Inquiries
                        </span>
                    </div>
                </div>
            </div>
            <div className="p-3 sm:p-4 2xl:p-5 !pt-0 mt-auto flex flex-wrap justify-end gap-3">
                <NavLink to="/leadslog" className="btn min-w-44 text-center">
                    View Email Log
                </NavLink>
            </div>
        </div>
    );
};
