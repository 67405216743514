import React, { FC, ReactNode } from 'react';
import { NursingHomesLogo } from './nursing_homes_logo';

const NursingHomesCard: FC<{ children: ReactNode }> = ({ children }) => {
    const cardHeading = (
        <a href="/" className="flex items-end p-3 sm:p-4 lg:px-8 lg:py-6 border-b border-brand_grey bg-brand_faint-blue">
            <NursingHomesLogo className="h-9 me-3 lg:h-10 xl:h-11" />
            <strong className="text-brand_grey-medium relative top-0.5 font-medium text-lg lg:text-xl lg:top-0">
                Portal
            </strong>
        </a>
    );

    return (
        <main>
            <section className="h-screen flex justify-center py-8">
                <div className="my-auto w-full max-w-[500px] ring-1 ring-brand_grey rounded-md lg:rounded-lg overflow-hidden shadow-md bg-white mx-3 sm:mx-4 lg:mx-8 h-fit"> 
                    {cardHeading}
                    {children}
                </div>
            </section>
        </main>
    );
};

export { NursingHomesCard };
