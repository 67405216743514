import React from 'react';
import { FormSelect } from '../../../../components/common/form_select';
import { useGetTourTypes } from '../hooks/useGetTourTypes';
import { FormRadioGroup } from '../../../../components/common/form_radio_group';

interface IOptions {
    asRadioGroup?: boolean;
}

const FormTourTypeSelect = ({ asRadioGroup = false }: IOptions) => {
    const { data: tourTypes = [] } = useGetTourTypes();

    const tourTypeOptions = tourTypes?.map((row) => ({
        label: row.TourMenuName,
        value: row.TourTypeID
    }));

    return asRadioGroup ? (
        <FormRadioGroup 
            options={tourTypeOptions} 
            name='TourTypeID' 
            groupLabel="Tour Type" 
        />
    ) : (
        <FormSelect
            maxWidth="350px"
            name="TourTypeID"
            label="Tour Type"
            options={tourTypeOptions}
        />
    );
};

export { FormTourTypeSelect };
