import React, {
    ChangeEvent,
    FC,
    forwardRef,
    ForwardRefRenderFunction,
    InputHTMLAttributes,
    MouseEvent,
    ReactElement,
    ReactNode
} from 'react';
import { ConnectForm } from '../form/form';
//import cx from 'classnames';
import { UseFormReturn } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faClockRotateLeft } from '@fortawesome/pro-solid-svg-icons';


type RadioOption = {
    value: string;
    label: string;
};

interface GroupProps {
    groupLabel?: string;
    name: string;
    options?: RadioOption[];
    showResetButton?: boolean;
    followUpInput?: ReactElement;
    info?: ReactNode;
    disabled?: boolean;
    layout?: 'normal' | 'horizontal';
}

interface RadioButtonProps
    extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
    value: string;
    label: string;
    name: string;
}

const FormRadioGroup: FC<GroupProps> = (props) => {
    const {
        name,
        options,
        groupLabel,
        showResetButton = false,
        followUpInput,
        info,
        disabled = false,
        layout = 'normal',
    } = props;

    /*const inlineLabelWidths = {
        small: 150,
        large: 250
    };*/

    return (
        <ConnectForm>
            {({ register, formState, watch, setValue }: UseFormReturn) => {
                const fieldError: { message?: string } =
                    formState?.errors?.[name];

                const errorMessage: ReactElement = (
                    <strong className="text-brand_status-error w-full">
                        {fieldError?.message}
                    </strong>
                );

                /*const optionsWrapperClassName = cx(
                    'position-relative d-flex w-100',
                    {
                        'gap-8 flex-row': inlineOptions,
                        'gap-2 flex-column': !inlineOptions
                    }
                );*/

                const handleChange = ({
                    target
                }: ChangeEvent<HTMLInputElement>) => {
                    setValue(name, target?.value, { shouldDirty: true });
                };

                /*const labelClassName = cx('fs-md-5', {
                    'd-flex align-items-center me-sm-5':
                        inlineOptions && inline,
                    'mb-1': !inlineOptions,
                    'fw-label': labelStyle === 'bold'
                });*/

                const handleReset = (event: MouseEvent<HTMLButtonElement>) => {
                    event.preventDefault();
                    setValue(name, null, { shouldDirty: true });
                };

                return (
                    <>
                        { layout === 'horizontal' ?
                            <div className="flex flex-col gap-1 lg:flex-row lg:gap-2 lg:items-center lg:flex-wrap">
                                {groupLabel && (
                                    <strong className="font-medium lg:min-w-[400px] lg:max-w-[400px] lg:text-lg">
                                        {groupLabel}
                                    </strong>
                                )}
                                <div className="flex flex-col gap-1.5 sm:flex-row sm:items-center sm:gap-6 lg:gap-10">
                                    <div className="flex flex-wrap items-center gap-x-4 gap-y-2 md:gap-x-5">
                                        {options.map((option) => {
                                            return (
                                                <RadioButtonWithRefs
                                                    checked={
                                                        option.value === watch(name)
                                                    }
                                                    value={option.value}
                                                    label={option.label}
                                                    key={option.value}
                                                    {...register(name)}
                                                    disabled={disabled}
                                                    onChange={handleChange}
                                                />
                                            );
                                        })}
                                        {followUpInput}
                                        {showResetButton && (
                                            <button
                                                title="reset"
                                                onClick={handleReset}
                                                type="button"
                                                className="rounded-full bg-brand_grey-light min-h-9 min-w-9"
                                                disabled={disabled}
                                            >
                                                <FontAwesomeIcon icon={faClockRotateLeft} className="h-4 w-4 link relative top-px" />
                                            </button>
                                        )}
                                    </div>
                                    {info && (
                                        <>
                                            {info}
                                        </>
                                    )}
                                </div>
                                {fieldError && errorMessage}
                            </div> :
                            <>
                                {options.map((option) => {
                                    return (
                                        <RadioButtonWithRefs
                                            checked={
                                                option.value === watch(name)
                                            }
                                            value={option.value}
                                            label={option.label}
                                            key={option.value}
                                            {...register(name)}
                                            disabled={disabled}
                                            onChange={handleChange}
                                        />
                                    );
                                })}
                            </>
                        }
                    </>   
                );
            }}
        </ConnectForm>
    );
};

const RadioButton: ForwardRefRenderFunction<any, RadioButtonProps> = (
    props,
    ref
) => {
    const { id, label: labelText, className, value, ...rest } = props;
    const inputId: string = id || `${rest.name}-${value}`;
    /*const inputClassName: string = cx('form-check-input', className);*/

    return (
        <div className="form-component form-radio">
            <label>
                <input
                    className="peer"
                    type="radio"
                    id={inputId}
                    value={value}
                    ref={ref}
                    {...rest}
                />
                <FontAwesomeIcon icon={faCircle} className="peer-checked:block"/>
                <span>
                    {labelText}
                </span>
            </label>            
        </div>
    );
};

const RadioButtonWithRefs = forwardRef(RadioButton);

export { FormRadioGroup };