import React from 'react';

const Footer = () => {
    return (
        <footer className="mt-auto">
            <div className="mt-3 sm:mt-4 lg:mt-8 text-white bg-brand_grey-dark text-sm leading-normal">
                <div className="container max-w-none 2xl:container">
                    <div className="flex flex-col xl:flex-row xl:justify-between py-7">
                        <span className="me-3 lg:whitespace-nowrap">
                            <a href="https://www.nursinghomes.com/" target="_blank">nursinghomes.com</a> is a website owned by A Place for Mom, Inc. <br className="hidden xl:inline"/>&copy; 2024 A Place for Mom, Inc. All Rights Reserved.
                        </span>
                        <nav className="flex flex-col md:flex-row mt-5 md:mt-3 xl:mt-auto">
                            {[
                                ['1', 'Review Policy', 'https://www.nursinghomes.com/review-policy/'],
                                ['2', 'Privacy Policy', 'https://www.nursinghomes.com/privacy/'],
                                ['3', 'Terms of Use', 'https://www.nursinghomes.com/terms/'],
                                ['4', 'Personal Information', 'https://www.nursinghomes.com/personal-information/'],
                            ].map(([index, title, url]) => (
                                <a key={index} href={url} className="mb-2 last:mb-0 md:mb-0 md:me-3 last:me-0 hover:underline" target="_blank">
                                    {title}
                                </a>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
