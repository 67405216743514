import React, { useState, useEffect, FC } from 'react';
import {
    IPropertyContactDetails,
    ContactDetailsHTMLType,
    ContactDetailsHeaders,
    ContactDetailsPlaceholders
} from '../../../interfaces/property';
import { updatePropertyContactDetails } from '../../../api/property';
import { showToast, ToastType } from '../../../utils/toast';
import {
    getObjectDifferenceWhenSameParams,
    isObjectEmpty
} from '../../../utils/common';
import { updateContactObjectSchema } from './schema/contact_details_schema';
import { LoadingDots } from '../../../components/common/loading_dots';
import { Modal } from '../../../components/common/modal';
import { Button } from '../../../components/common/button';
import { websiteJSXIcon } from './website_link_icon';
import cx from 'classnames';

interface IProps {
    data: IPropertyContactDetails;
    updateModalActive: boolean;
    handleCloseModal?: any;
    handleUpdateData?: any;
}

const UpdatePropertyContactDetailsForm: FC<IProps> = ({
    data,
    updateModalActive,
    handleCloseModal,
    handleUpdateData
}: IProps) => {
    const [busy, setBusy] = useState<boolean>(false);
    const [contactData, setContactData] =
        useState<IPropertyContactDetails>(null);
    const [newContactDetails, setNewContactDetails] =
        useState<IPropertyContactDetails>(null);
    const [changedTick, setChangedTick] = useState<number>(0);
    const [rowValidationError, SetRowValidationError] = useState<any[]>([]);
    const [tick, setchangeTick] = useState<number>(0);

    useEffect(() => {
        setContactData(data);
        setNewContactDetails(data);
        setchangeTick(tick + 1);
    }, [data]);

    const renderLoading = () => <LoadingDots show={true} />;

    const handleUpdateField = (key: string, value: string) => {
        const nhObject = JSON.parse(JSON.stringify(newContactDetails));
        const validValue = value?.trim() !== '' ? value : null;
        (nhObject as any)[key] = validValue;
        if (rowValidationError.length) {
            const errorObjectIndex = rowValidationError.reduce(
                (r, v, i) => r.concat(v.Key === key ? i : []),
                []
            );
            if (errorObjectIndex.length) {
                const cleanedErrorArray = rowValidationError.filter(function (
                    value,
                    index
                ) {
                    return errorObjectIndex.indexOf(index) === -1;
                });
                SetRowValidationError(cleanedErrorArray);
            }
        }
        setNewContactDetails(nhObject);
        setChangedTick(changedTick + 1);
    };

    const handlePropertyUpdate = async () => {
        const differences = getObjectDifferenceWhenSameParams(
            contactData,
            newContactDetails
        );

        if (!isObjectEmpty(differences)) {
            if (
                (!newContactDetails.GeneralPhoneNumber &&
                    newContactDetails.CarePhoneNumber) ||
                (!newContactDetails.GeneralPhoneNumber &&
                    newContactDetails.JobPhoneNumber)
            ) {
                const errorPayload = [
                    {
                        Key: 'GeneralPhoneNumber',
                        Message:
                            'The Default Phone Number cannot be blank whilst others are populated'
                    }
                ];
                const newErrorArray = rowValidationError.concat(errorPayload);
                SetRowValidationError(newErrorArray);
                setChangedTick(changedTick + 1);
                return;
            }

            const jobEmail =
                newContactDetails.JobEmail ||
                newContactDetails.JobEmail2 ||
                newContactDetails.JobEmail3 ||
                null;
            const careEmail =
                newContactDetails.CareEmail ||
                newContactDetails.CareEmail2 ||
                newContactDetails.CareEmail3 ||
                null;
            const generalEmail =
                newContactDetails.GeneralEmail ||
                newContactDetails.GeneralEmail2 ||
                newContactDetails.GeneralEmail3 ||
                null;
            if (
                (!generalEmail && jobEmail) ||
                (!generalEmail && careEmail)
            ) {
                const errorPayload = [
                    {
                        Key: 'GeneralEmail',
                        Message:
                            'The default email cannot be blank whilst other non-default emails are populated'
                    }
                ];
                const newErrorArray = rowValidationError.concat(errorPayload);
                SetRowValidationError(newErrorArray);
                setChangedTick(changedTick + 1);
                return;
            }
            try {
                await updateContactObjectSchema.validate(differences, {
                    abortEarly: false
                });
            } catch (err: any) {
                const errorCopy: any = err.inner;
                const errorPayload = errorCopy.map((e: any) => {
                    const errorInnerCopy: any = {};
                    errorInnerCopy.Key = e.path;
                    errorInnerCopy.Message = e.message;
                    return errorInnerCopy;
                });
                const newErrorArray = rowValidationError.concat(errorPayload);
                SetRowValidationError(newErrorArray);
                setChangedTick(changedTick + 1);
                return;
            }
            setBusy(true);
            try {
                await updatePropertyContactDetails(
                    contactData.NHID,
                    differences
                );
                showToast(
                    'Facility contact details saved successfully. Please allow up to 60 minutes for the changes to show on the website.',
                    ToastType.Success
                );
                SetRowValidationError([]);
            } catch (e) {
                showToast(
                    'An error occurred saving the updated contact details.',
                    ToastType.Error
                );
                console.log(e);
            } finally {
                setBusy(false);
                updateModalActive ? handleCloseModal() : handleUpdateData();
            }
        }
    };

    const renderRowErrorMessage = (key: string) => {
        const errorObjectArray = rowValidationError.filter(function (e) {
            return e.Key === key;
        });

        if (errorObjectArray.length) {
            return (
                <>
                    <em className="not-italic text-xs text-brand_status-error pt-2">
                        {`${errorObjectArray[0].Message} `}
                    </em>
                </>
            );
        }
    };

    // const checkRowError = () => {
    //     if (rowValidationError.length) {
    //         return true;
    //     }
    //     return false;
    // };

    const checkDefifferences = () => {
        if (contactData && newContactDetails) {
            const differences = getObjectDifferenceWhenSameParams(
                contactData,
                newContactDetails
            );

            if (!isObjectEmpty(differences)) {
                return false;
            }
            return true;
        }
        return false;
    };

    const renderUpdateButton = () => {
        return !updateModalActive ? (
            <button
                disabled={busy || checkDefifferences()}
                className="btn fixed bottom-6 left-1/2 -translate-x-1/2 shadow-xl ring-4 ring-white min-w-36"
                type="button"
                onClick={() => handlePropertyUpdate()}
            >
                Update
            </button>
        ) : (
            <div>
                <Button
                    type="button"
                    className="btn min-w-36"
                    disabled={busy || checkDefifferences()}
                    onClick={() => handlePropertyUpdate()}
                >
                    Update
                </Button>
            </div>
        );
    };

    const renderFormField = (
        htmlId: ContactDetailsHTMLType,
        key: string,
        placeHolder: string,
        label?: string
    ) => {
        let formInputFieldClass = '';
        const id = `contact-details-${key}`;

        const errorObjectArray = rowValidationError.filter(function (e) {
            return e.Key === key;
        });

        let errorFormat = '';
        if (errorObjectArray.length) {
            errorFormat = 'form-error';
        }
        switch (htmlId) {
            case ContactDetailsHTMLType.Website:
                formInputFieldClass = `max-w-[800px] ${errorFormat}`;
                break;
            default:
                formInputFieldClass = `${errorFormat}`;
                break;
        }

        const value = newContactDetails
            ? (newContactDetails as any)[key]
            : null;
        return (
            <div className="flex items-center gap-3">
                <div className={`form-component form-inline form-textbox w-full ${formInputFieldClass}`}>
                    <label htmlFor={id}>
                        {placeHolder}
                    </label>
                    <input
                        type="text"
                        id={id}
                        value={value}
                        onChange={e => handleUpdateField(key, e.target.value)}
                    />
                    { errorObjectArray.length ? ( renderRowErrorMessage(key)) : null }
                </div>    
                { htmlId === ContactDetailsHTMLType.Website ? contactData? websiteJSXIcon(contactData.GeneralWebsite): null : null }        
            </div>
        );
    };

    const renderCompleteUpdateForm = () => {
        return (
            <>
                {!updateModalActive && contactData && contactData.Name ? (
                    <section>
                        <div className="container max-w-none 2xl:container mt-4 sm:mt-6 md:mt-8 2xl:mt-10">
                            <div className="flex flex-col gap-2 text-center">
                                <div className="flex flex-wrap justify-center items-center gap-2">
                                    <h4 className="text-xl md:text-3xl font-bold mx-3 md:mx-5">
                                        {contactData.Name}
                                    </h4>
                                    <a
                                        className="link text-xs bg-brand_faint-blue px-2 py-1 inline-block leading-none rounded-lg md:text-md"
                                        href="#"
                                        target="_blank"
                                    >
                                        view on website
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>
                ) : null}
                <section>
                    <div className={cx(!updateModalActive && 'container max-w-none 2xl:container mt-4 sm:mt-6 md:mt-8 2xl:mt-10')}>
                        {busy ? renderLoading() : null}
                        <div className="grid gap-3 sm:gap-5 md:gap-6 2xl:gap-7">
                            <div className="ring-1 ring-brand_grey rounded-md lg:rounded-lg">
                                <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-3 xl:py-2 min-h-14 flex items-center rounded-t-md lg:rounded-t-lg">
                                    <div className="flex flex-wrap gap-1 items-center lg:gap-2 w-full">
                                        <h4 className="font-semibold leading-tight text-lg lg:text-xl me-4">
                                            Phone
                                        </h4>
                                        <ul className="marker:text-brand_grey-medium list-disc ps-5 space-y-0.5 text-xs">
                                            <li>
                                                Phone calls can be channeled to
                                                different numbers, depending on the
                                                enquiry type
                                            </li>
                                            <li>
                                                Only enter 'Care' or 'Job' numbers, if
                                                using different numbers from the
                                                'Default Number'
                                            </li>
                                        </ul>                                        
                                    </div>
                                </div>
                                <div className="p-3 sm:p-4 2xl:p-5">
                                    <div className="grid grid-cols-1 gap-2 md:grid-cols-3 lg:gap-6 xl:gap-12 items-start">
                                        {renderFormField(
                                            ContactDetailsHTMLType.PhoneNumber,
                                            'GeneralPhoneNumber',
                                            ContactDetailsPlaceholders.PhoneNumber1,
                                            ContactDetailsHeaders.GeneralPhoneNumber
                                        )}
                                        {renderFormField(
                                            ContactDetailsHTMLType.PhoneNumber,
                                            'CarePhoneNumber',
                                            ContactDetailsPlaceholders.PhoneNumber2,
                                            ContactDetailsHeaders.Care
                                        )}
                                        {renderFormField(
                                            ContactDetailsHTMLType.PhoneNumber,
                                            'JobPhoneNumber',
                                            ContactDetailsPlaceholders.PhoneNumber3,
                                            ContactDetailsHeaders.Job
                                        )}                                        
                                    </div>
                                </div>
                            </div>
                            <div className="ring-1 ring-brand_grey rounded-md lg:rounded-lg">
                                <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-3 xl:py-2 min-h-14 flex items-center rounded-t-md lg:rounded-t-lg">
                                    <div className="flex flex-wrap gap-1 items-center lg:gap-2 w-full">
                                        <h4 className="font-semibold leading-tight text-lg lg:text-xl me-4">
                                            Email
                                        </h4>
                                        <ul className="marker:text-brand_grey-medium list-disc ps-5 space-y-0.5 text-xs">
                                            <li>
                                                Email enquiries can be channeled to
                                                different email addresses, depending on
                                                the enquiry type
                                            </li>
                                            <li>
                                                Only enter 'Care Inquiry' or 'Job Inquiry' emails, 
                                                if using a different email address from the 'Default Emails'
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="p-3 sm:p-4 2xl:p-5">
                                    <div className="grid gap-6 lg:gap-8 2xl:gap-10">
                                        <div>
                                            <strong className="font-medium leading-tight mb-2 block">
                                                Default Email Addresses
                                            </strong>
                                            <div className="grid grid-cols-1 gap-2 md:grid-cols-3 lg:gap-6 xl:gap-12 items-start">
                                                {renderFormField(
                                                    ContactDetailsHTMLType.Email,
                                                    'GeneralEmail',
                                                    ContactDetailsPlaceholders.GeneralEmail1,
                                                    ContactDetailsHeaders.GeneralEmails
                                                )}
                                                {renderFormField(
                                                    ContactDetailsHTMLType.Email,
                                                    'GeneralEmail2',
                                                    ContactDetailsPlaceholders.GeneralEmail2
                                                )}
                                                {renderFormField(
                                                    ContactDetailsHTMLType.Email,
                                                    'GeneralEmail3',
                                                    ContactDetailsPlaceholders.GeneralEmail3
                                                )}
                                            </div>                                            
                                        </div>
                                        <div>
                                            <strong className="font-medium leading-tight mb-2 block">
                                                Care Inquiries (includes Care Inquiries, Brochure Inquiries and Tour Inquiries)
                                            </strong>
                                            <div className="grid grid-cols-1 gap-2 md:grid-cols-3 lg:gap-6 xl:gap-12 items-start">
                                                {renderFormField(
                                                    ContactDetailsHTMLType.Email,
                                                    'CareEmail',
                                                    ContactDetailsPlaceholders.CareEmail1,
                                                    ContactDetailsHeaders.CareExtended
                                                )}
                                                {renderFormField(
                                                    ContactDetailsHTMLType.Email,
                                                    'CareEmail2',
                                                    ContactDetailsPlaceholders.CareEmail2
                                                )}
                                                {renderFormField(
                                                    ContactDetailsHTMLType.Email,
                                                    'CareEmail3',
                                                    ContactDetailsPlaceholders.CareEmail3
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <strong className="font-medium leading-tight mb-2 block">
                                                Job Inquiries
                                            </strong>
                                            <div className="grid grid-cols-1 gap-2 md:grid-cols-3 lg:gap-6 xl:gap-12">
                                                {renderFormField(
                                                    ContactDetailsHTMLType.Email,
                                                    'JobEmail',
                                                    ContactDetailsPlaceholders.JobEmail1,
                                                    ContactDetailsHeaders.Job
                                                )}
                                                {renderFormField(
                                                    ContactDetailsHTMLType.Email,
                                                    'JobEmail2',
                                                    ContactDetailsPlaceholders.JobEmail2
                                                )}
                                                {renderFormField(
                                                    ContactDetailsHTMLType.Email,
                                                    'JobEmail3',
                                                    ContactDetailsPlaceholders.JobEmail3
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ring-1 ring-brand_grey rounded-md lg:rounded-lg">
                                <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 2xl:py-3 min-h-14 flex items-center rounded-t-md lg:rounded-t-lg">
                                    <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                                        Website
                                    </h4>
                                </div>
                                <div className="p-3 sm:p-4 2xl:p-5">
                                    { renderFormField(
                                        ContactDetailsHTMLType.Website,
                                        'GeneralWebsite',
                                        ContactDetailsPlaceholders.Website
                                    )}      
                                </div>
                            </div>
                        </div>
                        {!updateModalActive ? renderUpdateButton() : null}
                    </div>
                </section>
            </>
        );
    };

    const modalTitle = `Update ${contactData ? contactData.Name : null}`;

    return (
        <>
            {!updateModalActive ? (
                renderCompleteUpdateForm()
            ) : (
                <>
                    <Modal
                        isOpen={true}
                        onClose={handleCloseModal}
                        title={modalTitle}
                        size="xl"
                        footerContent={renderUpdateButton()}
                    >
                        {renderCompleteUpdateForm()}
                    </Modal>
                </>
            )}
        </>
    );
};

export { UpdatePropertyContactDetailsForm };
