import React, { useEffect, useState } from 'react';
import dateFormat from 'dateformat';
import DataTable  from '../common/data_table';
import { ILeadTotals } from '../../interfaces/leads';
import { SortOrder, TableColumn } from 'react-data-table-component';
import CsvExport, { DisplayType } from '../csv_export/csv_export';
import * as csvExportApi from '../../api/csv_export';
import { formatFileNameForCsvExport } from '../../utils/file';
import { Link } from 'react-router-dom';

type Props = {
    data: any[];
    dataPoint: string;
    totals: ILeadTotals;
    filters: any;
    templateTypes: any[];
    selNHID: number;
};

export default function LeadInsightsTable(props: Props) {
    const [tableData, setTableData] = useState<any[]>([]);
    const [sortField, setSortField] = useState<string>('');
    const [sortOrder, setSortOrder] = useState<string>('');

    const getEventDates = (row: any): [Date, Date] => {
        if (row.EventDate === 'totals') {
            return [null, null];
        }

        const now = Date.now();
        const date = new Date();
        date.setHours(0,0,0,0);
        date.setDate(1);

        switch (props.dataPoint) {
            case 'month': {
                if (!row.Month) {
                    return [null, null];
                }

                date.setFullYear(row.Year);

                const firstDay = new Date(date.getFullYear(), row.Month - 1, 1);
                const lastDay = new Date(date.getFullYear(), row.Month, 0);

                const isFuture = lastDay.getTime() > now;

                return [firstDay, isFuture ? new Date(now) : lastDay];
            }
            case 'week': {
                if (!row.Week) {
                    return [null, null];
                }

                date.setFullYear(row.Year);
                date.setMonth(0);
                date.setDate(row.Week * 7);

                const endOfWeek = new Date(date);
                date.setDate(date.getDate() - 6);
                const startOfWeek = new Date(date);

                const isFuture = endOfWeek.getTime() > now;

                return [startOfWeek, isFuture ? new Date(now) : endOfWeek];
            }
            case 'day':
                if (!row.EventDate) {
                    return [null, null];
                }

                return [new Date(row.EventDate), null];
        }
    };

    const formatDatePeriod = (row: any) => {
        if (row.EventDate === 'totals') {
            return 'Total';
        }

        const now = Date.now();
        const dateFormatStr = 'mmm dS, yyyy';

        let dateStr = '';

        const date = new Date();
        date.setHours(0,0,0,0);
        date.setDate(1);

        switch (props.dataPoint) {
            case 'month': {
                if (!row.Month) {
                    return '';
                }

                date.setFullYear(row.Year);

                const firstDay = new Date(date.getFullYear(), row.Month - 1, 1);
                const lastDay = new Date(date.getFullYear(), row.Month, 0);

                const isFuture = lastDay.getTime() > now;

                dateStr = `${dateFormat(firstDay, dateFormatStr)} - ${dateFormat(isFuture ? now : lastDay, dateFormatStr)}`;
                break;
            }
            case 'week': {
                if (!row.Week) {
                    return '';
                }

                date.setFullYear(row.Year);
                date.setMonth(0);
                date.setDate(row.Week * 7);

                const endOfWeek = new Date(date);
                date.setDate(date.getDate() - 6);
                const startOfWeek = new Date(date);

                const isFuture = endOfWeek.getTime() > now;

                dateStr = `${dateFormat(startOfWeek, dateFormatStr)} - ${dateFormat(isFuture ? now : endOfWeek, dateFormatStr)}`;
            }
                break;
            case 'day':
                if (!row.EventDate) {
                    return '';
                }

                dateStr = dateFormat(row.EventDate, dateFormatStr);
                break;
        }

        return dateStr;
    };

    const columns = [
        {
            name: 'Period',
            sortField: props.dataPoint === 'month' ? 'Month' : props.dataPoint === 'week' ? 'Week' : 'EventDate',
            cell: (row: any) => formatDatePeriod(row),
            sortable: true,
            width: '*'
        },
        {
            name: 'Page Views',
            sortField: 'PageViews',
            selector: (row: any) => row.PageViews,
            sortable: true,
            width: '*'
        },
        {
            name: 'Website Referrals',
            sortField: 'WebsiteReferrals',
            selector: (row: any) => row.WebsiteReferrals,
            sortable: true,
            width: '*'
        },
        {
            name: 'Phone Number Views',
            sortField: 'PhoneNumberViews',
            selector: (row: any) => row.PhoneNumberViews,
            sortable: true,
            width: '*'
        },
        {
            name: 'Email Inquiries *',
            sortField: 'EmailInquiries',
            sortable: true,
            cell: (row: any) => {
                const [dateFrom, dateTo] = getEventDates(row);
                const templateType = props.templateTypes.filter((type) => type.value !== 5);

                let dateFromStr = '';
                let dateToStr = '';

                if (dateFrom) {
                    dateFrom.setHours(0, 0, 0, 0);
                    dateFromStr = dateFormat(dateFrom, 'yyyy-mm-dd');
                }

                if (dateTo) {
                    dateTo.setHours(0, 0, 0, 0);
                    dateToStr = dateFormat(dateTo, 'yyyy-mm-dd');
                }

                return (
                    <div>
                        { row.EmailInquiries }
                        {
                            row.EmailInquiries > 0 &&
                            row.EventDate !== 'totals' &&
                            dateTo &&
                            dateFrom
                            ? <Link
                                to="/leadslog"
                                state={{
                                    filters: {
                                        DateFrom: dateFromStr ? new Date(dateFromStr) : null,
                                        DateTo: dateToStr ? new Date(dateToStr) : null,
                                        TemplateTypeID: templateType
                                    }
                                }}
                                className="ms-2 link text-sm"
                            >
                                view
                            </Link> : null
                        }
                    </div>
                );
            },
            width: '*'
        },
        {
            name: 'Brochure Inquiries *',
            sortField: 'BrochureInquiries',
            sortable: true,
            cell: (row: any) => {
                const [dateFrom, dateTo] = getEventDates(row);
                const templateType = props.templateTypes.find((type) => type.value === 5);

                let dateFromStr = '';
                let dateToStr = '';

                if (dateFrom) {
                    dateFrom.setHours(0, 0, 0, 0);
                    dateFromStr = dateFormat(dateFrom, 'yyyy-mm-dd');
                }

                if (dateTo) {
                    dateTo.setHours(0, 0, 0, 0);
                    dateToStr = dateFormat(dateTo, 'yyyy-mm-dd');
                }

                return (
                    <div>
                        { row.BrochureInquiries }
                        {
                            row.BrochureInquiries > 0 &&
                            row.EventDate !== 'totals' &&
                            dateTo &&
                            dateFrom
                            ? <Link
                                to="/leadslog"
                                state={{
                                    filters: {
                                        DateFrom: dateFromStr ? new Date(dateFromStr) : null,
                                        DateTo: dateToStr ? new Date(dateToStr) : null,
                                        TemplateTypeID: [templateType]
                                    }
                                }}
                                className="ms-2 link text-sm"
                            >
                                view
                            </Link>
                            : null
                        }
                    </div>
                );
            },
            width: '*'
        }
    ];

    const handleData = () => {
        const totalsRow = {
            TotalsRow: 1,
            EventDate: 'totals',
            PageViews: props.totals.PageViews,
            WebsiteReferrals: props.totals.WebsiteReferrals,
            PhoneNumberViews: props.totals.PhoneNumberViews,
            EmailInquiries: props.totals.EmailInquiries,
            BrochureInquiries: props.totals.BrochureInquiries
        };

        const data = [...props.data];

        if (sortField && sortOrder) {
            data.sort((a, b) => {
                switch (sortOrder) {
                    case 'asc':
                        return a[sortField] < b[sortField] ? 1 : -1;
                    case 'desc':
                        return a[sortField] > b[sortField] ? 1 : -1;
                }
            });
        }

        setTableData([
            totalsRow,
            ...data
        ]);
    };

    const handleSortChange = (column: TableColumn<any>, order: SortOrder) => {
        if (column?.id && order) {
            setSortField(column.sortField);
            setSortOrder(order);
        }
    };

    const startExport = async (): Promise<string> => {
        const params = {...props.filters};

        if (props.selNHID) {
            params.NHIDs = [props.selNHID];
            params.IsSingleProperty = true;
        }

        return csvExportApi.startExport('/lead/export', params);
    };

    useEffect(() => {
        handleData();
    }, [props.data, props.dataPoint, sortField, sortOrder]);

    return (
        <>
            <div className="mt-4 sm:mt-6 md:mt-8 lg:mt-10 flex flex-col items-center text-center gap-3 md:flex-row md:text-start">
                <div className="md:ms-auto">
                    <CsvExport 
                        startExportFunction={startExport}
                        modalTitleSuffix="Leads"
                        label="Export as CSV"
                        displayType={DisplayType.Button}
                        fileName={`${formatFileNameForCsvExport('leads')}`}
                    />
                </div>
            </div>
            <div className="mt-4 sm:mt-6 md:mt-8 lg:mt-10">
                <strong className="mb-2 md:mb-4 lg:mb-6 text-sm block font-medium md:text-end px-2 md:px-3 lg:px-4">
                    * Email Inquiries & Brochure Inquiries launched on April 1st 2024
                </strong>
                <div data-table="Leads Overview" className="relative [&>div]:scrollbar [&>div]:pb-2 [&>div]:-mx-3 [&>div]:w-[calc(100%+1.5rem)] [&>div]:px-3 sm:[&>div]:-mx-4 sm:[&>div]:w-[calc(100%+2rem)] sm:[&>div]:px-4 xl:[&>div]:mx-0 xl:[&>div]:w-full xl:[&>div]:px-0 before:h-full before:top-0 before:absolute before:z-10 before:bg-gradient-to-r before:from-white before:w-3 before:-left-3 after:h-full after:top-0 after:absolute after:z-10 after:bg-gradient-to-l after:from-white after:-right-3 after:w-3 sm:before:-left-4 sm:before:w-4 sm:after:-right-4 sm:after:w-4 xl:before:content-none xl:after:content-none">
                    <DataTable
                        onSort={handleSortChange}
                        useTotalsRow={true}
                        data={tableData}
                        columns={columns}
                        pagination={true}
                        paginationTotalRows={props.data.length}
                    />
                </div>
            </div>
        </>
    );
}
