import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareArrowUpRight } from '@fortawesome/pro-solid-svg-icons';

export const websiteJSXIcon = (website?: string) => {
    return (
        <>
            {website ? (
                <a href={website} target="_blank" rel="noreferrer" className="link flex items-center mt-2">
                    <FontAwesomeIcon icon={faSquareArrowUpRight} className="text-xl me-1 relative -top-px" />Visit
                </a>
            ) : null}
        </>
    );
};