import React, { useEffect } from 'react';
import { FormInput } from '../../../../../../components/common/form_input';
import { FormRadioGroup } from '../../../../../../components/common/form_radio_group';
import { FormCheckbox } from '../../../../../../components/common/form_checkbox';
import { onlyNumeric, yesNoOptions } from '../../../helpers';
import { ProfileCompletenessTag } from '../../components/profile_completeness_tag';
import { useFormContext } from 'react-hook-form';
import { SectionHeading } from '../../components/section_heading';
import { RoomTypeLink } from '../../components/room_type_link';

const ShortTermCareCostTab = () => {
    const { watch, setValue, trigger } = useFormContext();
    const inline = true;

    const isShortTermCareSelected = watch('ShortTermCare') === 'Yes';
    const isRoomTypeSharedSelected = watch('RoomTypeShared') === 'Yes';
    const isRoomTypePrivateRoomSelected = watch('RoomTypePrivateRoom') === 'Yes';
    const isRoomTypeSemiPrivateSelected = watch('RoomTypeSemiPrivate') === 'Yes';
    const isRoomTypePrivateSuiteSelected = watch('RoomTypePrivateSuite') === 'Yes';

    const isShortTermSharedCostPerDayNAChecked = watch('ShortTermSharedCostPerDayNA');
    const isShortTermPrivateCostPerDayNAChecked = watch('ShortTermPrivateCostPerDayNA');
    const isShortTermSemiPrivateCostPerDayNAChecked = watch('ShortTermSemiPrivateCostPerDayNA');

    useEffect(() => {
        if (isShortTermSharedCostPerDayNAChecked) {
            setValue('ShortTermSharedCostPerDay', '');
            trigger('ShortTermSharedCostPerDay');
        }
    }, [isShortTermSharedCostPerDayNAChecked]);

    useEffect(() => {
        if (isShortTermPrivateCostPerDayNAChecked) {
            setValue('ShortTermPrivateCostPerDay', '');
            trigger('ShortTermPrivateCostPerDay');
        }
    }, [isShortTermPrivateCostPerDayNAChecked]);

    useEffect(() => {
        if (isShortTermSemiPrivateCostPerDayNAChecked) {
            setValue('ShortTermSemiPrivateCostPerDay', '');
            trigger('ShortTermSemiPrivateCostPerDay');
        }
    }, [isShortTermSemiPrivateCostPerDayNAChecked]);

    const canUseSharedRoomShortTerm = isShortTermCareSelected && isRoomTypeSharedSelected && !isShortTermSharedCostPerDayNAChecked;
    const canUsePrivateRoomShortTerm = isShortTermCareSelected && isRoomTypePrivateRoomSelected  && !isShortTermPrivateCostPerDayNAChecked;
    const canUseSemiPrivateRoomShortTerm = isShortTermCareSelected && isRoomTypeSemiPrivateSelected  && !isShortTermSemiPrivateCostPerDayNAChecked;
    const canUsePrivateSuiteShortTerm = isShortTermCareSelected && isRoomTypePrivateSuiteSelected;

    const allFieldsDisabled = !(
        canUsePrivateRoomShortTerm ||
        canUseSemiPrivateRoomShortTerm ||
        canUseSharedRoomShortTerm ||
        canUsePrivateSuiteShortTerm
    );

    return (
        <>
            <SectionHeading
                title="Short Term Care Cost"
                subtitle={<>To edit the below inputs &lsquo;<a href={'/core-details'} className="link">Short Term Care</a>&rsquo; and the relevant { <RoomTypeLink/> } must be set to &lsquo;Yes&rsquo;.</>}

            />
            <div className="grid gap-3 lg:gap-5 2xl:gap-6 px-3 sm:px-4 2xl:px-5">
                <FormInput
                    allowedPattern={onlyNumeric}
                    layout="horizontal"
                    disabled={!canUseSharedRoomShortTerm}
                    info={
                        <>
                            <FormCheckbox 
                                name="ShortTermSharedCostPerDayNA"
                                id="ShortTermSharedCostPerDayNA"
                                label="Not Applicable"
                            />
                            {
                                !isShortTermSharedCostPerDayNAChecked &&
                                    <ProfileCompletenessTag
                                        isVisible={canUseSharedRoomShortTerm}
                                    />
                            }
                        </>                     
                    }
                    inlineLabel={false}
                    label="Shared room"
                    maxWidth='5rem'
                    name="ShortTermSharedCostPerDay"
                    prefix="$"
                    suffix="starting price per day"
                    type="number"
                />
                <FormInput
                    allowedPattern={onlyNumeric}
                    layout="horizontal"
                    disabled={!canUsePrivateRoomShortTerm}
                    info={
                        <>
                            <FormCheckbox 
                                name="ShortTermPrivateCostPerDayNA"
                                id="ShortTermPrivateCostPerDayNA"
                                label="Not Applicable"
                            />
                            {
                                !isShortTermPrivateCostPerDayNAChecked &&
                                    <ProfileCompletenessTag
                                        isVisible={canUsePrivateRoomShortTerm}
                                    />
                            }
                        </>                     
                    }
                    inlineLabel={false}
                    label="Private room"
                    maxWidth='5rem'
                    name="ShortTermPrivateCostPerDay"
                    prefix="$"
                    suffix="starting price per day"
                    type="number"
                />
                <FormInput
                    allowedPattern={onlyNumeric}
                    layout="horizontal"
                    disabled={!canUseSemiPrivateRoomShortTerm}
                    info={
                        <>
                            <FormCheckbox 
                                name="ShortTermSemiPrivateCostPerDayNA"
                                id="ShortTermSemiPrivateCostPerDayNA"
                                label="Not Applicable"
                            />
                            {
                                !isShortTermSemiPrivateCostPerDayNAChecked &&
                                    <ProfileCompletenessTag
                                        isVisible={canUseSemiPrivateRoomShortTerm}
                                    />
                            }
                        </>                     
                    }
                    inlineLabel={false}
                    label="Semi-private room"
                    maxWidth='5rem'
                    name="ShortTermSemiPrivateCostPerDay"
                    prefix="$"
                    suffix="starting price per day"
                    type="number"
                />
                <FormInput
                    allowedPattern={onlyNumeric}
                    layout="horizontal"
                    disabled={!canUsePrivateSuiteShortTerm}
                    info={
                        <ProfileCompletenessTag
                            isVisible={false}
                        />
                    }
                    label="Private suite"
                    maxWidth='5rem'
                    name="ShortTermPrivateSuiteCostPerDay"
                    prefix="$"
                    suffix="starting price per day"
                    type="number"
                />
                <FormRadioGroup
                    disabled={allFieldsDisabled}
                    groupLabel="Rates are all-inclusive?"
                    info={<ProfileCompletenessTag isVisible={false} />}
                    layout="horizontal"
                    name="ShortTermRatesAllInclusive"
                    options={yesNoOptions}
                    showResetButton={true}
                />
                <FormRadioGroup
                    disabled={allFieldsDisabled}
                    groupLabel="Rate packages/bundles available?"
                    info={<ProfileCompletenessTag isVisible={false} />}
                    layout="horizontal"
                    name="ShortTermRatePackagesAvailable"
                    options={yesNoOptions}
                    showResetButton={true}
                />
            </div>
        </>
    );
};

export { ShortTermCareCostTab };
