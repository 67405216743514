import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { IMenuItem, IMenuState, IMenuStateTotals } from '../../interfaces/menu';
import { ISessionState } from '../../interfaces/session';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { getLinkTotal } from '../../utils/menu';
import { isDefined } from '../../utils/common';
import { get } from 'lodash';
import { findMenuItem, findPageByID } from './common';


interface IProps {
    menuPath?: string;
}

const BreadcrumbMenu = ({ menuPath }: IProps) => {
    const { Menu }: IMenuState = useSelector(
        ({ MenuState }: any) => MenuState
    );

    const menuTotals: IMenuStateTotals = useSelector(
        ({ MenuTotalsState }: any) => MenuTotalsState
    );
    
    const { Session }: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const currentPage: IMenuItem = findMenuItem(Menu, menuPath, location.pathname);
    const canViewTestFeatures = get(Session, 'Perms.CanViewTestFeatures', false);

    if (!currentPage || (currentPage.IsTestFeature && !canViewTestFeatures)) {
        return;
    }

    const renderBreadcrumbs = () => {    
        const parentPage = findPageByID(Menu, currentPage.ParentPageID);
        const pages = parentPage?.Children || [currentPage];

        return (
            <nav className="hidden lg:block overflow-auto border-b border-brand_grey scrollbar scrollbar-sm scrollbar-full scrollbar-breadcrumbs">
                <ul className="flex text-md 2xl:text-base first:[&>li]:ms-auto last:[&>li]:me-auto [&>li]:whitespace-nowrap [&>li>*]:inline-block [&>li]:leading-none [&>li]:px-3 [&>li>*]:py-5 xl:[&>li>*]:py-6 [&>li>a]:relative before:[&>li.active>a]:w-full before:[&>li>a]:border-b-[3px] before:[&>li>a]:border-b-brand_primary hover:before:[&>li>a]:w-full before:[&>li>a]:ease-in-out before:[&>li>a]:duration-200 before:[&>li>a]:absolute before:[&>li>a]:w-0 before:[&>li>a]:bottom-0 before:[&>li>a]:left-0">
                    {
                        <>
                            {
                                parentPage ?
                                    <li>
                                        <strong className="font-semibold text-brand_grey-medium">
                                            { parentPage.MenuText }<FontAwesomeIcon icon={faChevronRight} className="ms-1.5 h-3 w-3 text-brand_grey-medium relative -top-px lg:h-3.5 lg:w-3.5" />
                                        </strong>
                                    </li> : null
                            }
                            {
                                pages.map((page: IMenuItem) => {
                                    const linkTotal = getLinkTotal(menuTotals, page.Slug);
                                    const menuText = <>
                                        {  page.MenuText } { isDefined(linkTotal) ? <><span className="bg-brand_faint-blue text-xs rounded-md py-0.5 px-1.5 ms-0.5">{linkTotal}</span></> : null }
                                    </>;

                                    if (currentPage.PageID === page.PageID) {
                                        return (
                                            <li className="link active" key={`breadcrumb-page-${page.PageID}`}>
                                                <NavLink to={ page.Route }>
                                                    { menuText }
                                                </NavLink>
                                            </li> 
                                        );
                                    }
                                    return (
                                        <li className="link text-brand_grey-dark" key={`breadcrumb-page-${page.PageID}`}>
                                            <NavLink to={ page.Route }>
                                                { menuText }
                                            </NavLink>
                                        </li>
                                    );
                                })
                            }
                        </> 
                    }
                </ul>
            </nav>
        );
    };


    return (
        <>
            { renderBreadcrumbs() }
        </>
    );
};

export default BreadcrumbMenu;
 