import React from 'react';
import cx from 'classnames';

interface ProfileCompletenessTagProps {
    isVisible: boolean;
    direction?: 'left' | 'right'
}

const ProfileCompletenessTag: React.FC<ProfileCompletenessTagProps> = ({ isVisible, direction = 'left' }) => {
    const bannerClassName = cx('sm:[clip-path:polygon(6%_0%,100%_0%,100%_100%,6%_100%,0%_50%)] sm:ps-4 bg-brand_secondary rounded-md text-white text-2xs font-medium whitespace-nowrap py-1 px-2 w-fit', {
        'hidden': !isVisible,
        'sm:![clip-path:polygon(0%_0%,94%_0%,100%_50%,94%_100%,0%_100%)] sm:!pe-4 sm:!ps-2': direction === 'right'
    });
    return (
        <span className={bannerClassName}>
            Included in Profile Completeness
        </span>
    );
};

export { ProfileCompletenessTag };