import React, { useState, useEffect } from 'react';
import PageCommunitySelect from '../../components/common/page_community_select';
import { getSingleProperty  } from '../../utils/common';
import { ISessionState, SessionType as EnumSessionType } from '../../interfaces/session';
import { useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import axios from 'axios';

import {
    renderQRCodePosterLink,
    renderQRCodeLink,
    qrCodeImageURL,
    qrCodePosterPdfURL,
    renderCSVLink
} from './review_score_certs_common';
import { useLocation } from 'react-router-dom';
import PremiumLink from '../../components/common/premium_sign_up_link';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

const showAdditional = false;

const QRCodePoster =({ nhIDs, orgNHID }: IProps) => {
    const { Session, Properties, Org }: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const { SessionType } = Session;

    const location = useLocation();
    const state = location?.state as any;

    const initNHID = state?.NHID || nhIDs?.length === 1 ? nhIDs[0] : null;
    const initProperty = initNHID ? getSingleProperty(SessionType, initNHID, Properties) : null;  
    
    const [selNHID, setSelNHID] = useState<number>(initNHID);
    const [qrCodeSize, setQrCodeSize] = useState<number>(300);
    const [qrCodeImgFormat, setQrCodeImgFormat] = useState<'SVG' | 'PNG'>('PNG');
    const [qrCodeExtraParams, setQrCodeExtraParams] = useState<string>('');
    const [qrCodePosterExtraParams, /*setQrCodePosterExtraParams*/] = useState<string>('');
    const [qrCodeImgURL, setQrCodeImgURL] = useState<string>('');
    const [qrCodePosterURL, setQrCodePosterURL] = useState<string>('');
    const [qrCodePosterDownloading, setQRCodePosterDownloading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [property, setProperty] = useState<any>(initProperty);

    useEffect(() => {
        createQrCodeImageURL();
        createQrCodePosterPdfURL();
    });

    useEffect(() => {
        createQrCodePosterPdfURL();
    }, [qrCodePosterExtraParams]);

    useEffect(() => {
        createQrCodeImageURL();
    }, [qrCodeExtraParams, qrCodeImgFormat, qrCodeSize]);

    const singleProperty = getSingleProperty(SessionType, selNHID, Properties);
    const singlePropertyName = singleProperty ? singleProperty.Name : '';

    const qrcodePosterFilename = () => {

        const prefix = 'qrcodeposter';

        if (singleProperty) {
            return `${prefix}_${singlePropertyName}_${singleProperty.NHID}.pdf`;
        } else if (SessionType === EnumSessionType.SessionTypeOrg) {
            return `${prefix}_${Org.Name}_${Org.NHID}.zip`;
        } else {
            return 'qrcodeposter_multiple_members.zip';
        }
    };

    // const renderSessionText = () => {
    
    //     if (singlePropertyName) {
    //         return (
    //             <span>for <strong>{singlePropertyName}</strong></span>
    //         );
    //     } else if (SessionType === EnumSessionType.SessionTypeOrg) {
    //         return (
    //             <span>for <strong>all {Org.Name} Communities</strong></span>
    //         );
    //     } else {
    //         return (
    //             <span>for <strong>all {nhIDs.length} Communities</strong></span>
    //         );
    //     }
    // };
    

    const createQrCodeImageURL = () => {

        const selNHIDs = selNHID ? [selNHID] : nhIDs;

        const url = qrCodeImageURL(
            selNHIDs, 
            orgNHID, 
            qrCodeSize, 
            qrCodeImgFormat, 
            qrCodeExtraParams
        );

        setQrCodeImgURL(url);
    };

    const createQrCodePosterPdfURL = () => {

        const selNHIDs = selNHID ? [selNHID] : nhIDs;

        const url = qrCodePosterPdfURL(
            selNHIDs, 
            orgNHID, 
            qrCodePosterExtraParams
        );

        setQrCodePosterURL(url);
    };

    const updateQrImageSize = (val: string) => {

        const newSize = parseInt(val, 10);
        if (!isNaN(newSize)) {
            setQrCodeSize(newSize);
        }
    };

    const updateQrImageFormat = (val: 'SVG' | 'PNG') => {
        setQrCodeImgFormat(val);
    };

    const updateQrImageExtraParams = (val: string) => {
        setQrCodeExtraParams(val);
    };

    // const updateQrPosterExtraParams = (val: string) => {
    //     setQrCodePosterExtraParams(val);
    // };

    const handleSelect = (NHID: number | null) => {
        if (NHID) {
            const property = getSingleProperty(SessionType, NHID, Properties);        
            
            if (property) {
                setProperty(property);
                setSelNHID(NHID);
            }
        } else {
            setProperty(null);
            setSelNHID(null);
        }
    };

    const handleQRCodePosterDownload = async (url: string) => {

        if (qrCodePosterDownloading) {
            return;
        }
        
        try {
            setIsLoading(true);
            setQRCodePosterDownloading(true);
            const resp = await axios.get(url,{
                responseType: 'blob',
            });

            const data = _.get(resp, 'data');
            saveAs(data, qrcodePosterFilename());
        } catch(err) {
            console.log(err);
        } finally {
            setIsLoading(false);
            setQRCodePosterDownloading(false);
        }
    };

    const handleQRCodeDownload = async (url: string) => {
        await axios.get(url);
    };

    if (!(nhIDs || []).length && !orgNHID) {
        return null;
    }

    return (
        <section>
            <div className="container max-w-none 2xl:container md:mt-8 lg:mt-10">
                <h2 className="font-bold text-2xl md:text-3xl lg:text-4xl whitespace-nowrap block">
                    Generate a QR Code Poster
                </h2>
                <div className="mt-4 sm:mt-6 grid grid-cols-1 gap-3 lg:grid-cols-12 lg:gap-4 xl:gap-8">
                    <div className="lg:col-span-5 xl:col-span-4 ring-1 ring-brand_secondary p-3 sm:p-4 xl:p-6 rounded-md sm:rounded-lg text-md h-fit">
                        <strong className="text-md md:text-lg lg:text-xl">
                            Where do I use my QR Code Poster
                        </strong>
                        <p className="mt-5">
                            We would recommend displaying your QR Code Poster in your reception area(s) so visiting family & friends can easily access them to leave a review.
                        </p>
                        <div className="flex gap-3 items-center text-xs mt-5">
                            <img className="ring-1 ring-brand_grey w-28 h-28 rounded-md" src="/images/widget/qr.jpg" alt="QR" />
                            <p>
                                Please note we can also supply just the QR Code images if you require them to display on your own marketing material, please email <a href="mailto:reviews@nursinghomes.com" className="link">reviews@nursinghomes.com</a> detailing your requirement.
                            </p>
                        </div>
                    </div>
                    <div className="lg:col-span-7 xl:col-span-8 ring-1 ring-brand_grey p-3 sm:p-4 xl:p-6 rounded-md sm:rounded-lg h-fit">
                        { orgNHID || nhIDs?.length > 1 ?
                            <div>
                                <strong className="block mb-2">
                                    Select a facility:
                                </strong>
                                <PageCommunitySelect handleSelect={handleSelect} selNHID={selNHID}/>
                            </div> : null
                        }
                        { 
                            property ?
                                property.IsPremium
                                ?
                                    renderQRCodePosterLink(
                                        nhIDs,
                                        qrCodePosterURL,
                                        singleProperty, 
                                        SessionType, 
                                        Org,
                                        handleQRCodePosterDownload,
                                        qrCodePosterDownloading
                                    ) : 
                                    <div className="text-center mt-6">
                                        <strong className="block">
                                            QR Code Posters are only available for Premium Subscribers
                                        </strong>
                                        <PremiumLink websiteUrl={property.WebsiteURL} />
                                    </div>
                            : null
                        }
                        { 
                            showAdditional ? renderQRCodeLink(
                                nhIDs,
                                orgNHID,
                                qrCodeSize,
                                updateQrImageSize,
                                qrCodeImgFormat,
                                updateQrImageFormat,
                                qrCodeExtraParams,
                                updateQrImageExtraParams,
                                qrCodeImgURL,
                                handleQRCodeDownload
                            ) : null
                        }
                        { property ? null : renderCSVLink(nhIDs, orgNHID) }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default QRCodePoster;