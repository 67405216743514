import React from 'react';
import { FormRadioGroup } from '../../../../../../components/common/form_radio_group';
import { FormInput } from '../../../../../../components/common/form_input';
import { ProfileCompletenessTag } from '../../components/profile_completeness_tag';
import { yesNoOptions } from '../../../helpers';
import { SectionHeading } from '../../components/section_heading';

const RoomTypeTab = () => {
    const inline = true;

    return (
        <>
            <SectionHeading title="Room Type" />
            <div className="grid gap-3 lg:gap-5 2xl:gap-6 px-3 sm:px-4 2xl:px-5">
                <small>
                    <ul>
                        <li>Shared: 2+ residents share one-bedroom and a bathroom.</li>
                        <li>Private: One resident in a one-bedroom with a private bathroom.</li>
                        <li>Semi-Private: Residents have a private bedroom and share a bathroom with one other resident.</li>
                        <li>Private Suite: One resident in a one-bedroom with a private, full-sized bathroom with a shower</li>
                    </ul>
                    <p>Disclaimer: Shared, Private, and Semi-Private accommodations may not come with showers in their bathrooms</p>
                </small>
                <FormRadioGroup
                    groupLabel="Shared"
                    info={<ProfileCompletenessTag isVisible={true} />}
                    layout="horizontal"
                    name="RoomTypeShared"
                    options={yesNoOptions}
                    showResetButton={true}
                />
                <FormRadioGroup
                    groupLabel="Private Room"
                    info={<ProfileCompletenessTag isVisible={true} />}
                    layout="horizontal"
                    name="RoomTypePrivateRoom"
                    options={yesNoOptions}
                    showResetButton={true}
                />
                <FormRadioGroup
                    groupLabel="Semi Private"
                    info={<ProfileCompletenessTag isVisible={true} />}
                    layout="horizontal"
                    name="RoomTypeSemiPrivate"
                    options={yesNoOptions}
                    showResetButton={true}
                />
                <FormRadioGroup
                    groupLabel="Private Suite"
                    info={<ProfileCompletenessTag isVisible={true} />}
                    layout="horizontal"
                    name="RoomTypePrivateSuite"
                    options={yesNoOptions}
                    showResetButton={true}
                />
                <FormInput
                    info={<ProfileCompletenessTag isVisible={false} />}
                    layout="horizontal"
                    label="Any other room types?"
                    maxWidth="300px"
                    name="RoomTypeOther"
                />
            </div>
        </>
    );
};

export { RoomTypeTab };