import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlashForward } from '@fortawesome/pro-light-svg-icons';
import { faCreditCardFront } from '@fortawesome/pro-thin-svg-icons';
import { LoadingDots } from '../common/loading_dots';
import { getReviewScoreForNHID, getReviewStatisticTotals } from '../../api/review';
import { IReviewStatisticTotals } from '../../interfaces/review';
import { ISessionState } from '../../interfaces/session';
import { useSelector } from 'react-redux';
import { ReviewScoreWidget } from '../../components/widgets';
import { getSingleProperty, getCommunityMode } from '../../utils/common';
import { PropertyMode } from '../../interfaces/session';
import { IReviewScore } from '../../interfaces/review';
import { get } from 'lodash';
import ReviewScoreDisplayFormatted from '../common/review_score_display_formatted';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
    selNHID?: number | null;
    setIsLoading?: any;
    isLoading?: boolean;
    showPortletArray: string[];
}

export const ShowcaseReviewsWidget = ({ nhIDs, orgNHID, selNHID, setIsLoading, isLoading, showPortletArray }: IProps) => { 
    const [ stats, setStats ] = useState<IReviewStatisticTotals>(null);
    const [ reviewScore, setReviewScore ] = useState<number>();

    useEffect(() => {   
        if (!orgNHID && !nhIDs) {
            return;
        }

        const fetchData = async (): Promise<void> => {    
            try {
                const [data, rsData]: [IReviewStatisticTotals, any] = 
                    await Promise.all([
                        getReviewStatisticTotals(selNHID),
                        fetchReviewScore()
                    ]);

                const { ReviewScoreDisplay } = rsData || {};

                setStats(data);
                setReviewScore(ReviewScoreDisplay);
            } catch (e) {
                console.error(e);
            } finally {
                if (setIsLoading) {
                    setIsLoading(false);
                }
            }
        };

       fetchData();
    }, [ orgNHID, nhIDs, selNHID ]);
    
    const { Session, Properties }: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    if (!Session) {
        return null;
    }

    const { SessionType } = Session;
    const propertyMode = getCommunityMode(orgNHID, nhIDs, selNHID);
    const singleProperty = getSingleProperty(SessionType, selNHID, Properties);
    const nhID = singleProperty?.NHID;

    const fetchReviewScore = async (): Promise<IReviewScore> => {
        const firstProperty: any = get(Properties, '[0]');

        return propertyMode === PropertyMode.SingleProperty 
            ? getReviewScoreForNHID(selNHID || nhIDs[0]) 
            : firstProperty 
                ? getReviewScoreForNHID(firstProperty.OrgNHID) 
                : null;
    };
    
    const renderStat = (stat: number) => <>{ stat } <FontAwesomeIcon icon={faSlashForward} /> { Properties ? Properties.length : 'N/A' }</>;

    const renderPostcards = () => {
        let totalNoReviewCardsLast12 = 0;

        if (stats) {
            totalNoReviewCardsLast12 = stats.TotalNoReviewCardsLast12 || 0;
        }

        return (
            <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg flex flex-col" key={'postcards'}>
                <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 2xl:py-3 min-h-14 flex items-center justify-between gap-2">
                    <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                        Review Cards
                    </h4>
                </div>
                <div className="p-3 sm:p-4 2xl:p-5 h-full">            
                    <p>
                        { singleProperty ? 'Review Cards ordered in the last 12 months?'
                        : <>Number of Communtities that have <strong>NO</strong> review cards ordered in the last 12 months:</> }
                    </p>
                    <div className="my-3 sm:my-4 2xl:my-5 flex justify-center items-center gap-4">
                        <FontAwesomeIcon icon={faCreditCardFront} className="text-brand_grey-medium h-12" />
                        <strong className="text-6xl">
                            { singleProperty ? <>{ !totalNoReviewCardsLast12 ? 'Yes' : 'No' }</>
                            : <>{ renderStat(totalNoReviewCardsLast12) }</> }
                        </strong>
                    </div>
                    <p>
                        Hand out / display reviews cards in your facility to generate more reviews.
                    </p>
                </div>
                <div className="p-3 sm:p-4 2xl:p-5 !pt-0 mt-auto flex flex-wrap justify-center gap-3">
                    <Link 
                        to="/review-cards"
                        state={{ NHID: nhID }}
                        className="btn !w-full text-center max-w-52"
                    >
                        Order Cards
                    </Link>
                </div>
            </div>
        );
    };

    const renderPostcardsv2 = () => {
        let totalNoReviewCardsLast12 = 0;

        if (stats) {
            totalNoReviewCardsLast12 = stats.TotalNoReviewCardsLast12 || 0;
        }

        return (
            <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg flex flex-col" key={'postcards-v2'}>
                <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 2xl:py-3 min-h-14 flex items-center justify-between gap-2">
                    <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                        Review Cards
                    </h4>
                </div>
                <div className="p-3 sm:p-4 2xl:p-5 h-full">            
                    <p>
                        { singleProperty ? 'Review Cards ordered in the last 12 months?'
                        : <>Number of Communtities that have <strong>NO</strong> review cards ordered in the last 12 months:</> }
                    </p>
                    <div className="my-3 sm:my-4 2xl:my-5 flex justify-center items-center gap-4">
                        <FontAwesomeIcon icon={faCreditCardFront} className="text-brand_grey-medium h-12" />
                        <strong className="text-6xl">
                            { singleProperty ? <>{ !totalNoReviewCardsLast12 ? 'Yes' : 'No' }</>
                            : <>{ renderStat(totalNoReviewCardsLast12) }</> }
                        </strong>
                    </div>
                    <p>
                        Hand out / display reviews cards in your facility to generate more reviews.
                    </p>
                </div>
                <div className="p-3 sm:p-4 2xl:p-5 !pt-0 mt-auto flex flex-wrap justify-center gap-3">
                    <Link 
                        to="/review-cards"
                        state={{ NHID: nhID }}
                        className="btn !w-full text-center max-w-52"
                    >
                        Order Cards
                    </Link>
                </div>
            </div>
        );
    };

    const renderInviteToReview = () => {
        return (
            <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg flex flex-col" key={'invite-to-review'}>
                <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 2xl:py-3 min-h-14 flex items-center justify-between gap-2">
                    <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                        Invite to Review
                    </h4>
                </div>
                <div className="p-3 sm:p-4 2xl:p-5 h-full flex flex-col">            
                    <p>
                        Request Reviews with Invite to Review via:
                    </p>
                    <div className="mt-3 sm:mt-4 2xl:mt-5 h-full flex flex-col justify-center items-center gap-4 text-3xl [&_svg]:h-8 [&_svg]:inline-block [&_svg]:me-3 [&_svg]:relative [&_svg]:-top-px">
                        <div className="text-brand_grey-medium" title="Email">
                            <strong>
                                <svg className="fill-brand_grey-medium" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M64 112c-8.8 0-16 7.2-16 16l0 22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1l0-22.1c0-8.8-7.2-16-16-16L64 112zM48 212.2L48 384c0 8.8 7.2 16 16 16l384 0c8.8 0 16-7.2 16-16l0-171.8L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64l384 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128z"/></svg>Email
                            </strong>
                        </div>
                        <div className="text-[#3b5998]" title="Facebook">
                            <strong>
                                <svg className="fill-[#3b5998]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z"/></svg>Facebook
                            </strong>
                        </div>
                        <div className="text-[#128c7e]" title="Whatsapp">
                            <strong>
                                <svg className="fill-[#128c7e]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/></svg>WhatsApp
                            </strong>
                        </div>
                    </div>   
                </div>
                <div className="p-3 sm:p-4 2xl:p-5 !pt-0 mt-auto flex flex-wrap justify-center gap-3">
                    <Link 
                        to="/invite-to-review"
                        state={{ NHID: nhID }}
                        className="btn !w-full text-center max-w-52"
                    >
                        Send Review Invites
                    </Link>
                </div>
            </div>
        );
    };

    const renderReviewScoreCertificate = () => {
        return (
            <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg flex flex-col" key={'review-score-cert'}>
                <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 2xl:py-3 min-h-14 flex items-center justify-between gap-2">
                    <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                        Review Score Certificates
                    </h4>
                </div>
                <div className="p-3 sm:p-4 2xl:p-5 h-full flex flex-col">            
                    <p>
                        Download a Review Score Certificate (includes a &apos;Review us&apos; QR Code) to display in your { singleProperty ? 'Community' : 'Communities' }.
                    </p>
                    <div className="mt-3 sm:mt-4 2xl:mt-5 flex justify-center h-full items-center [&>div]:text-9xl [&>div]:px-9 [&>div]:py-5 [&>div]:rounded-xl">
                        <ReviewScoreDisplayFormatted 
                            value={reviewScore}
                            showAsNotApplicableOnNull={true}
                        />
                    </div>
                </div>
                <div className="p-3 sm:p-4 2xl:p-5 !pt-0 mt-auto flex flex-wrap justify-center gap-3">
                    <NavLink 
                        to='/review-score-certificate'
                        state={{ NHID: nhID }}
                        className="btn !w-full text-center max-w-52"
                    >
                        Download
                    </NavLink>
                </div>
            </div>
        );
    };

    const renderQRCodePosters = () => {
        return (
            <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg flex flex-col" key={'qrcode-posters'}>
                <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 2xl:py-3 min-h-14 flex items-center justify-between gap-2">
                    <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                        'Review us' QR Code Posters
                    </h4>
                </div>
                <div className="p-3 sm:p-4 2xl:p-5 h-full flex flex-col">            
                    <p>
                        Download a 'Review us' QR Code Poster to display in your public areas and reception areas(s).
                    </p>
                    <div className="mt-3 sm:mt-4 2xl:mt-5 flex justify-center h-full items-center">
                        <img src="/images/widget/qr.jpg" className="ring-1 ring-brand_grey w-28 h-28 rounded-md" alt="QR" />
                    </div>
                </div>
                <div className="p-3 sm:p-4 2xl:p-5 !pt-0 mt-auto flex flex-wrap justify-center gap-3">
                    <Link 
                        to="/qr-code-posters"
                        state={{ NHID: nhID }}
                        className="btn !w-full text-center max-w-52"
                    >     
                        Download
                    </Link>
                </div>
            </div>
        );
    };

    const renderYourReviewScore = () => {
        return (
            <ReviewScoreWidget
                key={'review-score-widget'}
                nhIDs={nhIDs}
                selNHID={selNHID}
                propertyMode={propertyMode}
                widgetSize={'small'}
            />
        );
    };


    const renderPortlets = () => {

        const portletFnArray = [];

        if (showPortletArray.includes('invite-to-review')) {
            portletFnArray.push(renderInviteToReview);
        }
        if (showPortletArray.includes('postcards')) {
            portletFnArray.push(renderPostcards);
        }
        if (showPortletArray.includes('postcards-v2')) {
            portletFnArray.push(renderPostcardsv2);
        }
        if (showPortletArray.includes('qr-code-posters')) {
            portletFnArray.push(renderQRCodePosters);
        }
        if (showPortletArray.includes('review-score')) {
            portletFnArray.push(renderYourReviewScore);
        }
        if (showPortletArray.includes('review-score-certificate')) {
            portletFnArray.push(renderReviewScoreCertificate);
        }

        return portletFnArray.map((portletFn: any) => portletFn());
    };

    const renderWidget = () => {
        return (
            <>
                { renderPortlets() } 
            </>
        );    
    };

    const renderLoading = () => {
        return <LoadingDots showInPlace={true} show={true}/>;
    };

    return (
        <>
            { isLoading ? renderLoading() : renderWidget() }
        </>
    );
};
