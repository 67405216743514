import React from 'react';
import { IFiltersFormProps } from '../types';
import { RangeFilterType } from '../filter_types/range';
import { FilterType } from '../../../../interfaces/filters';

export const FiltersForm = ({
    filterMatrix,
    applyFilter,
}: IFiltersFormProps) => {

    const filterInputs = filterMatrix.filters.map(filterItem => {
        
        if (filterItem.Hidden) {
            return null;
        }
        
        switch (filterItem.Type) {
            case FilterType.Range:
                return <RangeFilterType 
                    filterMatrix={filterMatrix}
                    filterKey={filterItem.Key} 
                    apply={applyFilter}
                />;
        
            // to do - add a default render component per type
            // not sure if we should allow a render component override?

            default:
                return null;
        }
    }).filter(Boolean);

    return <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
        {filterInputs}
    </div>;
};
