import React, { FC, useState } from 'react';
import { LoadingDots } from '../../../../../../../components/common/loading_dots';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';

interface Props {
    onClearImage: () => void;
    src: string;
    isLoading: boolean;
}

const SelectedPhotoPreview: FC<Props> = ({ onClearImage, src, isLoading }) => {
    const [mouseIsOverImage, setMouseIsOverImage] = useState(false);
    const showClearImageButton = mouseIsOverImage && !isLoading;

    const clearImageButton = (
        <button
            onClick={onClearImage}
            className="absolute -right-2 -top-2 rounded-full bg-brand_status-warning cursor-pointer h-9 w-9 z-10 text-white ring-2 ring-white flex items-center justify-center"
        >
            <FontAwesomeIcon icon={faXmark} className="text-xl" />
        </button>
    );

    return (
        <div
            className="relative h-40 w-40"
            onMouseEnter={() => setMouseIsOverImage(true)}
            onMouseLeave={() => setMouseIsOverImage(false)}
        >
            {showClearImageButton && clearImageButton}
            <div className="relative rounded-md overflow-hidden h-40 w-40 [&>em]:top-1/2 [&>em]:-translate-y-1/2">
                { isLoading ? 
                    <LoadingDots  /> : 
                    <img
                        src={src}
                        className="object-cover block w-40 h-40"
                    />
                }
            </div>
        </div>
    );
};

export { SelectedPhotoPreview };
