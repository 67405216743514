import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { EmailShareButton, FacebookShareButton, WhatsappShareButton } from 'react-share';
import { LoadingDots } from '../../components/common/loading_dots';
import PageCommunitySelect from '../../components/common/page_community_select';
import { MEDIA_CREATOR_BASE_URL } from '../../config/keys';
import { ISessionState } from '../../interfaces/session';
import { getSingleProperty } from '../../utils/common';
import * as reviewAPI from '../../api/review';
import PremiumLink from '../../components/common/premium_sign_up_link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/pro-solid-svg-icons';
import { Button } from '../../components/common/button';
import { MomentaryTextSwap } from '../../components/common/momentary_text_swap';



interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

const defaultInviteLinkIDs = {
    inviteToReviewViaClientLinkID: 0,
    inviteToReviewViaClientEmailID: 0,
    inviteToReviewViaClientFacebookID: 0,
    inviteToReviewViaClientWhatsAppID: 0
};

const InviteToReview = ({nhIDs, orgNHID}: IProps) => {

    const [busyLoadingProperty, setBusyLoadingProperty] = useState<boolean>(false);
    const [property, setProperty] = useState<any>();
    const [selectedNHID, setSelectedNHID] = useState<number>(null);

    const [inviteLinkIDs, setInviteLinkIDs] = useState(defaultInviteLinkIDs);

    const { Session, Properties }: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const location = useLocation();
    const stateNHID = location?.state?.NHID || null;

    useEffect(() => {
        (async () => {
            const resp: any = await reviewAPI.getCollectionSources();
            const arr = resp?.data || [];

            const inviteToReviewViaClientLink = arr.find((item: any) => item.KeyName === 'invite-to-review-via-client-link');
            const inviteToReviewViaClientEmail = arr.find((item: any) => item.KeyName === 'invite-to-review-via-client-email');
            const inviteToReviewViaClientFacebook = arr.find((item: any) => item.KeyName === 'invite-to-review-via-client-facebook');
            const inviteToReviewViaClientWhatsApp = arr.find((item: any) => item.KeyName === 'invite-to-review-via-client-whatsapp');

            const inviteLinkIDs = {
                inviteToReviewViaClientLinkID: inviteToReviewViaClientLink?.CollectionSourceID || 0,
                inviteToReviewViaClientEmailID: inviteToReviewViaClientEmail?.CollectionSourceID || 0,
                inviteToReviewViaClientFacebookID: inviteToReviewViaClientFacebook?.CollectionSourceID || 0,
                inviteToReviewViaClientWhatsAppID: inviteToReviewViaClientWhatsApp?.CollectionSourceID || 0,
            };

            setInviteLinkIDs(inviteLinkIDs);
        })();
        
    }, []);

    useEffect(() => {

        setProperty(null);

        if (orgNHID && !stateNHID) {
            return;
        }

        const NHID = nhIDs?.length === 1 ? nhIDs[0] : stateNHID;

        if (NHID) {   
            setSelectedNHID(NHID);
            setSingleNHID();
        }
    }, [orgNHID, nhIDs]);

    useEffect(() => {
        setSingleNHID();
    }, [selectedNHID]);

    const setSingleNHID = () => {
        if (!selectedNHID) {
            return;
        }

        (async () => {
            setBusyLoadingProperty(true);
            try {

                const { SessionType } = Session;
                const property = getSingleProperty(SessionType, selectedNHID, Properties);

                if (property) {
                    setProperty(property);
                }
            } catch (e) {
                console.error(e);
            } finally {
                setBusyLoadingProperty(false);
            }
        })();     
    };

    const emailMessage = (): string => {
        return `Leave a review for ${property.Name} on www.nursinghomes.com`;
    };

    const facebookMessage = (): string => {
        return `Submit a review for ${property.Name}`;
    };

    const whatsappMessage = (): string => {
        return `${property.Name} would love your feedback. Please click the link to leave us a review`;
    };

    const onPropertySelection = (NHID: number | null) => {
        if (!NHID) {
            setProperty(null);
            setSelectedNHID(null);
            return;
        }
        setSelectedNHID(NHID);
    };

    const pageBody = (): JSX.Element => {

        const reviewSubmissionURL = property?.ReviewSubmissionURL || '';

        const generalReviewSubmissionUrl = `${reviewSubmissionURL}/sourceid/${inviteLinkIDs.inviteToReviewViaClientLinkID}`;
        const emailReviewSubmissionUrl = `${reviewSubmissionURL}/sourceid/${inviteLinkIDs.inviteToReviewViaClientEmailID}`;
        const facebookReviewSubmissionUrl = `${reviewSubmissionURL}/sourceid/${inviteLinkIDs.inviteToReviewViaClientFacebookID}`;
        const whatsappReviewSubmissionUrl = `${reviewSubmissionURL}/sourceid/${inviteLinkIDs.inviteToReviewViaClientWhatsAppID}`;

        return (
            <>
                {
                    orgNHID || nhIDs?.length > 1 ?
                        <div>
                            <strong className="block mb-2">
                                Select a facility for review:
                            </strong>
                            <PageCommunitySelect handleSelect={onPropertySelection} selNHID={selectedNHID}/>
                        </div>
                        : null
                }
                {
                    busyLoadingProperty ?
                        <LoadingDots show={true}/>
                        :
                        property ?
                            property.IsPremium ?
                                <div className="mt-6">
                                    <p>
                                        This link goes directly to your facility's review form on nursinghomes.com
                                    </p>
                                    <div className="mt-6">
                                        <p>
                                            <strong>Either</strong> copy the link:
                                        </p>
                                        <div className="mt-3 flex items-center">
                                            <div className="form-component form-textbox w-full">
                                                <input
                                                    className="!rounded-e-none h-10"
                                                    type="text"
                                                    disabled={true}
                                                    value={generalReviewSubmissionUrl}
                                                />
                                            </div>
                                            <Button
                                                className="!rounded-s-none rounded-e-md lg:rounded-e-lg"
                                                type="button"
                                                onClick={() => navigator.clipboard.writeText(generalReviewSubmissionUrl)}
                                            >
                                                <MomentaryTextSwap originalText='Copy' swapText='Copied!'/>
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="mt-6">
                                        <p>
                                            <strong>Or</strong> share the link via:
                                        </p>
                                        <div className="mt-3 flex flex-wrap gap-2 sm:flex-row sm:items-center [&_svg]:h-6 [&_svg]:inline-block [&_svg]:me-2 [&_svg]:relative [&_svg]:-top-px">
                                            <EmailShareButton
                                                url={emailReviewSubmissionUrl}
                                                title="Email"
                                                subject={emailMessage()}
                                            >
                                                <a href="#" className="btn btn-outline w-40 xl:w-48 !ring-brand_grey-medium !text-brand_grey-dark hover:!bg-brand_grey-light" title="Email">
                                                    <svg className="fill-brand_grey-dark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M64 112c-8.8 0-16 7.2-16 16l0 22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1l0-22.1c0-8.8-7.2-16-16-16L64 112zM48 212.2L48 384c0 8.8 7.2 16 16 16l384 0c8.8 0 16-7.2 16-16l0-171.8L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64l384 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128z"/></svg>Email
                                                </a>
                                            </EmailShareButton>
                                            <FacebookShareButton
                                                url={facebookReviewSubmissionUrl}
                                                title={facebookMessage()}
                                            >
                                                <a href="#" className="btn w-40 xl:w-48 !bg-[#3b5998] hover:!bg-[#30497c]" title="Facebook">
                                                    <svg className="fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z"/></svg>Facebook
                                                </a>
                                            </FacebookShareButton>
                                            <WhatsappShareButton
                                                url={whatsappReviewSubmissionUrl}
                                                title={whatsappMessage()}
                                            >
                                                <a className="btn w-40 xl:w-48 !bg-[#128c7e] hover:!bg-[#075e54]" title="Whatsapp">
                                                    <svg className="fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/></svg>WhatsApp
                                                </a>
                                            </WhatsappShareButton>
                                        </div>
                                    </div>
                                </div>
                                : 
                                <div className="text-center mt-6">
                                    <strong className="block">
                                        Invite to Review is only available for Premium Subscribers
                                    </strong>
                                    <PremiumLink websiteUrl={property.WebsiteURL} />
                                </div>
                            : null
                }
            </>
        );
    };

    return (
        <section>
            <div className="container max-w-none 2xl:container md:mt-8 lg:mt-10">
                <h2 className="font-bold text-2xl md:text-3xl lg:text-4xl whitespace-nowrap block">
                    Request Reviews
                </h2>
                <div className="mt-4 sm:mt-6 grid grid-cols-1 gap-3 lg:grid-cols-12 lg:gap-4 xl:gap-8">
                    <div className="lg:col-span-5 xl:col-span-4 ring-1 ring-brand_secondary p-3 sm:p-4 xl:p-6 rounded-md sm:rounded-lg text-md h-fit">
                        <strong className="text-md md:text-lg lg:text-xl">
                            How to Request a Review
                        </strong>
                        <ol className="[counter-reset:els] mt-6 ps-10 space-y-6 [&>li]:relative [&>li]:before:[counter-increment:els] [&>li]:before:content-[counter(els)] [&>li]:before:rounded-full [&>li]:before:h-8 [&>li]:before:w-8 [&>li]:before:bg-brand_primary [&>li]:before:inline-block [&>li]:before:text-white [&>li]:before:text-center [&>li]:before:leading-8 [&>li]:before:-left-10 [&>li]:before:-top-1.5 [&>li]:before:absolute">
                            {
                                orgNHID || nhIDs?.length > 1 ?
                                <li>
                                    Select which facility you want to be reviewed
                                </li> : null
                            }
                            <li>
                                <div>
                                    { orgNHID || nhIDs?.length > 1 ? 'Then' : '' } <strong>Either</strong>
                                    <ul className="marker:text-brand_grey-medium list-disc ps-5 space-y-2 text-sm mt-3">
                                        <li>
                                            Copy and paste the invite link into a new email on your email client (e.g. Outlook or Gmail)
                                        </li>
                                        <li>
                                            Add some personal text above the link in the email, asking them to click the link to leave a review on nursinghomes.com
                                        </li>
                                    </ul>
                                </div>
                                <div className="mt-2">
                                    <strong>
                                        Or
                                    </strong>
                                    <ul className="marker:text-brand_grey-medium list-disc ps-5 space-y-2 text-sm mt-3">
                                        <li>
                                            Click Email, Facebook or WhatsApp 
                                        </li>
                                        <li>
                                            Add some personal text asking them to click the link to leave a review on nursinghomes.com
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ol>
                    </div>
                    <div className="lg:col-span-7 xl:col-span-8 ring-1 ring-brand_grey p-3 sm:p-4 xl:p-6 rounded-md sm:rounded-lg h-fit">
                        {pageBody()}
                        {
                            !property && (orgNHID || nhIDs?.length > 1) ?
                                <a className="block mt-6 link" href={`${MEDIA_CREATOR_BASE_URL}/csvexport?${orgNHID ? `OrgNHID=${orgNHID}` : `NHID=${nhIDs.join()}`}`}>
                                    <FontAwesomeIcon icon={faFile} className="text-3xl me-2 relative top-0.5" />For multiple facilities please download a CSV of the facilities and their invite links
                                </a> 
                                : null
                        }  
                    </div>
                </div>
            </div>
        </section>
    );
};

export default (InviteToReview);
