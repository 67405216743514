import React, { FC, useCallback, useMemo } from 'react';
import { Button } from '../../../../components/common/button';
import { useQueries } from '@tanstack/react-query';
import { useState } from 'react';
import {
    getPropertyServiceCustomExtras,
    getPropertyServiceExtras,
    getPropertyServices
} from '../../../../api/service';
import { ServicesAndAmenitiesModal } from './services_and_amenities_modal';
import { getLastUpdatedTextFromQueryResults, prepareTabs } from './helpers';
import { IServiceTotals } from '../../../../interfaces/service';
import { ProfileCompletenessFilter } from './components/profile_completeness_filter';

interface IProps {
    row: IServiceTotals;
}

const ModalQueryWrapper: FC<IProps> = ({ row }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggleModal = (): void => setIsModalOpen((isOpen: boolean) => !isOpen);
    const [
        onlyShowProfileCompletenessFields,
        setOnlyShowProfileCompletenessFields
    ] = useState(null);

    const [extras, services, customExtras] = useQueries({
        queries: [
            {
                queryKey: ['portal', 'service', 'extras', row.NHID],
                queryFn: () => getPropertyServiceExtras(row.NHID),
                enabled: isModalOpen
            },
            {
                queryKey: [
                    'portal',
                    'property',
                    'profilecompleteness',
                    row.NHID
                ],
                queryFn: () => getPropertyServices(row.NHID),
                enabled: isModalOpen
            },
            {
                queryKey: ['portal', 'service', 'extras', 'custom', row.NHID],
                queryFn: () => getPropertyServiceCustomExtras(row.NHID),
                enabled: isModalOpen
            }
        ]
    });

    const { tabConfig, defaultValues } = useMemo(
        () =>
            prepareTabs(services, extras, customExtras, {
                onlyShowProfileCompletenessFields
            }),
        [services, extras, customExtras, onlyShowProfileCompletenessFields]
    );

    const lastUpdatedText = getLastUpdatedTextFromQueryResults(services, extras, customExtras);

    const handleChange = useCallback(
        (filterProfileCompletnessFields: boolean | null) => {
            setOnlyShowProfileCompletenessFields(
                filterProfileCompletnessFields
            );
        },
        []
    );

    return (
        <>
            <Button onClick={toggleModal} className="ms-auto">
                Edit
            </Button>
            <ServicesAndAmenitiesModal
                defaultFormValues={defaultValues}
                isLoading={services.isLoading}
                isOpen={isModalOpen}
                onClose={toggleModal}
                lastUpdated={lastUpdatedText}
                row={row}
                headerActions={
                     !services.isLoading && <div className="my-3 sm:my-4 2xl:my-5 flex items-center justify-center gap-3">
                        <ProfileCompletenessFilter onChange={handleChange} value={onlyShowProfileCompletenessFields} />
                    </div>
                }
                tabConfig={tabConfig}
            />
        </>
    );
};

export { ModalQueryWrapper };
