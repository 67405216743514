import React from 'react';
import { useSelector } from 'react-redux';
import { ISessionState } from '../../interfaces/session';
import { LoadingDots } from '../common/loading_dots';
import DisplayAsset from '../../components/assets/display_asset';
import { 
    NHAssetType,
    IPortalPropertyPhotoAsset,
    IPortalPropertyPhotoObj,
    IPortalPropertyTagObj
} from '../../interfaces/asset';
import cx from 'classnames';
import ProfileCompletenessText from '../../components/profile_completeness/profile_completeness_text';
import { ProfileScoreType } from '../../interfaces/property';
import { TagKeyNameIcon } from '../tag_keyname_icon';
import { Button } from '../common/button';

interface tagFilter {
    NHID: number;
    TagID: number
}

interface IProps {
    handleSelectedNHID: (NHID: number | null) => void;
    propertyPhotos: IPortalPropertyPhotoAsset[];
    handleMultiPropertiesTagFilter?:(multiPropertiesTagFilter: tagFilter[]) => void;
    multiPropertiesTagFilterValue: tagFilter[];
    busyLoadingPhotos: boolean;
    setShowPhotoModal: (showPhotoModal: boolean) => void;
}

const MultiOrgPropertyPhotos = ({
    handleSelectedNHID,
    propertyPhotos,
    handleMultiPropertiesTagFilter,
    multiPropertiesTagFilterValue,
    busyLoadingPhotos,
    setShowPhotoModal
}: IProps) => {
    
    const sessionState: ISessionState = useSelector(
        ({SessionState}: any) => SessionState
    );

    const handleTagFilter = (selNHID: number, tagID: number) => {
        let multiPropertiesTagFilter: tagFilter[] = [];
        if(tagID && !multiPropertiesTagFilterValue?.find(el => el.NHID === selNHID)) {
            multiPropertiesTagFilterValue.forEach(e => multiPropertiesTagFilter.push(e));
            multiPropertiesTagFilter.push({
                NHID: selNHID,
                TagID: tagID
            });
        } else {
            multiPropertiesTagFilter = multiPropertiesTagFilterValue.filter( (propertyTagFilter:tagFilter) => {
                if( propertyTagFilter.NHID === selNHID && tagID) {
                    propertyTagFilter.NHID = selNHID;
                    propertyTagFilter.TagID = tagID;
                    return {
                        propertyTagFilter
                    };
                } else if( propertyTagFilter.NHID === selNHID && !tagID) {
                    return null;
                } else {
                    return propertyTagFilter;
                }
            });
        }
        handleMultiPropertiesTagFilter(multiPropertiesTagFilter);
    };

    const renderMultiPropertiesPhotos = () => {
        return (
            <div className="grid gap-3 sm:gap-5 md:gap-6 2xl:gap-7">
                
                { propertyPhotos?.map((property) => { 
                    const { WebsiteURL, StateSlug, CitySlug, Slug } = property.Property;

                    return (
                        <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg flex flex-col" key={property.Property.Name}>
                            <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-3 min-h-14 flex flex-col items-start xl:items-center xl:justify-between gap-2 xl:flex-row h-full">
                                <div className="flex flex-wrap gap-y-1 items-center gap-x-2 w-full [&>em]:w-full">
                                    <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                                        {property.Property.Name}
                                    </h4>
                                    <span className="inline-block text-xs lg:text-sm leading-tight text-brand_grey-medium">
                                        {property.Property.Address}
                                    </span>
                                    <a
                                        className="link text-xs bg-white px-2 py-1 inline-block leading-none rounded-lg"
                                        href={`${WebsiteURL}/${StateSlug}/${CitySlug}/${Slug}`}
                                        target="_blank" 
                                    >
                                        view on website
                                    </a>  
                                    <ProfileCompletenessText
                                        NHID={property.Property.NHID}
                                        profileScoreType={ProfileScoreType.Photo}
                                    />
                                </div>
                                <div className="flex flex-wrap gap-2 xl:ms-auto xl:flex-row xl:flex-nowrap">
                                    <Button 
                                        key={`show-property-photos-upload-form-${property.Property.NHID}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleSelectedNHID(property.Property.NHID);
                                            setShowPhotoModal(true);
                                            window.scrollTo({ top: 0, behavior: 'auto' });
                                        }}
                                    >
                                        Add Photos
                                    </Button>
                                    { property.Photo.length ?
                                        <Button 
                                            key={`show-property-photos${property.Property.NHID}`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleSelectedNHID(property.Property.NHID);
                                                window.scrollTo({ top: 0, behavior: 'auto' });
                                            }}
                                        >
                                            Update Photos
                                        </Button> : null
                                    }
                                </div>
                            </div>
                            <div>
                                { property.Photo.length ?
                                    <>
                                        <ul className="p-3 sm:p-4 2xl:p-5 flex flex-wrap gap-2.5 [&>li]:inline-flex [&>li]:gap-1.5 [&>li]:items-center [&>li]:cursor-pointer [&>li]:bg-brand_faint-blue [&>li.selected]:ring-0 [&>li.selected]:bg-brand_secondary [&>li.selected]:text-white [&>li]:ring-1 [&>li]:ring-brand_grey [&>li]:h-8 [&>li]:leading-8 [&>li]:rounded-full [&>li]:text-xs [&>li]:px-2.5 [&>li>span]:bg-brand_secondary [&>li>span]:text-white [&>li.selected>span]:bg-white [&>li.selected>span]:text-brand_secondary [&>li>span]:leading-none [&>li>span]:text-2xs [&>li>span]:rounded-md [&>li>span]:py-1 [&>li>span]:p-1.5">
                                            <li
                                                key={`show-property-photos-${property.Property.NHID}-tag-all`}
                                                className={
                                                    cx({selected: multiPropertiesTagFilterValue.length === 0 ||!multiPropertiesTagFilterValue.find(el => el.NHID === property.Property.NHID)})
                                                }                                                       
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleTagFilter(property.Property.NHID, null);
                                                }}
                                            >
                                                All Photos <span>{property.Photo.length}</span>
                                            </li>
                                            {
                                                property.Tag.map((tag:IPortalPropertyTagObj) => {
                                                    return (
                                                        <li 
                                                            key={`show-property-photos-${property.Property.NHID}-tag-${tag.TagID}`}
                                                            className={cx({selected: multiPropertiesTagFilterValue.length > 0 && multiPropertiesTagFilterValue.some(el => el.NHID === property.Property.NHID && el.TagID === tag.TagID),  red: !tag.IsTagPublishWebsite })
                                                            } 
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                handleTagFilter(property.Property.NHID, tag.TagID);
                                                            }}
                                                        >
                                                            <TagKeyNameIcon tagKeyName={tag.TagKeyName} className="text-lg" />{tag.TagName} <span>{tag.Photo.length}</span>
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                        <ul className="px-3 sm:px-4 2xl:px-5 pb-3 sm:pb-4 2xl:pb-5 gap-3 flex overflow-x-auto scrollbar scrollbar-full scrollbar-breadcrumbs [&>li]:bg-white [&>li]:relative [&>li>span]:absolute [&>li>span]:bottom-3 [&>li>span]:right-3 [&>li>span]:h-8 [&>li>span]:leading-8 [&>li>span]:px-3 [&>li>span]:bg-brand_secondary [&>li>span]:whitespace-nowrap [&>li>span]:text-2xs [&>li>span]:text-white [&>li>span]:rounded-full [&>li>img]:object-cover [&>li>img]:rounded-md [&>li>img]:min-w-72 [&>li>img]:max-w-72 [&>li>img]:h-44">
                                            {
                                                multiPropertiesTagFilterValue.length === 0 ||  !multiPropertiesTagFilterValue.find(el => el.NHID === property.Property.NHID) ?
                                                property.Photo.map((photo:IPortalPropertyPhotoObj) => {
                                                    return (
                                                        <li key={`${photo.TagName}-${photo.AssetID}`}>
                                                            <span>
                                                                {photo.TagName}
                                                            </span>
                                                            <DisplayAsset
                                                                TransformedAssetUrl={photo.TransformedAssetUrl}
                                                                FileName={photo.FileName}
                                                                AssetType={NHAssetType.Photos} 
                                                            />
                                                        </li>
                                                    );
                                                }) : multiPropertiesTagFilterValue.find(el => el.NHID === property.Property.NHID ) ?
                                                property.Photo.map((photo:IPortalPropertyPhotoObj) => {
                                                    return (
                                                        multiPropertiesTagFilterValue.find(el => el.NHID === property.Property.NHID && el.TagID === photo.TagID ) ?
                                                            <li key={photo.AssetID}>
                                                                <span>
                                                                    {photo.TagName}
                                                                </span>
                                                                <DisplayAsset
                                                                    TransformedAssetUrl={photo.TransformedAssetUrl}
                                                                    FileName={photo.FileName}
                                                                    AssetType={NHAssetType.Photos} 
                                                                />
                                                            </li> : null
                                                    );
                                                }) : null
                                            }
                                        </ul>
                                    </> :
                                    <span className="msg msg-warning">
                                        No photo uploaded
                                    </span>
                                }
                            </div>
                        </div>
                    );
                    })
                }
            </div>
        );
    };

    return sessionState.Org || (sessionState.Properties || []).length > 1 ? 
    <>
        <LoadingDots show={busyLoadingPhotos} /> 
        { renderMultiPropertiesPhotos() }
    </> : null;
};

export default MultiOrgPropertyPhotos;