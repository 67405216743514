import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import 'react-toastify/dist/ReactToastify.minimal.css';

export enum ToastType {
    Success,
    Error,
    Info,
    Warning
}
const defaultOnClose = () => { 
    console.log('On close');
};

export const showToast = (message: string, type: ToastType = ToastType.Success, onClose = defaultOnClose) => {
    switch (type) {
        case ToastType.Success :
            toast.success(message, { onClose });
            break;
        case ToastType.Error :
            toast.error(message);
            break;
        case ToastType.Warning :
            toast.warn(message);
            break;
        case ToastType.Info :
            toast.info(message);
            break;
        default :
            toast.success(message);
            break;
    }
};

export const Toast = () => {
    return (
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            //draggable
            pauseOnHover
        />
    );
};
