import React, { FC, useState } from 'react';
import {
    IAssetPayLoad,
    ICloudinaryData,
    NHAssetType
} from '../../../../../../../interfaces/asset';
import { useFormContext } from 'react-hook-form';
import { MeetTheTeamMemberType } from '../../../../types';
import { useSessionState } from '../../../../../../../hooks/use_session_state';
import { FormInput } from '../../../../../../../components/common/form_input';
import UploadWidget from '../../../../../../../components/cloudinary/upload_widget';
import { createAsset } from '../../../../../../../api/assets';
import { SelectedPhotoPreview } from './selected_photo_preview';
import cx from 'classnames';
interface Props {
    inlineLabelWidth?: 'small' | 'large';
}
const PhotoUpload: FC<Props> = ({  inlineLabelWidth = 'small' }) => {
    const inlineLabelWidths = {
        small: 150,
        large: 250
    };
    const [isLoading, setIsLoading] = useState(false);
    const { watch, setValue } = useFormContext<MeetTheTeamMemberType>();

    const { UserID: userId } = useSessionState().Session;

    const selectedFacilityId = watch('propertyId');
    const currentImage = watch('photoUrl');

    const handleAssetUpload = async (
        cloudinaryData: ICloudinaryData
    ): Promise<void> => {
        setIsLoading(true);
        const assetPayLoad: IAssetPayLoad = {
            NHID: selectedFacilityId,
            AssetType: NHAssetType.MeetTeamPhotos,
            ...cloudinaryData
        };
        try {
            const assetId = await createAsset(assetPayLoad);
            setValue('profileImageAssetId', assetId);
            setValue('photoUrl', assetPayLoad.TransformedAssetUrl);
        } catch (err) {
            /* do nothing */
        }
        setIsLoading(false);
    };

    const onClearImage = () => {
        setValue('profileImageAssetId', null);
        setValue('photoUrl', null);
    };

    const showUploadImageButton = !(currentImage || isLoading);

    return (
        <div className="form-component">
            <label>
                Photo Upload
            </label>
            <div className="mt-1">
                <FormInput name="profileImageAssetId" type="hidden" />
                {showUploadImageButton ? 
                    <UploadWidget
                        nHID={selectedFacilityId}
                        fileName={`${userId}-${Date.now()}`}
                        assetTypeName="Select Photos"
                        assetType={NHAssetType.MeetTeamPhotos}
                        onAssetUpload={handleAssetUpload}
                        showAssetUploadResult={() => {
                            /* nothing to do */
                        }}
                    /> :
                    <SelectedPhotoPreview
                        src={currentImage}
                        isLoading={isLoading}
                        onClearImage={onClearImage}
                    />
                }                
            </div>
        </div>
    );
};

export { PhotoUpload };
