import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { ISessionState } from '../../interfaces/session';
import { LoadingDots } from '../common/loading_dots';
import AppContext from '../../context/app_context';
import { IPortalPropertyAsset } from '../../interfaces/asset';
import { 
    mapProfileExtraDatatoDisplay,
    getFacilityCountFromProfileData
} from '../../utils/profile_completeness';

import { 
    INhPortalScoreItem,
    IScoreExtraDisplayItem
} from '../../interfaces/property';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';
import DataTable from '../../components/common/data_table';
import BrochureUploadModal from './brochure_upload_modal';
import { Button } from '../common/button';

interface IProps {
    handleSelNHID: (NHID: number | null) => void;
    orgNHID: number | null;
    nhIDs: number[];
    selNHID: number | null;
    propertyBrochures: IPortalPropertyAsset[];
    setPropertyBrochures: any;
    busyLoadingAsset: boolean;
    setBusyLoadingAsset: any;
    handleTableSortChange: any
    handleReloadPropertyBrochures: any;
}

const MultiOrgPropertyBrochures = ({
    handleSelNHID,
    orgNHID,
    nhIDs,
    selNHID,
    propertyBrochures,
    setPropertyBrochures,
    busyLoadingAsset,
    setBusyLoadingAsset,
    handleTableSortChange,
    handleReloadPropertyBrochures
}: IProps) => {

    const [ showBrochureModal, setShowBrochureModal ] = useState<boolean>(false);
    const [ updateBrochureNHID, setUpdateBrochureNHID ] = useState<number>(null);
    const [ individualPropertyBrochures, setIndividualPropertyBrochures] = useState<any[]>([]);
    
    const sessionState: ISessionState = useSelector(
        ({SessionState}: any) => SessionState
    );

    const profileCompletenessState: INhPortalScoreItem = useSelector(
        ({ ProfileCompletenessState }: any) => ProfileCompletenessState
    );


    const property = (propertyBrochures || []).length > 0  ? propertyBrochures[0] : null;
    const asset = property && (property.Asset || []).length > 0  ? property.Asset[0] : null;

    const isOrgBrochureExists: boolean = property?.NHID === orgNHID && asset?.AssetID > 0 ? true : false;

    const columns = [
        {
            id: 'NHID',
            name: 'Facility Name',
            cell: (row: IPortalPropertyAsset) => formatName(row),
            width: '*'
        },
        {
            name: <>Profile Complete <br/>for PDF Brochure</>,
            sortable: true,
            sortField: 'ProfileComplete',
            cell: (row: any) => formatProfileComplete(row),
            width: '*'
        },
        {
            name: 'PDF Brochure showing on website',
            cell: (row: IPortalPropertyAsset) => displayBrochure(row),
            width: '*'
        },
        {
            name: '',
            cell: (row: IPortalPropertyAsset) => updateBrochure(row),
            width: '*'
        }
    ];

    const formatProfileComplete = (row: any) => {
        const { Property } = row;
        const isOrg = Property.NHID === orgNHID;
        if (isOrg) {
            return null;
        }
        return (
            <div className="mx-auto">
                <FontAwesomeIcon 
                    icon={row.ProfileComplete ? faCheck : faXmark} 
                    className={`h-6 w-6 ${row.ProfileComplete ? 'text-brand_status-success' : 'text-brand_status-error'}`}
                /> 
            </div>
        );
    };

    const formatName = (row: IPortalPropertyAsset) => {

        const { Property } = row;

        const isOrg = Property.NHID === orgNHID ? true : false;
        let propertyWebsiteLink;
        if(isOrg) {
            propertyWebsiteLink = `${Property.WebsiteURL}/provider/${Property.Slug}`;
        } else {
            propertyWebsiteLink = `${Property.WebsiteURL}/${Property.StateSlug}/${Property.CitySlug}/${Property.Slug}`;
        }
        return (
            <div className="flex flex-wrap gap-2 break-words py-1">
                <a 
                    href="#" 
                    onClick={(e) => handleLinkClick(Property.NHID, e)}
                >
                    <strong className="block leading-tight lg:text-lg">
                        {Property.Name}
                    </strong>
                </a>
                { !isOrg ?
                    <span className="inline-block text-xs lg:text-sm me-3 leading-tight text-brand_grey-medium">
                        { Property.Address }
                    </span> : null 
                }
                <a
                    className="link text-xs bg-brand_faint-blue px-2 py-1 inline-block leading-none rounded-lg relative -top-px"
                    href={propertyWebsiteLink}
                    target="_blank"
                >
                    view on website
                </a>
            </div> 
        );
    };

    const displayBrochure = (row: IPortalPropertyAsset) => {

        const { Property, Asset } = row;
        const pdfThumbnailsLink = [];
        const asset = (Asset || []).length > 0  ? Asset[0] : null;
        if( asset && asset.TransformedAssetUrl && asset.PageCount) {
            for (let i = 0; i < asset.PageCount; i++) {
                pdfThumbnailsLink.push(
                    <a className="ring-1 ring-brand_grey-light px-1 py-2" href={asset.TransformedAssetUrl.replace(/image\/upload\/.*?\/brochure_pdfs/, `pg_${i+1}/brochure_pdfs`)} target='_blank' key={i} rel='noreferrer'>
                        <img src={asset.TransformedAssetUrl.replace(/image\/upload\/.*?\/brochure_pdfs/, `c_scale,w_100,h_100,pg_${i+1}/f_jpg/brochure_pdfs`)} alt={`${Property?.Name} PDF Brochure page ${i+1}`} />
                    </a>
                );
            }
        }

        return (
            <>
                { Asset.length > 0 && Asset[0]?.AssetID > 0 ?
                    <div key={Asset[0].AssetID} className="flex flex-wrap gap-2">
                        { pdfThumbnailsLink }
                    </div> : isOrgBrochureExists && orgNHID !== Property.NHID ?
                    <span className='msg msg-info'>
                        Provider PDF Brochure being used
                    </span> : !isOrgBrochureExists && orgNHID !== Property.NHID ?
                    <span className='msg msg-error'>
                        No PDF Brochure currently showing on website
                    </span> : null
                }
                { orgNHID && orgNHID === Property.NHID ?
                    <span className="msg msg-info">
                        { isOrgBrochureExists ? 
                            'This Provider PDF Brochure will show on all Facility pages where no Facility PDF Brochure has been uploaded'
                        :
                            'If a Provider PDF Brochure is uploaded it will show on all Facility pages where no Facility PDF Brochure has been uploaded'
                        }
                    </span> : null
                }
            </>  
        );
    };

    const handleOpenModal = async(
        nhID: number, 
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();
        const individualPropertyBrochures = await handleReloadPropertyBrochures([nhID]);
        if (propertyBrochures) {
            setIndividualPropertyBrochures(individualPropertyBrochures);
        }
        setUpdateBrochureNHID(nhID);
        setShowBrochureModal(true);
    };

    const updateBrochure = (row: IPortalPropertyAsset) => {

        return (
            <>
                { row.NHID ? 
                    <Button 
                        key={`update-property-brochure-${row.NHID}`}
                        className="ms-auto"
                        onClick={(e) => {
                            e.preventDefault();
                            handleOpenModal(row.NHID, e);
                            window.scrollTo(0, 0);
                        }}
                    >
                        {row.Asset.length > 0 && row.Asset[0]?.AssetID > 0 ? 'Update' : 'Add'} PDF Brochure
                    </Button> : null
                }
            </>
        );
    };

    const handleLinkClick = (
        nhID: number, 
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        e.preventDefault();
        handleSelNHID(nhID);
        window.scrollTo(0, 0);
    };

    const scoreExtra: IScoreExtraDisplayItem = mapProfileExtraDatatoDisplay(profileCompletenessState, selNHID);
    
    if (!scoreExtra) {
        return <LoadingDots/>;
    }
   
    const { HasPDFBrochureCount } = scoreExtra;
    const facilityCount = selNHID ? 1 : getFacilityCountFromProfileData(profileCompletenessState);

    const renderMultiPropertiesBrochures = () => {
        return (
            <>
                <div data-table="PDF Brochures" className="relative [&>div]:scrollbar [&>div]:pb-2 [&>div]:-mx-3 [&>div]:w-[calc(100%+1.5rem)] [&>div]:px-3 sm:[&>div]:-mx-4 sm:[&>div]:w-[calc(100%+2rem)] sm:[&>div]:px-4 xl:[&>div]:mx-0 xl:[&>div]:w-full xl:[&>div]:px-0 before:h-full before:top-0 before:absolute before:z-10 before:bg-gradient-to-r before:from-white before:w-3 before:-left-3 after:h-full after:top-0 after:absolute after:z-10 after:bg-gradient-to-l after:from-white after:-right-3 after:w-3 sm:before:-left-4 sm:before:w-4 sm:after:-right-4 sm:after:w-4 xl:before:content-none xl:after:content-none">
                    <DataTable
                        columns={columns}
                        data={propertyBrochures}
                        onSort={handleTableSortChange}
                    />
                </div>
                  
                { showBrochureModal ?
                    <BrochureUploadModal
                        handleClose={() => setShowBrochureModal(false)}
                        handleReloadPropertyBrochures={handleReloadPropertyBrochures}
                        orgNHID={orgNHID}
                        nhIDs={nhIDs}
                        selNHID={updateBrochureNHID}
                        propertyBrochures={individualPropertyBrochures}
                        setPropertyBrochures={setPropertyBrochures}
                        busyLoadingAsset={busyLoadingAsset}
                        setBusyLoadingAsset={setBusyLoadingAsset}
                    /> : null
                }
            </>
        );
    };

    return sessionState.Org || (sessionState.Properties || []).length > 1  
        ? 
            busyLoadingAsset ? 
                <LoadingDots show={true} /> 
            : 
            renderMultiPropertiesBrochures() 
        : 
            null;

};

export default MultiOrgPropertyBrochures;