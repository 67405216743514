import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { LoadingDots } from '../common/loading_dots';
import { getReviewReplyData } from '../../api/charts';
import ReviewsRepliesBarChart from './reviews_replies_bar_chart';
import moment from 'moment';
import { formatFromDateForAPIRequestUTC, formatToDateForAPIRequestUTC } from '../../utils/date';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCircle } from '@fortawesome/pro-solid-svg-icons';


interface IProps {
    nhIDs: number[];
    orgNHID: number;
    selNHID: number | null;
    cumulativeDefault: boolean;
    title?: string;
    showFilters?: boolean;
}

export const ReviewReplyChartWidget = ({ 
    nhIDs, 
    orgNHID, 
    selNHID, 
    cumulativeDefault,
    title,
    showFilters = true
}: IProps) => {

    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    /**
     *  Code below needs reverting back once we have a years worth of data
     * 
     * const [ dateFrom, setDateFrom ] = useState<any>(moment(now).add(1, 'year').startOf('month').toDate());
     * const [ dateTo, setDateTo ] = useState<any>(moment(now).toDate());
     */

    const now = new Date();
    const startDate = new Date('2024-02-01');

    const [ dateFrom, setDateFrom ] = useState<any>(startDate);
    const [ dateTo, setDateTo ] = useState<any>(now);

    const [ cumulative, setCumulative ] = useState<boolean>(cumulativeDefault);
    const [ data, setData] = useState<any[]>([]);

    useEffect(() => {
        if (!orgNHID && !nhIDs) return;

        const fetchData = async (): Promise<void> => {    
            try {
                setIsLoading(true);
                const data: any[] = await getReviewReplyData(
                    formatFromDateForAPIRequestUTC(dateFrom), 
                    formatToDateForAPIRequestUTC(dateTo),
                    selNHID
                ); 
                setData(data);
            } catch (e) {
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        };

       fetchData();
    }, [ orgNHID, nhIDs, dateFrom, dateTo, selNHID]);

    const handleReset = () => {
        setDateFrom(startDate);
        setDateTo(now);
        setCumulative(false);
    };

    const renderFilters = () => {

        return (
            <>
                <div className="ring-1 ring-brand_grey rounded-xl p-3 sm:p-4 bg-brand_faint-blue mb-4 sm:mb-6 md:mb-8 lg:mb-10 flex flex-wrap gap-3 items-center lg:w-fit lg:mx-auto lg:gap-8">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 items-center sm:gap-4 w-full lg:w-auto">
                        <div className="form-component form-inline form-datepicker lg:min-w-64">
                            <label
                                htmlFor="DateFrom"
                            >
                                Date Submitted From
                            </label>
                            <DatePicker
                                id="DateFrom"
                                selected={dateFrom}
                                placeholderText='Click to select a date'
                                onChange={(date: Date) => {
                                    setDateFrom(date);
                                }}
                                dateFormat={'dd MMM yyyy'}
                                minDate={startDate}
                                maxDate={new Date(dateTo)}
                                showPopperArrow={false}
                            />
                        </div>
                        <div className="form-component form-inline form-datepicker lg:min-w-64">
                            <label
                                htmlFor="DateTo"
                            >
                                Date Submitted To
                            </label>
                            <DatePicker
                                id="DateTo"
                                selected={dateTo}
                                placeholderText='Click to select a date'
                                onChange={(date: Date) => {
                                    if (date) {
                                        date.setHours(23, 59, 59, 999);
                                    }
                                    setDateTo(date);
                                }}
                                dateFormat={'dd MMM yyyy'}
                                minDate={new Date(dateFrom)}
                                maxDate={new Date()}
                                showPopperArrow={false}
                                />
                        </div>
                        <div className="grid grid-cols-1 gap-2">
                            <div className="form-component form-radio">
                                <label>
                                    <input
                                        className="peer"
                                        type="radio"
                                        name="cumulative"
                                        checked={!cumulative}
                                        onChange={() => setCumulative(false)}
                                    /> 
                                    <FontAwesomeIcon icon={faCircle} className="peer-checked:block"/>
                                    <span>
                                        Per Month
                                    </span>
                                </label> 
                            </div>
                            <div className="form-component form-radio">
                                <label>
                                    <input
                                        className="peer"
                                        type="radio"
                                        name="cumulative"
                                        checked={cumulative}
                                        onChange={() => setCumulative(true)}
                                    /> 
                                    <FontAwesomeIcon icon={faCircle} className="peer-checked:block"/>
                                    <span>
                                        Cumulative
                                    </span>
                                </label> 
                            </div>
                        </div>
                    </div>
                    <button
                            className="ms-auto btn btn-outline btn-outline-tertiary btn-small flex items-center text-md"
                            onClick={handleReset}
                        >
                            <FontAwesomeIcon icon={faXmark} className="me-1" />Reset
                        </button>
                </div>
            </>
        );
    };

    return (
        <>
            { showFilters ? 
                renderFilters() : null
            }
            <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg">
                <div className="p-3 sm:p-4 2xl:p-5 h-full flex flex-col"> 
                    { isLoading ? <LoadingDots show={true}/> : null }
                    <ReviewsRepliesBarChart
                        chartData={data}
                        cumulative={cumulative}
                        title={title}
                    />
                </div>
            </div>
        </>
    );
};
