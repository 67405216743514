import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBars,
    faUser,
    faRightFromBracket,
    faPhoneFlip,
    faEnvelope
} from '@fortawesome/pro-regular-svg-icons';
import HeaderSessionText from '../../components/common/header_session_text';
import { logout } from '../../modules/login';
import HeaderContactInfo from '../common/header_contact_info';
import { secureHttp } from '../../utils/request';
import { ENV } from '../../config/keys';
import _ from 'lodash';
import { Modal } from '../../components/common/modal';
import { useSessionState } from '../../hooks/use_session_state';
import Menu from './menu';

const Header = () => {
    const [ showInfoModal, setShowInfoModal ] = useState<boolean>(false);
    const [ showNav, setShowNav ] = useState<boolean>(false);
    const [ showAccountManagerModal, setShowAccountManagerModal] = useState<boolean>(false);
    const sessionState = useSessionState();

    const navigate = useNavigate();

    const firstName = _.get(sessionState, 'User.FirstName', '');
    const lastName = _.get(sessionState, 'User.LastName', '');

    const {AccountManager} = sessionState;

    const accountManagerProfileImage = <img
        src={sessionState.AccountManager?.PhotoURL || '/images/profile-image-placeholder.png'}
        alt={`Profile image for ${sessionState.AccountManager?.Name}`} 
        className="rounded-full inline-block"
    />;
    
    const modalContent = 
        <div className="flex gap-3">
            <div>
                { AccountManager?.Name &&
                    <strong className="text-md md:text-lg lg:text-xl mb-1 block">
                        { AccountManager.Name }
                    </strong> 
                }
                <p>
                    Any questions, please contact your Account Manager{ AccountManager?.Name && <>, {AccountManager.Name}</> }.
                </p>
                { AccountManager?.PhoneNumber || AccountManager?.Email ?
                    <ul className="mt-5 space-y-3 text-md [&>li>a]:flex [&>li>a]:gap-2 [&>li>a>svg]:text-3xl">
                        { AccountManager?.PhoneNumber && 
                            <li>
                                <a className="link" href={`tel:${AccountManager.PhoneNumber}`}>
                                    <FontAwesomeIcon icon={faPhoneFlip} />{AccountManager.PhoneNumber}
                                </a>
                            </li>
                        }
                        { AccountManager?.Email && 
                            <li>
                                <a className="link" href={`mailto:${AccountManager.Email}`}>
                                    <FontAwesomeIcon icon={faEnvelope} />{AccountManager.Email}
                                </a>
                            </li>
                        }
                    </ul> : null
                }           
            </div>
            <span className="[&>img]:h-36 [&>img]:w-36 [&>img]:min-w-[9rem]">
                {accountManagerProfileImage}
            </span>
        </div>
    ;

    return (
        <>
            <header className="bg-white border-b border-brand_grey shadow-[0_5px_5px_-5px_rgba(0,0,0,0.25)] lg:shadow-none lg:z-20">
                <div className="container max-w-none 2xl:container px-0 flex flex-col lg:flex-row">
                    <div className="flex items-center border-b border-brand_grey-light lg:border-none">
                        <FontAwesomeIcon icon={faBars} onClick={() => setShowNav(true)} className="h-4 w-4 px-3 py-4 sm:px-4 lg:hidden" />
                        <a href="/" className="flex items-end border-s border-brand_grey-light ps-3 py-2.5 lg:px-8 lg:border-none lg:flex-col lg:items-center 2xl:ps-0">
                            <img src="/logo.svg" className="h-7 me-1.5 lg:h-10 lg:me-0 xl:h-11" alt="Portal" />
                            <strong className="text-2xs text-brand_grey-medium -mb-0.5 font-medium lg:text-xs lg:mb-0 lg:-mt-1 xl:text-md">
                                Portal
                            </strong>
                        </a>
                        <FontAwesomeIcon 
                            icon={faRightFromBracket} 
                            className="ms-auto cursor-pointer h-4 w-4 px-3 py-4 sm:px-4 lg:hidden"
                            onClick={() => {
                                logout();   
                                navigate(0);
                            }}
                        />
                    </div>
                    <div className="px-3 sm:px-4 py-2.5 w-full flex items-center lg:ps-0 lg:justify-between lg:pe-8 2xl:pe-0">
                        <div className="lg:order-2 lg:text-center pe-3">
                            <HeaderSessionText />
                        </div>
                        <div className="ms-auto lg:order-1 lg:ms-0 lg:min-w-[200px]">
                            <button onClick={() => setShowAccountManagerModal(true)} className="flex items-center text-start">
                                <span className="[&>img]:h-12 [&>img]:w-12 [&>img]:min-w-[3rem] lg:[&>img]:h-14 lg:[&>img]:w-14 lg:[&>img]:min-w-[3.5rem]">
                                    {accountManagerProfileImage}
                                </span>
                                <div className="hidden sm:block ps-2 mt-1">
                                    <span className="block text-brand_grey-medium leading-3 text-2xs xl:text-xs xl:leading-4">
                                        Contact your <br/>account manager
                                    </span>
                                    <strong className="font-semibold leading-none text-xs lg:text-md xl:text-base">
                                        { sessionState.AccountManager?.Name }
                                    </strong>
                                </div>
                            </button>
                        </div>
                        <div className="hidden lg:flex flex-col items-end order-3 min-w-[200px] h-full">
                            <div className="text-xs">
                                {
                                    ENV !== 'production' ?
                                    <a 
                                        href='#'
                                        className='link text-brand_grey-medium me-6'
                                        onClick={() => {
                                            secureHttp.get('/auth/expireToken');
                                        }}
                                    >
                                        Expire JWT
                                    </a> : null
                                }
                                <a 
                                    href='#'
                                    className='link text-brand_grey-medium'
                                    onClick={() => {
                                        logout();      
                                        navigate(0);     
                                    }}
                                >
                                    <FontAwesomeIcon icon={faRightFromBracket} className="text-lg me-1.5 relative top-px" />Log Out
                                </a> 
                            </div>
                            <div className="mt-auto text-md xl:text-base">
                                <FontAwesomeIcon icon={faUser} className="text-brand_secondary text-xl me-1.5 relative top-px xl:text-2xl" />
                                <strong className="font-semibold">
                                    {firstName} {lastName}
                                </strong>
                            </div>
                        </div>
                    </div>          
                </div>
                <Modal
                    isOpen={showAccountManagerModal}
                    onClose={() => setShowAccountManagerModal(false)}
                    title={'Your account manager'}
                    size={'sm'}
                >
                    {modalContent}
                </Modal>
                <HeaderContactInfo
                    show={showInfoModal}
                    handleClose={() => setShowInfoModal(false)}
                />
            </header>
            <Menu
                showNav={showNav}
                setShowNav={setShowNav}
            />
        </>
    );
};

export default Header;
