import React, { FC } from 'react';
import { DialogTitle } from '@headlessui/react';

interface Props {
    title: string;
    subtitle?: string;
    rightSideText?: string;
}

const ModalTitle: FC<Props> = (props) => {
    const { title, subtitle, rightSideText } = props;

    return (
        <div className="flex flex-col md:flex-row md:items-center md:justify-between">
            <div className="flex flex-row flex-wrap gap-2 w-full">
                <DialogTitle className="m-0 inline ml-2">{title}</DialogTitle>
                {subtitle && (
                    <span className="font-normal flex items-end text-brand_grey-medium text-sm lg:text-md">
                        {subtitle}
                    </span>
                )}
            </div>
            <div className="font-normal w-full flex md:justify-end text-brand_grey-medium text-sm">
                {rightSideText}
            </div>
        </div>
    );
};

export { ModalTitle };