import React from 'react';
import { useQuery } from '@tanstack/react-query';

import { getReviewsOverview } from '../../../../api/review';
import { MultiFacilityChartLarge } from './multi_facility_chart_large';
import { IMultiFacilityChartControllerProps } from '../types';
import { LoadingDots } from '../../../common/loading_dots';

export const MultiFacilityChartController = ({
    selNHID,
}: IMultiFacilityChartControllerProps) => { 

    const filters = {
        NHID: selNHID,
    };

    const { data, isLoading, isFetching } = useQuery({ 
        queryKey: [ 'review', 'overview',  filters ], 
        queryFn: async () => {
                        
            const data = await getReviewsOverview(filters);    

            return {
                reviewOverview: data.data
            };
        },
    });

    if (isLoading || isFetching || !data) { 
        return <div className="flex h-full w-full justify-center items-center">
            <LoadingDots show={true} showInPlace={true} />
        </div>;
    }

    const { reviewOverview } = data ?? {};

    const chartData = [
        {
            facilities: 0,
            threshold: '0'
        },
        {
            facilities: 0,
            threshold: '1-4'
        },
        {
            facilities: 0,
            threshold: '5-11'
        },
        {
            facilities: 0,
            threshold: '12+'
        },
    ];

    reviewOverview?.rows?.forEach(reviewOverview => {

        if (!reviewOverview.TotalReviewWithin12MonthsCount) {
            chartData[0].facilities++;
        }

        if (reviewOverview.TotalReviewWithin12MonthsCount >= 1 && reviewOverview.TotalReviewWithin12MonthsCount <= 4) {
            chartData[1].facilities++;
        }

        if (reviewOverview.TotalReviewWithin12MonthsCount >= 5 && reviewOverview.TotalReviewWithin12MonthsCount <= 11) {
            chartData[2].facilities++;
        }

        if (reviewOverview.TotalReviewWithin12MonthsCount >= 12) {
            chartData[3].facilities++;
        }

    });


    return <>
        <strong className="block text-center">
            Number of reviews per facility in last 12 months
        </strong>
        <MultiFacilityChartLarge 
            chartData={chartData}
        />
    </>;
};
