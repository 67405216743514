import React, { useContext, useEffect, useState } from 'react';
import * as csvExportApi from '../../api/csv_export';
import CsvExport, { DisplayType } from '../csv_export/csv_export';
import { getLeadsBreakdown } from '../../api/leads';
import { formatFileNameForCsvExport } from '../../utils/file';
import DataTable from '../common/data_table';
import { LoadingDots } from '../common/loading_dots';
import { formatAddress } from '../../utils/property';
import AppContext from '../../context/app_context';

type Props = {
    orgNHID?: number;
    nhids?: number[];
    selNHID?: number | null;
};

interface IFilters {
    Name?: string;
}

export default function LeadsBreakdownTable(props: Props) {
    const [busy, setBusy] = useState<boolean>(false);

    const [sortField, setSortField] = useState<string>('');
    const [sortOrder, setSortOrder] = useState<string>('');

    const [tableData, setTableData] = useState<any[]>([]);

    const [filters, setFilters] = useState<IFilters>({});

    const updateFilter = (key: keyof IFilters, value: any) => setFilters((prev) => ({
        ...prev,
        [key]: value
    }));

    const getData = async () => {
        const params: any = {...filters};

        if (!props.selNHID) {
            if (props.orgNHID) {
                params.OrgNHID = props.orgNHID;
            }
    
            if (props.nhids) {
                params.NHIDs = props.nhids;
            }
        } else {
            params.NHIDs = [props.selNHID];
        }

        if (sortField && sortOrder) {
            params.SortOrder = sortOrder;
            params.SortField = sortField;
        }

        const resp = await getLeadsBreakdown(params);
        setTableData(resp);
    };

    const appContext: any = useContext(AppContext);

    const columns = [
        /* total mobile width > 768 */
        /* total tablet width > 1024 */
        {
            name: 'Provider / Facility Name',
            sortField: 'Name',
            cell: (row: any, index: number) => (
                <div className="flex flex-wrap gap-2 break-words py-1">
                    <strong className="block leading-tight lg:text-lg">
                        {row.Name}
                    </strong>
                    { props.orgNHID && index === 0 ? 
                        null : 
                        <span className="inline-block text-xs lg:text-sm me-3 leading-tight text-brand_grey-medium">
                            { formatAddress(row) }
                        </span>
                    }
                    <a
                        className={`link text-xs bg-brand_faint-blue px-2 py-1 inline-block leading-none rounded-lg relative -top-px ${props.orgNHID && index === 0 ? 'bg-white' : ''}`}
                        href={`${row.WebsiteURL}/${row.StateSlug}/${row.CitySlug}/${row.Slug}`}
                        target="_blank"
                    >
                        view on website
                    </a>
                </div>
            ),
            sortable: true,
            width: '*'
        },
        {
            name: <>Page<br />Views</>,
            sortField: 'PageViews',
            selector: (row: any) => row.PageViews,
            sortable: true,
            center: true,
            width: '*'
        },
        {
            name: <>Website<br />Referrals</>,
            sortField: 'WebsiteReferrals',
            selector: (row: any) => row.WebsiteReferrals,
            sortable: true,
            center: true,
            width: '*'
        },
        {
            name: <>Phone Number<br />Views</>,
            sortField: 'PhoneNumberViews',
            cell: (row: any, index: any) => {
                if (props.orgNHID && index === 0) {
                    return 'N/A';
                }

                return row.PhoneNumberViews;
            },
            sortable: true, 
            center: true,
            width: '*'
        },
        {
            name: <>Email<br />Inquiries *</>,
            sortField: 'EmailInquiries',
            cell: (row: any, index: any) => {
                if (props.orgNHID && index === 0) {
                    return 'N/A';
                }

                return row.EmailInquiries;
            },
            sortable: true,
            center: true,
            width: '*'
        },
        {
            name: <>Brochure<br />Inquiries *</>,
            sortField: 'BrochureInquiries',
            cell: (row: any, index: any) => {
                if (props.orgNHID && index === 0) {
                    return 'N/A';
                }

                return row.BrochureInquiries;
            },
            sortable: true,
            center: true,
            width: '*'
        }
    ];

    const handleSortChange = (column: any, order: string) => {
        if (column.sortField && order) {
            setSortField(column.sortField);
            setSortOrder(order);
        }
    };

    const startExport = async (): Promise<string> => {
        const params: any = {...filters};

        if (!props.selNHID) {
            if (props.orgNHID) {
                params.OrgNHID = props.orgNHID;
            }
    
            if (props.nhids) {
                params.NHIDs = props.nhids;
            }
        } else {
            params.NHIDs = [props.selNHID];
        }

        return csvExportApi.startExport('/lead/breakdown/export', params);
    };

    useEffect(() => {
        setBusy(true);
        getData();
        setBusy(false);
    }, [props.selNHID, filters, sortField, sortOrder]);

    return (
        <>
            <div className="flex flex-col items-center text-center gap-3 md:flex-row md:text-start">
                <div className="form-component form-inline form-textbox w-full max-w-96">
                    <label
                        htmlFor="firstName"
                    >
                        Search by Name
                    </label>
                    <input
                        type="text"
                        id="Name"
                        onChange={(e) => updateFilter('Name', e.target.value)}
                        placeholder="Enter..."
                    />
                </div>
                <div className="md:ms-auto">
                    <CsvExport 
                        startExportFunction={startExport}
                        modalTitleSuffix="Leads Breakdown"
                        label="Export as CSV"
                        displayType={DisplayType.Button}
                        fileName={`${formatFileNameForCsvExport('leads_breakdown')}`}
                    />
                </div>
            </div>
            <div className="mt-4 sm:mt-6 md:mt-8 lg:mt-10">
                <strong className="mb-2 md:mb-4 lg:mb-6 text-sm block font-medium md:text-end px-2 md:px-3 lg:px-4">
                    * Email Inquiries & Brochure Inquiries launched on April 1st 2024
                </strong>
                <div data-table="Lead Breakdown by Facility" className={`relative [&>div]:scrollbar [&>div]:pb-2 [&>div]:-mx-3 [&>div]:w-[calc(100%+1.5rem)] [&>div]:px-3 sm:[&>div]:-mx-4 sm:[&>div]:w-[calc(100%+2rem)] sm:[&>div]:px-4 xl:[&>div]:mx-0 xl:[&>div]:w-full xl:[&>div]:px-0 before:h-full before:top-0 before:absolute before:z-10 before:bg-gradient-to-r before:from-white before:w-3 before:-left-3 after:h-full after:top-0 after:absolute after:z-10 after:bg-gradient-to-l after:from-white after:-right-3 after:w-3 sm:before:-left-4 sm:before:w-4 sm:after:-right-4 sm:after:w-4 xl:before:content-none xl:after:content-none ${props.orgNHID && !props.selNHID ? 'rdt_FirstRowHighlight' : ''}`}>
                    { busy ? <LoadingDots show={true} /> : null }
                    <DataTable
                        onSort={handleSortChange}
                        useTotalsRow={true}
                        data={tableData}
                        columns={columns}
                        sortServer={true}
                    />
                </div>
            </div>
        </>
    );
}
