import React, { useState, useEffect } from 'react';
import ExportDisplay from './export_display';
import * as exportApi from '../../api/csv_export';
import { handleExternalDownload } from '../../utils/file';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/pro-solid-svg-icons';
import { Button } from '../common/button';
import { Modal } from '../common/modal';

interface IProps {
    startExportFunction: IStartExportFunction;
    modalTitleSuffix: string;
    label?: string;
    displayType?: DisplayType;
    fileName?: string;
}

export enum DisplayType {
    Button,
    Link
}

interface IExportStatus {
    totalRecordsToFetch: number;
    totalRecordsFetched: number;
    etaMsecs: number;
    completed: boolean;
}

interface IStartExportFunction {
    (): Promise<string>;
}

const CsvExport = (props: IProps) => {
    const [exportJobID, setExportJobID] = useState<string>(null);
    const [exportStatus, setExportStatus] = useState<IExportStatus>(null);
    const [exportRunning, setExportRunning] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            if (!exportRunning) {
                return;
            }

            try {
                setExportJobID( await props.startExportFunction());
            } catch (e) {
                console.error(`Export data error: ${e}`);
                setExportRunning(false);
            }
        })();
    }, [exportRunning]);

    useEffect(() => {
        if (!exportJobID) {
            return;
        }
        setStatusUpdateTimer();
    }, [exportJobID]);

    useEffect(() => {
        if (!exportStatus) {
            return;
        }

        if (exportStatus.completed) {
            setExportRunning(false);
            (async () => {
                await downloadExport();
            })();
        } else if (exportRunning) {
            setStatusUpdateTimer();
        }
    }, [exportStatus]);

    const startExport = async () => {
        setExportJobID(null);
        setExportStatus(null);
        setExportRunning(true);
    };

    const setStatusUpdateTimer = () => {
        setTimeout(getExportStatus, 3000);
    };

    const getExportStatus = async () => {
        if (exportStatus?.completed === true) {
            return;
        }
        setExportStatus(await exportApi.getCsvExportStatus(exportJobID));
    };
    
    const downloadExport = async () => {
        const { fileName } = props;
        const downloadURL = await exportApi.downloadCsvExport(exportJobID);

        if (fileName?.length)  {
            handleExternalDownload(downloadURL, fileName);
            return;
        }

        const link = document.createElement('a');
        link.href = downloadURL;
        link.click();
    };

    const cancelExport = async () => {
        if (exportJobID !== null) {
            await exportApi.cancelCsvExport(exportJobID);
        }
        setExportRunning(false);
    };

    const renderExportModal = () => {
        if (!exportRunning) {
            return;
        }

        return (
            <Modal
                onClose={() => {}}
                size='sm'
                isOpen={true}
                footerContentClose={false}
                title={`Exporting ${props.modalTitleSuffix}`}
                footerContent={
                    <button className="mt-2 mr-2 btn btn-outline-primary pull-left" onClick={() => cancelExport()}>Cancel</button>
                }
            >
                <div className='min-h-28'>
                    <ExportDisplay
                        totalItemsToFetch={exportStatus ? exportStatus.totalRecordsToFetch : null}
                        totalItemsFetched={exportStatus ? exportStatus.totalRecordsFetched : null}
                        etaMsecs={exportStatus ? exportStatus.etaMsecs : null}
                    />
                </div>
            </Modal>
        );
    };

    return (
        <>
            {
                props.displayType === DisplayType.Button ?
                <Button onClick={() => startExport()}>
                    <FontAwesomeIcon icon={faFile} className="text-3xl me-2" />{props.label !== undefined ? props.label : 'Export'}
                </Button> :
                <a className="btn" onClick={() => startExport()} href="#">
                    <FontAwesomeIcon icon={faFile} className="text-3xl me-2" />{props.label !== undefined ? props.label : 'Download as CSV'}
                </a>
            }
            {renderExportModal()}
        </>
    );
};

export default CsvExport;
