import React from 'react';
import { NavLink } from 'react-router-dom';
import { IPublishedReviewsWidgetXSProps } from './types';
import { LoadingDots } from '../../common/loading_dots';

export const PublishedReviewsWidgetXS = ({ 
    selNHID, 
    totalPublished,
    loadingData
}: IPublishedReviewsWidgetXSProps) => { 
    const dateNow = new Date();
    dateNow.setHours(0, 0, 0, 0);

    const renderNumber = (number: number, pulsePositive = false) => {
        if (pulsePositive && number) {
            return (
                <em className="pulse">
                    { number }
                </em>
            );
        }

        return number;
    };

    const renderButton = (text: string, url: string, count: number, filters?: any) => {
        if (!count) {
            return (
                <span className="btn mt-auto min-w-44 text-center disabled">
                    { text }
                </span>  
            );
        }

        return (
            <NavLink 
                className="btn mt-auto min-w-44 text-center"
                to={url}
                state={{filters}}
            >
                { text }
            </NavLink>  
        );
    };

    return <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg flex flex-col h-full 2xl:min-h-52">
        <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 2xl:py-3 min-h-14 flex items-center">
            <h4 className="font-semibold leading-none text-lg lg:text-xl">
                All Published Reviews
            </h4>
        </div>
        <div className="p-3 sm:p-4 2xl:p-5 flex flex-col h-full">
            <div className="flex flex-col justify-between items-center gap-3 h-full">
                {
                    loadingData ? 
                        <LoadingDots show={true} showInPlace={true} />
                    : <>
                        <strong className="text-6xl flex items-center h-full">
                            { renderNumber(totalPublished) }
                        </strong>
                    </>
                }
                { 
                    renderButton(
                        'View Reviews',
                        '/reviews', 
                        totalPublished, 
                        { NHID: selNHID }
                    ) 
                }
            </div>
        </div>
    </div>;
};
