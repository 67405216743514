import { useMutation } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { IPropertyVirtualTourRow, IPropertyVirtualTourData, IPropertyVirtualTourResponse } from '../../../../interfaces/asset';
import { showToast, ToastType } from '../../../../utils/toast';
import { OpType } from '../../../../interfaces/common';

import * as assetApi from '../../../../api/assets';

const useUpdatePropertyVirtualTours = (
    queryKey: string[], 
    cb: (data?: IPropertyVirtualTourRow) => void
) => {

    const callback = () => cb ? cb() : null;
    const queryClient = useQueryClient();
    
    const mutationFn = (virtualTourData: IPropertyVirtualTourData) => {
        const { NHID, TourURL, Action } = virtualTourData;
        const payload = { NHID, TourURL: TourURL?.trim() };

        const actionMap = {
            [OpType.Create]: () => assetApi.createPropertyVirtualTour(payload),
            [OpType.Update]: () => assetApi.updatePropertyVirtualTour(payload),
            [OpType.Delete]: () => assetApi.deletePropertyVirtualTour(NHID)
        };

        const actionFn = actionMap[Action];
        if (!actionFn) {
            return Promise.reject(new Error(`useUpdatePropertyVirtualTours: Invalid action type: ${Action}`));
        }

        return actionFn();
    };

    const onMutate = async (virtualTourData: IPropertyVirtualTourData) => {
        await queryClient.cancelQueries({ queryKey });

        const previousData: IPropertyVirtualTourResponse = queryClient.getQueryData(queryKey);
        const updatedData = { ...previousData, rows: [...previousData.rows] };
        
        const index = updatedData.rows.findIndex((row: IPropertyVirtualTourRow) => row.NHID === virtualTourData.NHID);
        
        if (index !== -1) {
            updatedData.rows[index].TourURL = virtualTourData.TourURL.trim();

            if ([OpType.Create, OpType.Update].includes(virtualTourData.Action)) {
                updatedData.rows[index].PublishWebsite = true;
                updatedData.rows[index].Verified = false;
            }
            
            if ([OpType.Delete, OpType.Update].includes(virtualTourData.Action)) {
                updatedData.totalRowsWithVirtualTour--;
            }

            if (virtualTourData.Action === OpType.Delete) {
                updatedData.rows[index].TourURL = null;
            }

            queryClient.setQueryData(queryKey, updatedData);
        }
        
        callback();

        return { updatedData };
    };

    const onSuccess = () => showToast('Saved successfully!', ToastType.Success);

    const onError = ({ context }: { context: any }) => {
        if (context?.previousData) {
            queryClient.setQueryData(queryKey, context.previousData);
        }

        showToast('Something went wrong!', ToastType.Error);
    };

    const onSettled = () => {
        queryClient.invalidateQueries({ queryKey });
        callback();
    };

    const mutation = useMutation({
        mutationFn,
        onMutate,
        onSuccess,
        onError,
        onSettled
    });

    return mutation;
};

export { useUpdatePropertyVirtualTours };