import React from 'react';
import cx from 'classnames';

interface IProps {
    show?: boolean;
    showInPlace?: boolean;
}

export const LoadingDots = (props: IProps) => {
    return (
        <em 
            className={cx('loading-dots', { '!hidden' : !props.show, 'left-1/2 -translate-x-1/2' : !props.showInPlace })}
        >
            <span>
                { [0, 1, 2, 3].map((dot: number) => <div key={dot} />) }
            </span>
        </em>
    );
};
