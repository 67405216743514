import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PageCommunitySelect from '../../components/common/page_community_select';
import { getCommunityMode, getSingleProperty  } from '../../utils/common';
import { ISessionState, PropertyMode } from '../../interfaces/session';
import { useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import _, { get } from 'lodash';
import axios from 'axios';

import {
    renderReviewScoreCertLink,
    reviewScoreCertificatePdfURL,
    reviewScoreCertFilename,
} from './review_score_certs_common';
import ReviewScoreDisplayFormatted from '../../components/common/review_score_display_formatted';
import { getReviewScoreForNHID } from '../../api/review';
import { IReviewScore } from '../../interfaces/review';
import { useCommunitySelectHistory } from '../../hooks/use_community_select_history';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

const showAdditional = false;

const ReviewScoreCert =({ nhIDs, orgNHID }: IProps) => {
    const [selNHID, setSelNHID] = useState<number>(null);
    const [reviewScoreCertExtraParams, /*setReviewScoreCertExtraParams*/] = useState<string>('');
    const [reviewScoreCertURL, setReviewScoreCertURL] = useState<string>('');
    const [includeReviewScoreBreakdown, /*setIncludeReviewScoreBreakdown*/] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [reviewScoreCertDownloading, setReviewScoreCertDownloading] = useState<boolean>(false);
    const [reviewScore, setReviewScore] = useState<number>();

    const location = useLocation();
    const state = location?.state as any;
    const stateNHID = state?.NHID || null;

    useEffect(() => {

        if (orgNHID && !stateNHID) {
            return;
        }

        const nhID = nhIDs?.length === 1 ? nhIDs[0] : stateNHID;

        if (nhID) {   
            setSelNHID(nhID);
        }
    }, [orgNHID, nhIDs]);

    useEffect(() => {
        createReviewScoreCertificatePdfURL();
    });

    useEffect(() => {
        fetchReviewScore();
    });

    useEffect(() => {
        createReviewScoreCertificatePdfURL();
    }, [includeReviewScoreBreakdown, reviewScoreCertExtraParams]);

    const { Session, Org, Properties }: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const { SessionType } = Session;

    const singleProperty = getSingleProperty(SessionType, selNHID, Properties);
    const propertyMode = getCommunityMode(orgNHID, nhIDs, selNHID);

    const fetchReviewScore = async () => {
        const firstProperty: any = get(Properties, '[0]');
        const nhID = selNHID 
            ? selNHID 
            : propertyMode === PropertyMode.SingleProperty 
                ? nhIDs[0]
                : firstProperty.OrgNHID;

        if (nhID) {
            const reviewScore: IReviewScore = await getReviewScoreForNHID(nhID);
            setReviewScore(reviewScore.ReviewScoreDisplay);
        } else {
            setReviewScore(null);
        }
    };
    
    const createReviewScoreCertificatePdfURL = () => {

        const selNHIDs = selNHID ? [selNHID] : nhIDs;

        const url = reviewScoreCertificatePdfURL(
            selNHIDs, 
            orgNHID,
            reviewScoreCertExtraParams
        );

        setReviewScoreCertURL(url);
    };

    // const updateReviewScoreCertExtraParams = (val: string) => {
    //     setReviewScoreCertExtraParams(val);
    // };

    // const updateIncludeReviewScoreBreakdown = (val: boolean) => {
    //     setIncludeReviewScoreBreakdown(val);
    // };

    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        setSelNHID(selNHID);
    });

    const handleReviewScoreCertDownload = async (url: string) => {

        if (reviewScoreCertDownloading) {
            return;
        }
        
        try {
            setIsLoading(true);
            setReviewScoreCertDownloading(true);
            const resp = await axios.get(url,{
                responseType: 'blob',
            });

            const data = _.get(resp, 'data');
            saveAs(data, reviewScoreCertFilename(singleProperty, SessionType, Org));
        } catch(err) {
            console.log(err);
        } finally {
            setIsLoading(false);
            setReviewScoreCertDownloading(false);
        }
    };

    return (
        <section>
            <div className="container max-w-none 2xl:container md:mt-8 lg:mt-10">
                <h2 className="font-bold text-2xl md:text-3xl lg:text-4xl whitespace-nowrap block">
                    Generate a Review Certificate
                </h2>
                <div className="mt-4 sm:mt-6 grid grid-cols-1 gap-3 lg:grid-cols-12 lg:gap-4 xl:gap-8">
                    <div className="lg:col-span-5 xl:col-span-4 ring-1 ring-brand_secondary p-3 sm:p-4 xl:p-6 rounded-md sm:rounded-lg text-md h-fit">
                        <strong className="text-md md:text-lg lg:text-xl">
                            Where do I use my Review Score Certificate
                        </strong>
                        <p className="mt-5">
                            We would recommend always displaying your most recent Review Score Certificate in your reception area(s) so residents, and visiting family & friends can view your most up-to-date score.
                        </p>
                        <div className="flex gap-3 items-center text-xs mt-5 [&>div]:text-5xl [&>div]:px-6 [&>div]:py-4 [&>div]:rounded-lg">
                            <ReviewScoreDisplayFormatted value={reviewScore} showAsNotApplicableOnNull={true}/>
                            <p>
                                We would also recommend sharing your latest Review Score Certificate on your social media and website.
                            </p>
                        </div>
                    </div>
                    <div className="lg:col-span-7 xl:col-span-8 ring-1 ring-brand_grey p-3 sm:p-4 xl:p-6 rounded-md sm:rounded-lg h-fit">
                        { orgNHID || nhIDs?.length > 1 ?
                            <div>
                                <strong className="block mb-2">
                                    Select a facility:
                                </strong>
                                <PageCommunitySelect handleSelect={handleSelect} selNHID={selNHID}/>
                            </div> : null
                        }
                        { reviewScore === null 
                            ? <div className="my-4">Once your facility has a review score, you will be able to download your Review Score Certificate here.</div>
                            : (
                                <>
                                    { 
                                        renderReviewScoreCertLink(
                                            nhIDs,
                                            reviewScoreCertURL,
                                            singleProperty,
                                            SessionType,
                                            Org,
                                            handleReviewScoreCertDownload,
                                            reviewScoreCertDownloading
                                        )
                                    }
                                    { 
                                        showAdditional ? renderReviewScoreCertLink(
                                            nhIDs,
                                            reviewScoreCertURL,
                                            singleProperty,
                                            SessionType,
                                            Org,
                                            handleReviewScoreCertDownload,
                                            reviewScoreCertDownloading
                                        ): null
                                    }
                                </>
                        )}

                    </div>
                </div>
            </div>
        </section>         
    );
};

export default ReviewScoreCert;