import React from 'react';
import { IPublishedReviewsOverYearChartProps } from './types';
import { PropertyMode } from '../../../interfaces/session';
import { MultiFacilityChartController } from './multi_facility_chart';
import { SingleFacilityChartController } from './single_facility_chart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentsQuestionCheck } from '@fortawesome/pro-regular-svg-icons';
import { NavLink } from 'react-router-dom';

export const PublishedReviewsOverYearChart = ({ 
    selNHID,
    propertyMode,
}: IPublishedReviewsOverYearChartProps) => { 
    const isMultiMode = [PropertyMode.MultiProperty, PropertyMode.Org].includes(propertyMode);
    
    const headerComponent = <div className='flex flex-row gap-4 align-center items-center'>
        <FontAwesomeIcon icon={faCommentsQuestionCheck} className="min-w-8 min-h-8 " />
        <h4 className="font-semibold leading-tight text-lg lg:text-xl">
            A higher number of recent reviews will likely result in more leads; <span className="font-medium text-sm 2xl:block">we recommend aiming for at least 1 review per month per facility</span>
        </h4>
    </div>;
    
    const footerComponent = <div>
        <NavLink className="btn mt-auto text-center" to={'/reviews-overview'}>
            View Reviews Overview
        </NavLink>
    </div>;

    let chartComponent = <SingleFacilityChartController selNHID={selNHID} />;

    if (isMultiMode) {
        chartComponent = <MultiFacilityChartController selNHID={selNHID} />;
    }

    return <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg flex flex-col h-full">
        <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 2xl:py-3 flex items-center justify-between gap-2">
            {headerComponent}
        </div>
        <div className='p-3 sm:p-4 2xl:p-5 h-full'>
            {chartComponent}
        </div>
        <div className="pb-3 sm:pb-3 2xl:pb-5 flex items-center justify-center">
            {footerComponent}
        </div>
    </div>;
};
