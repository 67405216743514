import React, { FC, useState } from 'react';
import { useSessionState } from '../../../../../hooks/use_session_state';
import { Modal } from '../../../../../components/common/modal';
import { PropertyTeamForm } from '../property_team_form';
import { Button, ButtonProps } from '../../../../../components/common/button';
import { MeetTheTeamMemberType } from '../../types';
import { useUpdateTeamMemberMutation } from '../../hooks/useUpdateTeamMemberMutation';

interface Props {
    styleType?: ButtonProps['styleType'];
    className?: string;
    memberDetails: MeetTheTeamMemberType;
    isInSinglePropertyContext?: boolean;
}

const EditTeamMemberModal: FC<Props> = (props) => {
    const {
        styleType = 'solid',
        className,
        memberDetails,
        isInSinglePropertyContext = false
    } = props;
    const { publishWebsite } = memberDetails;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggleModal = () => setIsModalOpen((isOpen: boolean) => !isOpen);

    const { mutate } = useUpdateTeamMemberMutation({
        propertyId: memberDetails.propertyId,
        includeIdInQueryKey: isInSinglePropertyContext
    });

    const handleSubmit = async (formValues: MeetTheTeamMemberType) => {
        try {
            await mutate(formValues);
            toggleModal();
        } catch (err) {
            /* do nothing */
        }
    };

    const sessionState = useSessionState();

    const formId = 'update-team-member';

    const actionButtons = (
        <Button type="submit" form={formId}>
            Save Changes
        </Button>
    );

    const reasonForNotPublishingToWebsite = !memberDetails.publishWebsite && (
        <div className='text-danger fw-bold'>Not currently published on website: {memberDetails.unpublishWebsiteComment}</div>
    );

    return (
        <>
            <Modal
                isOpen={isModalOpen}
                onClose={toggleModal}
                title="Edit Team Member"
                footerContent={actionButtons}
            >
                { reasonForNotPublishingToWebsite }
                <PropertyTeamForm
                    onSubmit={handleSubmit}
                    id={formId}
                    defaultValues={{
                        ...memberDetails,
                        publishWebsite: publishWebsite ? 'yes' : 'no'
                    }}
                    properties={sessionState.Properties}
                />
            </Modal>
            <Button
                styleType={styleType}
                className={className}
                onClick={toggleModal}
            >
                Edit
            </Button>
        </>
    );
};

export { EditTeamMemberModal };