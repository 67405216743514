import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ISessionState } from '../../interfaces/session';
import { LoadingDots } from '../common/loading_dots';
import DataTable from '../../components/common/data_table';
import DisplayAsset from './display_asset';
import AppContext from '../../context/app_context';
import { 
    NHAssetType,
    IGetPortalPropertyWebAndCardLogoAsset
} from '../../interfaces/asset';
import cx from 'classnames';

import { 
    INhPortalScoreItem,
    IScoreExtraDisplayItem
} from '../../interfaces/property';

import { 
    mapProfileExtraDatatoDisplay,
    getFacilityCountFromProfileData
} from '../../utils/profile_completeness';
import LogoUploadModal from './logo_upload_modal';
import { Button } from '../common/button';

interface IProps {
    handleSelNHID: (NHID: number | null) => void;
    orgNHID?: number | null;
    nhIDs: number[];
    selNHID: number | null;
    noLogoPropertyFilter: boolean | null;
    handleNoLogoPropertyFilter: (noLogoPropertyFilterValue:boolean | null) => void;
    propertyLogos: IGetPortalPropertyWebAndCardLogoAsset[];
    setPropertyLogos: any;
    busyLoadingAsset: boolean;
    setBusyLoadingAsset: any;
    handleReloadPropertyLogos: any;
}

const MultiOrgPropertyLogos = ({
    handleSelNHID,
    orgNHID,
    nhIDs,
    selNHID,
    noLogoPropertyFilter,
    handleNoLogoPropertyFilter,
    propertyLogos,
    setPropertyLogos,
    busyLoadingAsset,
    setBusyLoadingAsset,
    handleReloadPropertyLogos

}: IProps) => {

    const [ showLogoModal, setShowLogoModal ] = useState<boolean>(false);
    const [ updateLogoNHID, setUpdateLogoNHID ] = useState<number>(null);
    const [ individualPropertyLogos, setIndividualPropertyLogos] = useState<any[]>([]);
    const [ scoreExtra, setScoreExtra ] = useState<IScoreExtraDisplayItem>();

    const sessionState: ISessionState = useSelector(
        ({SessionState}: any) => SessionState
    );

    const profileCompletenessState: INhPortalScoreItem = useSelector(
        ({ ProfileCompletenessState }: any) => ProfileCompletenessState
    );

    const appContext: any = useContext(AppContext);

    const property = (propertyLogos || []).length > 0  ? propertyLogos[0] : null;
    const asset = property && (property.Asset || []).length > 0  ? property.Asset[0] : null;

    const isOrgLogoExists: boolean = property?.NHID === orgNHID && asset?.WebLogoAssetID > 0 ? true : false;
    const isOrgCardLogoExists: boolean = property?.NHID === orgNHID && asset?.CardLogoAssetID > 0 ? true : false;

    const columns = [
        {
            id: 'NHID',
            name: 'Facility Name',
            cell: (row: IGetPortalPropertyWebAndCardLogoAsset) => formatName(row),
            width: '*'
        },
        {
            name: 'Logo showing on website',
            cell: (row: IGetPortalPropertyWebAndCardLogoAsset) => displayWebLogo(row),
            width: '*'
        },
        {
            name: 'Logo used for Review Card',
            cell: (row: IGetPortalPropertyWebAndCardLogoAsset) => displayReviewCardLogo(row),
            width: '*'
        },
        {
            name: '',
            cell: (row: IGetPortalPropertyWebAndCardLogoAsset) => updateLogos(row?.Property?.NHID ? row.Property.NHID: null),
            width: '*'
        }
    ];

    const formatName = (row: IGetPortalPropertyWebAndCardLogoAsset) => {

        const { Property } = row;

        const isOrg = Property.NHID === orgNHID ? true : false;
        let propertyWebsiteLink;
        if(isOrg) {
            propertyWebsiteLink = `${Property.WebsiteURL}/provider/${Property.Slug}`;
        } else {
            propertyWebsiteLink = `${Property.WebsiteURL}/{${Property.StateSlug}/${Property.CitySlug}/${Property.Slug}`;
        }
        return (
            <div className="flex flex-wrap gap-2 break-words py-1">
                <a 
                    href="#" 
                    onClick={(e) => handleLinkClick(Property.NHID, e)}
                >
                    <strong className="block leading-tight lg:text-lg">
                        { Property.Name }
                    </strong>
                </a>
                { !isOrg ?
                    <span className="inline-block text-xs lg:text-sm me-3 leading-tight text-brand_grey-medium">
                        { Property.Address }
                    </span> : null
                }
                <a
                    className="link text-xs bg-brand_faint-blue px-2 py-1 inline-block leading-none rounded-lg relative -top-px"
                    href={propertyWebsiteLink}
                    target="_blank"
                >
                    view on website
                </a> 
            </div>  
        );
    };

    const displayWebLogo = (row: IGetPortalPropertyWebAndCardLogoAsset) => {

        const { Property, Asset } = row;

        return (
            <div className="grid gap-2">
                {
                    Asset.length > 0 && Asset[0]?.WebLogoAssetID > 0 ?
                        <div key={Asset[0].WebLogoAssetID} className="[&>img]:ring-1 [&>img]:ring-brand_grey-light [&>img]:p-1 [&>img]:rounded-md [&>img]:max-w-[200px]">
                            <DisplayAsset
                                TransformedAssetUrl={Asset[0].WebLogoTransformedAssetUrl}
                                FileName={Asset[0].WebLogoFileName}
                                AssetType={NHAssetType.Logos} 
                            />
                        </div> :
                        <span className={cx('msg', isOrgLogoExists ? 'msg-info' : 'msg-error')}>
                            {isOrgLogoExists && orgNHID !== Property.NHID ?
                                'Provider logo being used' :
                                'No logo currently showing on website'
                            }
                        </span>  
                }
                {   orgNHID && orgNHID === Property.NHID ?
                    <span className="msg msg-info">
                        { isOrgLogoExists ? 
                            'This Provider logo will show on all Facility pages where no Facility logo has been uploaded' :
                            'If a Provider logo is uploaded it will show on all Facility pages where no Facility logo has been uploaded'
                        }
                    </span> : null
                }
            </div>
        );
    };

    const handleOpenModal = async(
        nhID: number, 
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();
        const individualPropertyLogos = await handleReloadPropertyLogos([nhID]);
        if (individualPropertyLogos) {
            setIndividualPropertyLogos(individualPropertyLogos);
        }
        setUpdateLogoNHID(nhID);
        setShowLogoModal(true);
    };

    const updateLogos = (nhID: number) => {

        return (
            nhID ? 
                <Button 
                    key={`update-property-logo-${nhID}`}
                    className="ms-auto"
                    onClick={(e) => {
                        e.preventDefault();
                        handleOpenModal(nhID, e);
                        window.scrollTo(0, 0);
                    }}
                >
                    Update Logos
                </Button>
            : null
        );
    };

    const displayReviewCardLogo = (row: IGetPortalPropertyWebAndCardLogoAsset) => {

        const { Property, Asset } = row;

        return (
            <div className="grid gap-2">
                {
                    Asset.length > 0 && Asset[0]?.CardLogoAssetID > 0 ?
                        <div key={Asset[0].CardLogoAssetID} className="[&>img]:ring-1 [&>img]:ring-brand_grey-light [&>img]:p-1 [&>img]:rounded-md [&>img]:max-w-[200px]">
                            <DisplayAsset
                                TransformedAssetUrl={Asset[0].CardLogoTransformedAssetUrl}
                                FileName={Asset[0].CardLogoFileName}
                                AssetType={NHAssetType.CardLogos} 
                            />
                            <div className="mt-2 grid gap-2">
                                <a 
                                    className="block text-2xs link leading-none"
                                    rel="noreferrer" 
                                    target="_blank" 
                                    href={`https://www.nursinghomes.com/logos/reviewcard/${Asset[0].CardLogoFileName}.pdf`}
                                >
                                    Download PDF Print Version
                                </a>
                                <Link    
                                    to="/review-cards"
                                    state={{ NHID: row.NHID }}
                                    className="block text-2xs link leading-none"
                                >
                                    Order Review Cards
                                </Link>
                            </div>
                        </div>  
                    :
                        <span className={cx('msg', isOrgCardLogoExists ? 'msg-info' : 'msg-error')}>
                            {isOrgCardLogoExists && orgNHID !== Property.NHID ?
                                'Provider review card logo being used' :
                                'Currently no review card logo'
                            }
                        </span>  
                }
                {   orgNHID && orgNHID === Property.NHID ?
                        <span className="msg msg-info">
                            { isOrgCardLogoExists ? 
                                'This Provider review card logo will be used for all Facility pages where no Facility review card logo has been uploaded' :
                                'If a Provider review card logo is uploaded it will used for Facility pages where no Facility review card logo has been uploaded'
                            }
                        </span>
                    : null
                }
            </div>  
        );
    };

    const handleLinkClick = (
        nhID: number, 
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        e.preventDefault();
        handleSelNHID(nhID);
        window.scrollTo(0, 0);
    };

    useEffect(() => {        
        const scoreExtra = mapProfileExtraDatatoDisplay(profileCompletenessState, selNHID);
        setScoreExtra(scoreExtra);
    }, [ orgNHID, selNHID, profileCompletenessState ]);


    if (!scoreExtra) {
        return <LoadingDots/>;
    }

    const { HasLogoCount } = scoreExtra;
    const facilityCount = selNHID ? 1 : getFacilityCountFromProfileData(profileCompletenessState);

    const renderMultiPropertiesLogos = () => {

        return (
            <>
                <div className="flex flex-col items-center text-center gap-3 md:flex-row md:text-start">
                    <strong className="text-md md:text-lg lg:text-xl lg:whitespace-nowrap">
                        {HasLogoCount}/{facilityCount} Facilities have Logos showing on website
                    </strong>
                </div>       
                {/* <div className="d-flex flex-column flex-md-row px-10 align-items-center w-100">
                    <div className="me-4 me-xl-10 mb-3 mb-md-0 text-md-nowrap lh-sm">
                        <strong>Show Facilities with no Logo</strong>
                    </div>
                    <div className="d-flex align-items-center h-100">
                        <div className="form-check form-check-custom mb-0 ms-auto">
                            <input
                                type="checkbox"
                                className="form-check-input mb-0"
                                name="profileCompleteness"
                                checked={noLogoPropertyFilter}
                                onChange={() => handleNoLogoPropertyFilter(!noLogoPropertyFilter)}
                            />
                        </div>
                    </div>
                </div> */}
                <div className="mt-4 sm:mt-6 md:mt-8 lg:mt-10">
                    <div data-table="Logos" className="relative [&>div]:scrollbar [&>div]:pb-2 [&>div]:-mx-3 [&>div]:w-[calc(100%+1.5rem)] [&>div]:px-3 sm:[&>div]:-mx-4 sm:[&>div]:w-[calc(100%+2rem)] sm:[&>div]:px-4 xl:[&>div]:mx-0 xl:[&>div]:w-full xl:[&>div]:px-0 before:h-full before:top-0 before:absolute before:z-10 before:bg-gradient-to-r before:from-white before:w-3 before:-left-3 after:h-full after:top-0 after:absolute after:z-10 after:bg-gradient-to-l after:from-white after:-right-3 after:w-3 sm:before:-left-4 sm:before:w-4 sm:after:-right-4 sm:after:w-4 xl:before:content-none xl:after:content-none">
                        <DataTable
                            columns={columns}
                            data={propertyLogos}
                        />
                    </div>
                </div>
                {
                    showLogoModal ?
                        <LogoUploadModal
                            handleClose={() => setShowLogoModal(false)}
                            handleReloadPropertyLogos={handleReloadPropertyLogos}
                            orgNHID={orgNHID}
                            nhIDs={nhIDs}
                            selNHID={updateLogoNHID}
                            propertyLogos={individualPropertyLogos}
                            setPropertyLogos={setPropertyLogos}
                            busyLoadingAsset={busyLoadingAsset}
                            setBusyLoadingAsset={setBusyLoadingAsset}
                        />
                    : null
                }                    
            </>
        );
    };

    return sessionState.Org || (sessionState.Properties || []).length > 1  
        ? 
            busyLoadingAsset ? 
                <LoadingDots show={true} /> 
            : 
                renderMultiPropertiesLogos() 
        : 
            null;

};

export default MultiOrgPropertyLogos;