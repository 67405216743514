import React from 'react';
import { Modal } from '../../../../components/common/modal';
import { Button } from '../../../../components/common/button';
import { Form } from '../../../../components/common/form';
import coreDetailsFormSchema from '../property_core_details_form.validation';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as nhAPI from '../../../../api/property';
import { useState } from 'react';
import { mapValuesToFormFields } from './helpers';
import { useCreateTabs } from '../../../../hooks/use_create_tabs';
import { coreDetailsTabSections } from '../tabs/core_details_tab_sections';
import { costFundingTabSections } from '../tabs/cost_funding_tab_sections';
import { longFormatDate } from '../../../../utils/date';
import { ProfileScoreType } from '../../../../interfaces/property';
import { updateProfileCompleteness } from '../../../../utils/profile_completeness'; 
import {  ToastType, showToast } from '../../../../utils/toast';
interface IProps {
    row: any;
    hasCoreDetails: boolean;
    profileScoreType: ProfileScoreType
}

const CareOfferedCostFundingModal = ({ row, hasCoreDetails, profileScoreType }: IProps) => {

    const [isOpen, setIsOpen] = useState(false);

    const tabContentConfig = profileScoreType === ProfileScoreType.FacilityBaseData ? {
        title: 'Care Offered',
        sections: coreDetailsTabSections
    } : {
        title: 'Cost / Funding',
        sections: costFundingTabSections

    };

    const { TabHeaders, TabContent } = useCreateTabs(tabContentConfig.sections, {
        isScrollTab: true
    });

    const apiCall = hasCoreDetails
        ? nhAPI.updatePropertyCoreDetailData
        : nhAPI.createPropertyCoreDetailData;

    const queryClient = useQueryClient();
    const { mutate, isPending } = useMutation({
        mutationFn: async (payload: any) => apiCall(row.NHID, payload),
        onSuccess: () => {

            showToast(`${tabContentConfig.title} details successfully updated. Please allow up to 60 minutes for the changes to show on the website.`, ToastType.Success);
            queryClient.invalidateQueries({
                queryKey: ['property', 'core', 'details']
            });

            updateProfileCompleteness();
            
            setIsOpen(false);
        }
    });

    const defaultValues = mapValuesToFormFields(row);

    const handleSubmit = async (data: any) => {
        mutate(data);
    };

    const formId = String(row.NHID);

    const footerContent = (
        <Button form={formId} type="submit" isLoading={isPending} className="min-w-36">
            Update
        </Button>
    );

    return (
        <>  
            <Button onClick={() => setIsOpen(true)} className="ms-auto">
                Edit
            </Button>
            <Modal
                onClose={() => setIsOpen(false)}
                isOpen={isOpen}
                title={tabContentConfig.title}
                subTitle={row.Name}
                lastUpdated={row.LastUpdated ? longFormatDate(row.LastUpdated): 'No updates made'}
                tabHeaders={<TabHeaders />}
                size="xl"
                footerContent={footerContent}
                noBodyPadding
            >
                <Form
                    onSubmit={handleSubmit}
                    defaultValues={defaultValues}
                    id={formId}
                    validationSchema={coreDetailsFormSchema}
                >
                    <TabContent />
                </Form>
            </Modal>
        </>
    );
};

export { CareOfferedCostFundingModal };
