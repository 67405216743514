import React, { FC } from 'react';
import { FormSelect } from '../../../../../components/common/form_select';
import { FormTextArea } from '../../../../../components/common/form_text_area';
import { FormInput } from '../../../../../components/common/form_input';
import { Form } from '../../../../../components/common/form';
import { schema } from './property_team_form.validation';
import { buildDateOptions } from './helpers';
import { ISessionProperty } from '../../../../../interfaces/session';
import { MeetTheTeamMemberType } from '../../types';
import { FormFacilitySelect, PhotoUpload } from './components';

interface Props {
    properties: ISessionProperty[];
    defaultValues?: MeetTheTeamMemberType;
    onSubmit: (values: MeetTheTeamMemberType) => Promise<void>;
    id?: string;
}

const PropertyTeamForm: FC<Props> = ({
    properties,
    defaultValues,
    onSubmit,
    id
}) => {
    return (
        <Form<MeetTheTeamMemberType>
            id={id}
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            validationSchema={schema}
        >
            {({ watch }) => {
                const description = watch('description');
                const descriptionLength = description?.length || 0;

                const descriptionFieldDescription = (
                    <>
                        <ul className="marker:text-brand_grey-medium list-disc ps-5 text-sm text-brand_grey-medium leading-tight">
                            <li>
                                Please enter a named individual, and avoid using
                                a single profile to cover all team members.
                            </li>
                            <li>
                                Please try and make the description as personal
                                as possible, to help the caregivers get to know
                                the team who will be looking after their loved
                                one.
                            </li>
                            <li>
                                Please avoid any corporate promotional text in
                                the description.
                            </li>
                            <li>
                                Please write about the role, personal
                                experience, qualifications, achievements and
                                passion for care of this team member.
                            </li>
                            <li>
                                The description can be written either in first
                                person (I ..) or third person ('Name'/They..)
                            </li>
                            <li>
                                These profiles will show on the website within
                                60 minutes of being entered, however every
                                profile is moderated by the nursinghomes.com
                                team, so maybe unpublished at time of
                                moderation.
                            </li>
                        </ul>
                        <p className="fw-bold fst-normal mb-1">
                            Please write about the role, personal experience,
                            qualifications, achievements and passion for care of
                            this team member, using a minimum of 100 characters,
                            and ideally 200-300 characters - # characters
                            including spaces: {descriptionLength}
                        </p>
                    </>
                );

                return (
                    <div className="grid gap-3">
                        <FormFacilitySelect properties={properties} />
                        <div className="flex flex-wrap gap-3">
                            <div className="w-full max-w-96">
                                <FormInput
                                    name="firstName"
                                    label="First Name"
                                />
                            </div>
                            <div className="w-full max-w-96">
                                <FormInput name="lastName" label="Last Name" />
                            </div>
                        </div>
                        <div className="flex flex-wrap gap-3">
                            <div className="w-full max-w-96">
                                <FormInput name="position" label="Job Title" />
                            </div>
                            <FormSelect
                                name="yearStarted"
                                options={buildDateOptions()}
                                label="Year Started"
                                maxWidth="200px"
                            />
                        </div>
                        <FormTextArea
                            name="description"
                            label="Description"
                            rows={5}
                            intro={descriptionFieldDescription}
                        />
                        <PhotoUpload />
                    </div>
                );
            }}
        </Form>
    );
};

export { PropertyTeamForm };
