import React from 'react';
import { Modal } from '../../components/common/modal';
//import { ModalTitle } from '../../components/common/modal/modalTitle';
import IndividualPropertyVideos from './individual_property_videos';

import { 
    IPortalPropertyAsset
 } from '../../interfaces/asset';

interface IProps {
    handleClose(): void;
    handleReloadPropertyVideos: (nhID: number[]) => Promise<IPortalPropertyAsset[]>;
    orgNHID: number | null;
    nhIDs: number[];
    selNHID: number | null;
    propertyVideos: IPortalPropertyAsset[];
    setPropertyVideos: (propertyVideos: IPortalPropertyAsset[]) => void;
    busyLoadingAsset: boolean;
    setBusyLoadingAsset: (busyState: boolean) => void;
}

const VideoUploadModal = ({
    handleClose,
    handleReloadPropertyVideos,
    orgNHID,
    nhIDs,
    selNHID,
    propertyVideos,
    setPropertyVideos,
    busyLoadingAsset,
    setBusyLoadingAsset 
}: IProps) => {

    const propertyName = (propertyVideos || []).length > 0 ? propertyVideos[0].Property.Name : '';

    /*const title = (
        <ModalTitle
            title='Upload a New Video'
            rightSideText={propertyName}
        />
    );*/

    return (
        <>
            <Modal
                isOpen={true}
                onClose={handleClose}
                title='Upload a New Video'
                subTitle={propertyName}
                size='xl'
            >
                <IndividualPropertyVideos
                    handleReloadPropertyVideos={handleReloadPropertyVideos}
                    orgNHID={orgNHID}
                    nhIDs={nhIDs}
                    selNHID={selNHID}
                    propertyVideos={propertyVideos}
                    setPropertyVideos={setPropertyVideos}
                    busyLoadingAsset={busyLoadingAsset}
                    setBusyLoadingAsset={setBusyLoadingAsset}
                    handleClose={handleClose}
                />
            </Modal>
        </>
    );
};

export default VideoUploadModal;