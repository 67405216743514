// Dan to do whole form
import React, { useMemo, useState } from 'react';
import { getTourDetailsList } from '../../../api/tours';
import { useQuery } from '@tanstack/react-query';
import DataTable, { DataTableSortOrder } from '../../../components/common/data_table';
import { getColumns } from './property_tour_availability_columns';
import { TableExpandedRowItem } from './components/table_expanded_row_item';

import PageCommunitySelectWrapper from '../../../components/common/page_community_select_wrapper';
import { useCommunitySelectHistory } from '../../../hooks/use_community_select_history';
import { displaySuccessToast, failedToSaveTourError, moveOrgToTopRow, prepareDataForForm } from './helpers';
import BookATourForm from './book_a_tour_form/book_a_tour_form';
import { Button } from '../../../components/common/button';
import { useUpdateTourDetails } from './hooks/useUpdateTourDetails';

import { ProfileScoreType } from '../../../interfaces/property';
import ProfileCompletenessHeader from '../../../components/profile_completeness/profile_completeness_header';
import { useProfileCompletenessFilter } from '../../../hooks/use_profile_completeness_filter';
import { boolSort, textSort } from '../../../utils/common';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { usePremiumStatus } from '../../../hooks/use_premium_status';
import { useSessionPropertiesMap } from '../../../hooks/useSessionPropertiesMap';
interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

const PropertyTourAvailability = ({ nhIDs, orgNHID }: IProps) => {
    const isSingleFacilitySession = nhIDs?.length === 1;
    const [selNHID, setSelNHID] = useState<number|null>(isSingleFacilitySession ? nhIDs[0] : null);
    const showTable = nhIDs?.length !== 1 && !selNHID;
    const [sortField, setSortField] = useState<string>('Name');
    const [sortOrder, setSortOrder] = useState<DataTableSortOrder>(DataTableSortOrder.Ascending);
    const { isPremium } = usePremiumStatus(selNHID);
    const propertyDictionary = useSessionPropertiesMap({ includeOrg: true });
    const {
        withProfileCompleteness, 
        handleProfileCompletenessFilterChange, 
        profileCompletenessFilterValue
    } = useProfileCompletenessFilter(ProfileScoreType.TourAvailability);

    const {mutate, isPending } = useUpdateTourDetails(selNHID);

    const sortFn = useMemo(() => {
        switch(sortField) {
            case'Name':
                return textSort;
            case 'ProfileComplete': 
                return boolSort;
            case'TourTypeName':
                return textSort;
            case 'UpdatedAt':
                return textSort;
            case 'UpdatedBy':
                return textSort;
            default:
              console.log(`No sort function declared for column ${sortField}`);
        }
    }, [sortField]);

    const handleTableSortChange = (
        column: TableColumn<any>,
        sortOrder: SortOrder
    ) => {
        setSortField(column.sortField);
        setSortOrder(
            sortOrder === 'asc'
                ? DataTableSortOrder.Ascending
                : DataTableSortOrder.Descending
        );
    };

    const { data = { rows: [], totalRowCount: 0 }, isLoading } = useQuery({
        queryFn: () => getTourDetailsList(),
        queryKey: ['portal', 'tour', 'details']
    });

    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        if (!isSingleFacilitySession) {
            setSelNHID(selNHID);
        }
    });

    const selectedPropertyTourAvailabilityDetails = data.rows?.find(
        (item) => item.NHID === selNHID
    ) || data.rows?.[0];

    const formId = 'book-a-tour';

    const filteredRows = 
        moveOrgToTopRow(withProfileCompleteness(data.rows.map((item) =>  ({...item, Name: propertyDictionary[item.NHID]?.Name})
    )).sort(sortFn(sortField, sortOrder)));

    return (
        <>    
            <PageCommunitySelectWrapper
                label={'Tour Availability for'}
                handleSelect={handleSelect}
                selNHID={selNHID}
            />                 
            <ProfileCompletenessHeader
                selNHID={selNHID}
                nhIDs={nhIDs}
                orgNHID={orgNHID}
                profileScoreType={ProfileScoreType.TourAvailability}
                handleProfileCompletenessFilter={handleProfileCompletenessFilterChange}
                profileCompletenessFilterValue={profileCompletenessFilterValue}
                title="for Tour Availability"
            />
            <section>
                <div className="container max-w-none 2xl:container mt-4 sm:mt-6 md:mt-8 2xl:mt-10">
                    { showTable ? 
                        <div data-table="Tour Availability" className="relative [&>div]:scrollbar [&>div]:pb-2 [&>div]:-mx-3 [&>div]:w-[calc(100%+1.5rem)] [&>div]:px-3 sm:[&>div]:-mx-4 sm:[&>div]:w-[calc(100%+2rem)] sm:[&>div]:px-4 xl:[&>div]:mx-0 xl:[&>div]:w-full xl:[&>div]:px-0 before:h-full before:top-0 before:absolute before:z-10 before:bg-gradient-to-r before:from-white before:w-3 before:-left-3 after:h-full after:top-0 after:absolute after:z-10 after:bg-gradient-to-l after:from-white after:-right-3 after:w-3 sm:before:-left-4 sm:before:w-4 sm:after:-right-4 sm:after:w-4 xl:before:content-none xl:after:content-none">
                            <DataTable
                                isLoading={isLoading}
                                columns={getColumns({ moveToSingleFacilityView: setSelNHID })}
                                data={filteredRows}
                                onSort={handleTableSortChange}
                                pagination={true}
                                paginationTotalRows={data?.totalRowCount}
                                expandableRows
                                expandableRowsComponent={TableExpandedRowItem}
                            />
                        </div> : 
                        <div className="grid gap-3 sm:gap-5 md:gap-6 2xl:gap-7">
                            <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg">
                                <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 2xl:py-3 min-h-14 flex items-center">
                                    <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                                        Tour Availability
                                    </h4>
                                </div>
                                <div className="p-3 sm:p-4 2xl:p-5 h-full">
                                    { selectedPropertyTourAvailabilityDetails && (
                                        <BookATourForm
                                            id={formId}
                                            defaultFormValues={prepareDataForForm(selectedPropertyTourAvailabilityDetails)}
                                            handleSubmit={(values) => mutate(values, { onError: failedToSaveTourError, onSuccess: displaySuccessToast })}
                                            actionButton={
                                                isPremium 
                                                    ? ({formState}) => (
                                                        <Button 
                                                            className="!fixed bottom-6 left-1/2 -translate-x-1/2 shadow-xl ring-4 ring-white" 
                                                            isLoading={isPending} 
                                                            type="submit" 
                                                            form={formId} 
                                                            disabled={!formState.isDirty}
                                                        >
                                                            Update
                                                        </Button>
                                                    ) : (
                                                        <div className='msg msg-error'>
                                                            Premium Subscription Required
                                                        </div>
                                                    )
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </section>
        </>
    );
};

export { PropertyTourAvailability };
