import React from 'react';
import { useQuery } from '@tanstack/react-query';

import { getReviewCountTotals } from '../../../api/review';
import { formatFromDateForAPIRequestUTC } from '../../../utils/date';
import { PublishedReviewsWidgetXS } from './published_reviews_widget_xs';
import { IPublishedReviewsWidgetProps } from './types';

export const PublishedReviewsWidget = ({ 
    selNHID,
    widgetSize,
}: IPublishedReviewsWidgetProps) => { 
    const dateNow = new Date();
    dateNow.setHours(0, 0, 0, 0);
    const todayUTC = formatFromDateForAPIRequestUTC(dateNow);
    const filters = {
        NHID: selNHID,
        TodaysDateUTC: todayUTC
    };
    
    const { data, isLoading, isFetching } = useQuery({ 
        queryKey: [ 'review', 'totals', 'count', filters ], 
        queryFn: async () => {
            const data = await getReviewCountTotals(filters.NHID, filters.TodaysDateUTC);    
            
            return {
                reviewCounts: data
            };
        },
    });

    const loadingData = (isLoading || isFetching || !data);

    const { reviewCounts } = data ?? {};

    let totalPublished = 0;

    if (reviewCounts) {
        
        const {
            TotalPublished,
        } = reviewCounts;

        totalPublished = TotalPublished || 0;
    }
    
    return <PublishedReviewsWidgetXS 
        selNHID={selNHID} 
        totalPublished={totalPublished}
        loadingData={loadingData}
    />;                          
};
