import React from 'react';
import { useSessionPropertiesMap } from '../../../../hooks/useSessionPropertiesMap';
interface Props {
    IsOrganization: boolean;
    NHID: number;
    TourAvailabilityID?: number;
    onNameClicked: () => void;
}

const FacilityNameColumnItem = (item: Props) => {
    const { IsOrganization, NHID, onNameClicked, TourAvailabilityID } = item;
    const hasTourAvailabilityDetails = !!TourAvailabilityID;
    const propertyDictionary = useSessionPropertiesMap({ includeOrg: true });

    const {
        RegionCode,
        PostCode,
        City,
        Address1,
        Name,
        WebsiteURL,
        CitySlug,
        Slug,
        StateSlug
    } = propertyDictionary?.[NHID] || {};

    const websiteLink = IsOrganization
        ? `${WebsiteURL}/provider/${Slug}`
        : `${WebsiteURL}/${StateSlug}/${CitySlug}/${Slug}`;

    const inheritToursMessage = (
        <div className="mt-2 mb-2 text-nowrap position-absolute top-0 py-1 ms-5">
            <span className="msg msg-info">
                {hasTourAvailabilityDetails
                    ? 'As Tour Availability is set for this Provider, it will show the \'Request Tour\' button on all Facility pages where no Facility Availability has been set.'
                    : 'If Tour Availability is set for the Provider, it will show the \'Request Tour\' button on all Facility pages where no Facility Availability has been set.'}
            </span>
        </div>
    );

    return (
        <div className="flex flex-wrap gap-2 break-words py-1">
            {IsOrganization && inheritToursMessage}
            <a 
                href="#" 
                onClick={onNameClicked}
            >
                <strong className="block leading-tight lg:text-lg">
                    {Name}
                </strong>
            </a>
            { !IsOrganization ?
                <span className="inline-block text-xs lg:text-sm me-3 leading-tight text-brand_grey-medium">
                    {Address1}, {City}, {PostCode}, {RegionCode}
                </span> : null 
            }
            <a
                className="link text-xs bg-brand_faint-blue px-2 py-1 inline-block leading-none rounded-lg relative -top-px"
                href={websiteLink}
                target="_blank"
            >
                view on website
            </a>
        </div> 
    );
};

export { FacilityNameColumnItem };
