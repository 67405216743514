import React from 'react';

interface Props {
    title: string;
    subtitle?: any;
}

const SectionHeading = ({title, subtitle}: Props) => {
    return (
        <div className="px-3 sm:px-4 2xl:px-5 py-2 sm:py-3 2xl:py-4 mb-3 lg:mb-5 2xl:mb-6 bg-brand_faint-grey">
            <strong className="font-semibold leading-tight text-lg lg:text-xl">
                {title}
            </strong>
            { subtitle && 
                <span className="block text-brand_grey-medium text-xs mt-1">
                    {subtitle}
                </span>
            }
        </div>
    );
};

export { SectionHeading };