import React, { ReactElement } from 'react';

import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import PageTitle from '../../components/common/page_title';
import { IMenuItem, IMenuState } from '../../interfaces/menu';
import { ISessionProperty, ISessionState } from '../../interfaces/session';
import { LoadingDots } from '../../components/common/loading_dots';
import { get } from 'lodash';
import MobileSelectMenu from '../../components/menu/mobile_select_menu';
import BreadcrumbMenu from '../../components/menu/breadcrumb_menu';
import { findMenuItem } from '../../components/menu/common';

interface IProps {
    component: any;
    menuPath?: string;
    bannerComponent?: any;
    renderPageTitle?: (headerTitle: string) => ReactElement;
    automaticallyAddPageTitle?: boolean
}

const PageTemplate = ({component, menuPath, bannerComponent, automaticallyAddPageTitle = true}: IProps) => {
    const { Menu }: IMenuState = useSelector(
        ({ MenuState }: any) => MenuState
    );

    const { Session, Org, Properties }: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    let nhIDs: number[] | null;
    let orgNHID: number | null;

    if (Org) {
        orgNHID = Org.NHID;
        nhIDs = null;
    } else {
        if (Properties) {
            orgNHID = null;
            nhIDs = Properties.map((item: ISessionProperty) => item.NHID);
        }
    }

    const currentPage: IMenuItem = findMenuItem(Menu, menuPath, location.pathname);
    const canViewTestFeatures = get(Session, 'Perms.CanViewTestFeatures', false);

    if (!currentPage || (currentPage.IsTestFeature && !canViewTestFeatures)) {
        return;
    }
    
    const renderSupportLink = () => {
        return (
            <a 
                onClick={() => window.frames[0].document.getElementById('doc360-button').click()}
                className="fw-bold support-link"
            >
                Learn more in the Support Center
            </a>
        );
    };

    const renderMeta = () => {
        const title = `${currentPage.PageTitle} | nursinghomes.com Portal `;

        return (
            <Helmet>
                <meta charSet="utf-8" />
                <title>{ title }</title>
                <meta name="description" content={ title } />
            </Helmet>
        );
    };

    const renderComponent = (): any => {
        if (!Session) {
            return null;
        }

        const Component = component;

        const props = { 
            nhIDs,
            orgNHID,
            pageTitle: currentPage.PageTitle
        };

        return (
            <Component {...props}/>
        );
    };

    const renderBannerComponent = () => {
        if (!bannerComponent) {
            return null;
        }

        const BannerComponent = bannerComponent;

        const props = { 
            nhIDs,
            orgNHID
        };

        return (
            <BannerComponent {...props} />
        );
    };


    const renderComponentWithHeader = () => {
        const { HeaderTitle, HeaderText, SupportArticleButtonShow } = currentPage;

        return (
            <>
                <BreadcrumbMenu menuPath={menuPath} />
                { renderBannerComponent() }
                <header>
                    <div className="container max-w-none 2xl:container mt-4 sm:mt-6 md:mt-8 2xl:mt-10">
                        { automaticallyAddPageTitle && 
                            <PageTitle title={HeaderTitle}/>
                        }
                        { currentPage?.Slug === 'review-score' ?
                            <Link to="/review-score-simulator" className="link">
                                Review Score Simulator
                            </Link>: null
                        }
                        { currentPage?.Slug === 'review-score-simulator' ?
                            <Link to="/review-score" className="link">
                                Review Score Breakdown
                            </Link>: null
                        }
                        { HeaderText && 
                            <div className="mt-2 [&>ul]:list-disc [&>ul]:ps-5 [&>ul]:space-y-2 [&>ul]:text-md">
                                {parse(HeaderText)}
                            </div>
                        }
                        { SupportArticleButtonShow && 
                            renderSupportLink()
                        }
                    </div>
                </header>
                <main>
                    { renderComponent() }
                </main> 
            </>
        );
    };

    return (
        <>
            { !Session ? <LoadingDots show={true}/> : null }
            { renderMeta() }
            <nav>
                <div className="container max-w-none mt-3 sm:mt-4 lg:hidden">
                    <MobileSelectMenu menuPath={menuPath} />
                </div>
            </nav>
            { currentPage.HeaderShow ? renderComponentWithHeader() : renderComponent() } 
        </>
    );
};

export default PageTemplate;
 