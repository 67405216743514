import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { IFiltersContainerProps } from './types';
import ActiveFilters from '../active_filters';
import CsvExport, { DisplayType } from '../../csv_export/csv_export';
import { formatFileNameForCsvExport } from '../../../utils/file';
import { FiltersForm } from './filter_form';

export const FiltersContainer = ({
    filterMatrix,
    applyFilter,
    resetFilters,
    activeFilterProps,
    exportProps,
}: IFiltersContainerProps) => {

    return (
        <div>
            <input type="checkbox" className="hidden peer/filters" id="filters" />
            <div className="hidden peer-checked/filters:block relative ring-1 ring-brand_grey rounded-xl p-3 sm:p-4 bg-brand_faint-blue mb-4 sm:mb-6 md:mb-8 lg:mb-10">
                <div className="flex items-center mb-6">
                    <strong className="text-lg md:text-xl">
                        Filter Results
                    </strong>
                    <button
                        className="ms-4 btn btn-outline btn-outline-tertiary btn-small flex items-center text-md"
                        onClick={resetFilters}
                    >
                        <FontAwesomeIcon icon={faXmark} className="me-1" />Clear all filters
                    </button>
                    <label htmlFor="filters" className="ms-auto cursor-pointer rounded-full bg-brand_tertiary hover:bg-brand_tertiary-dark text-white w-8 h-8 flex justify-center items-center">
                        <FontAwesomeIcon icon={faXmark} />
                    </label>
                </div>
                <FiltersForm filterMatrix={filterMatrix} applyFilter={applyFilter} />
            </div>
            <div className="flex flex-col items-center text-center gap-3 md:flex-row md:text-start">
                <ActiveFilters 
                    filterMatrices={[filterMatrix]} 
                    totalFilteredSize={activeFilterProps.totalFilteredCount}
                    totalSize={activeFilterProps.totalCount}
                    isLoading={activeFilterProps.isBusy}
                    pageOffset={activeFilterProps.pageOffset}
                    pageLimit={activeFilterProps.pageLimit}
                    singularNoun={activeFilterProps.singularNoun}
                    pluralNoun={activeFilterProps.pluralNoun}
                    showTotals={activeFilterProps.showTotals}
                    applyFilter={applyFilter}
                    showResetLink={true}
                    refreshData={activeFilterProps.refreshData}
                />
                <div className="md:ms-auto flex flex-wrap justify-center md:justify-end gap-3 lg:flex-nowrap">
                    <label htmlFor="filters" className="btn btn-outline btn-outline-tertiary">
                        <FontAwesomeIcon icon={faFilter} className="text-3xl me-2" />Filters
                    </label>
                    {exportProps.startExport && <CsvExport 
                        startExportFunction={exportProps.startExport}
                        modalTitleSuffix={exportProps.modalTitleSuffix}
                        label="Export as CSV"
                        displayType={DisplayType.Button}
                        fileName={`${formatFileNameForCsvExport(exportProps.fileName)}`}
                    />}
                </div>
            </div>
        </div>
    );
};
