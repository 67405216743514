import React from 'react';

import { 
    IPropertyCoreDetailInsuranceType,
    IPropertyCoreDetailBasic,
    IPropertyCoreDetailPayload,
    IPropertyCoreDetailNHID
} from '../../../interfaces/property';

import * as nhAPI from '../../../api/property';
import Swal from 'sweetalert2';
import { isBoolean } from '../../../utils/common';
import { numericOnly } from './helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faClockRotateLeft, faCheck } from '@fortawesome/pro-solid-svg-icons';

type DurationUnit =  'years' | 'days' | 'months';

interface IFormInputData { 
    Label: string;
    Key: keyof IPropertyCoreDetailPayload;
    IncludedInProfileCompleteness?: boolean;
    DurationUnit?: DurationUnit;
    MaxLength?: number;
    MinIntegerValue?: number;
    MaxIntegerValue?: number;
}

const defaultCoreDetails: any = {
    LongTermCare: null,
    ShortTermCare: null,
    RespiteCare: null,
    OtherFacilityTypes: null,
    AffiliatedHospitals: null,
    AvgShortTermResidentsPc: null,
    AvgShortStayDuration: null,
    AvgLongTermResidentsPc: null,
    AvgLongTermStayDuration: null,
    MinAgeRequirement: null,
    MinAge: null,
    AdmitPediatricResidents: null,
    PediatricAvgShortTermResidentsPc: null,
    PediatricAvgShortStayDuration: null,
    PediatricAvgLongTermResidentsPc: null,
    PediatricAvgLongTermStayDuration: null,
    PediatricMaxAgeRequirement: null,
    PediatricMaxAge: null,
    RoomTypeOther: null,
    RoomTypeSemiPrivate: null,
    RoomTypeShared: null,
    RoomTypePrivateSuite: null,
    RoomTypePrivateRoom: null,
    LongTermSharedCostPerDay: null,
    LongTermPrivateCostPerDay: null,
    LongTermSemiPrivateCostPerDay: null,
    LongTermPrivateSuiteCostPerDay: null,
    LongTermSharedCostPerDayNA: null,
	LongTermPrivateCostPerDayNA: null,
	LongTermSemiPrivateCostPerDayNA: null,
    LongTermRatesAllInclusive: null,
    LongTermRatePackagesAvailable: null,
    ShortTermSharedCostPerDay: null,
    ShortTermPrivateCostPerDay: null,
    ShortTermSemiPrivateCostPerDay: null,
    ShortTermPrivateSuiteCostPerDay: null,
    ShortTermSharedCostPerDayNA: null,
	ShortTermPrivateCostPerDayNA: null,
	ShortTermSemiPrivateCostPerDayNA: null,
    ShortTermRatesAllInclusive: null,
    ShortTermRatePackagesAvailable: null,
    RespiteSharedCostPerDay: null,
    RespitePrivateCostPerDay: null,
    RespiteSemiPrivateCostPerDay: null,
    RespitePrivateSuiteCostPerDay: null,
    RespiteSharedCostPerDayNA: null,
	RespitePrivateCostPerDayNA: null,
	RespiteSemiPrivateCostPerDayNA: null,
    RespiteMinStayRequirement: null,
    RespiteMinStay: null,
    RespiteRatesAllInclusive: null,
    RespiteRatePackagesAvailable: null,
    AcceptMedicareAdvantage: null,
    AcceptMedicaidPending: null,
    BedsAllDualLicenced: null,
    BedsMedicareCount: null,
    BedsMedicaidCount: null,
    PrivateInsurancePc: null,
    ManagedCareContractsInNetwork: null,
    FacilityHasMemoryCareUnit: null,
    FacilityHasBehaviouralHealthUnit: null,
};

export const costKeyArray = [
    'LongTermSharedCostPerDay', 
    'LongTermSemiPrivateCostPerDay', 
    'LongTermPrivateCostPerDay', 
    'ShortTermSharedCostPerDay', 
    'ShortTermSemiPrivateCostPerDay', 
    'ShortTermPrivateCostPerDay', 
    'RespiteSharedCostPerDay', 
    'RespiteSemiPrivateCostPerDay', 
    'RespitePrivateCostPerDay', 
];

const costKeyNAArray = costKeyArray.map((val: string) => `${val}NA`);

const getRadioValue = (value: boolean) => value === true ? '1' : '0';

const setCoreDetailsDefaults = (coreDetails: IPropertyCoreDetailBasic) => {
    let formDefaults: any = {};

    if (coreDetails) {
        for (const [key, value] of Object.entries(coreDetails)) {
            if (!costKeyNAArray.includes(key)) {
                formDefaults[key] = isBoolean(value) ? getRadioValue(value) : value;
            } else {
                formDefaults[key] = value;
            }
        }
    } else {
        formDefaults = { ...defaultCoreDetails };
    }

    return formDefaults;
};

export const renderRadios = (register: any, key: string, readOnly: boolean) =>
    <>
        <div className="form-component form-radio">
            <label>
                <input
                    className="peer"
                    {...register(key as any)}
                    id={key}
                    name={key}
                    type="radio"
                    key={key}
                    value={1}
                    disabled={readOnly}
                /> 
                <FontAwesomeIcon icon={faCircle} className="peer-checked:block"/>
                <span>
                    Yes
                </span>
            </label> 
        </div>
        <div className="form-component form-radio">
            <label>
                <input
                    className="peer"
                    {...register(key as any)}
                    id={key}
                    name={key}
                    type="radio"
                    key={key}
                    value={0}
                    disabled={readOnly}
                /> 
                <FontAwesomeIcon icon={faCircle} className="peer-checked:block"/>
                <span>
                    No
                </span>
            </label> 
        </div>
    </>;

export const renderRadioGroup = (register: any, setValue: any, data: IFormInputData[], readOnly: boolean) => 
    data.map(({ Label, Key, IncludedInProfileCompleteness }: IFormInputData, index: number) => 
        <div className="flex flex-col gap-1 lg:flex-row lg:gap-2 lg:items-center lg:flex-wrap" key={`key-${index}`}>
            <strong className="font-medium lg:min-w-[400px] lg:max-w-[400px] lg:text-lg">
                { Label }
            </strong>
            <div className="flex flex-col gap-1.5 sm:flex-row sm:items-center sm:gap-6 lg:gap-10">
                <div className="flex flex-wrap items-center gap-x-4 gap-y-2 md:gap-x-5">
                    { renderRadios(register, Key, readOnly) }
                    <button
                        title="Reset"
                        onClick={(e) => { 
                            e.preventDefault();
                            setValue(Key, null, { shouldDirty: true });    
                        }}
                        className="rounded-full bg-brand_grey-light min-h-9 min-w-9"
                    >
                        <FontAwesomeIcon icon={faClockRotateLeft} className="h-4 w-4 link relative top-px" />
                    </button>
                </div>
                { IncludedInProfileCompleteness ? 
                    <span className="sm:[clip-path:polygon(6%_0%,100%_0%,100%_100%,6%_100%,0%_50%)] bg-brand_secondary rounded-md text-white text-2xs font-medium whitespace-nowrap py-1 px-2 sm:ps-4 w-fit">
                        Included in Profile Completeness
                    </span> : null
                }
            </div>
        </div>
    );

export const renderPercent = (register: any, errors: any, data: IFormInputData[], readOnly: boolean) => 
    data.map(({ Label, Key, IncludedInProfileCompleteness }: IFormInputData, index: number) => 
        <div className="flex flex-col gap-1 lg:flex-row lg:gap-2 lg:items-center lg:flex-wrap" key={`key-${index}`}>
            <strong className="font-medium lg:min-w-[400px] lg:max-w-[400px] lg:text-lg">
                { Label }
            </strong>
            <div className="flex flex-col gap-1.5 sm:flex-row sm:items-center sm:gap-6 lg:gap-10">
                <div className="form-component form-textbox form-group">
                    <input 
                        {...register(Key as any)} 
                        onKeyDown={numericOnly}
                        type="number" 
                        maxLength={3} 
                        max={100}
                        min="0"
                        size={1}
                        disabled={readOnly}
                        className="max-w-16 sm:max-w-20"
                        />
                    <em>
                        %
                    </em>
                </div>
                { IncludedInProfileCompleteness ? 
                    <span className="sm:[clip-path:polygon(6%_0%,100%_0%,100%_100%,6%_100%,0%_50%)] bg-brand_secondary rounded-md text-white text-2xs font-medium whitespace-nowrap py-1 px-2 sm:ps-4 w-fit">
                        Included in Profile Completeness
                    </span> : null
                }
            </div>
            {errors[Key] ? <strong className="text-brand_status-error w-full">{ (errors as any)[Key].message }</strong> : null}
        </div>
    );

const renderDurationInput = (register: any, key: string, unit: DurationUnit, readOnly: boolean) =>
    <div className="form-component form-textbox form-group">
        <input
            {...register(key as any)} 
            type="number" 
            maxLength={4} 
            size={1}
            disabled={readOnly}
            className="max-w-16 sm:max-w-20"
        />
        <em>
            { unit }
        </em>
    </div>;

export const renderDuration = (register: any, errors: any, { Label, Key, DurationUnit }: IFormInputData, readOnly: boolean) =>
    <div className="flex flex-col gap-1 lg:flex-row lg:gap-2 lg:items-center lg:flex-wrap">
        <strong className="font-medium lg:min-w-[400px] lg:max-w-[400px] lg:text-lg">
            { Label }
        </strong>
        <div className="flex flex-col gap-1.5 sm:flex-row sm:items-center sm:gap-6 lg:gap-10">
            { renderDurationInput(register, Key, DurationUnit, readOnly) }
        </div>
        {errors[Key] ? <strong className="text-brand_status-error w-full">{ (errors as any)[Key].message }</strong> : null}
    </div>;

export const renderText = (register: any, errors: any, data: IFormInputData[], doubleWidth = false) => 
    data.map(({ Label, Key }: IFormInputData) => 
        <div className="flex flex-col gap-1 lg:flex-row lg:gap-2 lg:items-center lg:flex-wrap" key={`${Label}-${Key}`}>
            <strong className="font-medium lg:min-w-[400px] lg:max-w-[400px] lg:text-lg">
                { Label }
            </strong>
            <div className={`flex flex-col gap-1.5 sm:flex-row sm:items-center sm:gap-6 lg:gap-10 w-full ${doubleWidth ? 'max-w-96' : 'max-w-56'}`}>
                <div className="form-component form-textbox w-full">
                    <input
                        type="text"
                        {...register(Key as any)} 
                    />
                </div>
            </div>
            {errors[Key] ? <strong className="text-brand_status-error w-full">{ (errors as any)[Key].message }</strong> : null}
        </div>
    );

export const renderOptionalDuration = (register: any, errors: any, setValue: any, watch: any, { Label, Key, DurationUnit, IncludedInProfileCompleteness }: IFormInputData, readOnly: boolean) => {
    const requirementKey = `${Key}Requirement`;

    return (
        <div className="flex flex-col gap-1 lg:flex-row lg:gap-2 lg:items-center lg:flex-wrap">
            <strong className="font-medium lg:min-w-[400px] lg:max-w-[400px] lg:text-lg">
                { Label }
            </strong>
            <div className="flex flex-col gap-1.5 sm:flex-row sm:items-center sm:gap-6 lg:gap-10">
                <div className="flex flex-wrap items-center gap-x-4 gap-y-2 md:gap-x-5">
                    { renderRadios(register, requirementKey, readOnly) }
                    { renderDurationInput(register, Key, DurationUnit, readOnly || watch(requirementKey) !== '1') }
                    <button
                        title="Reset"
                        onClick={(e) => { 
                            e.preventDefault();
                            setValue(requirementKey, null, { shouldDirty: true });    
                        }}
                        className="rounded-full bg-brand_grey-light min-h-9 min-w-9"
                    >
                        <FontAwesomeIcon icon={faClockRotateLeft} className="h-4 w-4 link relative top-px" />
                    </button>
                </div>
                { IncludedInProfileCompleteness ? 
                    <span className="sm:[clip-path:polygon(6%_0%,100%_0%,100%_100%,6%_100%,0%_50%)] bg-brand_secondary rounded-md text-white text-2xs font-medium whitespace-nowrap py-1 px-2 sm:ps-4 w-fit">
                        Included in Profile Completeness
                    </span> : null
                }
            </div>
            {errors[Key] ? <strong className="text-brand_status-error w-full">{ (errors as any)[Key].message }</strong> : null}
        </div>   
    );
};

export const renderCostPerDay = (register: any, errors: any, data: IFormInputData[], readOnly: boolean, costFieldReadOnly: boolean, includeNA: boolean) => 
    data.map(({ Label, Key, IncludedInProfileCompleteness }: IFormInputData, index: number) => 
        <div className="flex flex-col gap-1 lg:flex-row lg:gap-2 lg:items-center lg:flex-wrap" key={`key-${index}`}>
            <strong className="font-medium lg:min-w-[400px] lg:max-w-[400px] lg:text-lg">
                { Label }
            </strong>
            <div className="flex flex-col gap-1.5 sm:flex-row sm:items-center sm:gap-6 lg:gap-10">
                <div className="flex flex-wrap items-center gap-x-4 gap-y-2 md:gap-x-5">
                    <div className="form-component form-textbox form-group">
                        <em>
                            $
                        </em>
                        <input
                            {...register(Key as any)} 
                            type="number" 
                            maxLength={4}
                            size={1}
                            disabled={readOnly || costFieldReadOnly}
                            className="max-w-16 sm:max-w-20"
                        />
                        <em>
                            starting price per day
                        </em>
                    </div>
                    { includeNA &&
                        <div className="form-component form-checkbox">
                            <label>
                                <input
                                    {...register(`${Key}NA` as any)} 
                                    type="checkbox"
                                    id={`${Key}NA`}
                                    name={`${Key}NA`}
                                    disabled={readOnly}
                                    />
                                <FontAwesomeIcon icon={faCheck} className="peer-checked:block"/>
                                Not Applicable
                            </label>         
                        </div>
                    }
                </div>
                { IncludedInProfileCompleteness ? 
                    <span className="sm:[clip-path:polygon(6%_0%,100%_0%,100%_100%,6%_100%,0%_50%)] bg-brand_secondary rounded-md text-white text-2xs font-medium whitespace-nowrap py-1 px-2 sm:ps-4 w-fit">
                        Included in Profile Completeness
                    </span> : null
                }
            </div>
            {errors[Key] ? <strong className="text-brand_status-error w-full">{ (errors as any)[Key].message }</strong> : null}
        </div>
    );

    export const renderInteger = (register: any, errors: any, data: IFormInputData[], readOnly: boolean) => 
        data.map(({ Label, Key, MinIntegerValue, MaxIntegerValue, IncludedInProfileCompleteness }: IFormInputData, index: number) => 
            <div className="flex flex-col gap-1 lg:flex-row lg:gap-2 lg:items-center lg:flex-wrap" key={`key-${index}`}>
                <strong className="font-medium lg:min-w-[400px] lg:max-w-[400px] lg:text-lg">
                    { Label }
                </strong>
                <div className="flex flex-col gap-1.5 sm:flex-row sm:items-center sm:gap-6 lg:gap-10">
                    <div className="flex flex-wrap items-center gap-x-4 gap-y-2 md:gap-x-5">
                        <div className="form-component form-textbox">
                            <input 
                                {...register(Key as any, {
                                    validate: { minValidFn: (value: string) => parseInt(value, 10) >= MinIntegerValue,
                                        maxValidFn: (value: string) => parseInt(value) <= MaxIntegerValue },
                                })} 
                                type="number" 
                                maxLength={3} 
                                size={1}
                                disabled={readOnly}
                                className="max-w-24 sm:max-w-28"
                            />
                        </div>
                    </div>
                    { IncludedInProfileCompleteness ? 
                        <span className="sm:[clip-path:polygon(6%_0%,100%_0%,100%_100%,6%_100%,0%_50%)] bg-brand_secondary rounded-md text-white text-2xs font-medium whitespace-nowrap py-1 px-2 sm:ps-4 w-fit">
                            Included in Profile Completeness
                        </span> : null
                    }
                </div>
                {errors[Key] ? <strong className="text-brand_status-error w-full">{ (errors as any)[Key].message }</strong> : null}
            </div>
        );

const setInsuranceTypeDefaults = (insuranceTypes: IPropertyCoreDetailInsuranceType[]) => {
    if (!insuranceTypes) {
        return null;
    }

    const formDefaults: any = {};

    insuranceTypes.forEach(({ PropertyInsuranceTypeID, IsActive }: IPropertyCoreDetailInsuranceType) =>
        formDefaults[`InsuranceType_${PropertyInsuranceTypeID}`] = isBoolean(IsActive) ? getRadioValue(IsActive) : IsActive
    );
    
    return formDefaults;
};

export const getFormDefaults = async (nhID: number): Promise<any> => {

    let formDefaults = {};
    let insuranceTypes: any;

    try {

        const rows = await nhAPI.getPropertyCoreDetails();

        if (rows?.length) {

            const data = rows.find((item: IPropertyCoreDetailNHID) => item.NHID === nhID);

            if (data) {

                const { InsuranceTypes, CoreDetails } = data;
                
                formDefaults = { 
                    ...setCoreDetailsDefaults(CoreDetails),
                    ...setInsuranceTypeDefaults(InsuranceTypes)
                };

                insuranceTypes = InsuranceTypes;

                return [formDefaults, insuranceTypes];
            } else {
                throw new Error(`Core details not found for nhid ${nhID}`);
            }
        }

        return [null, null];

    } catch(err) {
        Swal.fire({
            title: 'Error',
            text: `An error occurred getting Core Details: ${(err as any).message}`,
            icon: 'warning',
            iconHtml: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path d="M96 64c0-17.7-14.3-32-32-32S32 46.3 32 64l0 256c0 17.7 14.3 32 32 32s32-14.3 32-32L96 64zM64 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg>',
            confirmButtonText: 'Okay',
            allowOutsideClick: false,
            buttonsStyling: false,
            customClass: {
                confirmButton: 'btn btn-success m-1',
            }
        });  
    }
};
