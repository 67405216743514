import React, { FC, forwardRef, ForwardRefRenderFunction, InputHTMLAttributes, ReactElement } from 'react';
import cx from 'classnames';
import { UseFormReturn } from 'react-hook-form';
import { ConnectForm } from '../form/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';


type CheckBoxOption = {
    label: string;
    value: number;
};

interface GroupProps {
    groupLabel?: string;
    name: string;
    options?: CheckBoxOption[];
    inline?: boolean;
    inlineOptions?: boolean;
    labelStyle?: 'normal' | 'bold';
    inlineLabelWidth?: 'small' | 'large' | 'auto';
}

interface CheckBoxProps
    extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
    value: number;
    label: string;
    hasError?: boolean;
}

type FieldRequired = { isRequired: (fieldName: string) => boolean };

const inlineLabelWidths = {
    small: 150,
    large: 250,
    auto: 0
};

const FormCheckBoxGroup: FC<GroupProps> = (props) => {
    const {
        name,
        options,
        groupLabel,
        inline,
        inlineOptions = inline,
        inlineLabelWidth = 'small'
    } = props;

    return (
        <ConnectForm>
            {({
                formState,
                register,
                setValue,
                watch,
                isRequired
            }: UseFormReturn & FieldRequired) => {
                const handleChange = (
                    e: React.ChangeEvent<HTMLInputElement>
                ) => {
                    let currentValue = watch(name);
                    const changedValue = Number(e.target.value);
                    if (currentValue.includes(changedValue)) {
                        currentValue = currentValue.filter(function (
                            item: number
                        ) {
                            return item !== changedValue;
                        });
                    } else {
                        currentValue.push(changedValue || null);
                    }

                    setValue(name, currentValue, { shouldDirty: true });
                };

                const fieldError: { message?: string } =
                    formState?.errors?.[name];
                const errorMessage: ReactElement = (
                    <strong className="text-brand_status-error w-full">
                        {fieldError?.message}
                    </strong>
                );

                const groupWrapperClassName = cx('flex gap-y-2 gap-x-4 flex-wrap', {
                    'flex-row': inlineOptions,
                    'flex-col': !inlineOptions
                });


                const wrapperClassName = cx('flex gap-1 lg:gap-2 lg:flex-wrap', {
                    'flex-col lg:flex-row lg:items-center': inline,
                    'flex-col': !inline
                });

                return (
                    <div className={wrapperClassName}>
                        {groupLabel && (
                            <strong
                                className="font-medium lg:text-lg"
                                style={{
                                    minWidth: inline
                                        ? inlineLabelWidth === 'auto'
                                            ? ''
                                            : `${inlineLabelWidths[inlineLabelWidth]}px`
                                        : '100%',
                                    maxWidth: '100%'
                                }}
                            >
                                {groupLabel}
                                {isRequired(name) && '*'}
                            </strong>
                        )}
                        <div className={groupWrapperClassName}>
                            {options.map((option) => {
                                return (
                                    <CheckboxWithRefs
                                        value={option.value}
                                        checked={watch(name).includes(
                                            option.value
                                        )}
                                        label={option.label}
                                        key={`${name}_${option.value}`}
                                        className="m-0"
                                        hasError={!!fieldError?.message}
                                        {...register(name)}
                                        onChange={handleChange}
                                    />
                                );
                            })}
                        </div>
                        {fieldError && errorMessage}
                    </div>
                );
            }}
        </ConnectForm>
    );
};

// ref declared to silence console warnings, but left unused. TODO - find better solution.
const CheckBox: ForwardRefRenderFunction<any, CheckBoxProps> = (props, ref) => {
    const { id, label: labelText, className, value, hasError, ...rest } = props;
    const inputId: string = id || rest.name + labelText + '-' + value;
    // const inputClassName: string = cx('form-check-input', className, { 'border border-danger': hasError});
    return (
        <div className={cx('form-component form-checkbox', { 'form-error' : hasError })}>
            <label>
                <input
                    className="peer"
                    type="checkbox"
                    id={inputId}
                    value={value}
                    {...rest}
                />
                <FontAwesomeIcon icon={faCheck} className="peer-checked:block"/>
                {labelText}
            </label>
        </div>
    );
};

const CheckboxWithRefs = forwardRef(CheckBox);

export { FormCheckBoxGroup };
