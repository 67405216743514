export class DataRows<T> {
    data: T[];
    totalRows: number;
    totalFilteredRows: number;
}

export interface IDataControls {
    Offset?: number,
    Limit?: number,
    SortField?: string,
    SortOrder?: string
}

export interface IMessageType {
    type: string,
    message: string
}

export enum OpType {
    Create = 'create',
    Update = 'update',
    Delete = 'delete'
}
