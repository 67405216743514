import Select, { StylesConfig, ClassNamesConfig, Props as SelectProps } from 'react-select';
import { ConnectForm } from '../form/form';
import React, { FC, ReactElement } from 'react';
import cx from 'classnames';

type Option = { label: string; value: any };

interface Props extends SelectProps {
    label?: string;
    name: string;
    options: Option[];
    isMulti?: boolean;
    isSearchable?: boolean;
    inlineLabel?: boolean;
    maxWidth?: string;
    layout?: 'normal' | 'horizontal';
}

const FormSelect: FC<Props> = (props) => {
    const {
        name,
        id,
        label,
        options,
        isMulti = false,
        isSearchable = true,
        inlineLabel = true,
        maxWidth,
        layout = 'normal',
        ...rest
    } = props;

    return (
        <ConnectForm>
            {(methods: any) => {
                const { register, formState, setValue, watch, isRequired } =
                    methods;
                const fieldError = formState?.errors?.[name];
                const inputId: string = id || name;
                const currentSelectedValue = watch(name);

                const errorMessage: ReactElement = (
                    <>
                        { layout === 'horizontal' ?
                            <strong className="text-brand_status-error w-full">
                                {fieldError?.message}
                            </strong> :
                            <span>
                                {fieldError?.message}
                            </span>
                        }
                    </>
                );

                const filterValue: Option[] = options.filter(
                    ({ value }: Option) =>
                        value === Number(currentSelectedValue) || value === currentSelectedValue
                );

                return (
                    <>
                        { layout === 'horizontal' ?
                            <div className="flex flex-col gap-1 lg:flex-row lg:gap-2 lg:items-center lg:flex-wrap">
                                {label && (
                                    <label
                                        htmlFor={inputId}
                                        className="font-medium lg:min-w-[400px] lg:max-w-[400px] lg:text-lg"
                                    >
                                        {label}
                                        {isRequired(name) && ' *'}
                                    </label>
                                )}
                                <div className="flex flex-col gap-1.5 sm:flex-row sm:items-center sm:gap-6 lg:gap-10 flex-grow">
                                    <div className="flex flex-wrap items-center gap-x-4 gap-y-2 md:gap-x-5 w-full max-w-[600px]">
                                        <div className={cx('form-component form-select flex-grow', { 'form-error' : fieldError })} style={{maxWidth: maxWidth}}>
                                            <Select
                                                {...register(name)}
                                                {...rest}
                                                classNamePrefix="select"
                                                id={inputId}
                                                options={options}
                                                value={filterValue}
                                                onChange={(val: Option) =>
                                                    setValue(name, val?.value, { shouldDirty: true })
                                                }
                                                unstyled
                                                isSearchable={isSearchable}
                                                isMulti={isMulti}
                                            />                                    
                                        </div>
                                    </div>
                                    {fieldError && errorMessage}
                                </div>
                            </div> :   
                            <div className={cx('form-component form-select', { 'form-inline' : inlineLabel, 'form-error' : fieldError })} style={{maxWidth: maxWidth}}>
                                {label && (
                                    <label
                                        htmlFor={inputId}
                                    >
                                        {label}
                                        {isRequired(name) && ' *'}
                                    </label>
                                )}
                                <Select
                                    {...register(name)}
                                    {...rest}
                                    classNamePrefix="select"
                                    id={inputId}
                                    options={options}
                                    value={filterValue}
                                    onChange={(val: Option) =>
                                        setValue(name, val?.value, { shouldDirty: true })
                                    }
                                    unstyled
                                    isSearchable={isSearchable}
                                    isMulti={isMulti}
                                />                                    
                                {fieldError && errorMessage}
                            </div>
                        }
                    </>

                );
            }}
        </ConnectForm>
    );
};

export { FormSelect };