import React, { FC, Fragment, ReactElement, ReactNode } from 'react';
import {
    Dialog,
    DialogPanel,
    DialogTitle,
    Transition,
    TransitionChild
} from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import cx from 'classnames';
import { Button } from '../button';

interface Props {
    onClose: () => void;
    isOpen: boolean;
    title?: ReactNode;
    subTitle?: ReactNode;
    lastUpdated?: ReactNode;
    children?: ReactNode;
    closeButton?: boolean;
    footerContent?: ReactElement;
    footerContentClose?: boolean;
    tabHeaders?: ReactElement;
    blurBackdrop?: boolean;
    noBodyPadding?: boolean;
    size?: 'sm' | 'md' | 'lg' | 'xl' | 'full';
}

const Modal: FC<Props> = (props) => {
    const {
        onClose,
        isOpen,
        title,
        subTitle,
        lastUpdated,
        children,
        footerContent,
        footerContentClose = true,
        closeButton = true,
        tabHeaders,
        size = 'xl',
        blurBackdrop = true,
        noBodyPadding = false
    } = props;

    const stylePanel = {
        '--size':
            size === 'sm'
                ? '576px'
                : size === 'md'
                ? '768px'
                : size === 'lg'
                ? '896px'
                : size === 'xl'
                ? '1280px'
                : '100%'
    } as React.CSSProperties;

    const modalContentClassName = cx(
        'overflow-y-auto scrollbar scrollbar-modal relative',
        { 'p-3 sm:p-4 2xl:p-5': !noBodyPadding },
        // if there are tabs - keep the modal content full height so the tabs
        // don't move about due to different heights of the content
        { 'h-screen': !!tabHeaders }
    );

    return (
        <Transition appear show={isOpen} as={Fragment} static>
            <Dialog
                as="div"
                className="relative z-50 focus:outline-none"
                onClose={onClose}
            >
                <div
                    className={cx(
                        'fixed z-10 w-screen overflow-y-auto',
                        'inset-y-0 left-0',
                        { 'backdrop-blur-sm': blurBackdrop }
                    )}
                >
                    <TransitionChild
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed w-full inset-y-0 overflow-y-auto bg-brand_grey-dark/50 z-10"></div>
                    </TransitionChild>

                    <TransitionChild
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 -translate-y-32"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0 -translate-y-32"
                    >
                        <div className="flex min-h-screen items-center justify-center p-3 sm:p-4 2xl:p-5 fixed inset-0 z-10">
                            <DialogPanel
                                transition
                                style={stylePanel}
                                className="flex flex-col relative w-full max-w-[var(--size)] max-h-[calc(100vh-1.5rem)] sm:max-h-[calc(100vh-2rem)] 2xl:max-h-[calc(100vh-2.5rem)] shadow-lg rounded-xl bg-white duration-300 ease-out data-[closed]:opacity-0 overflow-hidden"
                                >
                                {closeButton && (
                                    <button
                                        className="absolute right-3 sm:right-4 2xl:right-5 top-2.5 h-9 w-9 rounded-full hover:bg-brand_secondary hover:text-white flex items-center justify-center text-brand_grey-dark"
                                        onClick={onClose}
                                    >
                                        <FontAwesomeIcon
                                            icon={faXmark}
                                            className="h-5 w-5"
                                        />
                                    </button>
                                )}
                                {title && (
                                    <div
                                        className={cx(
                                            'border-b border-brand_grey px-3 sm:px-4 2xl:px-5 py-4 flex flex-wrap items-center justify-between gap-x-2 gap-y-1',
                                            { '!pr-[4.5rem]': closeButton }
                                        )}
                                    >
                                        {lastUpdated ? (
                                            <div className="flex flex-wrap items-end gap-x-4 gap-y-1">
                                                <DialogTitle
                                                    as="strong"
                                                    className="font-semibold leading-tight text-xl"
                                                >
                                                    {title}
                                                </DialogTitle>
                                                <span className="text-brand_grey-medium text-sm">
                                                    Last updated: {lastUpdated}
                                                </span>
                                            </div>
                                        ) : (
                                            <DialogTitle
                                                as="strong"
                                                className="font-semibold leading-tight text-xl w-full"
                                            >
                                                {title}
                                            </DialogTitle>
                                        )}
                                        {subTitle && (
                                            <span className="text-brand_grey-medium text-sm lg:text-md">
                                                {subTitle}
                                            </span>
                                        )}
                                    </div>
                                )}
                                {tabHeaders}
                                <div
                                    className={modalContentClassName}
                                    id="scrolling-tab-target"
                                >
                                    {children}
                                </div>
                                {footerContent && (
                                    <div className="p-3 sm:p-4 2xl:p-5 border-t border-brand_grey flex flex-wrap justify-end gap-x-4 gap-y-2 xs_max:[&>button]:w-full">
                                        {footerContentClose && (
                                            <Button
                                                onClick={onClose}
                                                styleType="outline"
                                            >
                                                Close
                                            </Button>
                                        )}
                                        {footerContent}
                                    </div>
                                )}
                            </DialogPanel>
                        </div>
                    </TransitionChild>
                </div>
            </Dialog>
        </Transition>
    );
};

export { Modal };