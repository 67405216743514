import React, { useCallback, useContext, useMemo, useState, useEffect } from 'react';
import PageCommunitySelectWrapper from '../../../components/common/page_community_select_wrapper';
import DataTable, { DataTableSortOrder } from '../../../components/common/data_table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';
import ProfileCompletenessHeader from '../../../components/profile_completeness/profile_completeness_header';
import { 
    ProfileScoreType,
    IPropertyCoreDetailNHID,
    INhPortalScoreItem,
    INhPropertyScores,
    INhPropertyScoresItem,
    IPropertyCoreDetailInsuranceType
} from '../../../interfaces/property';

import { getPropertyCoreDetails } from '../../../api/property';
import { useSelector } from 'react-redux';
import { ISessionState, ISessionProperty } from '../../../interfaces/session';
import PropertyCoreDetailsForm from './property_core_details_form';
import PropertyCostFundingForm from './property_cost_funding_form';
import { boolSort, textSort } from '../../../utils/common';
import AppContext from '../../../context/app_context';
import { useCommunitySelectHistory } from '../../../hooks/use_community_select_history';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { CareOfferedCostFundingModal } from './care_offered_modal/care_offered_cost_funding_modal';
import { useQuery } from '@tanstack/react-query';
import { RootState } from '../../../store/reducers';
import { shortFormatDate } from '../../../utils/date';
import PageTitle from '../../../components/common/page_title';
import { useLocation } from 'react-router-dom';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
    pageTitle: string;
    profileScoreType: ProfileScoreType;
}

interface ITableDataRow {
    NHID: number;
    HasCoreDetails?: boolean;
    Name: string;
    Address: string;
    CareOfferedProfileComplete: boolean;
    CostFundingProfileComplete: boolean;
    LongTermCare: boolean;
    ShortTermCare: boolean;
    RespiteCare: boolean;
    AdmitPediatricResidents: boolean;
    RoomTypesOffered: string[];
    SharedLongTermCost: string;
    SharedShortTermCost: string;
    SharedRespiteCost: string;
    PrivateLongTermCost: string;
    PrivateShortTermCost: string;
    PrivateRespiteCost: string;
    SemiPrivateLongTermCost: string;
    SemiPrivateShortTermCost: string;
    SemiPrivateRespiteCost: string;
    PrivateSuiteLongTermCost: string;
    PrivateSuiteShortTermCost: string;
    PrivateSuiteRespiteCost: string;
    CoreDetailsLastUpdated: string;
    CostFundingLastUpdated: string;
    MedicaidBedCount: string;
    MedicareBedCount: string;
    AcceptMedicareAdvantagePlans: boolean;
    AcceptMedicaidPendingPatients: boolean;
    InsuranceDetails: IPropertyCoreDetailInsuranceType[];
    FacilityHasMemoryCareUnit: boolean;
    FacilityHasBehaviouralHealthUnit: boolean;
    OtherFacilityTypes: string;
    MinAgeRequirement: boolean;
    MinAge: number;
    WebsiteURL: string; 
    StateSlug: string;
    CitySlug: string;
    Slug: string;
}

type ActiveInsuranceCount = number;
type TotalInsuranceCount = number;

const getMinAgeRequirement = (hasMinAgeRequirement: boolean | null, minAge?: number): string => {
    if (typeof hasMinAgeRequirement !== 'boolean' || !minAge) {
        return 'Unknown';
    }
    return hasMinAgeRequirement ? `Yes (${minAge})` : 'No';
};


const yesNoOrUnknown = (value: boolean | null): string => {
    // eslint-disable-next-line eqeqeq
    if (value == null) {
        return 'Unknown';
    }
    return value  ? 'Yes' :  'No';
};

const valueOrPlaceholder = (value: string | number | null, placeholder: string = 'Unknown'): string => {
        // eslint-disable-next-line eqeqeq
        if (value == null) {
            return placeholder;
        }

        return value.toString();
};

const getRoomTypesOffered = (item: IPropertyCoreDetailNHID): string[] => {
    const roomTypeArray = [];
    if (item.CoreDetails) {
      
        const types = [
            ['RoomTypePrivateRoom', 'Private'],
            ['RoomTypePrivateSuite', 'Private Suite'],
            ['RoomTypeSemiPrivate', 'Semi Private'],
            ['RoomTypeShared', 'Shared']
        ];

        for (const type of types) {
            if ((item.CoreDetails as any)[type[0]] === true) {
                roomTypeArray.push(type[1]);
           }
        }

        if (item.CoreDetails?.RoomTypeOther) {
            roomTypeArray.push(item.CoreDetails.RoomTypeOther);
        }
    }

    if (!roomTypeArray.length) {
        roomTypeArray.push('None Selected');
    }

    return roomTypeArray;
};

const formatCostOrUnknown = (cost: number | string): string => {
    if (!cost) {
        return 'Unknown';
    }

    return `$${cost}`;
};

const getInsuranceDetails = (item: IPropertyCoreDetailInsuranceType[]): [ActiveInsuranceCount, TotalInsuranceCount] => {
    if (!item) {
        return [0,0];
    }

    return [
        item.reduce((acc: number, val: IPropertyCoreDetailInsuranceType) => 
                acc + (val.IsActive ? 1 : 0), 0),
        item.length
    ];
};

const PropertyCoreDetails = ({ nhIDs, orgNHID, pageTitle, profileScoreType }: IProps) => {

    const { state } = useLocation();
    const { targetId, targetNHID } = state || {};

    const [ selNHID, setSelNHID ] = useState<number>();
    const [sortField, setSortField] = useState<string>('Name');
    const [sortOrder, setSortOrder] = useState<DataTableSortOrder>(DataTableSortOrder.Ascending);
    const [ profileCompletenessFilterValue, setProfileCompletenessFilterValue ] = useState<boolean | null>(null);
    const sessionState: ISessionState = useSelector(
        ({SessionState}: RootState) => SessionState
    );

    const profileCompletenessState: INhPortalScoreItem = useSelector(
        ({ ProfileCompletenessState }: RootState) => ProfileCompletenessState
    );

    const singlePropertySession = nhIDs?.length === 1;
    const singleNHID = singlePropertySession ? nhIDs[0] : selNHID;

    const appContext: any = useContext(AppContext);

    useEffect(() => {

        if (targetNHID) {
            handleSelect(targetNHID);
        }

        const el = document.getElementById(targetId);
        if (el) {
          el.scrollIntoView();
        }
      }, [targetId, targetNHID]);

    const { data: coreDetailData = [] } = useQuery({
        queryKey: ['property', 'core', 'details'],
        queryFn: getPropertyCoreDetails
    });

    
    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        setSelNHID(selNHID);
    });


    const getSessionData = useCallback((NHID: number) => {

        const property = (sessionState?.Properties || []).find((item: ISessionProperty) => (
            item.NHID === NHID
        ));

        const Addr =  property ? [
            property.Address1,
            property.City,
            property.PostCode,
            property.RegionCode
        ].join(', ') : '';

        const WebsiteURL = property?.WebsiteURL;
        const StateSlug = property?.StateSlug;
        const CitySlug = property?.CitySlug;
        const Slug = property?.Slug;

        return { Addr, WebsiteURL, StateSlug, CitySlug, Slug };
    }, [sessionState]);

    const getName = (NHID: number) => {

        const property = (sessionState?.Properties || []).find((item: ISessionProperty) => (
            item.NHID === NHID
        ));

        return property?.Name || '';
    };

    const getProfileComplete = (nhID: number, profileScoreType: ProfileScoreType) => {

        const propertyScore = (profileCompletenessState?.PropertyScores || []).find((item: INhPropertyScores) => (
            item.NHID === nhID
        ));

        const coreScore = (propertyScore?.Scores || []).find((item: INhPropertyScoresItem) => (
            item.KeyName === profileScoreType
        ));

        return coreScore ? coreScore.Score === coreScore.AvailableScore : false;
    };

    const getCareOfferedProfileComplete = useCallback((NHID: number) => {
        return getProfileComplete(NHID, ProfileScoreType.FacilityBaseData);
    }, [profileCompletenessState]);

    const getCostFundingProfileComplete = useCallback((NHID: number) => {
        return getProfileComplete(NHID, ProfileScoreType.CostFunding);
    }, [profileCompletenessState]);

    const sortFn = useMemo(() => {
        switch(sortField) {
            case'Name':
                return textSort;
            case 'ProfileComplete':
                return boolSort;
            case 'LastUpdated':
                return textSort;
            default:
              console.log(`No sort function declared for column ${sortField}`);
        }
    }, [sortField]);

    const rawTableData = useMemo(() => coreDetailData.map((item: IPropertyCoreDetailNHID): ITableDataRow => {
        const name = getName(item.NHID);
        const { Addr, WebsiteURL, StateSlug, CitySlug, Slug } = getSessionData(item.NHID);
        const careOfferedProfileComplete = getCareOfferedProfileComplete(item.NHID);
        const costFundingProfileComplete = getCostFundingProfileComplete(item.NHID);
        return {
            NHID: item.NHID,
            HasCoreDetails: !!item.CoreDetails,
            CoreDetailsLastUpdated: item.CoreDetails?.CoreDetailsLastUpdated,
            CostFundingLastUpdated: item.CoreDetails?.CostFundingLastUpdated,
            Name: name,
            Address: Addr,
            CareOfferedProfileComplete: careOfferedProfileComplete,
            CostFundingProfileComplete: costFundingProfileComplete,
            LongTermCare: item.CoreDetails?.LongTermCare,
            ShortTermCare: item.CoreDetails?.ShortTermCare,
            RespiteCare: item?.CoreDetails?.RespiteCare,
            AdmitPediatricResidents: item.CoreDetails?.AdmitPediatricResidents,
            RoomTypesOffered: getRoomTypesOffered(item),
            SharedLongTermCost: item.CoreDetails?.LongTermSharedCostPerDay?.toString(),
            SharedShortTermCost: item?.CoreDetails?.ShortTermSharedCostPerDay?.toString(),
            SharedRespiteCost: item?.CoreDetails?.RespiteSharedCostPerDay?.toString(),
            PrivateLongTermCost: item?.CoreDetails?.LongTermPrivateCostPerDay?.toString(),
            PrivateShortTermCost: item?.CoreDetails?.ShortTermPrivateCostPerDay?.toString(),
            PrivateRespiteCost: item?.CoreDetails?.RespitePrivateCostPerDay?.toString(),
            SemiPrivateLongTermCost: item?.CoreDetails?.LongTermSemiPrivateCostPerDay?.toString()    ,
            SemiPrivateShortTermCost: item?.CoreDetails?.ShortTermSemiPrivateCostPerDay?.toString(),
            SemiPrivateRespiteCost: item?.CoreDetails?.RespiteSemiPrivateCostPerDay?.toString(),
            PrivateSuiteLongTermCost: item?.CoreDetails?.LongTermPrivateSuiteCostPerDay?.toString(),
            PrivateSuiteShortTermCost: item?.CoreDetails?.ShortTermPrivateSuiteCostPerDay?.toString(),
            PrivateSuiteRespiteCost: item?.CoreDetails?.RespitePrivateSuiteCostPerDay?.toString(),
            MedicaidBedCount: item.CoreDetails?.BedsMedicaidCount?.toString(),
            MedicareBedCount: item.CoreDetails?.BedsMedicareCount?.toString(),
            AcceptMedicareAdvantagePlans: item.CoreDetails?.AcceptMedicareAdvantage,
            AcceptMedicaidPendingPatients: item.CoreDetails?.AcceptMedicaidPending,
            ManagedCareContractsInNetwork: item.CoreDetails?.ManagedCareContractsInNetwork,
            InsuranceDetails: item?.InsuranceTypes,
            FacilityHasMemoryCareUnit: item.CoreDetails?.FacilityHasMemoryCareUnit,
            FacilityHasBehaviouralHealthUnit: item.CoreDetails?.FacilityHasBehaviouralHealthUnit,
            OtherFacilityTypes: item.CoreDetails?.OtherFacilityTypes,
            MinAgeRequirement: item.CoreDetails?.MinAgeRequirement,
            MinAge: item.CoreDetails?.MinAge,
            WebsiteURL,
            StateSlug,
            CitySlug,
            Slug,
            ...item.CoreDetails
        };
    }).sort(sortFn(sortField, sortOrder)), [coreDetailData, sortField, sortOrder, getSessionData, getProfileComplete]);
   

    const filteredTableData = rawTableData.filter((item: ITableDataRow) => {
        
        const nhIDValid = !selNHID || item.NHID === selNHID;

        const itemProfileCompletenessValue = profileScoreType === ProfileScoreType.FacilityBaseData
            ? item.CareOfferedProfileComplete 
            : item.CostFundingProfileComplete;
        const profileCompletenessFilterValid = profileCompletenessFilterValue === null || 
            profileCompletenessFilterValue === itemProfileCompletenessValue;
        return nhIDValid && profileCompletenessFilterValid;
    });


    const formatCareOfferedProfileComplete = (row: ITableDataRow) => {

        return (
            <div className="mx-auto">
                <FontAwesomeIcon 
                    icon={row.CareOfferedProfileComplete ? faCheck : faXmark} 
                    className={`h-6 w-6 ${row.CareOfferedProfileComplete ? 'text-brand_status-success' : 'text-brand_status-error'}`}
                /> 
            </div>
        );
    };

    const formatCostFundingProfileComplete = (row: ITableDataRow) => {

        return (
            <div className="mx-auto">
                <FontAwesomeIcon 
                    icon={row.CostFundingProfileComplete ? faCheck : faXmark} 
                    className={`h-6 w-6 ${row.CostFundingProfileComplete ? 'text-brand_status-success' : 'text-brand_status-error'}`}
                /> 
            </div>
        );
    };

    const formatRoomTypesOffered = (row: ITableDataRow) => {
        return (
            <ul className="space-y-2">
                {
                    row.RoomTypesOffered.map((item: string) => (
                        <li>
                            {item}
                        </li>
                    ))
                }
            </ul>
        );
    };

    const formatCostPerDay = (row: ITableDataRow) => {

        if (
            !row.RoomTypesOffered.includes('Shared') &&
            !row.RoomTypesOffered.includes('Private') &&
            !row.RoomTypesOffered.includes('Semi Private') 
        ) {
            return 'None Selected';
        }

        return (
            <ul className="space-y-2">
                {
                    row.RoomTypesOffered.includes('Shared') ?
                    <li>
                        <strong className="block">Shared</strong>
                        <span className="block"><em>Long Term:</em> {formatCostOrUnknown(row.SharedLongTermCost)}</span>
                        <span className="block"><em>Short Term:</em> {formatCostOrUnknown(row.SharedShortTermCost)}</span>
                        <span className="block"><em>Respite:</em> {formatCostOrUnknown(row.SharedRespiteCost)}</span>
                    </li> : null
                }
                {
                    row.RoomTypesOffered.includes('Private') ?
                    <li>
                        <strong className="block">Private</strong>
                        <span className="block"><em>Long Term:</em> {formatCostOrUnknown(row.PrivateLongTermCost)}</span>
                        <span className="block"><em>Short Term:</em> {formatCostOrUnknown(row.PrivateShortTermCost)}</span>
                        <span className="block"><em>Respite:</em> {formatCostOrUnknown(row.PrivateRespiteCost)}</span>
                    </li>: null
                }
                {
                    row.RoomTypesOffered.includes('Semi Private') ?
                    <li>
                        <strong className="block">Semi-private</strong>
                        <span className="block"><em>Long Term:</em> {formatCostOrUnknown(row.SemiPrivateLongTermCost)}</span>
                        <span className="block"><em>Short Term:</em> {formatCostOrUnknown(row.SemiPrivateShortTermCost)}</span>
                        <span className="block"><em>Respite:</em> {formatCostOrUnknown(row.SemiPrivateRespiteCost)}</span>
                    </li>: null
                }
            </ul>
        );
    };

    const formatPaymentType = (row: ITableDataRow) => {
        return (
            <ul className="space-y-2">
                <li>
                    <strong className="block">Medicaid Bed Count</strong>
                    <span className="block">{valueOrPlaceholder(row.MedicaidBedCount)}</span>
                </li>
                <li>
                    <strong className="block">Medicare Bed Count</strong>
                    <span className="block">{valueOrPlaceholder(row.MedicareBedCount)}</span>
                </li>
                <li>
                    <strong className="block">Accept Medicare Advantage plans</strong>
                    <span className="block">{yesNoOrUnknown(row.AcceptMedicareAdvantagePlans)}</span>
                </li>
                <li>
                    <strong className="block">Accept Medicaid pending patients</strong>
                    <span className="block">{yesNoOrUnknown(row.AcceptMedicaidPendingPatients)}</span>
                </li>
            </ul>
        );
    };
    
    const formatInsuranceTypes = (row: ITableDataRow) => {
        const [activeInsuranceCount, totalInsuranceCount] = getInsuranceDetails(row.InsuranceDetails);

        return (
            <div className="mx-auto text-center">
                <div className="text-lg">
                    <strong>{activeInsuranceCount}</strong> / <strong>{totalInsuranceCount}</strong>
                </div>
                <span className="text-xs block">
                    completed
                </span>
            </div>
        );
    };

    const renderCareOffered  = (row: ITableDataRow) => 
    <ul className="space-y-2">
        <li><strong>Long Term Care:</strong> <span>{yesNoOrUnknown(row?.LongTermCare)}</span></li>
        <li><strong>Short Term Care:</strong> <span>{yesNoOrUnknown(row?.ShortTermCare)}</span></li>
        <li><strong>Respite Care:</strong> <span>{yesNoOrUnknown(row?.RespiteCare)}</span></li>
        <li><strong>Facility has a Memory Care Unit:</strong> <span>{yesNoOrUnknown(row?.FacilityHasMemoryCareUnit)}</span></li>
        <li><strong>Facility has a Behavioural Health Unit:</strong> <span>{yesNoOrUnknown(row?.FacilityHasBehaviouralHealthUnit)}</span></li>
        <li><strong>Speciality Niche Services:</strong> <span>{valueOrPlaceholder(row?.OtherFacilityTypes, 'None Listed')}</span></li>
        <li><strong>Minimum Age:</strong> <span>{getMinAgeRequirement(row?.MinAgeRequirement, row?.MinAge)}</span></li>
        <li><strong>Pediatric Residents:</strong> <span>{yesNoOrUnknown(row?.AdmitPediatricResidents)}</span></li>
    </ul>;

    const formatName = (row: ITableDataRow) => {

        const { WebsiteURL, StateSlug, CitySlug, Slug } = row;

        return (
            <div className="flex flex-wrap gap-2 break-words py-1">
                <a 
                    href="#" 
                    onClick={(e) => handleLinkClick(row.NHID, e)}
                >
                    <strong className="block leading-tight lg:text-lg">
                        {row.Name}
                    </strong>
                </a>
                <span className="inline-block text-xs lg:text-sm me-2 leading-tight text-brand_grey-medium">{ row.Address }</span>
                <a
                    className="link text-xs bg-brand_faint-blue px-2 py-1 inline-block leading-none rounded-lg relative -top-px"
                    href={`${WebsiteURL}/${StateSlug}/${CitySlug}/${Slug}`}
                    target="_blank"
                >
                    view on website
                </a>
            </div>  
        );
    };

    const careOfferedColumns = [
        {
            id: 'NHID',
            name: 'Facility Name',
            sortField: 'Name',
            sortable: true,
            cell: (row: ITableDataRow) => formatName(row),
            width: '*'
        },
        {
            name: <>Profile Complete <br/>for Care Offered</>,
            sortable: true,
            sortField: 'ProfileComplete',
            cell: (row: ITableDataRow) => formatCareOfferedProfileComplete(row),
            width: '*'
        },
        {
            name: 'Care Offered',
            cell: renderCareOffered,
            width: '*'
        },
        {
            name: 'Room Types Offered',
            cell: (row: ITableDataRow) => formatRoomTypesOffered(row),
            width: '*'
        },
        {
            name: 'Last Updated',
            sortable: true,
            sortField: 'LastUpdated',
            cell: (row: ITableDataRow) => <>{row.CoreDetailsLastUpdated ? shortFormatDate(row.CoreDetailsLastUpdated): 'No updates made'}</>,
            id: 'LastUpdated',
            width: '*'
        }, 
        {
            name: '',
            sortable: true,
            cell: (row: ITableDataRow) => 
            <CareOfferedCostFundingModal 
                row={row} 
                hasCoreDetails={row.HasCoreDetails} 
                profileScoreType={ProfileScoreType.FacilityBaseData}
            />,
            id: 'Actions',
            width: '*'
        },
    ];

    const costFundingColumns = [
        {
            id: 'NHID',
            name: 'Facility Name',
            sortField: 'Name',
            sortable: true,
            cell: (row: ITableDataRow) => formatName(row),
            width: '*'
        },
        {
            name: <>Profile Complete for Cost / Funding</>,
            sortable: true,
            sortField: 'ProfileComplete',
            cell: (row: ITableDataRow) => formatCostFundingProfileComplete(row),
            width: '*'
        },
        {
            name: 'Cost (starting at per day)',
            cell: (row: ITableDataRow) => formatCostPerDay(row),
            width: '*'
        },
        {
            name: 'Bed Allocation and Payment Types',
            cell: (row: ITableDataRow) => formatPaymentType(row),
            width: '*'
        },
        {
            name: 'Insurance Providers Accepted',
            cell: (row: ITableDataRow) => formatInsuranceTypes(row),
            width: '*'
        },
        {
            name: 'Last Updated',
            sortable: true,
            sortField: 'LastUpdated',
            id: 'LastUpdated',
            cell: (row: ITableDataRow) => <div className='fs-6 mt-4'>{row.CostFundingLastUpdated ? shortFormatDate(row.CostFundingLastUpdated): 'No updates made'}</div>,
            width: '*'
        },
        {
            name: '',
            sortable: true,
            cell: (row: ITableDataRow) => <CareOfferedCostFundingModal 
                    row={row} 
                    hasCoreDetails={row.HasCoreDetails} 
                    profileScoreType={ProfileScoreType.CostFunding}
                />,
            id: 'Actions',
            width: '*'
        }
    ];

    const columns = profileScoreType === ProfileScoreType.FacilityBaseData ? 
        careOfferedColumns : costFundingColumns;

    const handleLinkClick = (
        nhID: number, 
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        e.preventDefault();
        handleSelect(nhID);
        window.scrollTo(0, 0);
    };

    const handleTableSortChange = (
        column: TableColumn<ITableDataRow>,
        sortOrder: SortOrder
    ) => {
        setSortField(column.sortField);
        setSortOrder(
            sortOrder === 'asc'
                ? DataTableSortOrder.Ascending
                : DataTableSortOrder.Descending
        );
    };

    const selectedFacility = useMemo(() => {
        const currentSelectedRow = rawTableData.find((row: ITableDataRow) => selNHID === row.NHID);
        return singlePropertySession ? rawTableData[0] : currentSelectedRow;
    }, [rawTableData, selNHID]);


    const lastUpdated = profileScoreType === ProfileScoreType.FacilityBaseData ? 
        selectedFacility?.CoreDetailsLastUpdated : selectedFacility?.CostFundingLastUpdated;

    return (
        <>
             <div className="container max-w-none 2xl:container mt-4 sm:mt-6 md:mt-8 2xl:mt-10">
                <PageTitle title={pageTitle}/>
                { 
                    selectedFacility && <div>
                        {   lastUpdated 
                                ? `Last Updated: ${shortFormatDate(lastUpdated)}`
                                : 'No updates made'
                            }
                    </div>
                }
            </div>
            
            { 
                profileScoreType === ProfileScoreType.FacilityBaseData ? (
                    <>
                        <PageCommunitySelectWrapper
                            label={'Care Offered'}
                            selNHID={selNHID}
                            handleSelect={handleSelect}
                        />
                        <ProfileCompletenessHeader
                            selNHID={selNHID}
                            nhIDs={nhIDs}
                            orgNHID={orgNHID}
                            profileScoreType={ProfileScoreType.FacilityBaseData}
                            handleProfileCompletenessFilter={setProfileCompletenessFilterValue}
                            profileCompletenessFilterValue={profileCompletenessFilterValue}
                            title="for Care Offered"
                        /> 
                    </>
                ) : (
                    <>
                        <PageCommunitySelectWrapper
                            label={'Cost / Funding'}
                            selNHID={selNHID}
                            handleSelect={handleSelect}
                        />
                        <ProfileCompletenessHeader
                            selNHID={selNHID}
                            nhIDs={nhIDs}
                            orgNHID={orgNHID}
                            profileScoreType={ProfileScoreType.CostFunding}
                            handleProfileCompletenessFilter={setProfileCompletenessFilterValue}
                            profileCompletenessFilterValue={profileCompletenessFilterValue}
                            title="for Cost / Funding"
                        /> 
                    </>
                )
            }

            <section>
                <div className="container max-w-none 2xl:container mt-4 sm:mt-6 md:mt-8 2xl:mt-10"> 
                    { !singlePropertySession && !selNHID ?  
                        <div data-table={`${profileScoreType === ProfileScoreType.FacilityBaseData ? 'Care Offered' : 'Cost / Funding'}`} className="relative [&>div]:scrollbar [&>div]:pb-2 [&>div]:-mx-3 [&>div]:w-[calc(100%+1.5rem)] [&>div]:px-3 sm:[&>div]:-mx-4 sm:[&>div]:w-[calc(100%+2rem)] sm:[&>div]:px-4 xl:[&>div]:mx-0 xl:[&>div]:w-full xl:[&>div]:px-0 before:h-full before:top-0 before:absolute before:z-10 before:bg-gradient-to-r before:from-white before:w-3 before:-left-3 after:h-full after:top-0 after:absolute after:z-10 after:bg-gradient-to-l after:from-white after:-right-3 after:w-3 sm:before:-left-4 sm:before:w-4 sm:after:-right-4 sm:after:w-4 xl:before:content-none xl:after:content-none">
                            <DataTable
                                columns={columns}
                                onSort={handleTableSortChange}
                                data={filteredTableData}
                            />
                        </div> : 
                        profileScoreType === ProfileScoreType.FacilityBaseData ? 
                        <PropertyCoreDetailsForm
                            nhID={singleNHID} 
                        /> :
                        <PropertyCostFundingForm
                            nhID={singleNHID} 
                        /> 
                    }
                </div>
            </section>
        </>
    );
};


export default PropertyCoreDetails;
export type { IProps as IPropertyCoreDetails };