import React from 'react';
import { longFormatDateTime } from '../../../utils/date';
import { FacilityNameColumnItem } from './components/facillity_name_column_item';
import { ITourAvailabilityTableColumn, ITourDefaultValues } from './types';
import { mapToDaysOfWeek } from './helpers';
import { TourTimesColumnItem } from './components/tour_times_column_item';
import { EditTourAvailabilityModal } from './components/edit_tour_availability_modal';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const addMarginWhenIsOrganization = {
    when: (row: ITourDefaultValues) => row.IsOrganization,
    style: {
        position: 'static',
        marginTop: '55px',
        marginBottom: '10px'
    }
};

const addPaddingWhenIsOrganization = {
    when: (row: ITourDefaultValues) => row.IsOrganization,
    style: {
        paddingTop: '40px',
        paddingBottom: '30px'
    }
};

const addTransparencyWhenHasNoTourData = {
    when: (row: ITourDefaultValues) => row.InheritedAvailabilityDetailsFromOrg,
    style: {
        opacity: '50%'
    }
};

const conditionalCellStyles = [
    addTransparencyWhenHasNoTourData,
    addMarginWhenIsOrganization
];

interface IProps {
    moveToSingleFacilityView: (nhID: number) => void;
}

const getColumns = ({
    moveToSingleFacilityView
}: IProps): ITourAvailabilityTableColumn[] => {
    return [
        {
            id: 'NHID',
            name: 'Facility Name',
            sortField: 'Name',
            sortable: true,
            conditionalCellStyles: [addPaddingWhenIsOrganization],
            cell: (row) => {
                const handleNameClicked = () =>
                    moveToSingleFacilityView(row.NHID);
                return (
                    <FacilityNameColumnItem
                        {...row}
                        onNameClicked={handleNameClicked}
                    />
                );
            },
            width: '*'
        },
        {
            id: 'ProfileComplete',
            name: <>Profile Complete <br/>for Tour Availability</>,
            sortable: true,
            sortField: 'ProfileComplete',
            allowOverflow: true,
            cell: (row) => {
                if (row.IsOrganization) {
                    return null;
                }
                return (
                    <div className="mx-auto">
                        <FontAwesomeIcon 
                            icon={row.ProfileComplete ? faCheck : faXmark} 
                            className={`h-6 w-6 ${row.ProfileComplete ? 'text-brand_status-success' : 'text-brand_status-error'}`}
                        /> 
                    </div>
                );
            },
            width: '*'
        },
        {
            id: 'TourTypeName',
            name: 'Tour Type',
            sortField: 'TourTypeName',
            cell: (row) => row.TourTypeName,
            conditionalCellStyles,
            sortable: true,
            width: '*'
        },
        {
            id: 'OnsiteTourDays',
            name: 'Onsite Tour Days',
            cell: (row) => {
                return (
                    <div>
                        {mapToDaysOfWeek(row.PhysicalTourDays).map(
                            (day: string) => {
                                return <div key={day}>{day}</div>;
                            }
                        )}
                    </div>
                );
            },
            conditionalCellStyles,
            width: '*'
        },
        {
            id: 'OnsiteTourTimes',
            name: 'Onsite Tour Times',
            cell: ({ PhysicalTourStartTime, PhysicalTourEndTime }) => (
                <TourTimesColumnItem
                    startTime={PhysicalTourStartTime}
                    endTime={PhysicalTourEndTime}
                />
            ),
            conditionalCellStyles,
            width: '*'
        },
        {
            id: 'RemoteTourDays',
            name: 'Remote Tour Days',
            cell: (row) => {
                return (
                    <div>
                        {mapToDaysOfWeek(row.VirtualTourDays).map(
                            (day: string) => {
                                return <div>{day}</div>;
                            }
                        )}
                    </div>
                );
            },
            conditionalCellStyles,
            width: '*'
        },
        {
            id: 'RemoteTourTimes',
            name: 'Remote Tour Times',
            cell: ({ VirtualTourStartTime, VirtualTourEndTime }) => (
                <TourTimesColumnItem
                    startTime={VirtualTourStartTime}
                    endTime={VirtualTourEndTime}
                />
            ),
            conditionalCellStyles,
            width: '*'
        },
        {
            id: 'UpdatedAt',
            name: 'Updated Date',
            sortField: 'UpdatedAt',
            cell: (row) => longFormatDateTime(row.UpdatedAt),
            conditionalCellStyles,
            sortable: true,
            width: '*'
        },
        {
            id: 'UpdatedBy',
            name: 'Updated By',
            sortField: 'UpdatedBy',
            cell: (row) => row.UpdatedBy,
            conditionalCellStyles,
            sortable: true,
            width: '*'
        },
        {
            id: 'Actions',
            name: '',
            cell: (row) => {
                return <EditTourAvailabilityModal {...row} />;
            },
            width: '*'
        }
    ];
};

export { getColumns };
