import React from 'react';
import { FormInput } from '../../../../../../components/common/form_input';
import { FormRadioGroup } from '../../../../../../components/common/form_radio_group';
import { onlyNumeric, onlyUpto100, yesNoOptions } from '../../../helpers';
import { ProfileCompletenessTag } from '../../components/profile_completeness_tag';
import { useFormContext } from 'react-hook-form';
import { SectionHeading } from '../../components/section_heading';

const CareOfferedTab = () => {
    const { watch, setValue } = useFormContext();

    const hasMinimumAgeRequirement = watch('MinAgeRequirement');

    if (hasMinimumAgeRequirement !== 'Yes') {
        setValue('MinAge', null);
    }

    const inline = true;

    return (
        <>
            <SectionHeading title="Care Offered" />
            <div className="grid gap-3 lg:gap-5 2xl:gap-6 px-3 sm:px-4 2xl:px-5">
                <FormRadioGroup
                    groupLabel="Long Term Care"
                    info={<ProfileCompletenessTag isVisible={true} />}
                    layout="horizontal"
                    name="LongTermCare"
                    options={yesNoOptions}
                    showResetButton={true}
                />
                <FormRadioGroup
                    groupLabel="Short Term Care"
                    info={<ProfileCompletenessTag isVisible={true} />}
                    layout="horizontal"
                    name="ShortTermCare"
                    options={yesNoOptions}
                    showResetButton={true}
                />
                <FormRadioGroup
                    groupLabel="Respite Care"
                    info={<ProfileCompletenessTag isVisible={true} />}
                    layout="horizontal"
                    name="RespiteCare"
                    options={yesNoOptions}
                    showResetButton={true}
                />
                <FormRadioGroup
                    groupLabel="Is there a minimum age requirement?"
                    layout="horizontal"
                    name="MinAgeRequirement"
                    options={yesNoOptions}
                    showResetButton={true}
                    followUpInput={
                        <FormInput
                            allowedPattern={onlyNumeric}
                            inlineLabel={false}
                            disabled={hasMinimumAgeRequirement !== 'Yes'}
                            info={<ProfileCompletenessTag isVisible={true} />}
                            maxWidth={'5rem'}
                            name="MinAge"
                            suffix="years"
                            type="number"
                        />
                    }
                />
                <FormRadioGroup
                    groupLabel="Facility Has Memory Care Unit"
                    info={<ProfileCompletenessTag isVisible={true} />}
                    layout="horizontal"
                    name="FacilityHasMemoryCareUnit"
                    options={yesNoOptions}
                    showResetButton={true}
                />
                <FormRadioGroup
                    groupLabel="Facility Has Behavioural Health Unit"
                    info={<ProfileCompletenessTag isVisible={true} />}
                    layout="horizontal"
                    name="FacilityHasBehaviouralHealthUnit"
                    options={yesNoOptions}
                    showResetButton={true}
                />
                <FormInput
                    info={<ProfileCompletenessTag isVisible={false} />}
                    layout="horizontal"
                    inlineLabel={false}
                    label="Any other speciality/niche services?"
                    maxWidth={'300px'}
                    name="OtherFacilityTypes"
                />
                <FormInput
                    info={<ProfileCompletenessTag isVisible={false} />}
                    layout="horizontal"
                    inlineLabel={false}
                    label="Which hospitals is the facility affiliated with?"
                    maxWidth={'300px'}
                    name="AffiliatedHospitals"
                />
                <FormInput
                    allowedPattern={onlyUpto100}
                    info={<ProfileCompletenessTag isVisible={true} />}
                    layout="horizontal"
                    inlineLabel={false}
                    label="Average % of short-term residents per month"
                    maxWidth={'5rem'}
                    name="AvgShortTermResidentsPc"
                    suffix="%"
                    type='number'
                />
                <FormInput
                    allowedPattern={onlyNumeric}
                    layout="horizontal"
                    info={<ProfileCompletenessTag isVisible={false} />}
                    inlineLabel={false}
                    label="Average short-term stay duration"
                    maxWidth={'5rem'}
                    name="AvgShortStayDuration"
                    suffix="days"
                    type="number"
                />
                <FormInput
                    allowedPattern={onlyUpto100}
                    layout="horizontal"
                    info={<ProfileCompletenessTag isVisible={true} />}
                    inlineLabel={false}
                    label="Average % of long-term residents per month"
                    maxWidth={'5rem'}
                    name="AvgLongTermResidentsPc"
                    suffix="%"
                    type="number"
                />
                <FormInput
                    allowedPattern={onlyNumeric}
                    layout="horizontal"
                    info={<ProfileCompletenessTag isVisible={false} />}
                    inlineLabel={false}
                    label="Average long-term stay duration"
                    maxWidth={'5rem'}
                    name="AvgLongTermStayDuration"
                    suffix="months"
                    type="number"
                />
            </div>
        </>
    );
};

export { CareOfferedTab };
