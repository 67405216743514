import React, { FC, InputHTMLAttributes, ReactElement, ReactNode } from 'react';
import { ConnectForm } from '../form/form';
import cx from 'classnames';
import { UseFormReturn } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    name: string;
    children?: ReactNode;
    disabled?: boolean;
    // layout?: 'normal' | 'horizontal'; // no requirement for this yet
}

const FormCheckbox: FC<Props> = (props) => {
    const {
        name,
        id,
        disabled = false,
        label,
        ...rest
    } = props;

    return (
        <ConnectForm>
            {({ register, formState }: UseFormReturn) => {
                const fieldError: { message?: string } =
                    formState?.errors?.[name];

                const errorMessage: ReactElement = (
                    <span>
                        {fieldError?.message}
                    </span>
                );

                // const inputClassName: string = cx(
                //     'form-check',
                //     {
                //         'border border-danger': fieldError
                //     },
                //     className
                // );

                const inputId: string = id || name;

                // const inputWrapperClassName = cx({
                //     'mt-3': labelText,
                //     row: inline
                // });

                // const labelClassName = cx('fw-label fs-5', {
                //     'col-sm-4 col-lg-2 d-flex align-items-center  m-0': inline,
                //     'mb-1': !inline
                // });

                // const checkboxStyle = { marginLeft: inline ? '10px' : '0' };

                // const innerLabelClassName = cx(
                //     'position-relative kt-checkbox kt-checkbox--brand',
                //     {
                //         'col-sm-8 col-lg-10 pl-4': inline && labelText,
                //         'col-sm-12 ': inline && !labelText
                //     }
                // );

                return (
                    <div className={cx('form-component form-checkbox', { 'form-error' : fieldError })}>
                        <label>
                            <input
                                className="peer"
                                type="checkbox"
                                id={inputId}
                                disabled={disabled}
                                {...register?.(name)}
                                {...rest}
                            />
                            <FontAwesomeIcon icon={faCheck} className="peer-checked:block"/>
                            {label}
                        </label>
                        {fieldError && errorMessage}
                    </div>
                );
            }}
        </ConnectForm>
    );
};

export { FormCheckbox };
