import React, { useContext, useState } from 'react';
import parse from 'html-react-parser';
import DataTable from '../common/data_table';

import { formatDateMMMDDYYYY } from '../../utils/date';
import LeadEmailTableExpandable from './lead_email_table_expandable';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { LoadingDots } from '../common/loading_dots';

type Props = {
    filterMatrix: any;
    data: any[];
    totalRows: number;
    handlePageChange(pageNumber: number): void;
    handleLimitChange(newPerPage: number, pageNumber: number): void;
    handleSortChange(column: TableColumn<any>, sortOrder: SortOrder): void;
    limit: number;
    loading: boolean;
};

export default function LeadEmailTable(props: Props) {
    const [activeReadMore, setActiveReadMore] = useState<number | null>(null);

    const columns = [
        {
            name: 'Inquiry ID',
            sortable: true,
            sortField: 'EnquiryID',
            cell: (row: any) => (
                <a href="#" className='link'>
                    {row.EnquiryID}
                </a>
            ),
            width: '*'
        },
        {
            name: 'Inquiry Type',
            sortable: true,
            sortField: 'TemplateTypeID',
            cell: (row: any) => (
                row.TemplateTypeName
            ),
            width: '*'
        },
        {
            name: 'Facility',
            cell: (row: any) => (
                <>
                    <div>
                        <strong className="font-normal block leading-tight">
                            {row.PropertyName}
                        </strong>
                        <span className="inline-block text-xs lg:text-sm leading-tight text-brand_grey-medium">
                            {row.PropertyAddress}
                        </span>        
                    </div>
                </>
            ),
            width: '*'
        },
        {
            name: 'Inquirer Name',
            cell: (row: any) => (
                <>{row.SenderFirstName} {row.SenderLastName}</>
            ),
            width: '*'
        },
        {
            name: 'Inquirier Email',
            cell: (row: any) => (
                row.SenderEmail
            ),
            width: '*'
        },
        {
            name: 'Inquiry Date',
            cell: (row: any) => (
                formatDateMMMDDYYYY(row.CreatedAt)
            ),
            width: '*'
        },
        {
            name: '	Inquiry',
            minWidth: '280px',
            cell: (row: any) => {
                if (!row.SenderInquiry) {
                    return null;
                }

                if (row.SenderInquiry.length > 200) {
                    return (
                        <div>
                            {parse(row.SenderInquiry.substring(0, 200)) + '...'}
                            <button
                                className="block link"
                                onClick={() => setActiveReadMore(activeReadMore === row.EnquiryID ? null : row.EnquiryID)}
                            >
                                Read {activeReadMore === row.EnquiryID ? 'Less' : 'More'}
                            </button>
                        </div>
                    );
                }

                return (
                    <div>
                        {parse(row.SenderInquiry)}
                    </div>
                );
            },
            width: '*'
        }        
    ];

    if (props.loading) {
        return (
            <LoadingDots show={true} />
        );
    }

    if (!props.data || !props.data.length) {
        return (
            <span className="msg msg-warning !flex mx-auto w-fit">
                There are no results for this search
            </span>
        );
    }

    return (
        <DataTable
            columns={columns}
            data={props.data}
            expandableRows
            expandableRowsComponent={({ data }: any) => <LeadEmailTableExpandable data={data} />}
            pagination={true}
            paginationServer={true}
            paginationTotalRows={props.totalRows}
            onChangePage={props.handlePageChange}
            onChangeRowsPerPage={props.handleLimitChange}
            onSort={props.handleSortChange}
            sortServer={true}
            initialRowsPerPage={props.limit}
            expandableRowExpanded={(row: any) => row.EnquiryID === activeReadMore}
        />
    );
}
