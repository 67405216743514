import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useNavigate } from 'react-router-dom';
// import ReCAPTCHA from 'react-google-recaptcha';
import { sendPasswordResetEmail } from '../../api/login';
import { Button } from '../../components/common/button';

interface FormInputs {
    EmailAddress: string;
    ReCaptcha: string;
}

const PasswordResetEmail = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<FormInputs>();

    const onSubmit = async (data: any) => {
        const { EmailAddress } = data;

        setLoading(true);

        try {
            await sendPasswordResetEmail(EmailAddress);
            setLoading(false);
            navigate('/passwordresetconfirm', { state: { EmailAddress } });
        } catch(err) {
            console.log('sendPasswordResetEmail failed');
            console.log(err);
            navigate('/login');
        } finally {
            setLoading(false);
        }
    };

    return (
        <main>
            <section className="h-screen flex justify-center py-8">
                <div className="my-auto w-full max-w-[500px] ring-1 ring-brand_grey rounded-md lg:rounded-lg overflow-hidden shadow-md bg-white mx-3 sm:mx-4 lg:mx-8 h-fit"> 
                    <Link to={{pathname: '/'}} className="flex items-end p-3 sm:p-4 lg:px-8 lg:py-6 border-b border-brand_grey bg-brand_faint-blue">
                        <img src="/logo.svg" className="h-9 me-3 lg:h-10 xl:h-11" alt="Portal" />
                        <strong className="text-brand_grey-medium relative top-0.5 font-medium text-lg lg:text-xl lg:top-0">
                            Portal
                        </strong>
                    </Link> 
                    <form className="p-3 sm:p-4 lg:p-8" onSubmit={handleSubmit(onSubmit)}>
                        <div className="grid gap-3">
                            <p>
                                Please enter your email address to reset your password for your nursinghomes.com Portal.
                            </p>
                            <div className={`form-component form-inline form-textbox ${errors.EmailAddress ? 'form-error' : ''}`}>
                                <label
                                    htmlFor="email"
                                >
                                    Email Address
                                </label>
                                <input
                                    { ...register('EmailAddress', { required: 'Please enter your Email Address'}) }
                                    type="email"
                                    id="email"
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="EmailAddress"
                                    render={({ message }: any) => <span>{message}</span>}
                                />
                            </div>
                            {/* <div className="form-group">
                                <ReCAPTCHA
                                    {
                                        ...register('ReCaptcha', {
                                            required: 'Please complete the reCAPTCHA'
                                        })
                                    }
                                    sitekey="6Lcbpe0UAAAAAHsJsXEcaUjIKs6JSeDdGa3lCgL9"
                                    onChange={(value: string) => setValue('ReCaptcha', value, { shouldValidate: true })}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="ReCaptcha"
                                    render={({ message }: any) => <span className="form-error">{message}</span>}
                                />
                            </div> */}
                            <div className="mt-3 flex flex-wrap gap-3 items-center">
                                <div className="grid text-sm lg:text-md gap-0.5">
                                    <Link className="link inline-block" to={{
                                        pathname: '/login'
                                    }}> 
                                        Return to Login
                                    </Link>
                                </div>
                                <Button type="submit" className="ms-auto" >
                                    Reset Password
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </main>

    );
};

export default PasswordResetEmail;