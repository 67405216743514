import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import {TableColumn } from 'react-data-table-component';
import DataTable from '../common/data_table';
import { IPropertyServiceTotalParams, IServiceTotals } from '../../interfaces/service';
import { getPropertyServiceTotals } from '../../api/service';
import AppContext from '../../context/app_context';
import { LoadingDots } from '../common/loading_dots';
import { formatAddress } from '../../utils/property';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { generalSort } from '../../utils/common';
import { ServicesAndAmenitiesModal } from '../../pages/property_details/property_services_and_amenities/services_and_amenities_modal';
import { useQuery } from '@tanstack/react-query';
import { shortFormatDate } from '../../utils/date';

interface IProps {
    selNHID: number;
    nhIDs: number[];
    orgNHID: number;
    handleSelect: (nhID: number) => void; 
    profileCompletenessFilterValue: boolean | null;
}

const PropertyServiceTotalsTable = ({ 
    handleSelect, 
    // selNHID, 
    // nhIDs, 
    // orgNHID,
    profileCompletenessFilterValue
}: IProps) => {
    const [sortOrder, setSortOrder] = useState<{column: string, direction: 'ASC' | 'DESC'}>({column: '', direction: 'ASC'});

    // Not sure what the following code was for, didn't seem to be doing anything so I commented it out.
    // const [ scoreItem, setScoreItem ] = useState<IScoreDisplayItem>(null);

    // const profileCompletenessState: INhPortalScoreItem = useSelector(
    //     ({ ProfileCompletenessState }: any) => ProfileCompletenessState
    // );

    // useEffect(() => {
        
    //     const scoreItem = mapProfileDatatoDisplay(profileCompletenessState, selNHID)
    //         .find((item: IScoreDisplayItem) => item.KeyName === ProfileScoreType.FacilityBaseData);
    //     setScoreItem(scoreItem);

    // }, [ orgNHID, nhIDs, selNHID ]);

    const appContext: any = useContext(AppContext);

    const params: IPropertyServiceTotalParams = {
        ResourceProfileCompleted: profileCompletenessFilterValue
    };

    const { data: propertyServiceTotals, isLoading } = useQuery({
        queryFn: () => getPropertyServiceTotals(params),
        queryKey: ['portal', 'service', 'totals', profileCompletenessFilterValue]
    });

    const sortedTotals = propertyServiceTotals?.[0].sort(generalSort(sortOrder.column, sortOrder.direction)) || [];

    const columns = [{
        name: 'Facility Name',
        cell: (row: IServiceTotals) => formatFacility(row),
        sortable: true,
        sortField: 'PropertyName',
        id: 'PropertyName',
        width: '*'
    }, {
        name: <>Profile Complete <br/>for Services & Amenities</>,
        cell: (row: IServiceTotals) => formatProfileCompleteness(row),
        sortable: true,
        sortField: 'ProfileCompleteResourceCount',
        id: 'ProfileCompleteResourceCount',
        width: '*'
    }, {
        name: 'Misc Services',
        cell: (row: IServiceTotals) => formatServiceTotal(row, 'ServiceExtra'),
        sortable: true,
        sortField: 'ServiceExtraCount',
        id: 'ServiceExtraCount',
        width: '*'
    }, {
        name: 'Health Care Services',
        cell: (row: IServiceTotals) => formatServiceTotal(row, 'HealthCareServices'),
        sortable: true,
        sortField: 'HealthCareServicesCount',
        id: 'HealthCareServicesCount',
        width: '*'
    }, {
        name: 'Activities',
        cell: (row: IServiceTotals) => formatServiceTotal(row, 'Activities'),
        sortable: true,
        sortField: 'ActivitiesCount',
        id: 'ActivitiesCount',
        width: '*'
    }, {
        name: 'Community Amenities',
        cell: (row: IServiceTotals) => formatServiceTotal(row, 'CommunityAmenities'),
        sortable: true,
        sortField: 'CommunityAmenitiesCount',
        id: 'CommunityAmenitiesCount',
        width: '*'
    }, {
        name: 'Dining',
        cell: (row: IServiceTotals) => formatServiceTotal(row, 'Dining'),
        sortable: true,
        sortField: 'DiningCount',
        id: 'DiningCount',
        width: '*'
    }, {
        name: 'Room Amenities',
        cell: (row: IServiceTotals) => formatServiceTotal(row, 'RoomAmenities'),
        sortable: true,
        sortField: 'RoomAmenitiesCount',
        id: 'RoomAmenitiesCount',
        width: '*'
    },
    {
        name: 'Last Updated',
        sortable: true,
        sortField: 'LastUpdated',
        id: 'LastUpdated',
        cell: (row: IServiceTotals) => <>{row.LastUpdated ? shortFormatDate(row.LastUpdated): 'No updates made'}</>,
        width: '*'
    },
    {
        name: '',
        sortable: false,
        cell: (row: IServiceTotals) => <ServicesAndAmenitiesModal row={row} />,
        id: 'Actions',
        width: '*'
    }];

    const formatProfileCompleteness = (row: IServiceTotals) => {
        const complete = row.ProfileCompleteResourceCount === row.ProfileCompleteResourceTotal;

        return (
            <div className="mx-auto">
                <FontAwesomeIcon 
                    icon={complete ? faCheck : faXmark} 
                    className={`h-6 w-6 ${complete ? 'text-brand_status-success' : 'text-brand_status-error'}`}
                /> 
            </div>
        );
    };

    const formatServiceTotal = (row: IServiceTotals, key: string, showProfileComplete = false) => {

        const complete = (row as any)[`${key}Count`] === (row as any)[`${key}Total`];

        return (
            <div className="mx-auto text-center">
                { showProfileComplete && <div>
                        <FontAwesomeIcon 
                            icon={complete ? faCheck : faXmark} 
                            className={`h-6 w-6 ${complete ? 'text-brand_status-success' : 'text-brand_status-error'}`}
                        /> 
                    </div>
                }
                <div className="text-lg">
                    <strong>{(row as any)[`${key}Count`]}</strong> / <strong>{(row as any)[`${key}Total`]}</strong>
                </div>
                <span className="text-xs block">
                    completed
                </span>
            </div>
        );
    };

    const formatFacility = (row: IServiceTotals) => {

        const { WebsiteURL, StateSlug, CitySlug, Slug } = row;

          return (
                <div className="flex flex-wrap gap-2 break-words py-1">
                    <Link 
                        to={'/amenities'}
                        onClick={(e) => handleLinkClick(row.NHID, e)}
                    >
                        <strong className="block leading-tight lg:text-lg">
                            {row.PropertyName}
                        </strong>
                    </Link>
                    <span className="inline-block text-xs lg:text-sm me-3 leading-tight text-brand_grey-medium">
                        { formatAddress(row) }
                    </span>
                    <a
                        className="link text-xs bg-brand_faint-blue px-2 py-1 inline-block leading-none rounded-lg relative -top-px"
                        href={`${WebsiteURL}/${StateSlug}/${CitySlug}/${Slug}`}
                        target="_blank"
                    >
                        view on website
                    </a>
                </div>
        );
    };

    const handleLinkClick = (
        nhID: number, 
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        e.preventDefault();
        handleSelect(nhID);
    };

    const handleTableSortChange = (column: TableColumn<any>, sortOrder: string) => {
        setSortOrder({ 
            column: column.sortField, direction: sortOrder === 'asc' 
                ? 'ASC'
                : 'DESC' 
        });
    };

    return (
        <>
            { 
                isLoading 
                    ? <LoadingDots show={true}/> 
                    : (  
                        <DataTable
                            columns={columns}
                            data={sortedTotals}
                            sortServer={true}
                            fixedHeader={false}
                            onSort={handleTableSortChange}
                        />
                    )
            }
        </>
    );
};

export default PropertyServiceTotalsTable;