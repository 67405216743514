import React, { useEffect, useState } from 'react';

import { mapProfileDatatoDisplay } from '../../utils/profile_completeness';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { ISessionState } from '../../interfaces/session';
import { ISessionProperty } from '../../interfaces/session';

import { 
    INhPortalScoreItem,
    IScoreDisplayItem,
    ProfileScoreType
} from '../../interfaces/property';

interface IProps {
    NHID: number;
    profileScoreType: ProfileScoreType;
}

const ProfileCompletenessSingle = ({ NHID, profileScoreType }: IProps) => {

    const [ scoreItem, setScoreItem ] = useState<IScoreDisplayItem>(null);
    const [ websiteURL, setwebsiteURL ] = useState<string>(null);
    const [ sessionProperty, setSessionProperty ] = useState<ISessionProperty>(null);

    const profileCompletenessState: INhPortalScoreItem = useSelector(
        ({ ProfileCompletenessState }: any) => ProfileCompletenessState
    );

    const sessionState: ISessionState = useSelector(
        ({SessionState}: any) => SessionState
    );

    useEffect(() => {        

        const scoreArray = mapProfileDatatoDisplay(profileCompletenessState, NHID);

        const scoreItem = scoreArray.find((item: IScoreDisplayItem) => 
            item.KeyName === profileScoreType);

        setScoreItem(scoreItem);

        const sessionProperty = (sessionState?.Properties || []).find((item: ISessionProperty) => item.NHID === NHID);
        if (sessionProperty) {

            const { WebsiteURL, StateSlug, CitySlug, Slug } = sessionProperty;
            setwebsiteURL(`${WebsiteURL}/${StateSlug}/${CitySlug}/${Slug}`);
            setSessionProperty(sessionProperty);
        } else {
            setwebsiteURL(null);
            setSessionProperty(null);
        }

    }, [NHID, profileCompletenessState, profileScoreType]);

    const profileComplete = 
        scoreItem?.FacilityCount === 1 &&
        scoreItem?.AchievedScoreCount === 1;

    const fieldDescription = scoreItem?.Description || '';
    const label = scoreItem?.Label || '';

    return (
        <div className="flex flex-col gap-2 text-center">
            {
                sessionProperty ?
                <div className="flex flex-wrap justify-center items-center gap-2">
                    <h4 className="text-xl md:text-3xl font-bold mx-3 md:mx-5">
                        {sessionProperty.Name}
                    </h4> 
                    <a
                        className="link text-xs bg-brand_faint-blue px-2 py-1 inline-block leading-none rounded-lg md:text-md"
                        href={websiteURL}
                        target="_blank"
                    >
                        view on website
                    </a>
                </div>: null
            }
            <div className="flex flex-wrap justify-center items-center">
                <span className="flex items-center">
                    <FontAwesomeIcon icon={profileComplete ? faCheck : faXmark} className={`me-2 text-3xl md:text-4xl relative -top-px ${profileComplete ? 'text-brand_status-success' :  'text-brand_status-error'}`}/><strong className="text-base md:text-lg">Profile {profileComplete ? '' : 'NOT'} Complete for {label}</strong>
                </span>
                <span className="block md:inline-block mx-3">({fieldDescription})</span>
            </div>
        </div>
    );
};

export default ProfileCompletenessSingle;