import React, { useEffect, useState } from 'react';
import * as yup from 'yup';

import { Form } from '../../../../components/common/form/form';
import { FormInput } from '../../../../components/common/form_input/form_input';
import { IPropertyVirtualTourFormData } from '../../../../interfaces/asset';
import { OpType } from '../../../../interfaces/common';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';


interface IProps {
    formData: IPropertyVirtualTourFormData;
    handleSubmit: (payload: IPropertyVirtualTourFormData, action: OpType) => void;
    id: string;
}

const tourUrlErrMsg = 'Please enter a valid url including https://';
const validationSchema = yup.object().shape({
    TourURL: yup.string().url(tourUrlErrMsg).required(tourUrlErrMsg)
});

const PropertyVirtualTourForm = ({ handleSubmit, formData, id }: IProps) => {
    const [isDelete, setIsDelete] = useState<boolean>();
    const [action, setAction] = useState<OpType>();
    
    useEffect(() => {
        if(isDelete === null) {
            return;
        }

        setAction(isDelete ? OpType.Delete : OpType.Update);
    }, [isDelete]);


    useEffect(() => {
        setAction(formData.TourURL ? OpType.Update : OpType.Create);
        setIsDelete(null);
    }, [formData]);
    

    const onSubmit = (data: IPropertyVirtualTourFormData) => {
        handleSubmit(data, action);
    };

    return (
        <div className="grid gap-3 sm:gap-4 2xl:gap-5">
            <span className="msg msg-info">
                Supported '360' Virtual Tour types include Matterport and Google 360 Tours. Videos or photos will not be accepted. Please note the NursingHomes.com team will need to manually verify this link before it goes live, this usually takes up to 1 business day.                   
            </span>
            <Form
                key={`${formData.NHID}-${action}`}
                id={id}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                defaultValues={formData}
            >
                <FormInput
                    name="TourURL"
                    label="360 Virtual Tour URL"
                    disabled={isDelete}
                    placeholder="https://"
                    maxWidth="600px"
                />
            </Form>
            {
                [OpType.Delete, OpType.Update].includes(action) && 
                <div className="form-component form-checkbox">
                    <label>
                        <input
                            className="peer"
                            id="DeleteVirtualTour"
                            type="checkbox"
                            name="lowres"
                            checked={isDelete}
                            onChange={() => setIsDelete(!isDelete)}
                            />
                        <FontAwesomeIcon icon={faCheck} className="peer-checked:block"/>
                        Delete this Tour
                    </label>         
                </div>
            }
        </div>
    );
};

export default PropertyVirtualTourForm;
