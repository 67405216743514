import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import FilterMatrix from '../../../modules/filter_matrix';
import { getConnections } from '../../../api/review';

interface IProps {
    filterMatrix: FilterMatrix;
    applyFilter(key: string, value: any, refreshData?: boolean): void;
}

const ReviewerConnectionFilter = ({ filterMatrix, applyFilter }: IProps) => {

    const [ reviewerConnectionOptions, setReviewerConnectionOptions ] = useState<any[]>([]);

    useEffect(() => {
        const setOptions = async () => {
            const reviewerConnections = await getConnections();

            const options = reviewerConnections.map((item: any) => ({
                label: item.Name,
                value: item.ReviewerConnectionID
            }));
            
            setReviewerConnectionOptions(options);
        };

        setOptions();
    }, []);

    return (
        <div className="form-component form-inline form-select">
            <label 
                htmlFor="reviewConnectionSelect"
            >
                Reviewer Connection
            </label>
            <Select
                id="reviewConnectionSelect"
                onChange={(option: any) => applyFilter('ReviewerConnections', option)}
                options={reviewerConnectionOptions}
                isMulti
                name="reviewConnectionSelect"
                classNamePrefix="select"
                value={filterMatrix.getFormFieldValue('ReviewerConnections')}
                unstyled
            />
        </div>
    );
};

export default ReviewerConnectionFilter;
