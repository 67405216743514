import React from 'react';
import { ProfileScoreType } from '../../interfaces/property';
import ProfileCompletenessDial from './profile_completeness_dial';
import ProfileCompletenessVideoFilter from './profile_completeness_video_filter';
import ProfileCompletenessSingle from '../../components/profile_completeness/profile_completeness_single';
import { useSelector } from 'react-redux';
import { ISessionState } from '../../interfaces/session';

interface IProps {
    selNHID: number;
    nhIDs: number[];
    orgNHID: number;
    handleProfileCompletenessFilter: any;
    handleNoVideoFilter: any;
    showWithNoVideo: boolean;
    profileCompletenessFilterValue: boolean | null;
}

const ProfileCompletenessVideoHeader = ({ 
    selNHID, 
    nhIDs, 
    orgNHID,
    handleProfileCompletenessFilter,
    handleNoVideoFilter,
    showWithNoVideo,
    profileCompletenessFilterValue
}: IProps) => {

    const singleNHID = selNHID || (nhIDs?.length === 1 ? nhIDs[0] : null);
    const orgNHIDSelected =  orgNHID && orgNHID === singleNHID;

    const sessionState: ISessionState = useSelector(
        ({SessionState}: any) => SessionState
    );

    const orgDetails = sessionState?.Org;
    const websiteURL = sessionState?.Org?.WebsiteURL;

    return (
        <section>
            <div className="container max-w-none 2xl:container mt-4 sm:mt-6 md:mt-8 2xl:mt-10">
                {
                    singleNHID ? orgNHIDSelected ? 
                            <div className="flex flex-col gap-2 text-center">
                                {
                                    orgDetails ?
                                    <div className="flex flex-wrap justify-center items-center gap-2">
                                        <h4 className="text-xl md:text-3xl font-bold mx-3 md:mx-5">
                                            {orgDetails.Name}
                                        </h4> 
                                        {
                                            websiteURL ?
                                            <a
                                                className="link text-xs bg-brand_faint-blue px-2 py-1 inline-block leading-none rounded-lg md:text-md"
                                                href={`${websiteURL}/provider/${orgDetails.Slug}`}
                                                target="_blank"
                                            >
                                                view on website
                                            </a> : null
                                        } 
                                    </div>: null
                                }
                            </div> 
                            : 
                            <ProfileCompletenessSingle
                                NHID={singleNHID}
                                profileScoreType={ProfileScoreType.Video}
                            /> 
                        :
                        <div className="flex flex-col items-center gap-4 md:flex-row md:justify-between md:gap-12">
                            <ProfileCompletenessDial
                                selNHID={selNHID}
                                nhIDs={nhIDs}
                                orgNHID={orgNHID}
                                profileScoreType={ProfileScoreType.Video}
                            />
                            <ProfileCompletenessVideoFilter
                                handleProfileCompletenessFilter={handleProfileCompletenessFilter}
                                showWithNoVideo={showWithNoVideo}
                                handleNoVideoFilter={handleNoVideoFilter}
                                profileCompletenessFilterValue={profileCompletenessFilterValue}
                            />
                        </div>
                }
            </div>
        </section>
    );
};

export default ProfileCompletenessVideoHeader;
