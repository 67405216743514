import React from 'react';
import { IPropertyVirtualTourRow } from '../../../../interfaces/asset';

interface IProps {
    selectedFacility: IPropertyVirtualTourRow;
    children: React.ReactNode;
}

const PropertyVirtualTourCard = ({ selectedFacility, children }: IProps) => {

    if (!selectedFacility) {
        return null;
    }

    const { TourURL } = selectedFacility;

    return (
        <section>
            <div className="container max-w-none 2xl:container mt-4 sm:mt-6 md:mt-8 2xl:mt-10">
                <div className="flex flex-col items-center text-center gap-3 md:flex-row md:text-start">
                    <strong className="text-md md:text-lg lg:text-xl lg:whitespace-nowrap">
                        {TourURL ? 'Update' : 'Add'} 360 Virtual Tour
                    </strong>
                </div>
                <div className="mt-4 sm:mt-6">
                    {children}
                </div>
            </div>
        </section>
    );
};

export default PropertyVirtualTourCard;

