import React from 'react';

interface IProps {
    loading: boolean;
}

const NoUserData = ({ loading }: IProps) => {
    return (
        <>
            <span className="msg msg-warning !flex mx-auto w-fit">
                There are no results for this search
            </span>
            {/*<div className="row mt-4">
                <p className="no-data-msg mb-4">
                    { loading ? 'Loading users...' : 'No users found' }
                </p>
            </div>*/}
        </>
    );
};

export default NoUserData;
