import React, { useState } from 'react';

import PageCommunitySelectWrapper from '../../components/common/page_community_select_wrapper';
import { ShowcaseReviewsWidget } from '../../components/dashboard/showcase_reviews_widget';
import { getCommunityMode } from '../../utils/common';

import { LeadsWidget } from '../../components/dashboard/leads_widget';
import { ProfileCompletnessWidget } from '../../components/dashboard/profile_completness_widget';
import { ProfileCompletnessWidgetOther } from '../../components/dashboard/profile_completness_widget_other';
import { LeadsEmailLog } from '../../components/dashboard/leads_email_log';
import { PageType } from '../../interfaces/page';
import { useCommunitySelectHistory } from '../../hooks/use_community_select_history';
import { PublishedReviewsWidget, ReviewsRequiringActionWidget, ReviewScoreWidget } from '../../components/widgets';
import { PublishedReviewsOverYearChart } from '../../components/charts/published_reviews_over_year_chart';
import { faCommentDots, faHouseBlank } from '@fortawesome/pro-solid-svg-icons';
import { faFilter } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

const Dashboard = ({ nhIDs, orgNHID }: IProps) => { 
    const [ selNHID, setSelNHID ] = useState<number>(null);
    const [ reviewWidgetIsLoading, setReviewWidgetIsLoading ] = useState<boolean>(false);
    const [ showcaseReviewsWidgetIsLoading, setShowcaseReviewsWidgetIsLoading ] = useState<boolean>(false);

    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        setSelNHID(selNHID);
    });

    const propertyMode = getCommunityMode(orgNHID, nhIDs, selNHID);

    const renderBody = () => {
        return (
            <>
                <section>
                    <div className="container max-w-none 2xl:container mt-4 sm:mt-6 md:mt-8 2xl:mt-10">
                        <div className="grid grid-cols-1 gap-4 sm:gap-5 2xl:gap-10">
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-5 2xl:gap-10">
                                <div className="flex flex-col">
                                    <div className="flex flex-row gap-2 items-center mb-4 sm:mb-5">
                                        <FontAwesomeIcon icon={faCommentDots} className="min-w-6 min-h-6 relative -top-px" />
                                        <h4 className="font-bold leading-tight text-xl lg:text-2xl">Reviews</h4>
                                    </div>
                                    <div className="grid grid-flow-row gap-4 sm:gap-5 h-full">
                                        <div className="grid gap-4 sm:grid-cols-2 sm:gap-5 md:grid-cols-3 lg:grid-cols-2 2xl:grid-cols-3">
                                            <div>
                                                <ReviewScoreWidget
                                                    nhIDs={nhIDs}
                                                    selNHID={selNHID}
                                                    propertyMode={propertyMode}
                                                    widgetSize={'xs'}
                                                />                                        
                                            </div>
                                            <div>
                                                <PublishedReviewsWidget
                                                    selNHID={selNHID}
                                                    widgetSize={'xs'}
                                                />                                        
                                            </div>
                                            <div className="sm:col-span-2 md:col-span-1 lg:col-span-2 2xl:col-span-1">
                                                <ReviewsRequiringActionWidget
                                                    selNHID={selNHID}
                                                    widgetSize={'xs'}
                                                />                                        
                                            </div>
                                        </div>
                                        <div className="h-full">
                                            <PublishedReviewsOverYearChart
                                                selNHID={selNHID}
                                                propertyMode={propertyMode}
                                            />                                        
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="flex flex-row gap-2 items-center mb-4 sm:mb-5">
                                        <FontAwesomeIcon icon={faHouseBlank} className="min-w-6 min-h-6 relative -top-px" />
                                        <h4 className="font-bold leading-tight text-xl lg:text-2xl">Profile Completeness</h4>
                                    </div>
                                    <div className="h-full">
                                        <ProfileCompletnessWidget
                                            orgNHID={orgNHID}
                                            nhIDs={nhIDs}
                                            selNHID={selNHID}
                                            setIsLoading={setReviewWidgetIsLoading}
                                            isLoading={reviewWidgetIsLoading}
                                            widgetSize={'small'}
                                            propertyMode={propertyMode}
                                        />                                    
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="flex flex-row gap-2 items-center mb-4 sm:mb-5">
                                    <FontAwesomeIcon icon={faFilter} className="min-w-6 min-h-6 relative -top-px" />
                                    <h4 className="font-bold leading-tight text-xl lg:text-2xl">Leads, Ranking and Features</h4>
                                </div>
                                <div className="grid grid-cols-1 gap-4 sm:gap-5 2xl:gap-10">
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-5 2xl:gap-10">
                                        <div className="grid sm:grid-cols-2 gap-4">
                                            <ShowcaseReviewsWidget
                                                orgNHID={orgNHID}
                                                nhIDs={nhIDs}
                                                selNHID={selNHID}
                                                setIsLoading={setShowcaseReviewsWidgetIsLoading}
                                                isLoading={showcaseReviewsWidgetIsLoading}
                                                showPortletArray={['postcards','invite-to-review']}
                                            />
                                        </div>
                                        <div>
                                            <ProfileCompletnessWidgetOther
                                                orgNHID={orgNHID}
                                                nhIDs={nhIDs}
                                                selNHID={selNHID}
                                                setIsLoading={setReviewWidgetIsLoading}
                                                isLoading={reviewWidgetIsLoading}
                                                widgetSize={'small'}
                                                propertyMode={propertyMode}
                                            />                                    
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-5 2xl:gap-10">
                                        <div>
                                            <LeadsWidget selNHID={selNHID} /> 
                                        </div>
                                        <div>
                                            <LeadsEmailLog selNHID={selNHID} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    };
    
    return (
        <>
            <PageCommunitySelectWrapper    
                label={'Dashboard for'}
                handleSelect={handleSelect}
                selNHID={selNHID}
                pageType={PageType.Dashboard}
            />
            { renderBody() }
        </>
    );
};

export default Dashboard;
 