import React, { useState } from 'react';
import { LoadingDots } from '../common/loading_dots';
import UploadWidget from '../../components/cloudinary/upload_widget';
import * as assetApi from '../../api/assets';
import { 
    ICloudinaryData,
    IAssetUploadLog,
    NHAssetType,
    IAssetPayLoad,
    IPortalPropertyPhotoAsset,
    IPortalPropertyTagObj,
    IPortalPropertyPhotoObj,
    IPortalPropertyTagModalData
} from '../../interfaces/asset';
import { IMessageType } from '../../interfaces/common';
import { ITag } from '../../interfaces/tag';
import Swal from 'sweetalert2';
import { updateProfileCompleteness } from '../../utils/profile_completeness';
import { TagKeyNameIcon } from '../tag_keyname_icon';
interface IProps {
    selectedNHID: number | null;
    orgNHID?: number | null;
    nhIDs?: number[];
    propertyPhotos: IPortalPropertyPhotoAsset[];
    setPropertyPhotos: (photos: IPortalPropertyPhotoAsset[]) => void;
    photoTags: ITag[];
    busyLoadingPhotos: boolean;
    setBusyLoadingPhotos: (busyState: boolean) => void;
    setPhotoUploadMessages: (messages: IMessageType[]) => void;
    handleClose?: any;
}

const PhotoUploadForm = ({
    selectedNHID,
    propertyPhotos,
    setPropertyPhotos,
    photoTags,
    busyLoadingPhotos,
    setBusyLoadingPhotos,
    setPhotoUploadMessages,
    handleClose
}: IProps) => {

    const [selectedTag, setSelectedTag] = useState<IPortalPropertyTagModalData>(null);
    const OthersTagID = 10;

    const handleSelectedTag = (tag: IPortalPropertyTagModalData)  => {
        setSelectedTag(tag);
    };

    const handleAssetUpload = async(cloudinaryData: ICloudinaryData): Promise<any> => {
        const assetPayLoad: IAssetPayLoad = { NHID: selectedNHID, AssetType: NHAssetType.Photos, TagID:selectedTag.TagID, ...cloudinaryData };
        const assetID = await assetApi.createAsset(assetPayLoad);

        updateProfileCompleteness();

        return {
            AssetID: assetID,
            TransformedAssetUrl: cloudinaryData.TransformedAssetUrl,
            Sequence: cloudinaryData.Sequence,
            FileName: cloudinaryData.TransformedAssetUrl.match(/([^/]+)(?=\.\w+$)/)[0]
        };
    };
    
    const handleShowAssetUploadResult = (assetUploadLog : IAssetUploadLog[]) => {
        let hasPhotosUploaded = false;
        const photoObj:IPortalPropertyPhotoObj[] = [];
        const photoUploadMessages: IMessageType[] = [];
        for (const item of assetUploadLog) {
            if(item.Type === 'success') {
                hasPhotosUploaded = true;
                break;
            }
        }

        for (const item of assetUploadLog) {
            photoObj.push({
                AssetID: item.AssetDetail.AssetID,
                FileName: item.AssetDetail.FileName,
                PhotoSequence:  item.AssetDetail.Sequence,
                TagID: selectedTag.TagID,
                TagName: selectedTag.TagName,
                Title: null,
                TransformedAssetUrl: item.AssetDetail.TransformedAssetUrl
            });
        }

        if( !hasPhotosUploaded ) {
            Swal.fire({
                title: 'Error',
                text: 'An error occurred while uploading photos, please try again.',
                icon: 'warning',
                iconHtml: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path d="M96 64c0-17.7-14.3-32-32-32S32 46.3 32 64l0 256c0 17.7 14.3 32 32 32s32-14.3 32-32L96 64zM64 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg>',
                confirmButtonText: 'Okay',
                allowOutsideClick: false,
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-success m-1',
                }
            });
        } else {
            try {
                setBusyLoadingPhotos(true);
                const updatedPropertyPhotos: IPortalPropertyPhotoAsset[] = propertyPhotos.map((obj: IPortalPropertyPhotoAsset) => {
                    if(obj.NHID === selectedNHID) {
                        //if the destination tag not exists in the propert photos tag array
                        obj.Photo = obj.Photo.concat(photoObj);
                        const isDestinationTagExists = obj.Tag.find((currentTag:IPortalPropertyTagObj )=> currentTag.TagID === selectedTag.TagID);
                        if(!isDestinationTagExists) {
                            const tagObj:IPortalPropertyTagObj  = {
                                AssetTagID: 0,
                                TagID: selectedTag.TagID, 
                                TagName: selectedTag.TagName, 
                                TagSequence: selectedTag.TagSequence,
                                IsTagPublishWebsite: selectedTag.IsTagPublishWebsite,
                                IsTagPublishPortal: selectedTag.IsTagPublishWebsite,
                                IsTagPublishCMS: selectedTag.IsTagPublishCMS,
                                Photo: photoObj
                            };
                            let selectedTagIndex = obj.Tag.findIndex((tagItem) => selectedTag.TagSequence < tagItem.TagSequence);
                            selectedTagIndex = selectedTagIndex !==  -1 ? selectedTagIndex : selectedTag.TagSequence;
                            obj.Tag = [...obj.Tag.slice(0, selectedTagIndex), tagObj, ...obj.Tag.slice(selectedTagIndex)];
                            return obj;
                        }
                        obj.Tag = obj.Tag.filter((currentTag:IPortalPropertyTagObj) => {
                            if(currentTag.TagID === selectedTag.TagID && isDestinationTagExists) {
                                photoObj.forEach((photoItem:IPortalPropertyPhotoObj)=>currentTag.Photo.push(photoItem));
                                return currentTag;
                            } else {
                                return currentTag;
                            }
                        });
                        return obj;
                    }
                    return obj;
                });
                photoUploadMessages.push({type: 'info', message:'Your Photo(s) have successfully been uploaded, they will take up to 60 minutes to show on nursinghomes.com'});
                if(selectedTag.TagID === OthersTagID) {
                    photoUploadMessages.push({type: 'error', message:'Please change the tag of the Photos tagged with \'Other\', if there is a more relevant tag to use. This provides a much better user experience on the website'});
                }
                setPropertyPhotos(updatedPropertyPhotos);
                setPhotoUploadMessages([]);
                setPhotoUploadMessages(photoUploadMessages);
                updateProfileCompleteness();
                handleClose ?. ();
            } catch (err) {
                Swal.fire({
                    title: 'Error',
                    text: 'Failed to update tag for the photo.',
                    icon: 'warning',
                    iconHtml: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path d="M96 64c0-17.7-14.3-32-32-32S32 46.3 32 64l0 256c0 17.7 14.3 32 32 32s32-14.3 32-32L96 64zM64 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg>',
                    confirmButtonText: 'Okay',
                    allowOutsideClick: false,
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'btn btn-success m-1',
                    }
                });
            } finally {
                setBusyLoadingPhotos(false);
            }
        }
    };

    const renderPhotoUploadForm = () => {
        const property = propertyPhotos[0];
        return (
            <div className="grid grid-cols-1 gap-3 lg:grid-cols-12 lg:gap-4 xl:gap-8">
                <ol className="lg:col-span-7 xl:col-span-8 [counter-reset:els] ps-10 space-y-6 [&>li]:relative [&>li]:before:[counter-increment:els] [&>li]:before:content-[counter(els)] [&>li]:before:rounded-full [&>li]:before:h-8 [&>li]:before:w-8 [&>li]:before:bg-brand_primary [&>li]:before:inline-block [&>li]:before:text-white [&>li]:before:text-center [&>li]:before:leading-8 [&>li]:before:-left-10 [&>li]:before:-top-1.5 [&>li]:before:absolute">
                    <li className="grid gap-3 sm:gap-4 2xl:gap-5">
                        <strong className="block text-md md:text-lg lg:text-xl">
                            Select a Relevant Tag
                        </strong>
                        <span className="msg msg-warning">
                            If you prefer to select the relevant tag(s) after uploading your photos, select the tag 'Other' and then change the tag after uploading. This is recommended when bulk uploading photos which require different tags.
                        </span>
                        <ul className="flex flex-wrap gap-2.5 [&>li]:inline-flex [&>li]:gap-1.5 [&>li]:items-center [&>li]:cursor-pointer [&>li]:bg-brand_faint-blue hover:[&>li]:ring-0 [&>li.selected]:ring-0 hover:[&>li]:bg-brand_secondary [&>li.selected]:bg-brand_secondary hover:[&>li]:text-white [&>li.selected]:text-white [&>li]:ring-1 [&>li]:ring-brand_grey [&>li]:h-8 [&>li]:leading-8 [&>li]:rounded-full [&>li]:text-xs [&>li]:px-2.5">
                            { photoTags.map((tag:ITag) => {
                                    return (
                                        tag.IsTagPublishWebsite === true ?
                                            <li 
                                                key={`tag-${tag.TagID}`}
                                                className={
                                                    selectedTag?.TagID === tag.TagID ? 
                                                        'selected' 
                                                    : null
                                                } 
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleSelectedTag({
                                                        TagID: tag.TagID,
                                                        TagName: tag.TagName,
                                                        TagSequence: 0,
                                                        IsTagPublishWebsite: tag.IsTagPublishWebsite,
                                                        IsTagPublishPortal: tag.IsTagPublishWebsite,
                                                        IsTagPublishCMS:tag.IsTagPublishCMS,
                                                        AssetID: 0
                                                    });
                                                }}
                                            >
                                                <TagKeyNameIcon tagKeyName={tag.TagKeyName} className="text-lg" />{tag.TagName}
                                            </li>
                                        : null
                                    );
                                })
                            }
                        </ul>
                    </li>
                    <li className="grid gap-3 sm:gap-4 2xl:gap-5">
                        <strong className="block text-md md:text-lg lg:text-xl">
                            Choose Photo(s) to Upload
                        </strong>
                        <UploadWidget 
                            nHID = {selectedNHID}
                            fileName={property.Property.Slug}
                            assetTypeName = 'Select Photos to Upload' 
                            assetType = {NHAssetType.Photos}
                            onAssetUpload = {handleAssetUpload}
                            showAssetUploadResult = {handleShowAssetUploadResult}
                            selectedTagID = {selectedTag?.TagID}
                        />
                        <div className="grid gap-2 text-sm">
                            <p>
                                Please note only JPEG (jpeg/jpg), PNG, GIF &amp; WebP files accepted. Maximum file size 40mb.
                            </p>
                            <p>
                                Large photo files can take up to 5 minutes to upload on slower connections. If in doubt please refresh the page after a couple of minutes.
                            </p>
                        </div>
                    </li>
                </ol>
                <div className="lg:col-span-5 xl:col-span-4 ring-1 ring-brand_secondary p-3 sm:p-4 xl:p-6 rounded-md sm:rounded-lg text-md h-fit">
                    <strong className="text-md md:text-lg lg:text-xl">
                        Uploading Photos - Tips &amp; Terms
                    </strong>
                    <ul className="marker:text-brand_grey-medium list-disc ps-5 space-y-2 text-sm mt-3">
                        <li>
                            You can upload an unlimited number of Photos, we recommend you upload at least 1 photo for each tag that is applicable to this Facility.
                        </li>
                        <li>
                            We prefer that you do not upload stock photos, but if you do, please make sure that you have permission for the photos to be used on third party websites.
                        </li>
                        <li>
                            Please do not upload logos, award logos, accreditation certificates or any other images that are not photographs (including photos containing a prominent accreditation). Also please do not upload a photo with a logo, award logo, image or text overlaid on the photo.
                        </li>
                        <li>
                            nursinghomes.com reserve the right to remove any photos we deem unsuitable.
                        </li>
                        <li>
                            <strong>By pressing Upload button, I/we agree to the terms &amp; conditions set out on <a className="link" href="https://www.nursinghomes.com/terms" target="_blank">www.nursinghomes.com/terms</a></strong>
                        </li>
                    </ul>
                </div>
            </div>
        );
    };

    return (
        <>
            <LoadingDots show={busyLoadingPhotos} />
            { renderPhotoUploadForm() }
        </>
    );
};

export default PhotoUploadForm;