import React, { useState } from 'react';
import { Modal } from '../../components/common/modal';
import { IPortalPropertyTagModalData } from '../../interfaces/asset';
import { ITag } from '../../interfaces/tag';
import Swal from 'sweetalert2';
import { Button } from '../common/button';
import { TagKeyNameIcon } from '../tag_keyname_icon';


interface IProps {
    photoTags: ITag[];
    sourceTag: IPortalPropertyTagModalData;
    handleClose(): void;
    handleTagUpdate(sourceTag: IPortalPropertyTagModalData, destinationTag: IPortalPropertyTagModalData): void;
}

const PhotoTagUpdateModal = ({
    photoTags,
    sourceTag, 
    handleClose, 
    handleTagUpdate   
}: IProps) => {

    const defaultTag: IPortalPropertyTagModalData = sourceTag ? sourceTag : null;
    const [selectedTag, setSelectedTag] = useState<IPortalPropertyTagModalData>(defaultTag);
  
    const handleSelectedTag = (tag: IPortalPropertyTagModalData)  => {
        setSelectedTag(tag);
    };

    const handleOnSubmit = () => {
          
        if( !selectedTag?.TagID ) {
            Swal.fire({
                title: 'Update Photo Tag',
                text: 'Please select a relevant tag.',
                icon: 'warning',
                iconHtml: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path d="M96 64c0-17.7-14.3-32-32-32S32 46.3 32 64l0 256c0 17.7 14.3 32 32 32s32-14.3 32-32L96 64zM64 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg>',
                confirmButtonText: 'Okay',
                allowOutsideClick: false,
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-success m-1',
                }
            });
        } else {
            handleTagUpdate(sourceTag, selectedTag);
        }
    };

    const renderForm = () => {
        return (
            <form onSubmit={handleOnSubmit} id="photo_tag_modal">
                <ul className="flex flex-wrap gap-2.5 [&>li]:inline-flex [&>li]:gap-1.5 [&>li]:items-center [&>li]:cursor-pointer [&>li]:bg-brand_faint-blue hover:[&>li]:ring-0 [&>li.selected]:ring-0 hover:[&>li]:bg-brand_secondary [&>li.selected]:bg-brand_secondary hover:[&>li]:text-white [&>li.selected]:text-white [&>li]:ring-1 [&>li]:ring-brand_grey [&>li]:h-8 [&>li]:leading-8 [&>li]:rounded-full [&>li]:text-xs [&>li]:px-2.5">
                    { photoTags.map((tag:ITag) => {
                            return (
                                tag.IsTagPublishWebsite === true ?
                                <li 
                                    key={`tag-${tag.TagID}`}
                                    className={
                                        selectedTag.TagID === tag.TagID ? 
                                            'selected' : null
                                    } 
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleSelectedTag({
                                            TagID: tag.TagID,
                                            TagName: tag.TagName,
                                            TagSequence: tag.TagSequence,
                                            IsTagPublishWebsite: tag.IsTagPublishWebsite,
                                            IsTagPublishPortal: tag.IsTagPublishWebsite,
                                            IsTagPublishCMS:tag.IsTagPublishCMS,
                                            AssetID: 0
                                        });
                                    }}
                                >
                                    <TagKeyNameIcon tagKeyName={tag.TagKeyName} className="text-lg" />{tag.TagName}
                                </li> : null
                            );
                        })
                    }
                </ul>        
            </form>
        );
    };

    const actionButtons = (
        <Button 
            type="submit"
            form="photo_tag_modal"
        >
            Update Tag
        </Button>        
    );

    return (
        <Modal
            isOpen={true}
            onClose={() => handleClose()}
            title={<>Update Photo Tag for Photo ID: {sourceTag.AssetID}</>}
            footerContent={actionButtons}
            size='md'
            blurBackdrop
        >
            { renderForm() }
        </Modal>
    );
};

export default PhotoTagUpdateModal;
