import React, { CSSProperties, FC } from 'react';

interface Props {
    imgUrl?: string;
    alt?: string;
}

const ProfileImage: FC<Props> = ({ imgUrl, alt }) => {
    return (
        <img
            alt={alt}
            className="block min-w-44 max-w-44 min-h-44 max-h-44 object-cover"
            src={imgUrl || '/images/profile-image-placeholder.png'}
        />
    );
};

export { ProfileImage };
