import React from 'react';
import { Modal } from '../../components/common/modal';
import RatingStars from '../common/rating_stars';
import { Button } from '../common/button';

interface IProps {
    review: any;
    handleClose(): void;
    handlePublishReview(reviewID: number): Promise<void>;
    cancelText: string;
    saveText: string[];
}

const PublishModal = ({
    review, 
    handleClose, 
    handlePublishReview,
    cancelText,
    saveText
}: IProps) => {
    const footerButtons = (
        <>
            <Button
                type="button"
                styleType="outline"
                onClick={() => handleClose()}
            >
                Close &amp; Do Not {cancelText}
            </Button>        
            <Button 
                type="submit" 
                onClick={() => handlePublishReview(review.ReviewID)}
            >
                {saveText[0]} {saveText[1]} 
            </Button>
        </>
    );

    return (
        <Modal
            isOpen={true}
            onClose={handleClose}
            title={`Publish Review ID: ${review.ReviewID} for ${review.Name}`}
            size='lg'
            footerContent={footerButtons}
            footerContentClose={false}
        >
            <div className="grid gap-3 lg:gap-5 2xl:gap-6">  
                { 
                    review.OverallRating && 
                    <div className="flex flex-col gap-1 lg:flex-row lg:gap-2 lg:items-center lg:flex-wrap [&>div]:relative [&>div]:-top-px">
                        <strong className="font-semibold lg:text-lg">Overall Rating:</strong> 
                        <RatingStars
                            keyProp={review.ReviewID}
                            value={review.OverallRating}
                        />
                    </div>
                }
                { review.ExperienceTypeName && 
                    <div className="flex flex-col gap-1 lg:flex-row lg:gap-2 lg:items-center lg:flex-wrap">
                        <strong className="font-semibold lg:text-lg">Reviewer Experience Type:</strong> {review.ExperienceTypeName}
                    </div>
                }
                { review.ConnectionName && 
                    <div className="flex flex-col gap-1 lg:flex-row lg:gap-2 lg:items-center lg:flex-wrap">
                        <strong className="font-semibold lg:text-lg">Relationship to the resident or client:</strong> {review.ConnectionName}
                    </div>
                }
                <div className="flex flex-col gap-1 lg:flex-row lg:gap-2 lg:items-center lg:flex-wrap">
                    <strong className="font-semibold lg:text-lg">Review:</strong> { review.ReviewContent }
                </div>
            </div>
        </Modal>
    );
};

export default PublishModal;
