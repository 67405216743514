import React, { useEffect, useState, useCallback }  from 'react';
import DatePicker from 'react-datepicker';
import FilterMatrix from '../../../modules/filter_matrix';
import ReviewRatingTypeFilter from './review_rating_type_filter';
import ReviewPublicationStatusFilter from './review_publication_status_filter';
import ReviewExperienceTypeFilter from './review_experiencetype_filter';
import ReviewerConnectionFilter from './reviewer_connection_filter';
import { getReviewsBySearchString, getReviewScheme } from '../../../api/review';
import AutoComplete from '../../common/autocomplete';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCircle } from '@fortawesome/pro-solid-svg-icons';


interface IProps {
    filterMatrix: FilterMatrix;
    applyFilter(key: string, value: any, refreshData: boolean): void;
    refreshData: () => void;
    published: boolean;
    applyReviewScoreFilters?: () => void
}

const ReviewFilters = ({ filterMatrix, applyFilter, refreshData, published, applyReviewScoreFilters }: IProps) => {

    const [reviewScheme, setReviewScheme] = useState<any>();
    const [, updateState] = useState<any>();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [ reviewSearchOptions, setReviewSearchOptions ] = useState<any>([]);

    const handleFilterChange = (key: string, value: any, refreshData = true) => {
        applyFilter(key, value, refreshData);
        forceUpdate();
    };

    const resetFilters = () => {
        filterMatrix.reset();
        refreshData();
    };

    useEffect(() => {
        (async () => {
            try {
                const reviewSchemeData: any = await getReviewScheme();
                setReviewScheme(reviewSchemeData);
            } catch (e) {
                console.error(e);
            }
        })();
    }, []);

    const getLabelKey = (option: any) => {
        const by = option.ReviewerDisplayName ? `by ${option.ReviewerDisplayName.trim()}` : '';

        return `Review ID: ${option.ReviewID} ${by} on ${moment(option.CreatedAt).format('MMMM Do YYYY h:mm a')}`;
    };

    const onSelectCompleted = (option: any) => {
        if (!option) {
            applyFilter('ReviewID', null, true);
            return;
        }

        applyFilter('ReviewID', option.ReviewID, true);
    };

    const getReviewsByIDSearch = async (query: any) => {
        const data = await getReviewsBySearchString(query, {
            IsPublished: true
        });

        setReviewSearchOptions(data);

        return data;
    };

    return (
        <div className="grid gap-3">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
                <div className="form-component form-inline form-datepicker">
                    <label 
                        htmlFor="DateSubmittedFrom"
                    >
                        Date Submitted From
                    </label>
                    <DatePicker
                        id="DateSubmittedFrom"
                        selected={filterMatrix.getFormFieldValue('DateSubmittedFrom')}
                        onChange={(date: Date) => {
                            if (date) {
                                date.setHours(0, 0, 0, 0);
                            }
                            handleFilterChange('DateSubmittedFrom', date);
                        }}
                        dateFormat={'dd MMM yyyy'}
                        placeholderText="Click to select a date"
                        showPopperArrow={false}
                    />
                </div>
                <div className="form-component form-inline form-datepicker">            
                    <label 
                        htmlFor="DateSubmittedTo"
                    >
                        Date Submitted To
                    </label>
                    <DatePicker
                        id="DateSubmittedTo"
                        selected={filterMatrix.getFormFieldValue('DateSubmittedTo')}
                        onChange={(date: Date) => {
                            if (date) {
                                date.setHours(23, 59, 59, 999);
                            }
                            handleFilterChange('DateSubmittedTo', date);
                        }}
                        dateFormat={'dd MMM yyyy'}
                        placeholderText="Click to select a date"
                        showPopperArrow={false}
                    />
                </div>   
                { published ?
                    <>
                        <div className="form-component form-inline form-datepicker">
                            <label 
                                htmlFor="DatePublishedFrom"
                            >
                                Date Published From
                            </label>
                            <DatePicker
                                id="DatePublishedFrom"
                                selected={filterMatrix.getFormFieldValue('DatePublishedFrom')}
                                onChange={(date: Date) => {
                                    if (date) {
                                        date.setHours(0, 0, 0, 0);
                                    }
                                    handleFilterChange('DatePublishedFrom', date);
                                }}
                                dateFormat={'dd MMM yyyy'}
                                placeholderText="Click to select a date"
                                showPopperArrow={false}
                            />
                        </div>
                        <div className="form-component form-inline form-datepicker">
                            <label 
                                htmlFor="DatePublishedTo">
                                Date Published To
                            </label>
                            <DatePicker
                                id="DatePublishedTo"
                                selected={filterMatrix.getFormFieldValue('DatePublishedTo')}
                                onChange={(date: Date) => {
                                    if (date) {
                                        date.setHours(23, 59, 59, 999);
                                    }
                                    handleFilterChange('DatePublishedTo', date);
                                }}
                                dateFormat={'dd MMM yyyy'}
                                placeholderText="Click to select a date"
                                showPopperArrow={false}
                            />
                        </div>
                    </> : null
                }
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-3">
                <div className="form-component form-inline form-textbox">
                    <label 
                        htmlFor="ReviewerDisplayName"
                    >
                        Reviewer Display Name
                    </label>
                    <input
                        type="text"
                        id="ReviewerDisplayName"
                        onBlur={(e) => handleFilterChange('ReviewerDisplayName', e.target.value)}
                        onChange={(e) => handleFilterChange('ReviewerDisplayName', e.target.value)}
                        onKeyUp={(e: any) => e.keyCode === 13 ? handleFilterChange('ReviewerDisplayName', e.target.value) : ''}
                        value={filterMatrix.getFormFieldValue('ReviewerDisplayName')}
                    />
                </div>
                <div className="xl:col-span-2 form-component form-inline form-textbox">
                    <label 
                        htmlFor="ReviewText"
                    >
                        Review Text
                    </label>
                    <input
                        type="text"
                        id="ReviewText"
                        onBlur={(e) => handleFilterChange('ReviewText', e.target.value)}
                        onChange={(e) => handleFilterChange('ReviewText', e.target.value)}
                        onKeyUp={(e: any) => e.keyCode === 13 ? handleFilterChange('ReviewText', e.target.value) : ''}
                        value={filterMatrix.getFormFieldValue('ReviewText')}
                    />                
                </div>    
                <div className="xl:col-span-2 form-component form-inline form-autocomplete">
                    <label 
                        htmlFor="ReviewID"
                    >
                        Review ID
                    </label>
                    <AutoComplete
                        id="ReviewSearch"
                        isLoading={false}
                        getLabelKey={getLabelKey}
                        usePagination={true}
                        defaultValue={''}
                        onSelectCompleted={onSelectCompleted}
                        getData={getReviewsByIDSearch}
                        options={reviewSearchOptions}
                        customFilterBy={() => true}
                    />
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <ReviewExperienceTypeFilter
                    filterMatrix={filterMatrix}
                    applyFilter={handleFilterChange}
                    reviewScheme={reviewScheme}
                />
                <ReviewerConnectionFilter
                    filterMatrix={filterMatrix}
                    applyFilter={handleFilterChange}
                />
            </div>
            { published ? 
                <div>
                    <ReviewRatingTypeFilter
                        filterMatrix={filterMatrix}
                        applyFilter={handleFilterChange}
                        refreshData={refreshData}
                        reviewScheme={reviewScheme}
                    />                
                </div> : null
            }
            { !published ? 
                <div>
                    <ReviewPublicationStatusFilter
                        filterMatrix={filterMatrix}
                        applyFilter={handleFilterChange}
                        reviewScheme={reviewScheme}
                    /> 
                </div> : null
            }
            { published ?
                <div className="px-3 py-1.5 md:py-3 flex flex-col gap-2 lg:flex-row lg:gap-5 xl:gap-7"> 
                    <strong className="font-semibold text-sm lg:text-sm">
                        Reply Status 
                    </strong>                       
                    <div className="form-component form-radio">
                        <label>
                            <input 
                                className="peer"
                                type="radio"
                                name='replyStatus'
                                checked={!filterMatrix.getFormFieldValue('HasReply')}
                                onChange={() => handleFilterChange('HasReply', undefined)}
                            />
                            <FontAwesomeIcon icon={faCircle} className="peer-checked:block"/>
                            <span>
                                Any
                            </span>
                        </label>                        
                    </div>
                    <div className="form-component form-radio">
                        <label>
                            <input 
                                className="peer"
                                type="radio"
                                name='replyStatus'
                                checked={filterMatrix.getFormFieldValue('HasReply') === 'true'}
                                onChange={() => handleFilterChange('HasReply', 'true')}
                            />
                            <FontAwesomeIcon icon={faCircle} className="peer-checked:block"/>
                            <span>
                                Has a Reply (Published or Pending Verification)
                            </span>
                        </label>
                    </div>
                    <div className="form-component form-radio">
                        <label>
                            <input 
                                className="peer"
                                type="radio"
                                name='replyStatus'
                                checked={filterMatrix.getFormFieldValue('HasReply') === 'false'}
                                onChange={() => handleFilterChange('HasReply', 'false')}
                            />
                            <FontAwesomeIcon icon={faCircle} className="peer-checked:block"/>
                            <span>
                                Has No Reply
                            </span>
                        </label>
                    </div>
                </div> : null
            }
            { applyReviewScoreFilters &&
                <a className="px-3 link underline" onClick={applyReviewScoreFilters}>
                    Select all relevant filters to show only Reviews used in the Review Score
                </a>
            }
            {/*<button
                className="btn btn-outline btn-outline-tertiary btn-small flex items-center text-md"
                onClick={resetFilters}>
                    <FontAwesomeIcon icon={faXmark} className="me-1" />Clear all filters
            </button>*/}
        </div>
    );
};

export default ReviewFilters;
