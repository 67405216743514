import React from 'react';
import { NavLink } from 'react-router-dom';
import { 
    IReviewScoreFilterState
} from '../../../interfaces/review';
import ReviewScoreDisplayFormatted from '../../common/review_score_display_formatted';
import { IReviewScoreWidgetLargeProps } from './types';
import { buildMultiBulletsNoReviewScore, buildSingleBulletsNoReviewScore, inviteToReviewLink, renderScoreCardTxt, reviewCardLink } from './Review_score_widget_common';

const ReviewScoreWidgetLarge = ({
    propertyCountData,
    reviewScoreDisplay,
    reviewScoreFull,
    orgName,
    multiMode,
    singleProperty
}: IReviewScoreWidgetLargeProps) => {
    const singlePropertyName = singleProperty ? singleProperty.Name : '';
    const nhID = singleProperty ? singleProperty.NHID : null;

    const renderHeader = () => {
        if (multiMode) {
            return renderMultiHeader();
        }
        
        return renderSingleHeader();
    };

    const renderMultiHeader = () => {
        return <h4 className="font-semibold leading-tight text-lg lg:text-xl">
            Provider Review Score for {orgName}
        </h4>;
    };

    const renderSingleHeader = () => {
        return <h4 className="font-semibold leading-tight text-lg lg:text-xl">
            Review Score for <span className="hidden md:inline-block">for {singlePropertyName}</span>
        </h4>;
    };

    const renderStats = () => {
        if (!reviewScoreFull) {
            return null;
        }

        if (multiMode) {
            return renderMultiStats();
        }

        return renderSingleStats();
    };

    const renderSingleStats = () => {
        const { 
            TotalCount, 
            TotalLessThan15PosReviewsIn24Mnths, 
        } = propertyCountData || {};

        const arr: [string, string, number, number, string, IReviewScoreFilterState][] = [
            [
                'Do you have less than the required number of positive reviews?', 
                '(click \'View breakdown\' to see the required number of positive reviews for maximum points)',
                TotalLessThan15PosReviewsIn24Mnths, TotalCount, 'score-card-2',
                { NHID: nhID }
            ]
        ];

        return arr.map((item: [string, string, number, number, string, IReviewScoreFilterState]) => (
            renderScoreCard(...item)));
    };

    const renderMultiStats = () => {
        const { 
            TotalCount, 
            TotalLessThan15PosReviewsIn24Mnths, 
            TotalNoReviewScore
        } = propertyCountData || {};
       
        const arr: [string, string, number, number, string, IReviewScoreFilterState][] = [
            [
                'Facilities with No Review Score', 
                '(as no valid reviews in the last 24 months)',
                TotalNoReviewScore, TotalCount, 'score-card-1',
                { NoReviewScore: true },
            ],
            [
                'Facilities with less than the required number of positive reviews', 
                '(click \'View Facilities\' to see the required number of positive reviews for maximum points)',
                TotalLessThan15PosReviewsIn24Mnths, TotalCount, 'score-card-3',
                { BelowReviewScorePositiveReviewCountThreshold: true }
            ]
        ];

        return arr.map((item: [string, string, number, number, string, IReviewScoreFilterState]) => (
            renderScoreCard(...item)));
    };

    const renderScoreCard = (hdr: string, detail: string, num: number, denom: number, key: string, 
        filters: IReviewScoreFilterState) => {

        return <div className="pt-5 sm:pt-6 2xl:pt-7 mt-5 sm:mt-6 2xl:mt-7 flex flex-col md:flex-row gap-3 md:gap-6 md:items-center border-t border-brand_grey-light" key={key}>
            <div className="flex items-center text-6xl">
                { renderScoreCardTxt(reviewScoreDisplay, num, denom) }
            </div>
            <div>
                <strong className="font-semibold text-lg md:text-xl block">
                    { hdr }
                </strong>
                <span className="mt-1 block text-xs md:text-sm">
                    { detail }
                </span>
            </div>
            { 
                multiMode ? <NavLink className="btn ms-auto"
                    to={'/review-score'}
                    state={{ filters }}
                >
                    View Facilities
                </NavLink> : null
            }
        </div>;
    };

    const reviewScoreDisplayText = () => (
        <ReviewScoreDisplayFormatted value={reviewScoreDisplay} showAsNotApplicableOnNull={true} showAsText={true} />
    );

    const buildMultiBullets = () => {
        return [
            <React.Fragment key={'multibullet-1'}>Your Provider Review Score is made up of the average of all your facility Review Scores.</React.Fragment>,
            <React.Fragment key={'multibullet-2'}>Each facility&apos;s Review Score is calculated using a combination of the Quantity of Reviews, and 
                the Average Rating of Reviews, using only Reviews published in the last 24 months.</React.Fragment>,
            <React.Fragment key={'multibullet-3'}>Your current Provider Review Score is { reviewScoreDisplayText() } (out of a maximum 10).</React.Fragment>,
            <React.Fragment key={'multibullet-4'}>To improve your Review Score, request new reviews using {inviteToReviewLink(nhID)} or {reviewCardLink(nhID)}</React.Fragment>
        ];
    };

    const buildSingleBullets = () => {
        return [
            <React.Fragment key={'singlebullet-1'}>Your Review Score is calculated using a combination of the Quantity of Reviews
            and the Average Rating of Reviews, using only Reviews published in the last 24 months.</React.Fragment>,
            <React.Fragment key={'singlebullet-2'}>Your current Review Score is { reviewScoreDisplayText() } (out of a maximum 10).</React.Fragment>,
            <React.Fragment key={'singlebullet-3'}>To improve your Review Score, request new reviews using {inviteToReviewLink(nhID)} or {reviewCardLink(nhID)}</React.Fragment>
        ];
    };

    const buildBullets = () => {
        if (multiMode) {
            return reviewScoreFull ? buildMultiBullets() : buildMultiBulletsNoReviewScore(orgName, nhID);
        }
        return reviewScoreFull ? buildSingleBullets() : buildSingleBulletsNoReviewScore(singlePropertyName, nhID);
    };

    return <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg flex flex-col">
        <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 2xl:py-3 min-h-14 flex items-center justify-between gap-2">
            {renderHeader()}
            <NavLink 
                className="btn"
                to={'/review-score'}
                state={ nhID ? { filters: { NHID: nhID} } : null }
            >
                { multiMode ? 'View all Scores' : 'View breakdown' }
            </NavLink>
        </div>
        <div className="p-3 sm:p-4 2xl:p-5 h-full">
            <div className="flex flex-col md:flex-row items-center gap-4 lg:gap-6 [&>div]:text-[3.75rem] [&>div]:px-12 [&>div]:py-7 [&>div]:rounded-2xl">
                <ReviewScoreDisplayFormatted 
                    value={reviewScoreDisplay}
                    showAsNotApplicableOnNull={true}
                />
                <ul className="marker:text-brand_grey-medium list-disc ps-5 space-y-2">
                    {
                        buildBullets().map((bullet: any, index: number) => (
                            <li key={`bullet-${index}`}>
                                {bullet}
                            </li>
                        ))
                    }
                </ul>
            </div>
            <div className="mb-1 sm:mb-2 2xl:mb-3">
                { renderStats() }
            </div>
        </div>
    </div>;
};

export default ReviewScoreWidgetLarge;
