import React, { FC, useState } from 'react';

interface IProps {
    originalText: string;
    swapText: string;
    swapTime?: number;
}

// This component is primarily for use inside buttons, replacing the text. 
// Parent component must have a non-static position e.g. 'relative'.
const MomentaryTextSwap: FC<IProps> = ({
    originalText,
    swapText,
    swapTime = 2000
}) => {
    const [displayedText, setDisplayedText] = useState(originalText);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

    const handleClick = async () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        setDisplayedText(swapText);
        const timerId = setTimeout(
            () => setDisplayedText(originalText),
            swapTime
        );
        setTimeoutId(timerId);
    };

    return (
        <>
            {/* use longest text to ensure the parent is sized to accomodate both values */}
            <div className="opacity-0">
                {swapText.length > originalText.length
                    ? swapText
                    : originalText}
            </div>
            <span
                className="absolute inset-0 flex items-center justify-center"
                onClick={handleClick}
            >
                {displayedText}
            </span>
        </>
    );
};

export { MomentaryTextSwap };
