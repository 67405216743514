import React, { FC } from 'react';
import { getPropertyTeamMembers } from '../../../../api/meet_the_team';
import { useQuery } from '@tanstack/react-query';
import { OnDragEndResponder } from 'react-beautiful-dnd';
import { DroppableMemberCardArea } from './member_cards';
import { useUpdateTeamSequenceOrderMutation } from '../hooks/useUpdateTeamSequenceOrderMutation';
import { LoadingSpinner } from '../components/loading_spinner';
import { AddTeamMemberModal } from '../components';
import { columnStyles } from './style';

const SinglePropertyMembersView: FC<{ nhID: number }> = ({ nhID }) => {
    const queryKey = ['meet-the-team', 'property', nhID];

    const { data = [], isLoading } = useQuery({
        enabled: !!nhID, // don't make the request until nhID is available
        queryKey,
        staleTime: 5000,
        refetchOnWindowFocus: false,
        queryFn: () => getPropertyTeamMembers(nhID)
    });

    const updateTeamSequenceOrderMutation = useUpdateTeamSequenceOrderMutation(
        nhID,
        queryKey
    );

    const handleDragEnd: OnDragEndResponder = async ({
        destination,
        source
    }) => {
        // eslint-disable-next-line
        if (destination?.index == null) return;

        updateTeamSequenceOrderMutation({
            sequence: destination.index + 1, // we want to start sequence count at 1
            memberId: data[source.index].id
        });
    };   

    const header = (
        <>
            <div className="flex gap-4 items-end text-sm lg:text-md font-medium text-brand_grey-medium pb-3 px-3 min-w-fit">
                <div className="min-w-14 max-w-14">Order</div>
                <div className="min-w-44 max-w-44">Photo</div>
                <div className="min-w-44 max-w-44">Full Name</div>
                <div className="min-w-44 max-w-44">Job title</div>
                <div className="min-w-20 max-w-20">Year Started</div>
                <div className="w-full min-w-56">Description</div>
                <div className="min-w-20 max-w-20">Publish to Website</div>
                <div className="ms-auto min-w-28 max-w-28">Actions</div>
            </div>
        </>
    );


    

    return (
        <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg flex flex-col ">
            <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-3 min-h-14 flex flex-col items-start xl:items-center xl:justify-between gap-2 xl:flex-row h-full">
                <span className="text-xs lg:text-sm">
                    To re-order a Team Member drag-and-drop the entire row to the desired position.
                </span>
                <AddTeamMemberModal
                    propertyId={nhID}
                    isInSinglePropertyContext={true}
                />  
            </div>
            <div className="p-3 sm:p-4 2xl:p-5 h-full overflow-x-auto scrollbar">
                {header}
                { isLoading ? <LoadingSpinner /> :
                    <DroppableMemberCardArea
                        onDragEnd={handleDragEnd}
                        teamMembers={data}
                    />
                }
            </div>
        </div>
    );
};

export { SinglePropertyMembersView };
