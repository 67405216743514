import React, { FC } from 'react';
import { Button } from '../../../components/common/button';
import { Link } from 'react-router-dom';

interface IProps {
    isLoading: boolean;
    buttonText?: string;
}

const FormActions: FC<IProps> = ({
    isLoading,
    buttonText = 'Submit'
}) => {
    return (
        <div className="mt-3 flex gap-3 items-center">
            <div className="flex flex-col text-sm lg:text-md gap-0.5">
                <Link className="link" to="/passwordresetemail">Reset Password</Link>
                <Link className="link" to="/requestaccess">Request Access</Link>
            </div>
            <Button type="submit" className="ms-auto" isLoading={isLoading}>
                {buttonText}
            </Button>
        </div>
    );
};

export { FormActions };
