import React, { FC } from 'react';

import { OnDragEndResponder } from 'react-beautiful-dnd';
import { MeetTheTeamMemberType } from '../../types';
import { ISessionProperty } from '../../../../../interfaces/session';
import { useUpdateTeamSequenceOrderMutation } from '../../hooks/useUpdateTeamSequenceOrderMutation';
import { AddTeamMemberModal, LoadingSpinner } from '../../components';
import { Button } from '../../../../../components/common/button';
import { DroppableMemberCardArea } from '../member_cards';
import ProfileCompletenessText from '../../../../../components/profile_completeness/profile_completeness_text';
import { ProfileScoreType } from '../../../../../interfaces/property';

interface Props {
    disabled: boolean;
    isLoading: boolean;
    property: ISessionProperty;
    data: MeetTheTeamMemberType[];
    onSelectProperty: (propertyId: number) => void;
}

const DroppableAreaContainer: FC<Props> = ({
    disabled = false,
    isLoading,
    property,
    data,
    onSelectProperty
}) => {
    const {
        Address1,
        City,
        CitySlug,
        Name,
        NHID,
        PostCode,
        RegionCode,
        Slug,
        StateSlug,
        WebsiteURL
    } = property;

    const updateTeamSequenceOrderMutation = useUpdateTeamSequenceOrderMutation(
        property.NHID,
        ['meet-the-team', 'property']
    );

    const handleDragEnd: OnDragEndResponder = async ({
        destination,
        source
    }) => {
        // eslint-disable-next-line
        if (destination?.index == null) return;

        updateTeamSequenceOrderMutation({
            sequence: destination.index + 1, // we want to start sequence count at 1
            memberId: data[source.index].id
        });
    };

    const header = (
        <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-3 min-h-14 flex flex-col items-start xl:items-center xl:justify-between gap-2 xl:flex-row h-full">
            <div className="flex flex-wrap gap-y-1 items-center gap-x-2 w-full [&>em]:w-full">
                <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                    {Name}
                </h4>
                <span className="inline-block text-xs lg:text-sm leading-tight text-brand_grey-medium">
                    {Address1}, {City}, {RegionCode} {PostCode}
                </span>
                <a
                    className="link text-xs bg-white px-2 py-1 inline-block leading-none rounded-lg"
                    href={`${WebsiteURL}/${StateSlug}/${CitySlug}/${Slug}`}
                    target="_blank" 
                >
                    view on website
                </a>
                <ProfileCompletenessText
                    NHID={NHID}
                    profileScoreType={ProfileScoreType.MeetTheTeam}
                />
            </div>
            <div className="flex flex-wrap gap-2 xl:ms-auto xl:flex-row xl:flex-nowrap">
                <AddTeamMemberModal 
                    propertyId={NHID} 
                />
                {data?.length > 0 && (
                    <Button onClick={() => onSelectProperty(NHID)}>
                        View all Team Members
                    </Button>
                )}
            </div>
        </div>
    );

    return (
        <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg flex flex-col">
            {header}
            <div>
                { isLoading ?  <LoadingSpinner /> : 
                    <DroppableMemberCardArea
                        disabled={disabled}
                        propertyId={property.NHID}
                        key={property.NHID}
                        onDragEnd={handleDragEnd}
                        teamMembers={data}
                    />
                }
            </div>
        </div>
    );
};

export { DroppableAreaContainer };
