import React, { ButtonHTMLAttributes, FC } from 'react';
import cx from 'classnames';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    isLoading?: boolean;
    variant?: 'primary' | 'secondary' | 'tertiary' | 'grey' | 'success' | 'info' | 'warning' | 'error';
    styleType?: 'solid' | 'outline' | 'link';
    small?: boolean;
    disabled?: boolean;
}

/* tailwind needs to physically see all the possible class names on the page - not ideal! */
const tailwind = [
    'btn-primary', 'btn-secondary', 'btn-tertiary', 'btn-grey', 'btn-success', 'btn-info', 'btn-warning', 'btn-error',
    'btn-outline-primary', 'btn-outline-secondary', 'btn-outline-tertiary', 'btn-outline-grey', 'btn-outline-success', 'btn-outline-info', 'btn-outline-warning', 'btn-outline-error',
    'link-primary', 'link-secondary', 'link-tertiary', 'link-grey', 'link-success', 'link-info', 'link-warning', 'link-error',
];

const Button: FC<Props> = (props) => {
    const {
        className,
        isLoading,
        children,
        variant = 'default',
        small,
        styleType = 'solid',
        disabled,
        ...rest
    } = props;

    const buttonClassName: string = cx(
        { 'relative': !(className?.includes('absolute') || className?.includes('fixed'))},
        { 'btn' : variant === 'default' && styleType === 'solid' },
        {[`btn btn-${variant}`] : variant !== 'default' && styleType === 'solid'},
        {[`btn btn-outline btn-outline-${variant}`]: styleType === 'outline'},
        { 'link' : variant === 'default' && styleType === 'link' },
        {[`link link-${variant}`] : variant !== 'default' && styleType === 'link'},
        { 'btn-small' : small && styleType !== 'link' },
        { 'link-small' : small && styleType === 'link' },
        className
    );

    const spinnerClassName: string = cx(
        'spinner-border rounded-full border-4 border-slate-200 absolute h-1/2 m-5',
        'aspect-square border-r-transparent border-opacity-40 animate-spin duration-75',
        { invisible: !isLoading }
    );

    return (
        <button
            className={buttonClassName}
            disabled={disabled}
            {...rest}
        >
            <div className={spinnerClassName} role="status" />
            <span className={cx({ 'opacity-0': isLoading })}>{children}</span>
        </button>
    );
};

export { Button };
export type { Props as ButtonProps };
