import React from 'react';
import { NavLink } from 'react-router-dom';
import ReviewScoreDisplayFormatted from '../../common/review_score_display_formatted';
import { IReviewScoreWidgetXSProps } from './types';
import { LoadingDots } from '../../common/loading_dots';

const ReviewScoreWidgetXS = ({  
    reviewScoreDisplay,
    multiMode,
    loadingData,
}: IReviewScoreWidgetXSProps) => {

    const renderHeader = () => {
        if (multiMode) {
            return renderMultiHeader();
        }
        
        return renderSingleHeader();
    };

    const renderMultiHeader = () => {
        return <>
            <h4 className="font-semibold leading-none text-lg lg:text-xl">
                Your Provider Review Score
            </h4>
        </>;
    };

    const renderSingleHeader = () => {
        return <>
            <h4 className="font-semibold leading-none text-lg lg:text-xl">
                Your Review Score
            </h4>
        </>;
    };

    return <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg flex flex-col h-full 2xl:min-h-52">
        <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 2xl:py-3 min-h-14 flex items-center">
            {renderHeader()}
        </div> 
        <div className="p-3 sm:p-4 2xl:p-5 flex flex-col h-full">
            <div className="flex flex-col justify-between items-center gap-3 h-full [&>div]:text-5xl [&>div]:px-6 [&>div]:py-4 [&>div]:rounded-lg">
                { 
                    loadingData ? 
                        <LoadingDots show={true} showInPlace={true} />
                    :
                        <ReviewScoreDisplayFormatted 
                            value={reviewScoreDisplay}
                            showAsNotApplicableOnNull={true}
                        />
                }
                <NavLink className="btn mt-auto text-center max-w-52" to={'/review-score'}>
                    View Breakdown
                </NavLink>
            </div>
        </div>
    </div>;
};

export default ReviewScoreWidgetXS;
