import { FC, useEffect } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';

interface Props {
    isOpen: boolean;
    title?: string;
    subtitle?: string;
    onClose: () => void;
    onConfirm: () => Promise<void>;
}

const DeleteEntityDialog: FC<Props> = (props) => {

    const { isOpen, title, subtitle, onClose, onConfirm } = props;

    {/*error icon:  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg> */}

    const dialogSettings: SweetAlertOptions = {
        title: title || 'Delete item',
        html: subtitle || 'Are you sure?',
        icon: 'warning',
        iconHtml: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path d="M96 64c0-17.7-14.3-32-32-32S32 46.3 32 64l0 256c0 17.7 14.3 32 32 32s32-14.3 32-32L96 64zM64 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg>',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        preConfirm: onConfirm,
        didClose: onClose,
        allowOutsideClick: false,
        buttonsStyling: false,
        customClass: {
            confirmButton: 'btn btn-success m-1',
            denyButton: 'btn btn-error m-1',
            cancelButton: 'btn btn-error m-1'
        }
    };

    useEffect(() => {
        if (isOpen) {
            Swal.fire(dialogSettings);
        }
    }, [isOpen]);

    return null;
};

export { DeleteEntityDialog };
