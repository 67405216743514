import React from 'react';

interface IProps {
    title: string;
}

const PageTitle = ({ title }: IProps) => {
    return (
        <h1 className="font-bold text-2xl md:text-3xl lg:text-4xl xl:text-5xl">
            {title}
        </h1>
    );
};

export default PageTitle;
