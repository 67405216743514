import React, { useState, useEffect, useContext } from 'react';
import PageCommunitySelectWrapper from '../../../components/common/page_community_select_wrapper';
import DataTable from '../../../components/common/data_table';
import { LoadingDots } from '../../../components/common/loading_dots';
import {
    IPropertyContactDetails,
    IPropertyContactDetailsParams,
    IPropertyContactNoValueDetails,
    ContactDetailsHeaders
} from '../../../interfaces/property';
import {
    getPropertyContactDetails,
    getPropertyContactDetailsFilterDetails
} from '../../../api/property';
import { Toast } from '../../../utils/toast';
import { DataTableSortOrder } from '../../../components/common/data_table';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { formatAddress } from '../../../utils/property';
import { websiteJSXIcon } from './website_link_icon';
import { useCommunitySelectHistory } from '../../../hooks/use_community_select_history';
import { BulkPropertyContactDetailsUpdateForm } from './bulk_property_contact_details_form';
import { UpdatePropertyContactDetailsForm } from './update_property_contact_details_form';
import { UpdatePropertyDetailModal } from './modal/update_property_contact_details_modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import AppContext from '../../../context/app_context';


interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

const PropertyContacts = ({ nhIDs, orgNHID }: IProps) => {
    const [busy, setBusy] = useState<boolean>(false);
    const [selNHID, setSelNHID] = useState<number[]>([]);
    const [contactDetails, setContactDetails] = useState<
        IPropertyContactDetails[]
    >([]);
    const [activeContactDetails, setActiveContactDetails] =
        useState<IPropertyContactDetails>(null);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);
    const [tableOffset, setTableOffset] = useState<number>(0);
    const [tableLimit, setTableLimit] = useState<number>(100);
    const [tableSortColumn, setTableSortColumn] = useState<string>('Name');
    const [tableSortOrder, setTableSortOrder] = useState<any>('DESC');
    const [tableTotalRows, setTableTotalRows] = useState<number>(0);
    const [changedTick, setChangedTick] = useState<number>(0);
    const [selectedFilterOptions, setSelectedFilterOptions] = useState<any>({
        HasNoPhone: false,
        HasNoWebsite: false,
        HasNoEmail: false
    });
    const [filterCounts, setFilterCounts] = useState<any>({
        HasNoPhone: 0,
        HasNoWebsite: 0,
        HasNoEmail: 0
    });

    useEffect(() => {
        fetchPropertyContactDetails();
    }, [
        tableOffset,
        tableLimit,
        tableSortColumn,
        tableSortOrder,
        selNHID,
        selectedFilterOptions
    ]);

    const fetchPropertyContactDetails = async () => {
        const params: IPropertyContactDetailsParams = {
            Offset: tableOffset,
            Limit: tableLimit,
            SortField: tableSortColumn,
            SortOrder: tableSortOrder,
            NHIDs: selNHID.length ? selNHID : nhIDs,
            OrgNHID: orgNHID,
            ...selectedFilterOptions
        };
        setBusy(true);

        const [[propertyContactDetails, totalRows], noValueData]: [
            [IPropertyContactDetails[], number],
            IPropertyContactNoValueDetails
        ] = await Promise.all([
            getPropertyContactDetails(params),
            getPropertyContactDetailsFilterDetails(params)
        ]);

        if (totalRows) {
            setTableTotalRows(totalRows);
        }
        if (noValueData) {
            setFilterCounts({
                HasNoPhone: noValueData.NoPhoneCount ?? 0,
                HasNoWebsite: noValueData.NoWebsiteCount ?? 0,
                HasNoEmail: noValueData.NoEmailCount ?? 0
            });
        }

        setBusy(false);
        setChangedTick(changedTick + 1);
        setContactDetails(propertyContactDetails);
    };

    const handleTablePageChange = async (pageNumber: number) => {
        setTableOffset((pageNumber - 1) * tableLimit);
    };

    const handleTableLimitChange = async (
        newPerPage: number,
        pageNumber: number
    ) => {
        setTableOffset((pageNumber - 1) * newPerPage);
        setTableLimit(newPerPage);
    };

    const handleTableSortChange = (
        column: TableColumn<any>,
        sortOrder: SortOrder
    ) => {
        setTableSortColumn(column.sortField);
        setTableSortOrder(
            sortOrder === 'asc'
                ? DataTableSortOrder.Ascending
                : DataTableSortOrder.Descending
        );
    };

    const handleOpenUpdateRowModal = async (row: IPropertyContactDetails) => {
        setActiveContactDetails(row);
        setIsUpdateModalOpen(true);
    };

    const handleCloseUpdateRowModal = async (row: IPropertyContactDetails) => {
        setActiveContactDetails(null);
        setIsUpdateModalOpen(false);
        fetchPropertyContactDetails();
    };

    const appContext: any = useContext(AppContext);

    const columns = [
        {
            name: 'Facility Name',
            selector: (row: IPropertyContactDetails) => row.Name,
            sort: true,
            sortable: true,
            sortField: 'Name',
            cell: (row: IPropertyContactDetails) => formatFacility(row),
            width: '*'
        },
        {
            name: 'Phone',
            selector: (row: IPropertyContactDetails) => row.GeneralPhoneNumber,
            sort: true,
            sortable: true,
            sortField: 'GeneralPhoneNumber',
            cell: (row: IPropertyContactDetails) => formatPhoneNumbers(row),
            width: '*'
        },
        {
            name: 'Email',
            sortable: true,
            sortField: 'GeneralEmail',
            selector: (row: IPropertyContactDetails) => row.GeneralEmail,
            cell: (row: IPropertyContactDetails) => formatEmailAddresses(row),
            width: '*'
        },
        {
            name: 'Website',
            sortable: true,
            sortField: 'GeneralWebsite',
            selector: (row: IPropertyContactDetails) => row.GeneralWebsite,
            cell: (row: IPropertyContactDetails) => (
                <>
                    { row.GeneralWebsite ? 
                        <div>
                            <span title={(ContactDetailsHeaders as any)['GeneralWebsite']}>
                                {row.GeneralWebsite}
                            </span>
                            {websiteJSXIcon(row.GeneralWebsite)}
                        </div>
                         : 
                         <span title={(ContactDetailsHeaders as any)['GeneralWebsite']}>
                            No website
                         </span>
                    }
                </>
            ),
            width: '*'
        },
        {
            name: '',
            cell: (row: IPropertyContactDetails) => row ? renderUpdateButton(row) : null,
            width: '*'
        }
    ];

    const renderUpdateButton = (row: IPropertyContactDetails) => {
        return (
            <button
                type="button"
                className="btn ms-auto"
                disabled={busy}
                onClick={() => handleOpenUpdateRowModal(row)}
            >
                Update
            </button>
        );
    };

    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        if (selNHID) {
            handleClearFilters();
            setSelNHID([selNHID]);
        } else {
            setSelNHID([]);
        }
    });

    const formatFacility = (row: IPropertyContactDetails) => {
        const { WebsiteURL, StateSlug, CitySlug, Slug } = row;

        return (
            <div className="flex flex-wrap gap-2 break-words py-1">
                <strong className="block leading-tight lg:text-lg">
                    {row.Name}
                </strong>
                <span className="inline-block text-xs lg:text-sm me-3 leading-tight">
                    { formatAddress(row) }
                </span>
                <a
                    className="link text-xs bg-brand_faint-blue px-2 py-1 inline-block leading-none rounded-lg relative -top-px"
                    href={`${WebsiteURL}/${StateSlug}/${CitySlug}/${Slug}`}
                    target="_blank"
                >
                    view on website
                </a>
            </div>
        );
    };

    const formatPhoneNumbers = (row: IPropertyContactDetails) => {
        const { GeneralPhoneNumber, CarePhoneNumber, JobPhoneNumber } = row;
        const NoPhoneNumber = 'No number';

        return (
            <div>

                { GeneralPhoneNumber ? 
                    <>
                        <strong className="block">
                            {ContactDetailsHeaders.GeneralPhoneNumber}
                        </strong>
                        <span
                            title={
                                (ContactDetailsHeaders as any)[
                                    'GeneralPhoneNumber'
                                ]
                            }
                            >
                            {GeneralPhoneNumber}
                        </span>
                    </> : null
                }
                { CarePhoneNumber ? 
                    <>
                        <strong className="block mt-2">
                            {ContactDetailsHeaders.Care}
                        </strong>
                        <span
                            title={
                                (ContactDetailsHeaders as any)[
                                    'CarePhoneNumber'
                                ]
                            }
                            >
                            {CarePhoneNumber}
                        </span>
                    </> : null
                }
                { JobPhoneNumber ? 
                    <>
                        <strong className="block mt-2">
                            {ContactDetailsHeaders.Job}
                        </strong>
                        <span
                            title={
                                (ContactDetailsHeaders as any)['JobPhoneNumber']
                            }
                            >
                            {JobPhoneNumber}
                        </span>
                    </> : null
                }
                { !(JobPhoneNumber || CarePhoneNumber|| GeneralPhoneNumber) && NoPhoneNumber }

            </div>
        );
    };

    const emailDataTableObject = (
        dataArray: string[],
        row: IPropertyContactDetails
    ) => {
        const emailDataArray: any[] = [];

        dataArray.forEach((key: string) => {

            const item: any = (row as any)[key];
            if (item) {
                const inputId = `dt-${key}`;
                emailDataArray.push(
                    <>
                        <p id={inputId} title={(ContactDetailsHeaders as any)[key]}>
                            {(row as any)[key] || ''}
                        </p>
                    </>
                );
            }
        });

        return emailDataArray;
    };

    const formatEmailAddresses = (row: IPropertyContactDetails) => {
        const generalEmailKeys = [
            'GeneralEmail',
            'GeneralEmail2',
            'GeneralEmail3'
        ];
        const careEmailKeys = ['CareEmail', 'CareEmail2', 'CareEmail3'];
        const jobEmailKeys = ['JobEmail', 'JobEmail2', 'JobEmail3'];

        const generalEmailArray = emailDataTableObject(generalEmailKeys, row);
        const careEmailArray = emailDataTableObject(careEmailKeys, row);
        const jobEmailArray = emailDataTableObject(jobEmailKeys, row);

        return (
            <div>
                { generalEmailArray.length ?
                <>
                    <strong className="block">
                        {ContactDetailsHeaders.GeneralEmails}
                    </strong>
                    <ul>
                        {generalEmailArray}
                    </ul>
                </> : null
                }
                { careEmailArray.length ?
                    <>
                        <strong className="block mt-2">
                            {ContactDetailsHeaders.Care}
                        </strong>
                        <ul>
                            {careEmailArray}
                        </ul>

                    </> : null
                }
                { jobEmailArray.length ?
                    <>
                        <strong className="block mt-2">
                            {ContactDetailsHeaders.Job}
                        </strong>
                        <ul>
                            {jobEmailArray}
                        </ul>
                    </> : null
                }
                { !(jobEmailArray.length || careEmailArray.length || generalEmailArray.length) && 'No email' }

            </div>
        );
    };

    const renderLoading = () => <LoadingDots show={true} />;

    const handleUpdateFilters = (key: string, value: boolean) => {
        const filterOptions = JSON.parse(JSON.stringify(selectedFilterOptions));
        filterOptions[key] = value;
        setSelectedFilterOptions(filterOptions);
        setChangedTick(changedTick + 1);
    };

    const handleClearFilters = () => {
        setSelectedFilterOptions({
            HasNoPhone: false,
            HasNoWebsite: false,
            HasNoEmail: false
        });
        setChangedTick(changedTick + 1);
    };

    const renderCheckBox = (key: string, label: string) => {
        return (
            <div className="form-component form-checkbox">
                <label>
                    <input
                        className="peer"
                        type="checkbox"
                        name={key}
                        checked={selectedFilterOptions[key]}
                        disabled={!filterCounts[key]}
                        onChange={(e: any) => handleUpdateFilters(key, e.target.checked)}
                    />
                    <FontAwesomeIcon icon={faCheck} className="peer-checked:block"/>
                    <span 
                        onClick={
                            (e: any) => {
                                filterCounts[key] ? handleUpdateFilters(key, !selectedFilterOptions[key]) : null;
                            }
                        }
                    >
                        {label} {filterCounts[key] > 0 ? filterCounts[key] : <span className="text-brand_grey-medium text-xs">(all completed)</span>}
                    </span>
                </label>         
            </div>
        );
    };

    return (
        <>
            <Toast></Toast>
            <PageCommunitySelectWrapper
                label={'Phone / Website / Email'}
                handleSelect={handleSelect}
                selNHID={selNHID[0]}
            />
            <section>
                <div className="container max-w-none 2xl:container mt-4 sm:mt-6 md:mt-8 2xl:mt-10">
                    {(orgNHID && contactDetails.length && !selNHID.length) || (nhIDs && nhIDs.length > 1 && !selNHID.length) ? (
                        <BulkPropertyContactDetailsUpdateForm
                            nhIDs={nhIDs}
                            orgNHID={orgNHID}
                            contactData={contactDetails}
                            handleFetchData={fetchPropertyContactDetails}
                        />
                    ) : null }
                    {(orgNHID && contactDetails.length && !selNHID.length) || (nhIDs && nhIDs.length > 1 && !selNHID.length) ? (
                        <>
                            <div className="flex flex-col items-center text-center gap-3 md:flex-row md:text-start">
                                <div>
                                    <strong className="text-md md:text-lg lg:text-xl lg:whitespace-nowrap">
                                        All Facilities
                                    </strong>
                                </div>
                                <div className="md:ms-auto flex flex-col text-xs lg:flex-row gap-2 text-start lg:gap-5">
                                    {renderCheckBox('HasNoPhone', 'Has no Phone')}
                                    {renderCheckBox('HasNoEmail', 'Has no Email')}
                                    {renderCheckBox('HasNoWebsite', 'Has no Website')}
                                </div>
                            </div>
                            <div className="mt-4 sm:mt-6 md:mt-8 lg:mt-10">
                                <div data-table="Inquiry Phone / Email / Website" className="relative [&>div]:scrollbar [&>div]:pb-2 [&>div]:-mx-3 [&>div]:w-[calc(100%+1.5rem)] [&>div]:px-3 sm:[&>div]:-mx-4 sm:[&>div]:w-[calc(100%+2rem)] sm:[&>div]:px-4 xl:[&>div]:mx-0 xl:[&>div]:w-full xl:[&>div]:px-0 before:h-full before:top-0 before:absolute before:z-10 before:bg-gradient-to-r before:from-white before:w-3 before:-left-3 after:h-full after:top-0 after:absolute after:z-10 after:bg-gradient-to-l after:from-white after:-right-3 after:w-3 sm:before:-left-4 sm:before:w-4 sm:after:-right-4 sm:after:w-4 xl:before:content-none xl:after:content-none">
                                    { busy ? renderLoading() : null }
                                    <DataTable
                                        columns={columns}
                                        key={'NHID'}
                                        data={contactDetails}
                                        paginationTotalRows={tableTotalRows}
                                        pagination={true}
                                        paginationServer={true}
                                        initialRowsPerPage={tableLimit}
                                        sortServer={true}
                                        onChangePage={handleTablePageChange}
                                        onChangeRowsPerPage={
                                            handleTableLimitChange
                                        }
                                        onSort={handleTableSortChange}
                                    />
                                </div>
                            </div>
                        </>
                    ) : null}
                    {(!orgNHID &&
                        contactDetails.length &&
                        contactDetails.length === 1 &&
                        nhIDs &&
                        nhIDs.length === 1) ||
                    (selNHID.length &&
                        selNHID.length === 1 &&
                        contactDetails.length === 1 &&
                        selNHID[0] === contactDetails[0].NHID) ? (
                            <UpdatePropertyContactDetailsForm
                                data={contactDetails[0]}
                                updateModalActive={isUpdateModalOpen}
                                handleUpdateData={fetchPropertyContactDetails}
                            />
                    ) : null}
                </div>
            </section>
            <UpdatePropertyDetailModal
                propertyDetail={activeContactDetails}
                handleModalClose={handleCloseUpdateRowModal}
                isUpdateModalOpen={isUpdateModalOpen}
            />
        </>
    );
};

export default PropertyContacts;