import React, { useLayoutEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';

import { ISingleFacilityChartLargeProps } from '../types';

const chartContainer = 'publishedReviewsLastYearSingleFacility';

export const SingleFacilityChartLarge = ({ 
    chartData,
}: ISingleFacilityChartLargeProps) => {

    const labelBullet = (root: any) => {

        const label = am5.Label.new(root, {
            text: '{valueY}',
            fontSize: 14,
            fill: am5.color(0x000000),
            centerX: am5.percent(50),
            centerY: am5.percent(100),
            populateText: true
        });

        return am5.Bullet.new(root, {
            locationX: 0.5,
            locationY: 1,
            sprite: label
        });
    };

    const fitLabels = (series: any) => {
        series.columns.template.onPrivate('width', (width: any, target: any) => {
            am5.array.each(target.dataItem.bullets, (bullet: any) => {
                if (width > 10) {
                    bullet.get('sprite').show();
                } else {
                    bullet.get('sprite').hide();
                }
            });
        });
    };

    useLayoutEffect(() => {

        const root: any = am5.Root.new(chartContainer);

        const chart = root.container.children.push( 
            am5xy.XYChart.new(root, {
                panY: false,
                layout: root.verticalLayout,
                paddingTop: 30,
                x: am5.percent(50),
                centerX: am5.percent(50)
            })
        );
        
        // Create Y-axis
        const yAxisProps: any = {
            min: 0,
            renderer: am5xy.AxisRendererY.new(root, {})
        };
        const yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, yAxisProps)
        );

        const yRenderer = yAxis.get('renderer');
        yRenderer.grid.template.setAll({
            stroke: am5.color(0x000000),
            strokeWidth: 0.5
        });
      
        // Create X-Axis
        const xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });

        // Rotate X_AXIS Labels
        xRenderer.labels.template.setAll({
            textAlign: 'center',
            oversizedBehavior: 'wrap',
            fontSize: 14,
            breakWords: true,
            maxWidth: 60,
            paddingTop: 10,
            paddingBottom: 10

        });

        xRenderer.grid.template.setAll({
            stroke: am5.color(0x000000),
            strokeWidth: 0.5
        });

        const xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
            renderer: xRenderer,
              categoryField: 'displayDate'
            })
        );
     
        xAxis.data.setAll(chartData);

        // Create series
        const reviewSeries = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: 'Reviews',
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: 'ReviewsCount',
                categoryXField: 'displayDate'
            })
        );
        
        reviewSeries.set('fill', am5.color(0x007791));
        reviewSeries.set('stroke', am5.color(0x007791));

        reviewSeries.bullets.push(() => labelBullet(root));

        reviewSeries.data.setAll(chartData);
      
        reviewSeries.columns.template.setAll({ 
            cornerRadiusTL: 5, 
            cornerRadiusTR: 5,
            tooltipY: 0 
        });

        fitLabels(reviewSeries);

        // Add legend
        // const legend = chart.children.push(am5.Legend.new(root, {
            
        //     centerX: am5.percent(50),
        //     x: am5.percent(50)
        // }));

        // legend.data.setAll(chart.series.values);
        
        root.tapToActivate = true;
        root.tapToActivateTimeout = 2000;

        root.current = root;

        return () => {
            root.dispose();
        };
    }, [chartData]);

    return (
        <div id={chartContainer} className="w-full h-80"></div>
    );
};
