import React, { FC } from 'react';

interface IProps {
    message: string;
}

const ErrorMessage: FC<IProps> = ({ message }) => {
    return <small className=" text-red-600 -mt-2">{message}</small>;
};

export { ErrorMessage };
