const getNearestScrollingAncestor = (node: Element | null ): Element | null => {
    if (node === null) {
        return null;
    }

    if (node.id === 'scrolling-tab-target' || node.scrollHeight > node.clientHeight) {
        return node;
    }
    
    return getNearestScrollingAncestor(node.parentNode as Element);
};

export { getNearestScrollingAncestor };