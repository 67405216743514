import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import FilterMatrix from '../../../modules/filter_matrix';

interface IProps {
    filterMatrix: FilterMatrix;
    applyFilter(key: string, value: any, refreshData?: boolean): void;
    reviewScheme: any
}

const ReviewPublicationStatusFilter = ({ filterMatrix, applyFilter, reviewScheme }: IProps) => {

    const [ publicationStatusOptions, setPublicationStatusOptions ] = useState<any[]>([]);

    useEffect(() => {

        if (!reviewScheme) {
            return;
        }

        const options: any = [];
        reviewScheme.PublicationAuxStatus.forEach((item: any) => {

            const { ReviewPublicationStatus } = item;
            if (ReviewPublicationStatus.InternalDescription !== 'Published' && item.IsVisible) {
                options.push({
                    label: item.ExternalDescription,
                    value: item.PublicationAuxiliaryStatusID
                });
            }
        });
        
        setPublicationStatusOptions(options);

    }, [reviewScheme]);

    return (
        <div className="form-component form-inline form-select">
            <label 
                htmlFor="publicationStatusSelect"
            >
                Reason Not Published
            </label>
            <Select
                id="publicationStatusSelect"
                onChange={(option: any) => applyFilter('PublicationStatus', option)}
                options={publicationStatusOptions}
                isMulti
                name="PublicationAuxiliaryStatus"
                classNamePrefix="select"
                value={filterMatrix.getFormFieldValue('PublicationStatus')}
                unstyled
            />
        </div>
    );
};

export default ReviewPublicationStatusFilter;
