import React, { FC, ReactNode } from 'react';

interface IProps {
    data: ReactNode[];
}

const ServicesFields: FC<IProps> = ({ data }) => {
    return (
        <div className="grid gap-6 lg:gap-8 2xl:gap-10 px-3 sm:px-4 2xl:px-5">
            {Object.entries(data).map(([subkey, subvalue], index: number) => {
                return (
                    <div key={index}>
                        <h3 className="font-semibold leading-tight text-md lg:text-lg mb-4 lg:mb-6 2xl:mb-8">
                            {subkey}
                        </h3>
                        <div className="grid gap-6 lg:gap-8 2xl:gap-10">
                            {subvalue}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export { ServicesFields };