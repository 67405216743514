import React, { ReactNode } from 'react';
import Header from '../components/layout/header';
import Footer from '../components/layout/footer'; 
import { Navigate, useLocation } from 'react-router-dom';

import { 
    getJwtToken,
} from '../modules/login';

interface IProps {
    children: ReactNode;
}

const ProtectedRoute = ({
    children
}: IProps) => {

    const location = useLocation();
    const pathname = location.pathname + location.search;
    const token = getJwtToken();

    if (!token) {
        return <Navigate 
            to={'/login'}
            state={{ from: pathname }}
        />;
    }

    return (
        <main className="flex flex-col min-h-screen text-brand_grey-dark text-xs sm:text-sm md:text-md 2xl:text-base leading-snug">
            <Header />
            { children }
            <Footer />
        </main>
    );
};

export default ProtectedRoute;
