import React from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import dateFormat from 'dateformat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';



type Props = {
    templateTypes: any[];
    pageOffset: number;
    pageLimit: number;
    totalFilteredRows: number;
    totalRows: number;
    filterMatrix: any;
    data: any[];
    applyFilter(key: string, value: any, refreshData?: boolean): void;
    resetFilters(): void;
};

export default function LeadEmailFilters(props: Props) {
    return (
    <div>
        <input type="checkbox" className="hidden peer/filters" id="filters" />
        <div className="hidden peer-checked/filters:block relative ring-1 ring-brand_grey rounded-xl p-3 sm:p-4 bg-brand_faint-blue mb-4 sm:mb-6 md:mb-8 lg:mb-10">
            <div className="flex items-center">
                <strong className="text-lg md:text-xl">
                    Filter Results
                </strong>
                <button
                    className="ms-4 btn btn-outline btn-outline-tertiary btn-small flex items-center text-md"
                    onClick={props.resetFilters}>
                        <FontAwesomeIcon icon={faXmark} className="me-1" />Clear all filters
                </button>
                <label htmlFor="filters" className="ms-auto cursor-pointer rounded-full bg-brand_tertiary hover:bg-brand_tertiary-dark text-white w-8 h-8 flex justify-center items-center">
                    <FontAwesomeIcon icon={faXmark} />
                </label>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 mt-6">
                <div className="form-component form-inline form-select">
                    <label
                        htmlFor="InquiryType"
                    >
                        Inquiry Type
                    </label>
                    <Select 
                        id="InquiryType"
                        key="TemplateTypeID"
                        options={props.templateTypes}
                        placeholder="Select Inquiry Type..."
                        classNamePrefix="select"
                        isMulti
                        onChange={(option: any) => props.applyFilter('TemplateTypeID', option, true)}
                        value={
                            props.filterMatrix.getFormFieldValue('TemplateTypeID')?.length 
                            ? props.templateTypes?.filter((item: any) => {
                                const values = props.filterMatrix.getFormFieldValue('TemplateTypeID').map((type: any) => type.value);
                                return values.includes(item.value);
                            })
                            : []
                        }
                        unstyled
                    />
                </div>
                <div className="form-component form-inline form-textbox">
                    <label
                        htmlFor="SenderName"
                    >
                        Inquirer Name
                    </label>
                    <input
                        id="SenderName"
                        type="text"
                        onChange={(e: any) => props.applyFilter('SenderName', e.target.value)}
                        value={props.filterMatrix.getFormFieldValue('SenderName') || ''}
                    />
                </div>
                <div className="form-component form-inline form-textbox">
                    <label
                        htmlFor="SenderEmail"
                    >
                        Inquirer Email
                    </label>
                    <input
                        id="SenderEmail"
                        type="email"
                        onChange={(e: any) => props.applyFilter('SenderEmail', e.target.value, true)}
                        value={props.filterMatrix.getFormFieldValue('SenderEmail') || ''}
                    />
                </div>
                <div className="form-component form-inline form-datepicker">
                    <label
                        htmlFor="DateFrom"
                    >
                        Date From
                    </label>
                    <DatePicker
                        id="DateFrom"
                        selected={props.filterMatrix.getFormFieldValue('DateFrom') || null}
                        onChange={(date: Date) => {
                            date.setHours(0, 0, 0, 0);
                            const dateStr = dateFormat(date, 'yyyy-mm-dd');
                            props.applyFilter('DateFrom', new Date(dateStr), true);
                        }}
                        dateFormat="dd MMM yyyy"
                        placeholderText="Click to select a date"
                        value={props.filterMatrix.getFormFieldValue('DateFrom')}
                        showPopperArrow={false}
                    />
                </div>
                <div className="form-component form-inline form-datepicker">
                    <label
                        htmlFor="DateTo"
                    >
                        Date To
                    </label>
                    <DatePicker
                        id="DateTo"
                        selected={props.filterMatrix.getFormFieldValue('DateTo') || null}
                        onChange={(date: Date) => {
                            date.setHours(0, 0, 0, 0);
                            const dateStr = dateFormat(date, 'yyyy-mm-dd');
                            props.applyFilter('DateTo', new Date(dateStr), true);
                        }}
                        dateFormat="dd MMM yyyy"
                        placeholderText="Click to select a date"
                        value={props.filterMatrix.getFormFieldValue('DateTo')}
                        showPopperArrow={false}
                    />
                </div>
            </div>
        </div>
    </div>
    );
}
