import React from 'react';
import { Modal } from '../../components/common/modal';
//import { ModalTitle } from '../../components/common/modal/modalTitle';
import PhotoUploadForm from './photo_upload_form';
import { ITag } from '../../interfaces/tag';
import { 
    IPortalPropertyPhotoAsset
 } from '../../interfaces/asset';
import { IMessageType } from '../../interfaces/common';

interface IProps {
    handleClose(): void;
    handleReloadPropertyPhotos: (nhID: number[]) => Promise<IPortalPropertyPhotoAsset[]>;
    selNHID: number;
    propertyPhotos: IPortalPropertyPhotoAsset[];
    setPropertyPhotos: (propertyBrochures: IPortalPropertyPhotoAsset[]) => void;
    busyLoadingAsset: boolean;
    setBusyLoadingAsset: (busyState: boolean) => void;
    photoTags: ITag[];
    setPhotoUploadMessages: (messages: IMessageType[]) => void;
}

const PhotoUploadModal = ({
    handleClose,
    selNHID,
    propertyPhotos,
    setPropertyPhotos,
    busyLoadingAsset,
    setBusyLoadingAsset,
    photoTags,
    setPhotoUploadMessages
}: IProps) => {

    const propertyName = (propertyPhotos || []).length > 0 ? propertyPhotos[0].Property.Name : '';

    /*const title = (
        <ModalTitle
            title='Upload New Photos'
            rightSideText={propertyName}
        />
    );*/

    return (
        <>
            <Modal
                isOpen={true}
                onClose={handleClose}
                title={<>Upload New Photos <span className="text-md text-brand_grey-medium font-medium">(in 2 easy steps)</span></>}
                subTitle={propertyName}
                size='xl'
            >
                <PhotoUploadForm
                    selectedNHID={selNHID}
                    propertyPhotos={propertyPhotos}
                    setPropertyPhotos={setPropertyPhotos}
                    photoTags={photoTags}
                    busyLoadingPhotos={busyLoadingAsset}
                    setBusyLoadingPhotos={setBusyLoadingAsset}
                    setPhotoUploadMessages={setPhotoUploadMessages}
                    handleClose={handleClose}
                />
            </Modal>
        </>
    );
};

export default PhotoUploadModal;