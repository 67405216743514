import React from 'react';
import { Link } from 'react-router-dom';

const RequestAccess = () => { 
   
    return (
        <main>
            <section className="h-screen flex justify-center py-8">
                <div className="my-auto w-full max-w-[800px] ring-1 ring-brand_grey rounded-md lg:rounded-lg overflow-hidden shadow-md bg-white mx-3 sm:mx-4 lg:mx-8 h-fit"> 
                    <Link to={{pathname: '/'}} className="flex items-end p-3 sm:p-4 lg:px-8 lg:py-6 border-b border-brand_grey bg-brand_faint-blue">
                        <img src="/logo.svg" className="h-9 me-3 lg:h-10 xl:h-11" alt="Portal" />
                        <strong className="text-brand_grey-medium relative top-0.5 font-medium text-lg lg:text-xl lg:top-0">
                            Portal
                        </strong>
                    </Link> 
                    <div className="p-3 sm:p-4 lg:p-8 grid gap-3">
                        <strong className="font-semibold leading-tight text-xl md:text-2xl lg:text-3xl">
                            Request access to the nursinghomes.com Portal
                        </strong>
                        <p>If you do not have an existing account on the nursinghomes.com Portal (you can check by sending a&nbsp;
                            <Link className="link" to={{pathname: '/passwordresetemail'}}>&lsquo;password reset&rsquo; request</Link>), and you wish to manage your Reviews on nursinghomes.com please email us with the following information:
                        </p>
                        <ul className="marker:text-brand_grey-medium list-disc ps-5 space-y-2">
                            <li>
                                The Organization or Facility you wish to have access to.
                            </li>
                            <li>
                                Whether you wish to receive &lsquo;new review&rsquo; notifications.
                            </li>
                            <li>
                                Whether you have permission to add replies to reviews.
                            </li>
                        </ul>
                        <p>
                            We will then contact you to confirm the above.
                        </p>
                        <p>
                            Please email your request to <a className="link" href="mailto:support@nursinghomes.com?subject=nursinghomes.com Portal access request">support@nursinghomes.com</a>
                        </p>
                        <Link className="link inline-block" to={{
                            pathname: '/login'
                        }}> 
                            Return to Login
                        </Link>
                    </div>
                </div>
            </section>
        </main>

    );
};

export default RequestAccess;