import React, { useEffect, useState } from 'react';
import { mapProfileDatatoDisplay } from '../../utils/profile_completeness';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { 
    INhPortalScoreItem,
    IScoreDisplayItem,
    ProfileScoreType
} from '../../interfaces/property';

interface IProps {
    NHID: number;
    profileScoreType: ProfileScoreType;
}

const ProfileCompletenessText = ({ NHID, profileScoreType }: IProps) => {

    const [ scoreItem, setScoreItem ] = useState<IScoreDisplayItem>(null);

    const profileCompletenessState: INhPortalScoreItem = useSelector(
        ({ ProfileCompletenessState }: any) => ProfileCompletenessState
    );

    useEffect(() => {        
        const scoreArray = mapProfileDatatoDisplay(profileCompletenessState, NHID);

        const scoreItem = scoreArray.find((item: IScoreDisplayItem) => 
            item.KeyName === profileScoreType);

        setScoreItem(scoreItem);
    }, [NHID, profileCompletenessState ]);

    const profileComplete = 
        scoreItem?.FacilityCount === 1 &&
        scoreItem?.AchievedScoreCount === 1;

    const fieldDescription = scoreItem?.Description || '';
    const label = scoreItem?.Label || '';

    return (
        <em className="not-italic flex gap-1 items-center text-sm">
            <FontAwesomeIcon icon={profileComplete ? faCheck : faXmark} className={`h-5 w-5 ${profileComplete ? 'text-brand_status-success' :  'text-brand_status-error'}`}/>Profile {profileComplete ? '' : 'NOT'} Complete for {label}
        </em>
    );
};

export default ProfileCompletenessText;
