import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import FilterMatrix from '../../../modules/filter_matrix';
import { ExpressionType, rangeSelectOptions, SqlRange } from '../../../interfaces/filter_matrix';

interface IProps {
    filterMatrix: FilterMatrix;
    applyFilter(key: string, value: any, refreshData?: boolean): void;
    refreshData: () => void;
    reviewScheme: any
}

const ratingFilterKeys = [
    ['OverallRating', 'overall-experience'],
    ['CleanlinessRating','cleanliness'],
    ['FriendlinessRating','friendliness'],
    ['SafetySecurity','safety-security'],
    ['NursingCareRating', 'nursing-care'], 
    ['StaffRating', 'staff'],
    ['DiningRating', 'meals-dining'],  
    ['ActivitiesRating', 'activities'],
    ['RehabTherapyRating', 'rehab-therapy-services'],
    ['FacilitiesRating', 'facilities']
];

const ReviewRatingTypeFilter = ({ filterMatrix, applyFilter, refreshData, reviewScheme }: IProps) => {

    const [ ratingTypeOptions, setRatingTypeOptions ] = useState<any[]>([]);
    const [ selRatingTypeOption, setSelRatingTypeOption ] = useState<any>();

    useEffect(() => {
        const filteredRatingTypes = [];

        if (!reviewScheme?.RatingTypes?.length) {
            return;
        }

        for (let idx = 0; idx < reviewScheme.RatingTypes.length; idx++) {
            const filterKey = ratingFilterKeys.find((key: string[]) => reviewScheme.RatingTypes[idx].KeyName === key[1]);
            const notEnteredOption = filterMatrix.getNotEnteredOption(filterKey[0]);

            if (filterKey) {
                filteredRatingTypes.push({
                    ...reviewScheme.RatingTypes[idx],
                    FilterKey: filterKey[0],
                    NotEnteredOption: notEnteredOption
                });
            }
        }

        const options = filteredRatingTypes.map((item: any) => ({
            label: item.Name,
            value: item
        }));
        
        setRatingTypeOptions(options);

    }, [reviewScheme]);

    const applyAndRefreshIfValid = (key: string, value: any) => {

        applyFilter(key, value, false);

        const filter = filterMatrix.findFilter(getSelFilterKey());
        if (filter) {
            const prop = filter.PropFormatter(filter);
            if (prop) {
                refreshData();
            }
        } 
    };

    const handleRatingTypeOptionChange = (option: any) => {
        setSelRatingTypeOption(option);
    };

    const getRatingsFilterType = (): any => {

        const filterKey = getSelFilterKey();

        if (filterKey) {
            const filter = filterMatrix.getRangeValue(filterKey).operation;
            const notEnteredOption = selRatingTypeOption ? selRatingTypeOption.NotEnteredOption : null;
            const comparatorOption = rangeSelectOptions(notEnteredOption).find((option: any) => option.value === filter);
            return comparatorOption ? comparatorOption : {label: undefined, value: undefined};
        }
    };

    const getRatingsFilterTypeFrom = (): any => {

        const filterKey = getSelFilterKey();
        const ratingsFilterType = getRatingsFilterType();

        if (ratingsFilterType) {

            const fromValue = ratingsFilterType.value === ExpressionType.NotEntered ? '' :
                filterMatrix.getFormFieldValue(filterKey).from || '';

            return fromValue;
        }

        return '';
    };

    const getRatingsFilterTypeTo = (): any => {

        const filterKey = getSelFilterKey();

        const ratingsFilterType = getRatingsFilterType();
        if (ratingsFilterType) {
            return getRatingsFilterType().value === ExpressionType.Between ?
            filterMatrix.getFormFieldValue(filterKey).to : '';
        }

        return '';
    };

    const onRangeFromChange = (ratingFilterKey: string, newFromValue: string, maxAllowedLength?: number): SqlRange => {
        const existingFilter = filterMatrix.findFilter(ratingFilterKey).Value as SqlRange;

        // Handle defaulting the opcode choice to 'equals'
        if (typeof existingFilter === 'string') {
            if (validateRangeFilterValue(newFromValue, ExpressionType.Equal, maxAllowedLength)) {
                return new SqlRange(ExpressionType.Equal, newFromValue);
            } else {
                return;
            }
        }

        if (!validateRangeFilterValue(newFromValue, existingFilter.operation, maxAllowedLength)) {
            return;
        }

        return new SqlRange(existingFilter.operation, newFromValue, existingFilter.to);
    };

    const onRangeToChange = (ratingFilterKey: string, newToValue: string, maxAllowedLength?: number): SqlRange => {
        const existingFilter = filterMatrix.findFilter(ratingFilterKey).Value as SqlRange;
        if (!validateRangeFilterValue(newToValue, existingFilter.operation, maxAllowedLength)) {
            return;
        }

        return new SqlRange(existingFilter.operation, existingFilter.from, newToValue);
    };

    const validateRangeFilterValue = (val: string, operation: string, maxAllowedLength: number): boolean => {
        if (!val.length) {
            return true;
        }

        if (operation === ExpressionType.In) {
            const splitArray = (val || '')
            .split(',')
            .filter(item => item && item.length)
            .map(item => item.replace(/\s+/g, ''));

            if (!splitArray.length) {
                return false;
            }

            const valArray = splitArray.map((item: any) => !!(isFinite(item) && item !== ''));
            return !valArray.includes(false);
        } else {
            const input: any = val.replace(/\s+/g, '');
            return !!(isFinite(input) && input !== '' && (maxAllowedLength === undefined || input.length <= maxAllowedLength));
        }
    };

    const handleRatingsFilterTypeChange = (option: any) => {

        const filterKey = getSelFilterKey();

        if (filterKey) {
            const existingFilter = filterMatrix.findFilter(filterKey).Value as SqlRange;
            const newOpcode = option.value as ExpressionType || undefined;
        
            if ((newOpcode === ExpressionType.In && existingFilter.operation !== ExpressionType.In) ||
                (newOpcode !== ExpressionType.In && existingFilter.operation === ExpressionType.In)) {
                return new SqlRange(newOpcode);
            }
    
            const range = new SqlRange(newOpcode, existingFilter.from, existingFilter.to);
            applyAndRefreshIfValid(filterKey, range);
        }
    };

    const handleRatingsFilterFromChange = (value: string): void => {

        const filterKey = getSelFilterKey();
        if (filterKey) {
            const range = onRangeFromChange(filterKey, value, 4);
            if (range) {
                applyAndRefreshIfValid(filterKey, range);
            }
        }
    };

    const handleRatingsFilterToChange = (value: string): void => {

        const filterKey = getSelFilterKey();
        if (filterKey) {
            const range = onRangeToChange(filterKey, value, 4);
            if (range) {
                applyAndRefreshIfValid(filterKey, range);
            }
        }
    };

    const resetRatingsFilter = () => {

        ratingFilterKeys.forEach((filterKey: string[]) => applyFilter(filterKey[0], '', false));
        setSelRatingTypeOption(null);
        refreshData();
    };

    const getSelFilterKey = () => selRatingTypeOption ? selRatingTypeOption.value.FilterKey : null;

    return (
        <div className="flex items-center gap-3">
            <div className="grid gap-1 sm:grid-cols-3 lg:grid-cols-6 lg:gap-3 items-center w-full">
                <div className="sm:col-span-3 lg:col-span-3 form-component form-inline form-select">
                    <label>
                        Ratings
                    </label>
                    <Select
                        id="ratingTypeSelect"
                        options={ratingTypeOptions}
                        name="ratingTypeSelect"
                        classNamePrefix="select"
                        value={selRatingTypeOption || ''}
                        onChange={(option: any) => handleRatingTypeOptionChange(option)}
                        defaultValue={{ label: 'Select Rating type', value: null }}
                        unstyled
                        isSearchable={false}
                    />
                </div>
                { selRatingTypeOption ?
                    <>
                        <div className="form-component form-select">
                            <Select
                                isDisabled={!getSelFilterKey()}
                                options={rangeSelectOptions((selRatingTypeOption || {}).NotEnteredOption)}
                                name="Ratings"
                                classNamePrefix="select"
                                value={(getRatingsFilterType() || {}).value ? getRatingsFilterType() || '' : ''}
                                onChange={(option: any) => handleRatingsFilterTypeChange(option)}
                                unstyled
                                isSearchable={false}
                            />
                        </div>
                        <div className="form-component form-textbox">
                            <input
                                readOnly={getRatingsFilterType() && getRatingsFilterType().value === ExpressionType.NotEntered}
                                id="RatingFrom"
                                value={getRatingsFilterTypeFrom()}
                                onChange={(e) => handleRatingsFilterFromChange(e.target.value)}
                            />
                        </div>
                        <div className="form-component form-textbox">
                            <input
                                readOnly={getRatingsFilterType() && getRatingsFilterType().value !== ExpressionType.Between}
                                id="RatingTo"
                                value={getRatingsFilterTypeTo()}
                                onChange={(e) => handleRatingsFilterToChange(e.target.value)}
                            />
                        </div>
                    </> : null
                }
            </div> 
            { selRatingTypeOption ?
                <>
                    <div className="">
                        <a
                            className="text-xs link"
                            onClick={() =>  resetRatingsFilter()}
                        >
                            Clear
                        </a> 
                    </div>
                </> : null
            }     
        </div>
    );
};

export default ReviewRatingTypeFilter;
