import React from 'react';
import { ProfileScoreType } from '../../interfaces/property';
import ProfileCompletenessDial from '../../components/profile_completeness/profile_completeness_dial';
import ProfileCompletenessFilter from '../../components/profile_completeness/profile_completeness_filter';
import ProfileCompletenessSingle from '../../components/profile_completeness/profile_completeness_single';

interface IProps {
    selNHID: number;
    nhIDs: number[];
    orgNHID: number;
    profileScoreType: ProfileScoreType;
    handleProfileCompletenessFilter: any;
    profileCompletenessFilterValue: boolean | null;
    title: string
}

const ProfileCompletenessHeader = ({ 
    selNHID, 
    nhIDs, 
    orgNHID,
    profileScoreType, 
    handleProfileCompletenessFilter, 
    profileCompletenessFilterValue,
    title 
}: IProps) => {

    const singleNHID = selNHID || (nhIDs?.length === 1 ? nhIDs[0] : null);

    return (
        <section>
            <div className="container max-w-none 2xl:container mt-4 sm:mt-6 md:mt-8 2xl:mt-10">
            {
                singleNHID ? 
                    <ProfileCompletenessSingle
                        NHID={singleNHID}
                        profileScoreType={profileScoreType}
                    /> :
                    <div className="flex flex-col items-center gap-4 md:flex-row md:justify-between md:gap-12">
                        <ProfileCompletenessDial
                            selNHID={selNHID}
                            nhIDs={nhIDs}
                            orgNHID={orgNHID}
                            profileScoreType={profileScoreType}
                        />
                        <ProfileCompletenessFilter
                            handleProfileCompletenessFilter={handleProfileCompletenessFilter}
                            profileCompletenessFilterValue={profileCompletenessFilterValue}
                            title={title}
                        />
                    </div>
                }
            </div>
        </section>
    );
};

export default ProfileCompletenessHeader;
