import React from 'react';
import { Modal } from '../../components/common/modal';
//import { ModalTitle } from '../../components/common/modal/modalTitle';
import IndividualPropertyLogos from './individual_property_logos';

import { 
    IGetPortalPropertyWebAndCardLogoAsset
 } from '../../interfaces/asset';

interface IProps {
    handleClose(): void;
    handleReloadPropertyLogos: (nhID: number[]) => Promise<IGetPortalPropertyWebAndCardLogoAsset[]>;
    orgNHID: number | null;
    nhIDs: number[];
    selNHID: number | null;
    propertyLogos: IGetPortalPropertyWebAndCardLogoAsset[];
    setPropertyLogos: (propertyBrochures: IGetPortalPropertyWebAndCardLogoAsset[]) => void;
    busyLoadingAsset: boolean;
    setBusyLoadingAsset: (busyState: boolean) => void;
}

const LogoUploadModal = ({
    handleClose,
    handleReloadPropertyLogos,
    orgNHID,
    nhIDs,
    selNHID,
    propertyLogos,
    setPropertyLogos,
    busyLoadingAsset,
    setBusyLoadingAsset 
}: IProps) => {

    const propertyName = (propertyLogos || []).length > 0 ? propertyLogos[0].Property.Name : '';

    /*const title = (
        <ModalTitle
            title='Upload a New Logo'
            rightSideText={propertyName}
        />
    );*/

    return (
        <>
            <Modal
                isOpen={true}
                onClose={handleClose}
                title='Upload a New Logo'
                subTitle={propertyName}
                size='xl'
            >
                <IndividualPropertyLogos
                    handleReloadPropertyLogos={handleReloadPropertyLogos}
                    orgNHID={orgNHID}
                    nhIDs={nhIDs}
                    selNHID={selNHID}
                    propertyLogos={propertyLogos}
                    setPropertyLogos={setPropertyLogos}
                    busyLoadingAsset={busyLoadingAsset}
                    setBusyLoadingAsset={setBusyLoadingAsset}
                    handleClose={handleClose}
                />
            </Modal>
        </>
    );
};

export default LogoUploadModal;