import React from 'react';
import { useRef } from 'react';

import { IPortalEntity } from '../../interfaces/user';
import { SessionType } from '../../interfaces/session';

interface IProps {
    data: any;
}

const UserTableExpandableRows = ({ data }: IProps) => {

    const ref = useRef();
    const inViewport = true;

    const renderCommunities = () => {
        return (
            <> 
                <strong className="block mb-1">
                    Communities:
                </strong>
                <ul>
                    {  
                        (data.Entities || []).map((e: IPortalEntity, index: number) => 
                            <li key={`entity-community-${data.UserID}-${index}`}>
                                <a key={`entity-community-link-${data.UserID}-${index}`} href={`${e?.WebsiteURL}/${e?.StateSlug}/${e?.CitySlug}/${e?.Slug}/`} target="_blank" rel="noreferrer" className="link">
                                    { e?.EntityName }{ e?.EntityCity ?  `, ${e?.EntityCity}` : '' } (ID:&nbsp;{ e?.EntityID })
                                </a>
                            </li>
                        )
                    }
                </ul>
            </>
        );
    };

    const renderOrg = () => {

        const websiteURL = data.Entities.length ? data.Entities[0].WebsiteURL : '';

        return (
            <>
                <strong className="block mb-1">
                    Organization:
                </strong>
                <ul>
                        {
                        data.Entities.length ?
                            <li>
                                <a key={`entity-provider-link-${data.UserID}`} href={`${websiteURL}/provider/${data.Entities[0]?.Slug}/`} target="_blank" rel="noreferrer" className="link">
                                    { data.Entities[0]?.EntityName }{ data.Entities[0]?.EntityCity ?  `, ${data.Entities[0]?.EntityCity}` : '' } (ID:&nbsp;{ data.Entities[0]?.EntityID })
                                </a>
                            </li> : null
                        }
                </ul>
            </>
        );
    };
    
    const render = () => {
        return (
            <>
                {/* <p className="user-jobtitle">
                    <strong>Job Title:</strong> { data.UserJobTitle }  
                </p>
                <p className="user-organization">
                    <strong>Organization:</strong> { data.OrgName }
                </p>
                <p className="user-communities">
                    <strong>Communities:</strong> { data.MemberName }
                </p>
                <button
                    className="btn btn-primary user-editbutton"
                >
                    Edit User
                </button> */}
                {
                    data.KeyName === SessionType.SessionTypeProperty ? renderCommunities() : renderOrg()
                }
            </>
        );
    };

    return (
        <div className="p-2 md:p-3 lg:p-4" ref={ref}>
            {
                inViewport ? render() : null
            }
        </div>
    );
};
export default UserTableExpandableRows;
