import React, { FC } from 'react';

const NoResultsPlaceholder: FC = () => {
    return (
        <span className="msg msg-warning">
            No team members uploaded
        </span>
    );
};

export { NoResultsPlaceholder };
