import React, { FC } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { MeetTheTeamMemberType } from '../../types';
import {
    EditTeamMemberModal,
    DeleteTeamMemberDialogButton,
    ProfileImage
} from '../../components';
import { Tooltip } from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';

interface Props extends MeetTheTeamMemberType {
    imgUrl?: string;
    id?: number;
    index: number;
}

const DraggableMemberCard: FC<Props> = (props) => {
    const { id, index, firstName, lastName, photoUrl, publishWebsite, unpublishWebsiteComment } = props;

    const image = (
        <ProfileImage
            alt={`Photo of ${firstName}`}
            imgUrl={photoUrl}
        />
    );

    const fullName = (
        <strong className="text-xs text-ellipsis">
            {firstName} {lastName}
        </strong>
    );

    const reasonNotPublished = (
        <>
            <div
                className="px-3 text-center text-truncate text-danger cursor-pointer"
                data-tooltip-id={`not-published-reason-${id}`}
            >
                {unpublishWebsiteComment}
            </div>
            <Tooltip
                style={{
                    maxWidth: '250px',
                    textWrap: 'wrap',
                    lineHeight: '1.5em'
                }}
                id={`not-published-reason-${id}`}
                delayShow={1000}
                float={true}
                place="right"
                content={
                    unpublishWebsiteComment &&
                    `Not currently published on the website:
                        ${unpublishWebsiteComment}`
                }
            />
        </>
    );

    const publishedToWebsiteStatus = (
        <em className="mx-auto not-italic flex gap-1 items-center">
            <FontAwesomeIcon icon={publishWebsite ? faCheck : faXmark} className={`h-5 w-5 ${publishWebsite ? 'text-brand_status-success' :  'text-brand_status-error'}`}/>Published to Website
        </em>
    );

    const buttons = (
        <>
            <li>
                <EditTeamMemberModal
                    styleType="link"
                    memberDetails={props}
                />
            </li>
            <li>
                <DeleteTeamMemberDialogButton
                    memberDetails={props}
                    styleType="link"
                />
            </li>
        </>
    );

    return (
        <Draggable key={id} draggableId={String(id)} index={index}>
            {(provided) => (
                <li
                    className="bg-white rounded-md flex flex-col min-w-44 max-w-44 [&>img]:rounded-t-md"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    {image}
                    <div className="flex flex-col gap-1 p-2 text-2xs border-x border-brand_grey text-center h-full">
                        {fullName}
                        {reasonNotPublished}
                        <div className="mt-auto">
                            {publishedToWebsiteStatus}
                        </div>
                    </div>
                    <ul className="flex gap-2 justify-between p-2 text-2xs border border-brand_grey rounded-b-md mt-auto">
                        {buttons}
                    </ul>
                </li>
            )}
        </Draggable>
    );
};

export { DraggableMemberCard };