import React, { FC } from 'react';

import cx from 'classnames';

type Option = {label: string; value: any};

interface IProps {
    onChange: (selectedValue: any) => void;
    value: Option;
    label?: string;
    options: Option[]
}

const ButtonGroup: FC<IProps> = ({ options, onChange, value: currentOption, label }) => {

    const items = options.map((option: Option, index: number) => {
        return (
            <button
                key={index}
                onClick={() => onChange(option)}
                className={cx('btn btn-outline btn-outline-secondary', { active: currentOption.value === option.value })}
                type='button'
            >
                {option.label}
            </button>
        );
    });

    return (
        <div className="flex gap-4 items-center">
            { label && <label>{label}</label> }
            <div className="flex flex-nowrap items-center [&>.btn]:rounded-none [&>.btn]:-ms-px first:[&>.btn]:rounded-s-full first:[&>.btn]:ms-0 last:[&>.btn]:rounded-e-full">
                {items}
            </div>
        </div>
    );
};

export { ButtonGroup };