import React from 'react';
import { Button } from 'react-bootstrap';

interface IProps {
    formId: string;
    className?: string;
}
    
const PropertyVirtualTourSaveButton = ({ formId, className }: IProps) => {
    return (
        <div className={className}>
            <Button 
                type="submit" 
                className="btn btn-md btn-primary text-nowrap" 
                form={formId}
            >
                Save
            </Button>
        </div>
    );
};

export default PropertyVirtualTourSaveButton;
