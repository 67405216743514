import React, { FC, InputHTMLAttributes, ReactElement, ReactNode } from 'react';
import { ConnectForm } from '../form/form';
import cx from 'classnames';
import { UseFormReturn } from 'react-hook-form';

interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
    label?: string;
    name: string;
    rows?: number;
    inlineLabel?: boolean;
    intro?: ReactNode;
    description?: ReactNode;
    maxWidth?: string;
    disabled?: boolean;
    // layout?: 'normal' | 'horizontal'; // no requirement for this yet, if needed then will be very similar to form_input layout
}

type FieldRequired = { isRequired: (fieldName: string) => boolean };

const FormTextArea: FC<Props> = (props) => {
    const {
        name,
        id,
        label,
        intro,
        description,
        maxLength,
        rows = 3,
        inlineLabel = true,
        disabled = false,
        maxWidth,
        ...rest
    } = props;

    return (
        <ConnectForm>
            {({
                register,
                formState,
                watch,
                isRequired
            }: UseFormReturn & FieldRequired) => {

                const fieldError: { message?: string } =
                    formState?.errors?.[name];

                const errorMessage: ReactElement = (
                    <span>
                        {fieldError?.message}
                    </span>
                );

                const textAreaId: string = id || name;

                const characterCountDisplay: ReactElement = (
                    <div className="text-2xs text-brand_grey-medium text-end px-3 py-1">
                        {watch(name)?.length || 0}/{maxLength}
                    </div>
                );
                
                return (
                    <>
                        { intro && <>{intro}</> }
                        <div className={cx('form-component form-textarea', { 'form-inline' : inlineLabel, 'form-error' : fieldError })}>
                            { label && 
                                <label htmlFor={textAreaId}>
                                    {label}
                                    {isRequired(name) && '*'}
                                </label> 
                            }
                            <textarea
                                cols={30}
                                rows={rows}
                                id={textAreaId}
                                disabled={disabled}
                                style={maxWidth ? { maxWidth } : {}}
                                {...register?.(name)}
                                {...rest}
                            ></textarea>
                            {maxLength && characterCountDisplay}
                            { description && 
                                <div className="form-note">
                                    {description}
                                </div>
                            }
                            {fieldError && errorMessage}
                        </div>
                    </>
                );
            }}
        </ConnectForm>
    );
};

export { FormTextArea };