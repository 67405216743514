import React, { useContext } from 'react';

import { IPropertyVirtualTourRow, IPropertyVirtualTourResponse } from '../../../../interfaces/asset';
import DataTable from '../../../../components/common/data_table';
import { formatAddress } from '../../../../utils/property';
import AppContext from '../../../../context/app_context';
import { Button } from '../../../../components/common/button';


interface IProps {
    data: IPropertyVirtualTourResponse;
    handleOpenModal: (row: IPropertyVirtualTourRow) => void;
}

const PropertyVirtualTourTable = ({ data: { rows, totalRows, totalRowsWithVirtualTour }, handleOpenModal }: IProps) => {
    const appContext: any = useContext(AppContext);
    
    const columns = [
        {
            id: 'Name',
            name: 'Facility Name',
            cell: (row: IPropertyVirtualTourRow) => formatFacility(row),
            width: '*'
        },
        {
            name: <>360 Virtual Tour URL</>,
            cell: (row: IPropertyVirtualTourRow) => formatTourURL(row),
            width: '*'
        },
        {
            name: '',
            cell: (row: IPropertyVirtualTourRow) => formatActions(row),
            width: '*'
        }
    ];

    const formatTourURL = (row: IPropertyVirtualTourRow) => {
        if (!row.TourURL) {
            return null;
        }

        if (row.PublishWebsite) {
            const tourLink = <a href={row.TourURL} target="_blank" rel='noreferrer'>{ row.TourURL }</a>;

            if (!row.Verified) {
                return (
                    <>
                        <span className="msg msg-info mb-2">Pending Verification</span>
                        { tourLink }
                    </>
                );
            }
            
            return (
                <>
                    { tourLink }
                </>
            );
        }

        return (
            <>
                <span className="msg msg-error mb-2">This Virtual Tour has been unpublished by the NuringHomes.com team</span>
                <span className="msg msg-info">{ row.UnpublishWebsiteComment }</span>
            </>
        );
    };

    const formatFacility = (row: IPropertyVirtualTourRow) => {
        const { WebsiteURL, StateSlug, CitySlug, Slug } = row;

        return (
            <div className="flex flex-wrap gap-2 break-words py-1">
                <strong className="block leading-tight lg:text-lg">
                    { row.Name }
                </strong>
                <span className="inline-block text-xs lg:text-sm me-3 leading-tight text-brand_grey-medium">
                    { formatAddress(row) }
                </span>
                <a
                    className="link text-xs bg-brand_faint-blue px-2 py-1 inline-block leading-none rounded-lg relative -top-px"
                    href={`${WebsiteURL}/${StateSlug}/${CitySlug}/${Slug}`}
                    target="_blank"
                >
                    view on website
                </a> 
            </div>  
        );
    };

    const formatActions = (row: IPropertyVirtualTourRow) => {
        const { NHID, TourURL, PublishWebsite } = row;
        const showButton = !(!PublishWebsite && !!TourURL);
        
        return (
            <>
                { 
                    showButton && 
                    <Button 
                        key={`update-property-virtual-tour-${NHID}`}
                        className="ms-auto"
                        onClick={() => {
                            handleOpenModal(row);   
                            window.scrollTo(0, 0);
                        }}
                    >
                        { `${TourURL ? 'Update' : 'Add'} Virtual Tour` }
                    </Button>
                }
            </>
        );
    };
    
    return (
        <div className="container max-w-none 2xl:container mt-4 sm:mt-6 md:mt-8 2xl:mt-10">
            <div className="flex flex-col items-center text-center gap-3 md:flex-row md:text-start">
                <strong className="text-md md:text-lg lg:text-xl lg:whitespace-nowrap">
                    {totalRowsWithVirtualTour}/{totalRows} Facilities have 360 Virtual Tours showing on website
                </strong>
            </div> 
            <div className="mt-4 sm:mt-6 md:mt-8 lg:mt-10">
                <div data-table="Logos" className="relative [&>div]:scrollbar [&>div]:pb-2 [&>div]:-mx-3 [&>div]:w-[calc(100%+1.5rem)] [&>div]:px-3 sm:[&>div]:-mx-4 sm:[&>div]:w-[calc(100%+2rem)] sm:[&>div]:px-4 xl:[&>div]:mx-0 xl:[&>div]:w-full xl:[&>div]:px-0 before:h-full before:top-0 before:absolute before:z-10 before:bg-gradient-to-r before:from-white before:w-3 before:-left-3 after:h-full after:top-0 after:absolute after:z-10 after:bg-gradient-to-l after:from-white after:-right-3 after:w-3 sm:before:-left-4 sm:before:w-4 sm:after:-right-4 sm:after:w-4 xl:before:content-none xl:after:content-none">
                    <DataTable
                        columns={columns}
                        data={rows}
                    />
                </div>
            </div>
        </div>
    );
};

export default PropertyVirtualTourTable;
