import React, { useState } from 'react';
import { ReviewReplyChartWidget } from '../../components/charts/review_reply_chart_widget';
import PageCommunitySelectWrapper from '../../components/common/page_community_select_wrapper';
import { useLocation } from 'react-router-dom';
import { useCommunitySelectHistory } from '../../hooks/use_community_select_history';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

const ReviewsAndReplies = ({ nhIDs, orgNHID }: IProps) => { 
    const location = useLocation();
    const state = location?.state as any;
    const stateNHID = state?.nhIDs || null;

    const [ selNHID, setSelNHID ] = useState<number>(stateNHID);

    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        setSelNHID(selNHID);
    });

    return (
        <>
            <PageCommunitySelectWrapper
                label={'Reviews and replies for'}
                handleSelect={handleSelect}
                selNHID={selNHID}
            />
            <section>
                <div className="container max-w-none 2xl:container mt-4 sm:mt-6 md:mt-8 2xl:mt-10">
                    <ReviewReplyChartWidget
                        orgNHID={orgNHID}
                        nhIDs={nhIDs}
                        selNHID={selNHID}
                        cumulativeDefault={false}
                    />
                </div>
            </section>
        </>
    );
};

export default ReviewsAndReplies;