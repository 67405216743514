import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlashForward } from '@fortawesome/pro-light-svg-icons';
import { faThumbsUp } from '@fortawesome/pro-solid-svg-icons';

export const renderScoreCardTxt = (reviewScoreDisplay: number, num: number, denom: number) => {

    if (!reviewScoreDisplay) {
        return 'N/A';
    }

    const renderSinglePropertyText = (num: number) => (
        <>
            { 
                num ? <strong className="min-w-10 text-center block">
                    <em className="pulse">Yes</em>
                </strong> : <>
                    <FontAwesomeIcon icon={faThumbsUp} />No
                </>
            }
        </>
    );

    const renderMutiPropertyText = (num: number, denom: number) => (
        <>
            {
                num ? <>
                    <strong className="min-w-10 text-center block">
                        <em className="pulse">{num}</em>
                    </strong>
                    <FontAwesomeIcon icon={faSlashForward} className="text-brand_grey" />
                    <strong className="min-w-10 text-center block text-brand_grey">{denom}</strong>
                </> : <>
                    <FontAwesomeIcon icon={faThumbsUp} />
                    {num}
                    <FontAwesomeIcon icon={faSlashForward} className="text-brand_grey" />
                    <strong className="min-w-10 text-center block text-brand_grey">{denom}</strong>
                </>
            }
        </>
    );

    if (!denom) {
        return null;
    }

    return denom > 1 ? renderMutiPropertyText(num, denom)  : renderSinglePropertyText(num);

};

export const inviteToReviewLink = (nhID: number) => (
    <NavLink className="link" to={'/invite-to-review'} state={{ NHID: nhID }}>
        <strong>
            Invite to Review
        </strong>
    </NavLink>
);

export const reviewCardLink = (nhID: number) => (
    <NavLink className="link" to={'/review-cards'} state={{ NHID: nhID }}>
        <strong>
            Review Cards
        </strong>
    </NavLink>
);

export const buildMultiBulletsNoReviewScore = (orgName: string, nhID: number) => {
    return [
        <React.Fragment key={'multibulletnoreviewscore-1'}>Your Provider Review Score is made up of the average of all your facility Review Scores.</React.Fragment>,
        <React.Fragment key={'multibulletnoreviewscore-2'}>Each facility&apos;s Review Score is calculated using a combination of the Quantity of Reviews, and 
            the Average Rating of Reviews, using only Reviews published in the last 24 months.</React.Fragment>,
        <React.Fragment key={'multibulletnoreviewscore-3'}>There is currently no Review Score for {orgName} as none of its facilities have a Review Score. Facilities require 1+ qualifying reviews in the last 24 months to obtain a Review Score.</React.Fragment>,
        <React.Fragment key={'multibulletnoreviewscore-4'}>We recommend generating some new reviews using {inviteToReviewLink(nhID)} or {reviewCardLink(nhID)} in order to reap the benefits of having Review Scores for all of your facilities.</React.Fragment>
    ];
};

export const buildSingleBulletsNoReviewScore = (singlePropertyName: string, nhID: number) => {
    return [
        <React.Fragment key={'singlebulletnoreviewscore-1'}>Your Review Score is calculated using a combination of the Quantity of Reviews
        and the Average Rating of Reviews, using only Reviews published in the last 24 months.</React.Fragment>,
        <React.Fragment key={'singlebulletnoreviewscore-2'}>There is currently no Review Score for {singlePropertyName} as it has no qualifying reviews in the last 24 months.</React.Fragment>,
        <React.Fragment key={'singlebulletnoreviewscore-3'}>We recommend generating some new reviews {inviteToReviewLink(nhID)} or {reviewCardLink(nhID)} in order to reap the benefits of having a Review Score.</React.Fragment>
    ];
};
