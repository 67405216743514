import React, { useState, FC } from 'react';
import Select from 'react-select';
import Accordion from 'react-bootstrap/Accordion';
import { LoadingDots } from '../../../components/common/loading_dots';
import { updateBulkPropertyContactDetails } from '../../../api/property';
import { Toast, showToast, ToastType } from '../../../utils/toast';
import { isObjectEmpty } from '../../../utils/common';
import { bulkUpdateContactDetailsObjectSchema } from './schema/contact_details_schema';
import {
    ContactDetailsHTMLType,
    ContactDetailsHeaders,
    IPropertyContactDetails
} from '../../../interfaces/property';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faChevronRight, faChevronDown } from '@fortawesome/pro-solid-svg-icons';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
    handleFetchData: any;
    contactData: IPropertyContactDetails[];
}

const bulkUpdateoptions = [
    { label: 'Ignore', value: 0 },
    { label: 'Update', value: 1 },
    { label: 'Update only if no existing data', value: 2 },
    { label: 'Delete', value: 3 }
];

const BulkPropertyContactDetailsUpdateForm: FC<IProps> = ({
    nhIDs,
    orgNHID,
    handleFetchData,
    contactData
}: IProps) => {
    const [busy, setBusy] = useState<boolean>(false);
    const [newBulkData, setNewBulkData] = useState<any>({});
    const [newBulkSChange, setNewBulkSChange] = useState<boolean>(false);
    const [selectedOptions, setSelectedOptions] = useState<any>({
        GeneralPhoneNumber: 0,
        GeneralEmail: 0,
        GeneralWebsite: 0,
        CarePhoneNumber: 0,
        JobPhoneNumber: 0,
        GeneralEmail2: 0,
        GeneralEmail3: 0,
        CareEmail: 0,
        CareEmail2: 0,
        CareEmail3: 0,
        JobEmail: 0,
        JobEmail2: 0,
        JobEmail3: 0
    });
    const [bulkValidationError, SetBulkValidationError] = useState<any[]>([]);
    const [changedTick, setChangedTick] = useState<number>(0);

    const updateSelectedOptions = (selected: any, key: string) => {
        const newDataObject = selectedOptions;
        newDataObject[key] = selected.value;
        setSelectedOptions(newDataObject);
        if (selected.value > 0) {
            setNewBulkSChange(true);
        }
        if (
            (selected.value === 0 || selected.value === 3) &&
            bulkValidationError.length
        ) {
            const errorObjectArray = bulkValidationError.filter(function (e) {
                return e.Key !== key;
            });
            SetBulkValidationError(errorObjectArray);
        }
        setChangedTick(changedTick + 1);
    };

    const handleSetNewBulkData = (key: string, value: string) => {
        const newDataObject = newBulkData;
        newDataObject[key] = value;
        if (bulkValidationError.length) {
            const errorObjectArray = bulkValidationError.filter(function (e) {
                return e.Key !== key;
            });
            SetBulkValidationError(errorObjectArray);
        }
        setNewBulkData(newDataObject);
        setChangedTick(changedTick + 1);
    };

    const renderRowErrorMessage = (key: string) => {
        const errorObjectArray = bulkValidationError.filter(function (e) {
            return e.Key === key;
        });

        if (errorObjectArray.length) {
            return (
                <>
                    <span className="text-brand_status-error text-xs font-medium leading-tight hidden md:block">
                        {`${errorObjectArray[0].Message} `}
                    </span>
                </>
            );
        }
    };

    const checkBulkObjectError = () => {
        if (bulkValidationError.length) {
            return true;
        }
        return false;
    };

    const checkBulkOptionUpdateSelected = () => {
        for (const i in selectedOptions) {
            if (selectedOptions[i] > 0) return true;
        }
        return false;
    };

    const checkIsDeletedOrIgnored = (key: string) => {
        if (selectedOptions[key] === 3 || selectedOptions[key] === 0) {
            return true;
        }
        return false;
    };

    const handleBulkUpdate = async () => {
        const payload = newBulkData;
        let updateValidationErrors: any[] = [];
        Object.entries(selectedOptions).forEach(([key, value]) => {
            if (parseInt(value as string) > 0 && !isNaN(value as number)) {
                payload[`${key}Op`] = selectedOptions[key];
                if (
                    (parseInt(value as string) === 1 ||
                        parseInt(value as string) === 2) &&
                    !payload[key]
                ) {
                    updateValidationErrors = updateValidationErrors.concat([
                        {
                            Key: key,
                            Message:
                                'Please enter a value'
                        }
                    ]);
                }
            } else {
                delete payload[key];
            }
        });

        try {
            await bulkUpdateContactDetailsObjectSchema.validate(payload, {
                abortEarly: false
            });
        } catch (err: any) {
            const errorCopy: any = err.inner;
            const errorPayload = errorCopy.map((e: any) => {
                const errorInnerCopy: any = {};
                errorInnerCopy.Key = e.path;
                errorInnerCopy.Message = e.message;
                return errorInnerCopy;
            });
            updateValidationErrors =
                updateValidationErrors.concat(errorPayload);
        }

        if (updateValidationErrors.length) {
            SetBulkValidationError(updateValidationErrors);
            setChangedTick(changedTick + 1);
            return;
        }

        payload.OrgNHID = orgNHID;
        payload.NHIDs = nhIDs ?? null;
        try {
            await bulkUpdateContactDetailsObjectSchema.validate(payload, {
                abortEarly: false
            });
        } catch (err: any) {
            const errorPayload: any = err.errors ?? [err.message];
            const newErrorArray = errorPayload;
            SetBulkValidationError(newErrorArray);
            setChangedTick(changedTick + 1);
            return;
        }
        try {
            setBusy(true);
            await updateBulkPropertyContactDetails(payload);
            const successString = `${contactData.length} Facility contact details saved successfully. Please allow up to 60 minutes for the changes to show on the website.`;
            showToast(successString, ToastType.Success);
            setSelectedOptions({
                GeneralPhoneNumber: 0,
                GeneralEmail: 0,
                GeneralWebsite: 0,
                CarePhoneNumber: 0,
                JobPhoneNumber: 0,
                GeneralEmail2: 0,
                GeneralEmail3: 0,
                CareEmail: 0,
                CareEmail2: 0,
                CareEmail3: 0,
                JobEmail: 0,
                JobEmail2: 0,
                JobEmail3: 0
            });
            setNewBulkData({});
            setNewBulkSChange(false);
        } catch (e) {
            showToast(
                'An error occurred saving the updated contact details.',
                ToastType.Error
            );
            console.log(e);
        } finally {
            setBusy(false);
            handleFetchData();
        }
    };

    const renderLoading = () => <LoadingDots show={true} />;

    const renderBulkOptions = (
        htmlId: ContactDetailsHTMLType,
        key: string,
        label: string,
    ) => {
        let formClass;
        const labelId = `l-ct-${key}`;
        const selectId = `s-ct-${key}`;
        const fieldId = `f-ct-bulk-${key}`;

        const errorObjectArray = bulkValidationError.filter(function (e) {
            return e.Key === key;
        });
        let errorFormat = '';

        if (errorObjectArray.length) {
            errorFormat = 'form-error';
        }

        switch (htmlId) {
            case ContactDetailsHTMLType.Website:
                formClass = `sm:max-w-96 lg:max-w-3/5 ${errorFormat}`;
                break;
            case ContactDetailsHTMLType.PhoneNumber:
                formClass = `sm:max-w-60 lg:max-w-1/3 ${errorFormat}`;
                break;
            default:
                formClass = `sm:max-w-80 lg:max-w-1/2 ${errorFormat}`;
                break;
        }

        return (
            <div className="flex flex-col gap-1 sm:flex-row sm:items-center sm:gap-2">
                <label
                    htmlFor={labelId}
                    className="text-sm md:text-md sm:min-w-32"
                >
                    {label}
                </label>
                <div className="form-component form-select sm:min-w-48 lg:min-w-72">
                    <Select
                        options={bulkUpdateoptions}
                        defaultValue={bulkUpdateoptions[0]}
                        id={selectId}
                        classNamePrefix="select"
                        onChange={(selectedOption: any) =>
                            updateSelectedOptions(selectedOption, key)
                        }
                        value={
                            bulkUpdateoptions[selectedOptions[key]] ||
                            bulkUpdateoptions[0]
                        }
                        unstyled
                        isSearchable={false}
                    />
                </div>
                <div className={`form-component form-textbox w-full ${formClass}`}>
                    <input
                        type="text"
                        id={fieldId}
                        value={(newBulkData as any)[key] || ''}
                        disabled={checkIsDeletedOrIgnored(key)}
                        onChange={e =>
                            handleSetNewBulkData(key, e.target.value)
                        }
                    />
                </div>
                {renderRowErrorMessage(key)}
            </div>
        );
    };

    return (
        <>
            <Toast></Toast>
            {busy ? renderLoading() : null}
            <div className="relative ring-1 ring-brand_grey rounded-xl bg-brand_faint-blue mb-4 sm:mb-6 md:mb-8 lg:mb-10">
                <div className="flex items-center">
                    <FontAwesomeIcon icon={faChevronRight} className="hidden md:block absolute top-[27px] left-3 h-5 w-5 pointer-events-none" />
                    <label htmlFor="bulk" className="block cursor-pointer w-full p-3 sm:p-4 pe-10 md:!ps-11">
                        <strong className="text-lg md:text-xl">
                            Bulk Update <span className="hidden sm:inline-block">all Facility Contact details</span>
                        </strong>
                        <span className="block text-xs sm:text-sm">
                            Click to view options for bulk updating all facilities
                        </span>
                    </label>
                </div>
                <input type="checkbox" className="hidden peer/bulk" id="bulk" />
                <div className="hidden peer-checked/bulk:flex flex-col gap-3 p-3 sm:p-4 border-t border-brand_grey bg-white rounded-b-xl lg:gap-5">
                    <FontAwesomeIcon icon={faChevronDown} className="hidden md:block absolute top-[27px] left-3 h-5 w-5 pointer-events-none bg-brand_faint-blue" />
                    <label htmlFor="bulk" className="flex absolute top-4 right-3 sm:top-5 sm:right-4 cursor-pointer rounded-full bg-brand_tertiary hover:bg-brand_tertiary-dark text-white w-8 h-8 justify-center items-center">
                        <FontAwesomeIcon icon={faXmark} />
                    </label>
                    <div className="flex flex-col gap-2">
                        <strong className="text-md md:text-lg block">
                            Phone
                        </strong>
                        {renderBulkOptions(
                            ContactDetailsHTMLType.PhoneNumber,
                            'GeneralPhoneNumber',
                            ContactDetailsHeaders.GeneralPhoneNumber,
                        )}
                        {renderBulkOptions(
                            ContactDetailsHTMLType.PhoneNumber,
                            'CarePhoneNumber',
                            ContactDetailsHeaders.Care,
                        )}
                        {renderBulkOptions(
                            ContactDetailsHTMLType.PhoneNumber,
                            'JobPhoneNumber',
                            ContactDetailsHeaders.Job,
                        )}
                        <em className="block mt-2 text-sm text-brand_grey-medium">
                            The 'Default Number' will show if no separate 'Care' or 'Job' numbers are entered.
                        </em>
                    </div>
                    <div className="flex flex-col gap-2">
                        <strong className="text-md md:text-lg block">
                            Email
                        </strong>
                        {renderBulkOptions(
                            ContactDetailsHTMLType.Email,
                            'GeneralEmail',
                            ContactDetailsHeaders.GeneralEmail1,
                        )}
                        {renderBulkOptions(
                            ContactDetailsHTMLType.Email,
                            'GeneralEmail2',
                            ContactDetailsHeaders.GeneralEmail2,
                        )}
                        {renderBulkOptions(
                            ContactDetailsHTMLType.Email,
                            'GeneralEmail3',
                            ContactDetailsHeaders.GeneralEmail3,
                        )}
                        {renderBulkOptions(
                            ContactDetailsHTMLType.Email,
                            'CareEmail',
                            ContactDetailsHeaders.Care1,
                        )}
                        {renderBulkOptions(
                            ContactDetailsHTMLType.Email,
                            'CareEmail2',
                            ContactDetailsHeaders.Care2,
                        )}
                        {renderBulkOptions(
                            ContactDetailsHTMLType.Email,
                            'CareEmail3',
                            ContactDetailsHeaders.Care3,
                        )}
                        {renderBulkOptions(
                            ContactDetailsHTMLType.Email,
                            'JobEmail',
                            ContactDetailsHeaders.Job1
                        )}
                        {renderBulkOptions(
                            ContactDetailsHTMLType.Email,
                            'JobEmail2',
                            ContactDetailsHeaders.Job2
                        )}
                        {renderBulkOptions(
                            ContactDetailsHTMLType.Email,
                            'JobEmail3',
                            ContactDetailsHeaders.Job3
                        )}
                        <em className="block mt-2 text-sm text-brand_grey-medium">
                            The 'Default Email' address(es) will be used if no separate 'Care', 'Tour' or 'Job' email addresses are entered.
                        </em>
                    </div>
                    <div className="flex flex-col gap-2">
                        <strong className="text-md md:text-lg block">
                            Website
                        </strong>
                        {renderBulkOptions(
                            ContactDetailsHTMLType.Website,
                            'GeneralWebsite',
                            ContactDetailsHeaders.GeneralWebsite
                        )}
                    </div>
                    <button
                        type="button"
                        className="btn ms-auto"
                        disabled={
                            (isObjectEmpty(newBulkData) &&
                                !newBulkSChange &&
                                !checkBulkOptionUpdateSelected()) ||
                            checkBulkObjectError()
                        }
                        onClick={handleBulkUpdate}
                    >
                        Apply these Updates
                    </button>
                </div>
            </div>
        </>
    );
};

export { BulkPropertyContactDetailsUpdateForm };
