import React, { FC } from 'react';
import {
    DragDropContext,
    Droppable,
    OnDragEndResponder
} from 'react-beautiful-dnd';
import { MeetTheTeamMemberType } from '../../types';
import { NoResultsPlaceholder } from '../../components';
import { DraggableMemberCard } from './draggable_member_card';

interface Props {
    teamMembers: any[];
    onDragEnd: OnDragEndResponder;
    propertyId: number;
    disabled: boolean;
}

const DroppableMemberCardArea: FC<Props> = ({
    disabled = false,
    teamMembers,
    onDragEnd,
    propertyId
}) => {
    const draggableCards = teamMembers?.map(
        (member: MeetTheTeamMemberType, index: number) => (
            <DraggableMemberCard key={member.id} index={index} {...member} />
        )
    );

    const dropArea = (
        <Droppable
            isDropDisabled={disabled}
            droppableId={`meet-the-team-${propertyId}`}
            direction="horizontal"
        >
            {(provided) => (
                <ul
                    className="p-3 sm:p-4 2xl:p-5 gap-3 ring-1 ring-brand_grey-light flex overflow-x-auto scrollbar scrollbar-full scrollbar-media"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    {draggableCards}
                    {provided.placeholder}
                </ul>
            )}
        </Droppable>
    );

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            {teamMembers?.length ? dropArea : <div className="p-3 sm:p-4 2xl:p-5"><NoResultsPlaceholder /></div>}
        </DragDropContext>
    );
};

export { DroppableMemberCardArea };
