import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCheck, faSlashForward } from '@fortawesome/pro-solid-svg-icons';
import { ISessionState } from '../../interfaces/session';
import { useSelector } from 'react-redux';
import { LoadingDots } from '../common/loading_dots';
import { NavLink } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { 
    INhPortalScoreItem,
    IScoreDisplayItem,
    IScoreExtraDisplayItem
} from '../../interfaces/property';

import { 
    mapProfileDatatoDisplay,
    getOrgProfileScoreFromProfileData,
    getFacilityCountFromProfileData,
    getTotalScoreForNHIDFromProfileData,
    mapProfileExtraDatatoDisplay,
    getAverageScoreForNHIDsFromProfileData
} from '../../utils/profile_completeness';

import { get } from 'lodash';
import { PropertyMode } from '../../interfaces/session';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
    selNHID: number | null;
    setIsLoading?: any;
    isLoading?: boolean;
    propertyMode: PropertyMode,
    widgetSize: 'small' | 'large';
}

export const ProfileCompletnessWidget = ({  
    nhIDs, 
    orgNHID, 
    selNHID, 
}: IProps) => {
    
    const [ scoreArray, setScoreArray ] = useState<IScoreDisplayItem[]>([]);
    const [ scoreExtra, setScoreExtra ] = useState<IScoreExtraDisplayItem>();

    const profileCompletenessState: INhPortalScoreItem = useSelector(
        ({ ProfileCompletenessState }: any) => ProfileCompletenessState
    );

    useEffect(() => {        
        const scoreArray = mapProfileDatatoDisplay(profileCompletenessState, selNHID);
        const scoreExtra = mapProfileExtraDatatoDisplay(profileCompletenessState, selNHID);
        setScoreExtra(scoreExtra);
        setScoreArray(scoreArray);
}, [ orgNHID, nhIDs, selNHID, profileCompletenessState ]);

    const renderWidget = () => {

        const { Properties }: ISessionState = useSelector(
            ({ SessionState }: any) => SessionState
        );
        
        const singleNHID = selNHID || (nhIDs?.length === 1 ? nhIDs[0] : null);
        const facilityCount = selNHID ? 1 : getFacilityCountFromProfileData(profileCompletenessState);
        const orgIsSelected = orgNHID && !selNHID;

        if (!scoreExtra) {
            return <LoadingDots/>;
        }

        const score = singleNHID ? getTotalScoreForNHIDFromProfileData(profileCompletenessState, singleNHID) : orgNHID 
            ? getOrgProfileScoreFromProfileData(profileCompletenessState)  
            : getAverageScoreForNHIDsFromProfileData(profileCompletenessState, nhIDs);

        const { HasLogoCount, HasVirtualTourCount } = scoreExtra;

        const renderOrgText = () => {

            const name = get(Properties, '[0].OrgName', '');

            return (
                <>
                    <strong>
                        The Profile Completeness Score for {name} is {score}%
                    </strong>
                    <div className="flex gap-2 items-center text-sm">
                        <div className="flex justify-center items-center p-1 min-w-16 max-w-16 h-8 text-brand_secondary">
                            <strong className="w-4 text-end">X</strong>
                            <FontAwesomeIcon icon={faSlashForward} className="min-w-3 max-w-3 min-h-3 max-h-3 mx-0.5" />
                            <strong className="w-4">X</strong>
                            <span>%</span>
                        </div>
                        shows the % value/attainable for profile completeness per content type.
                    </div>
                    <div className="flex gap-2 items-center text-sm">
                        <div className="flex justify-center items-center bg-brand_faint-blue p-1 rounded-md link min-w-16 max-w-16 h-8">
                            <span className="w-4 text-center">X</span>
                            <FontAwesomeIcon icon={faSlashForward} className="min-w-3 max-w-3 min-h-3 max-h-3 mx-0.5" />
                            <span className="w-4 text-center">X</span>
                        </div>
                        shows the number of Facilities that are profile complete per content type.
                    </div>
                </>
            );
        };

        const renderMultiText = () => {

            if (!nhIDs?.length) {
                return null;
            } 

            const avgProfileScore = getAverageScoreForNHIDsFromProfileData(profileCompletenessState, nhIDs);

            return (
                <>
                    <strong>
                        The Profile Completeness Score for All {nhIDs.length} Facilities is {avgProfileScore}%
                    </strong>
                    <div className="flex gap-2 items-center text-sm">
                        <div className="flex justify-center items-center p-1 min-w-16 max-w-16 h-8 text-brand_secondary">
                            <strong className="w-4 text-end">X</strong>
                            <FontAwesomeIcon icon={faSlashForward} className="min-w-3 max-w-3 min-h-3 max-h-3 mx-0.5" />
                            <strong className="w-4">X</strong>
                            <span>%</span>
                        </div>
                        shows the % value/attainable for profile completeness per content type.
                    </div>
                    <div className="flex gap-2 items-center text-sm">
                        <div className="flex justify-center items-center bg-brand_faint-blue p-1 rounded-md link min-w-16 max-w-16 h-8">
                            <span className="w-4 text-center">X</span>
                            <FontAwesomeIcon icon={faSlashForward} className="min-w-3 max-w-3 min-h-3 max-h-3 mx-0.5" />
                            <span className="w-4 text-center">X</span>
                        </div>
                        shows the number of Facilities that are profile complete per content type.
                    </div>
                </>
            );
        };

        const renderSingleNHIDText = () => {

            const property = Properties.find((property: any) => property.NHID === singleNHID);

            if (!property) {
                return null;
            }

            return (
                <>
                    <strong>
                        The Profile Completeness Score for {property.Name} is {score}%
                    </strong>
                    <div className="flex gap-2 items-center text-sm">
                        <div className="flex justify-center items-center p-1 min-w-16 max-w-16 h-8 text-brand_secondary">
                            <strong className="w-4 text-end">X</strong>
                            <FontAwesomeIcon icon={faSlashForward} className="min-w-3 max-w-3 min-h-3 max-h-3 mx-0.5" />
                            <strong className="w-4">X</strong>
                            <span>%</span>
                        </div>
                        shows the % value/attainable for profile completeness per content type.
                    </div>
                </>
            );
        };

        return (
            <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg flex flex-col h-full">
                <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 2xl:py-3 min-h-14 flex items-center justify-between gap-2">
                    <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                        Profile Content
                    </h4>
                </div>
                <div className="p-3 sm:p-4 2xl:p-5 pb-0 sm:pb-1 2xl:pb-2 h-full">
                    <div className="flex flex-col gap-3 md:flex-row md:gap-4 md:items-center lg:gap-5">
                        <svg viewBox="0 0 36 36" className="mx-auto block min-w-24 w-24 h-24 md:mx-0 lg:ms-1.5">
                            <path className="fill-none stroke-brand_grey-light stroke-[4]" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                            <path className="fill-none stroke-brand_secondary stroke-[4] animate-[progress_1s_ease-out_forwards]" strokeDasharray={`${score},100`} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                            <text x="18" y="11" className="text-[4px] fill-brand_secondary font-bold tracking-tighter text-anchor-middle">Profile</text>
                            <text x="18" y="21.5" className="text-[10px] fill-brand_secondary font-bold tracking-tighter text-anchor-middle">{score}%</text>
                            <text x="18" y="27" className="text-[4px] fill-brand_secondary font-bold tracking-tighter text-anchor-middle">Complete</text>
                        </svg>
                        <div className="leading-tight flex flex-col gap-2">
                            { singleNHID ? renderSingleNHIDText() : orgNHID ? renderOrgText() : renderMultiText() }
                        </div>
                    </div>
                    <div className="mt-3 lg:mt-5 [&>ul>li>a]:py-1.5 [&>ul>li]:border-t [&>ul>li]:border-brand_grey-light [&>ul>li>a]:flex [&>ul>li>a]:items-center [&>ul>li>a]:gap-2 sm:[&>ul>li>a]:gap-3 sm:[&>ul>li>a]:px-3">
                        <ul className="border-b border-brand_grey-light">
                            {
                                scoreArray.map((score: IScoreDisplayItem) => (
                                    <li>
                                        <NavLink to={score.InternalURL}>
                                            <FontAwesomeIcon icon={score.AchievedScoreCount === facilityCount ? faCheck : faXmark} className={`min-w-6 min-h-6 ${score.AchievedScoreCount === facilityCount ? 'text-brand_secondary' : 'text-brand_status-error'}`} />
                                            <div className="flex xs_max:flex-col gap-1 flex-row sm:gap-3">
                                                <div className={`flex justify-center items-center p-1 min-w-16 max-w-16 h-8 ${score.AchievedScoreCount === facilityCount ? 'text-brand_secondary' : 'text-brand_status-error'}`}>
                                                    <strong className="w-4 text-end">{score.ScorePC}</strong>
                                                    <FontAwesomeIcon icon={faSlashForward} className="min-w-3 max-w-3 min-h-3 max-h-3 mx-0.5" />
                                                    <strong className="w-4">{score.AvailableScore}</strong>
                                                    <span>%</span>
                                                </div>
                                                {
                                                    facilityCount > 1 ?
                                                    <div className="flex justify-center items-center bg-brand_faint-blue p-1 rounded-md link min-w-16 max-w-16 h-8">
                                                        <span className="w-4 text-center">{score.AchievedScoreCount}</span>
                                                        <FontAwesomeIcon icon={faSlashForward} className="min-w-3 max-w-3 min-h-3 max-h-3 mx-0.5" />
                                                        <span className="w-4 text-center">{facilityCount}</span>
                                                    </div> : null
                                                }
                                            </div>
                                            <span className="link">
                                                {score.Label}
                                            </span>
                                            <span id={`${score.KeyName}`} className="hidden sm:block link text-xs bg-brand_faint-grey p-1 rounded-md cursor-help">
                                                criteria
                                            </span>
                                            <Tooltip anchorSelect={`#${score.KeyName}`} className="tooltip">
                                                {score.Description} <br/>({score.AvailableScore}% of the total Profile Completeness Score is attributed to {score.Label})
                                            </Tooltip>
                                            <span className="ms-auto link">
                                                View
                                            </span>
                                        </NavLink>
                                    </li>
                                ))
                            }
                        </ul>
                        <strong className="mt-4 block mb-2">
                            NOT included in Profile Completeness
                        </strong>
                        <ul>
                            <li>
                                <NavLink to="/logos">
                                    { orgIsSelected || facilityCount >= 1 ?
                                        <FontAwesomeIcon icon={HasLogoCount === facilityCount ? faCheck : faXmark} className={`min-w-6 min-h-6 ${HasLogoCount === facilityCount ? 'text-brand_secondary' : 'text-brand_status-error'}`} /> : null
                                    }
                                    { facilityCount > 1 ?
                                        <div className="flex xs_max:flex-col flex-row sm:gap-3">
                                            <div className="min-w-16"></div>
                                            <div className="flex items-center bg-brand_faint-blue p-1 rounded-md link min-w-16 max-w-16 h-8">
                                                <span className="w-5 text-center">{HasLogoCount}</span>
                                                <FontAwesomeIcon icon={faSlashForward} className="min-w-3 max-w-3 min-h-3 max-h-3 mx-0.5" />
                                                <span className="w-5 text-center">{facilityCount}</span>
                                            </div>
                                        </div> : <div className="min-w-16"></div>
                                    }
                                    <span className="link">
                                        Logo
                                    </span>
                                    <span className="ms-auto link">
                                        View
                                    </span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/virtualtours">
                                    { orgIsSelected || facilityCount >= 1 ?
                                        <FontAwesomeIcon icon={HasVirtualTourCount === facilityCount ? faCheck : faXmark} className={`min-w-6 min-h-6 ${HasVirtualTourCount === facilityCount ? 'text-brand_secondary' : 'text-brand_status-error'}`} /> : null
                                    }
                                    { facilityCount > 1 ?
                                        <div className="flex xs_max:flex-col flex-row sm:gap-3">
                                            <div className="min-w-16"></div>
                                            <div className="flex items-center bg-brand_faint-blue p-1 rounded-md link min-w-16 max-w-16 h-8">
                                                <span className="w-5 text-center">{HasVirtualTourCount}</span>
                                                <FontAwesomeIcon icon={faSlashForward} className="min-w-3 max-w-3 min-h-3 max-h-3 mx-0.5" />
                                                <span className="w-5 text-center">{facilityCount}</span>
                                            </div>
                                        </div> : <div className="min-w-16"></div>
                                    }
                                    <span className="link">
                                        360 Virtual Tour
                                    </span>
                                    <span className="ms-auto link">
                                        View
                                    </span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );    
    };

    return (
        <>
            { renderWidget() }
        </>
    );
};