/* eslint-disable */
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { faXmarkCircle, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as menuApi from '../../api/menu';
import { useInterval } from '../../hooks/use_interval';
import { IMenuItem, IMenuState, IMenuStateTotals } from '../../interfaces/menu';
import { ISessionState } from '../../interfaces/session';
import { setMenu } from '../../store/actions/menu_actions';
import { setMenuTotals } from '../../store/actions/menu_totals_actions';
import { isDefined } from '../../utils/common';
import { getLinkTotal } from '../../utils/menu';

const menuVersionDelay = 60000;
const menuTotalsDelay = 60000;

interface IProps {
    showNav: boolean;
    setShowNav(show: boolean): void;
}

const Menu = ({ showNav, setShowNav }: IProps) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const pathname = location.pathname + location.search;

    const { Session, Properties }: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const isOrgMulti = Session?.SessionType === 'org' || Properties?.length > 1;

    const { Menu, Version }: IMenuState = useSelector(
        ({ MenuState }: any) => MenuState
    );

    const menuTotals: IMenuStateTotals = useSelector(
        ({ MenuTotalsState }: any) => MenuTotalsState
    );

    const canViewTestFeatures = get(Session, 'Perms.CanViewTestFeatures', false);
    const [ activeHeaderItem, setActiveHeaderItem ] = useState<IMenuItem>(null);
    const [ activePageItem, setActivePageItem ] = useState<IMenuItem>(null);
    const [ addAnimation, setAddAnimation ] = useState<boolean>(false);

    const [ menuRefresh, setMenuRefresh ] = useState<number>(1);

    const getMenuTotals  = async () => {
        const menuTotals = await menuApi.getMenuTotals();

        if (menuTotals) {
            dispatch(setMenuTotals(menuTotals));
        } 
    };

    useInterval(async () => {
        if (!Menu) {
           return null; 
        }

        const version = await menuApi.getMenuVersion();

        if (version) {
            dispatch(setMenu(version));
        } 

    }, menuVersionDelay);
      
    useEffect(() => {
        const fetchMenu = async () => {
            const menu = await menuApi.getMenu();

            if (menu) {
                dispatch(setMenu(menu));
            }
        }; 
        
        fetchMenu();
    }, [ Version ]);
    
    useEffect(() => {
        if (addAnimation) {
            setTimeout(() => setAddAnimation(false), 500);
        }
     }, [ addAnimation ]);
 
    useInterval(async () => {
        getMenuTotals();
    }, menuTotalsDelay);

    useEffect(() => {
        getMenuTotals();
     }, []);
     
    useEffect(() => {

        const activePageItem = (() => {
            let activeItem: IMenuItem = null;

            
            const findActivePageItem = (menuItems: IMenuItem[]): IMenuItem => {
                const curPathName = location.pathname;
 
                for (let idx = 0; idx < menuItems.length; idx ++) {
                    const menuItem = menuItems[idx];

                    if (menuItem.Route === curPathName) {
                        activeItem = menuItem;
                    }

                    if (!activeItem && menuItem.Children) {
                        findActivePageItem(menuItem.Children);
                    }
    
                    if (activeItem) {
                        break;
                    }
                }

                return activeItem;
            };
  
            return findActivePageItem(Menu);
        })();

  
        if (activePageItem) {
            setActivePageItem(activePageItem);
            setMenuRefresh(menuRefresh + 1);

            if (showNav) {
                setShowNav(false);
            }
        }
    }, [pathname, Menu]);

    useEffect(() => {
        if (activePageItem) {
            const activeHeaderItem = (() => {
                let activeHeader: IMenuItem = null;
                
                const findActiveHeaderItem = (menuItems: IMenuItem[], headerItem?: IMenuItem): IMenuItem => {
                    for (let idx = 0; idx < menuItems.length; idx ++) {
                        const menuItem = menuItems[idx];
    
                        if (menuItem.Children) {
                            const child = menuItem.Children.find(
                                (child: IMenuItem) => child.PageID === activePageItem?.PageID
                            );

                            if (child) {
                                activeHeader = headerItem || menuItem;
                            } else {
                                findActiveHeaderItem(menuItem.Children, menuItem);
                            }

                            if (activeHeader) {
                                break;
                            }
                        }
                    }
    
                    return activeHeader;
                };
        
                return findActiveHeaderItem(Menu);
            })();

            setActiveHeaderItem(activeHeaderItem);
        }
    }, [activePageItem]);

    const handleLinkClick = () => setShowNav(!showNav);

    const renderMenu = () => {
        const menu =  Menu?.map((item: IMenuItem) => {
            if (!isOrgMulti && item.IsOrgMulti) {
                return null;
            }

            if (item.IsHeader) {             
                return (
                    <li 
                        className={item.PageID === activeHeaderItem?.PageID ? 'active' : ''}
                        key={`menu-item-${item.Slug}`}
                    >
                        { renderMenuItem(item) }
                        { renderChildMenu(item) }
                    </li>
                );
            }

            return (
                <li 
                    role="presentation" 
                    key={`menu-item-${item.Slug}`}
                    className={item.PageID === activePageItem?.PageID ? 'active' : ''}
                >
                    { renderMenuItem(item) }
                </li>
            );
        });

        return (
            <ul 
                role="presentation" 
                key={`root-menu-${menuRefresh}`} 
                className="
                    flex flex-col mt-12 border-t border-brand_grey lg:mt-0 lg:flex-row lg:items-center lg:whitespace-nowrap lg:border-none lg:bg-brand_faint-blue lg:shadow-[0_5px_5px_-5px_rgba(0,0,0,0.25)] 
                    first:[&>li>*]:text-brand_grey-dark first:[&>li>*]:p-3 first:[&>li>*]:cursor-pointer first:[&>li>*]:relative first:[&>li>*]:font-semibold first:[&>li>*]:text-base first:[&>li>*]:border-b first:[&>li>*]:border-brand_grey first:[&>li>*]:leading-none first:[&>li>*]:flex first:[&>li>*]:items-center first:[&>li>*]:justify-between sm:first:[&>li>*]:p-4
                    first:[&>li:hover>*>svg]:rotate-90 
                    [&>li]:relative lg:[&>li]:static 
                    [&>li.active]:after:w-1 [&>li.active]:after:absolute [&>li.active]:after:top-0 [&>li.active]:after:left-0 [&>li.active]:after:h-full [&>li.active]:after:block [&>li.active]:after:bg-brand_primary
                    [&>li>ul]:hidden [&>li>ul]:flex-col [&>li>ul]:py-2 [&>li>ul]:border-b [&>li>ul]:border-brand_grey [&>li>ul_a]:text-brand_tertiary [&>li>ul_a]:text-xs [&>li>ul_a]:py-1 [&>li>ul_a]:block 
                    [&>li.active>ul]:flex 
                    [&>li:hover>ul]:flex 
                    [&>li>ul>li]:mb-2 last:[&>li>ul>li]:mb-0 
                    [&>li>ul>li>*]:px-3 
                    [&>li>ul>li>span]:block [&>li>ul>li>span]:font-medium [&>li>ul>li>span]:text-brand_grey-medium [&>li>ul>li>span>svg]:hidden 
                    lg:[&>li]:px-6 lg:first:[&>li]:ms-auto lg:last:[&>li]:me-auto xl:[&>li]:px-9 
                    lg:first:[&>li>*]:border-none lg:first:[&>li>*]:py-0 lg:first:[&>li>*]:h-14 lg:first:[&>li>*]:text-lg lg:first:[&>li>*]:px-0 xl:first:[&>li>*]:text-xl 
                    lg:before:first:[&>li>*]:border-b-[3px] lg:before:first:[&>li>*]:border-b-brand_primary lg:hover:before:first:[&>li>*]:w-full lg:before:first:[&>li.active>*]:w-full lg:before:first:[&>li>*]:ease-in-out lg:before:first:[&>li>*]:duration-200 lg:before:first:[&>li>*]:absolute lg:before:first:[&>li>*]:w-0 lg:before:first:[&>li>*]:bottom-0 lg:before:first:[&>li>*]:left-0 
                    lg:[&>li.active]:after:content-none 
                    lg:[&>li>ul]:flex lg:[&>li>ul]:border-t lg:[&>li>ul]:py-6 lg:[&>li>ul]:shadow-[0_5px_5px_-5px_rgba(0,0,0,0.25)] lg:[&>li>ul]:bg-white lg:[&>li>ul]:bg-gradient-to-b lg:[&>li>ul]:from-brand_faint-white lg:[&>li>ul]:to-50% lg:[&>li>ul]:-translate-y-[104%] lg:[&>li:hover>ul]:translate-y-0 lg:[&>li>ul]:absolute lg:[&>li>ul]:top-14 lg:[&>li>ul]:w-full lg:[&>li>ul]:left-0 lg:[&>li>ul]:flex-row lg:[&>li>ul]:justify-center lg:[&>li>ul]:transition-transform lg:[&>li>ul]:-z-[1] 
                    lg:[&>li>ul>li]:mb-0 lg:[&>li>ul>li]:px-6 xl:[&>li>ul>li]:px-10 lg:[&>li>ul>li]:border-e last:lg:[&>li>ul>li]:border-none lg:[&>li>ul>li]:border-brand_grey-light 
                    lg:[&>li>ul>li>span]:px-0 lg:[&>li>ul>li>span]:mb-1.5 
                    lg:[&>li>ul>li>ul]:px-0 
                    lg:[&>li>ul>li>ul_a]:inline-block xl:[&>li>ul>li>ul_a]:text-md [&>li>ul>li>ul_a.active]:font-semibold
                "
            >
               { menu }
            </ul>
        );
    };

    const renderChildMenu = (item: IMenuItem): any => {
        if (!item.Children) {
            return null;
        }

        const childMenuItems = item.Children.map((item: IMenuItem) => {
            if (!isOrgMulti && item.IsOrgMulti) {
                return null;
            }

            if (item.IsHeader) {
                return (
                    <li role="presentation" key={`submenu-item-${item.Slug}`}>
                        { renderMenuItem(item) }
                        { renderChildMenu(item) }
                    </li>
                );
            }  

            return renderChildMenuItem(item);
        });

        return (
            <ul role="presentation" key={`submenu-${item.Slug}`}>
                { childMenuItems }
            </ul>
        );
    };

    const renderLink = (item: IMenuItem) => {
        const { MenuText, Route, IsExternal, Slug } = item;

        if (IsExternal) {
            return (
                <a href={Route} target="_blank" rel="noreferrer">
                    { MenuText }
                </a>
            );
        }

        const linkTotal = getLinkTotal(menuTotals, Slug);

        return (
            <NavLink 
                className = {() => (item.PageID === activePageItem?.PageID  ? 'link active' : 'link')}
                to={Route}
                onClick={() => setActivePageItem(item)}
            >
                <span>{ MenuText } { isDefined(linkTotal) ? `(${linkTotal})` : null}</span>
            </NavLink>
        );
    };

    const renderHeading = (item: IMenuItem) => {
        return <span>{ item.MenuText }<FontAwesomeIcon icon={faChevronRight} className="transition-all text-brand_grey-medium h-3 w-3 ms-3 lg:ms-1.5 xl:h-3.5 xl:w-3.5" /></span>;
    };

    const renderMenuItem = (item: IMenuItem) => {
        if (!canViewTestFeatures && item.IsTestFeature) {
            return null;
        }

        return item.Route ? renderLink(item) : renderHeading(item);
    };

    const renderChildMenuItem = (item: IMenuItem) => {
        if (!canViewTestFeatures && item.IsTestFeature) {
            return null;
        }

        return (
            <li className={item.PageID === activePageItem?.PageID ? 'active' : ''} role="presentation" key={`${item.Slug}-submenu-item`}>
                { renderLink(item) }
            </li>
        );
    };

    return (
        <nav className={`z-10 bg-brand_faint-blue absolute h-screen w-72 transition-transform shadow-[0_5px_5px_0_rgba(0,0,0,0.25)] sm:w-96 lg:relative lg:h-auto lg:w-auto lg:shadow-[0_5px_5px_-5px_rgba(0,0,0,0.25)] lg:translate-x-0 ${showNav ? 'z-100 !translate-x-0' : '-translate-x-72 sm:-translate-x-96'}`}>
            <button 
                role="button" 
                className="absolute right-0 lg:hidden" 
                onClick={handleLinkClick}
            >
                <FontAwesomeIcon icon={faXmarkCircle} className="cursor-pointer h-4 w-4 px-3 py-4 sm:px-4" />
            </button>
            { renderMenu() }
        </nav>
    );
};

export default Menu;
