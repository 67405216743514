import React from 'react';
import { FormInput } from '../../../../../../components/common/form_input';
import { FormRadioGroup } from '../../../../../../components/common/form_radio_group';
import { onlyNumeric, yesNoOptions } from '../../../helpers';
import { ProfileCompletenessTag } from '../../components/profile_completeness_tag';
import { SectionHeading } from '../../components/section_heading';

const PaymentTypesTab = () => {
    const inline = true;

    return (
        <>
            <SectionHeading title="Payment Types" />
            <div className="grid gap-3 lg:gap-5 2xl:gap-6 px-3 sm:px-4 2xl:px-5">
                <FormInput
                    allowedPattern={onlyNumeric}
                    layout="horizontal"
                    info={<ProfileCompletenessTag isVisible={false} />}
                    inlineLabel={false}
                    label="% of residents/patients who use private insurance to pay for care"
                    maxWidth='5rem'
                    name="PrivateInsurancePc"
                    suffix="%"
                    type="number"
                />
                <FormRadioGroup
                    groupLabel="Managed care contracts accepted as in-network?"
                    info={<ProfileCompletenessTag isVisible={true} />}
                    layout="horizontal"
                    name="ManagedCareContractsInNetwork"
                    options={yesNoOptions}
                    showResetButton={true}
                />
                <FormRadioGroup
                    groupLabel="Do you accept Medicare Advantage plans?"
                    info={<ProfileCompletenessTag isVisible={true} />}
                    layout="horizontal"
                    name="AcceptMedicareAdvantage"
                    options={yesNoOptions}
                    showResetButton={true}
                />
                <FormRadioGroup
                    groupLabel="Do you accept Medicaid pending patients?"
                    info={<ProfileCompletenessTag isVisible={true} />}
                    layout="horizontal"
                    name="AcceptMedicaidPending"
                    options={yesNoOptions}
                    showResetButton={true}
                />
            </div>
        </>
    );
};

export { PaymentTypesTab };
