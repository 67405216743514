import React, { FC } from 'react';

import cx from 'classnames';

interface IProps {
    className?: string;
}

const NursingHomesLogo: FC<IProps> = ({ className }) => {
    const imageClassName = cx('logo-large', className);
    return (
        <img
            src="/logo.svg"
            className={imageClassName}
            alt="Portal"
        />
    );
};

export { NursingHomesLogo };
