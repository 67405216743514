import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingDots } from '../../../components/common/loading_dots';
import { 
    IPropertyCoreDetailInsuranceType,
    IPropertyCoreDetailPayload,
    IPropertyInsuranceTypesPayloadItem,
} from '../../../interfaces/property';
import * as nhAPI from '../../../api/property';
import { updateProfileCompleteness } from '../../../utils/profile_completeness';
import { Toast, ToastType, showToast } from '../../../utils/toast';
import coreDetailsFormSchema from './property_core_details_form.validation';
import * as formCommon from './property_core_detail_cost_funding_form common';
import { Button } from '../../../components/common/button';

interface ICoreDetailFormProps {
    nhID: number
}

const PropertyCoreDetailsForm = ({ nhID }: ICoreDetailFormProps) => {
    const [ isLoading, setIsLoading ] = useState<boolean>();
    const [ isEdit, setIsEdit] = useState<boolean>(false);
    const [ ,setInsuranceTypes ] = useState<IPropertyCoreDetailInsuranceType[]>([]);

    const onSubmit = async (e: any) => {
        const { ...coreDetails } = e;

        const insuranceTypes: IPropertyInsuranceTypesPayloadItem[] = [];
        Object.keys(coreDetails).forEach((key: string) => {
            if (key.startsWith('InsuranceType')) {
                const propertyInsuranceTypeID = Number(key.split('_')[1]);
                const isActive = coreDetails[key];

                if (isActive) {
                    insuranceTypes.push({
                        PropertyInsuranceTypeID: propertyInsuranceTypeID,
                        IsActive: isActive === '1' ? true : false
                    });                    
                }

                delete coreDetails[key];
            }
        });

        delete coreDetails['LastUpdated'];
        delete coreDetails['CoreDetailsLastUpdated'];
        delete coreDetails['CostFundingLastUpdated'];

        const payload: IPropertyCoreDetailPayload = {
            ...coreDetails,
            InsuranceTypes: insuranceTypes.length ? insuranceTypes : null
        };

        setIsLoading(true);

        try {
            await (nhAPI as any)[`${isEdit ? 'update' : 'create'}PropertyCoreDetailData`](nhID, payload);
            showToast(`Care Offered details successfully ${isEdit ? 'updated' : 'created'}. Please allow up to 60 minutes for the changes to show on the website.`, ToastType.Success);

            initForm();
                
            window.scrollTo(0, 0);
        } catch(err) {
            showToast('An error occurred saving Core Details.', ToastType.Error);
        } finally {
            if (!isEdit) {
                setIsEdit(true);
            }
            
            setIsLoading(false);
            updateProfileCompleteness();
        }
    };

    const getFormDefaults = async (): Promise<any> => {

        setIsLoading(true); 
        const [formDefaults, insuranceTypes] = await formCommon.getFormDefaults(nhID);

        if (insuranceTypes) {
            setInsuranceTypes(insuranceTypes); 
        }

        setIsLoading(false);

        if (formDefaults.NHID) {
            setIsEdit(true);

        }

        return formDefaults;
    };

    const { 
        register,
        handleSubmit,
        formState: { errors, isDirty },
        watch,
        getValues,
        setValue,
        trigger,
        reset
    } = useForm({
        resolver: yupResolver(coreDetailsFormSchema), 
        defaultValues:  async () => await getFormDefaults(),
    });
    
    const initForm = async () => {
        setIsEdit(false);
        const defaults = await getFormDefaults();
        reset({...defaults});
    };

    useEffect(() => {
       initForm();
    }, [ nhID ]);

    const watchMinAgeRequirement  = watch('MinAgeRequirement');
    const watchPediatricMaxAgeRequirement = watch('PediatricMaxAgeRequirement');
    const watchRespiteMinStayRequirement = watch('RespiteMinStayRequirement');
    const watchAdmitPediatricResidents = watch('AdmitPediatricResidents');
    const watchAdmitPediatricResidentsSelected = watchAdmitPediatricResidents === '1';

    useEffect(() => {
        if (watchMinAgeRequirement !== '1') {
            setValue('MinAge', null);
            trigger('MinAge');
        }

        if (watchPediatricMaxAgeRequirement !== '1') {
            setValue('PediatricMaxAge', null);
            trigger('PediatricMaxAge');
        }

        if (watchRespiteMinStayRequirement !== '1') {
            setValue('RespiteMinStay', null);
            trigger('RespiteMinStay');
        }
    }, [watchMinAgeRequirement, watchPediatricMaxAgeRequirement, watchRespiteMinStayRequirement]);

    return (
        <>
            <Toast></Toast>
            {isLoading ? <LoadingDots /> : null}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid gap-3 sm:gap-5 md:gap-6 2xl:gap-7">
                    <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg">
                        <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 2xl:py-3 min-h-14 flex items-center">
                            <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                                Care Offered
                            </h4>
                        </div>
                        <div className="p-3 sm:p-4 2xl:p-5 h-full">
                            <div className="grid gap-6 lg:gap-8 2xl:gap-10">
                                { formCommon.renderRadioGroup(register, setValue, [
                                        { Label: 'Long term care', Key: 'LongTermCare', IncludedInProfileCompleteness: true },
                                        { Label: 'Short term care', Key: 'ShortTermCare', IncludedInProfileCompleteness: true },
                                        { Label: 'Respite care', Key: 'RespiteCare', IncludedInProfileCompleteness: true }
                                    ], false)
                                }
                                { formCommon.renderOptionalDuration(register, errors, setValue, watch, { Label: 'Is there a minimum age requirement?', Key: 'MinAge', DurationUnit: 'years', IncludedInProfileCompleteness: true }, false) }
                                { formCommon.renderRadioGroup(register, setValue, [
                                        { Label: 'Facility Has Memory Care Unit', Key: 'FacilityHasMemoryCareUnit', IncludedInProfileCompleteness: true },
                                        { Label: 'Facility Has Behavioural Health Unit', Key: 'FacilityHasBehaviouralHealthUnit', IncludedInProfileCompleteness: true }
                                    ], false)
                                }
                                { formCommon.renderText(register, errors, [{ Label: 'Any other speciality/niche services?', Key: 'OtherFacilityTypes' }], true)}
                                { formCommon.renderText(register, errors, [ { Label: 'Which hospitals is the facility affiliated with?', Key: 'AffiliatedHospitals' } ], true) }
                                { formCommon.renderPercent(register, errors, [{ Label: 'Average % of short-term residents per month', Key: 'AvgShortTermResidentsPc', IncludedInProfileCompleteness: true }], false) }
                                { formCommon.renderDuration(register, errors, { Label: 'Average short-term stay duration', Key: 'AvgShortStayDuration', DurationUnit: 'days' }, false) }
                                { formCommon.renderPercent(register, errors, [{ Label: 'Average % of long-term residents per month', Key: 'AvgLongTermResidentsPc', IncludedInProfileCompleteness: true }], false) }
                                { formCommon.renderDuration(register, errors, { Label: 'Average long-term stay duration', Key: 'AvgLongTermStayDuration', DurationUnit: 'months' }, false) }
                            </div>
                        </div>
                    </div>   
                    <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg">
                        <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 2xl:py-3 min-h-14 flex items-center">
                            <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                                Pediatric Residents
                            </h4>
                        </div>
                        <div className="p-3 sm:p-4 2xl:p-5 h-full">
                            <div className="grid gap-6 lg:gap-8 2xl:gap-10">
                                { formCommon.renderRadioGroup(register, setValue, [ { Label: 'Do you admit pediatric residents?', Key: 'AdmitPediatricResidents', IncludedInProfileCompleteness: true } ], false) }
                                { 
                                    formCommon.renderPercent(register, errors, [{ 
                                        Label: 'Average % of short-term pediatric residents per month', 
                                        Key: 'PediatricAvgShortTermResidentsPc',
                                        IncludedInProfileCompleteness: watchAdmitPediatricResidentsSelected 
                                    }], !watchAdmitPediatricResidentsSelected) }
                                { formCommon.renderDuration(register, errors, { Label: 'Average short-term pediatric stay duration', Key: 'PediatricAvgShortStayDuration', DurationUnit: 'days' }, !watchAdmitPediatricResidentsSelected) }
                                { 
                                    formCommon.renderPercent(register, errors, [{ 
                                        Label: 'Average % of long-term pediatric residents per month', 
                                        Key: 'PediatricAvgLongTermResidentsPc', 
                                        IncludedInProfileCompleteness: watchAdmitPediatricResidentsSelected 
                                    }], !watchAdmitPediatricResidentsSelected) }
                                { formCommon.renderDuration(register, errors, { Label: 'Average long-term pediatric stay duration', Key: 'PediatricAvgLongTermStayDuration', DurationUnit: 'months' }, !watchAdmitPediatricResidentsSelected) }
                                { formCommon.renderOptionalDuration(register, errors, setValue, watch, { Label: 'Is there a maximum age requirement for pediatric residents?', Key: 'PediatricMaxAge', DurationUnit: 'years' }, false) }
                            </div>
                        </div>
                    </div>
                    <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg">
                        <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 2xl:py-3 min-h-14 flex items-center">
                            <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                                Room Type
                            </h4>
                        </div>
                        <div className="p-3 sm:p-4 2xl:p-5 h-full">
                            <div className="grid gap-6 lg:gap-8 2xl:gap-10">
                                {                  
                                    formCommon.renderRadioGroup(register, setValue, [
                                        { Label: 'Shared', Key: 'RoomTypeShared', IncludedInProfileCompleteness: true },
                                        { Label: 'Private Room', Key: 'RoomTypePrivateRoom', IncludedInProfileCompleteness: true },
                                        { Label: 'Semi Private', Key: 'RoomTypeSemiPrivate', IncludedInProfileCompleteness: true },
                                        { Label: 'Private Suite', Key: 'RoomTypePrivateSuite', IncludedInProfileCompleteness: true },
                                    ], false)
                                }
                                { formCommon.renderText(register, errors, [{ Label: 'Any other room types?', Key: 'RoomTypeOther' } ]) }
                            </div>
                        </div>
                    </div>
                </div>
                <Button 
                    type="submit" 
                    className="fixed bottom-6 left-1/2 -translate-x-1/2 shadow-xl ring-4 ring-white"
                    disabled={!isDirty || isLoading}
                    isLoading={isLoading}
                >
                    Update
                </Button>
            </form>
        </>
    );
};

export default PropertyCoreDetailsForm;
