import React, { useState } from 'react';
import { Modal } from '../../components/common/modal';
import { IPortalPropertyPhotoObj } from '../../interfaces/asset';
import { useForm } from 'react-hook-form';
import { LoadingDots } from '../common/loading_dots';
import { Button } from '../common/button';
import cx from 'classnames';

interface IProps {
    photoDetail: IPortalPropertyPhotoObj;
    handleClose(): void;
    handlePhotoCaptionUpdate(assetID: number, tagID: number, photoCaption: string): void;
}

interface FormInputs {
    PhotoCaption: string;
}

const PhotoCaptionUpdateModal = ({
    photoDetail, 
    handleClose, 
    handlePhotoCaptionUpdate   
}: IProps) => {

    const defaultPhotoCaption = photoDetail.Title ? photoDetail.Title : '';
    const [ busy, setBusy ] = useState<boolean>(false);
    const { register, formState: { errors }, handleSubmit } = useForm<FormInputs>();

    const onSubmit = async (data: any) => {
        setBusy(true);

        const { 
            PhotoCaption            
        } = data;

        await handlePhotoCaptionUpdate(photoDetail.AssetID, photoDetail.TagID, PhotoCaption);
        setBusy(false);
    };

    const renderForm = () => {
        if (busy) return (
            <LoadingDots show={true} />
        );
        return (
            <form onSubmit={handleSubmit(onSubmit)} id="photo_caption_modal">
                <div className={cx('form-component form-inline form-textbox',{ 'form-error' : errors.PhotoCaption })}> 
                    <label 
                        htmlFor="PhotoCaption"
                    >
                        Photo Caption
                    </label>
                    <input
                        {
                            ...register('PhotoCaption', )
                        }
                        type="text"
                        id="PhotoCaption"
                        defaultValue={defaultPhotoCaption}
                    />
                </div>
            </form>
        );
    };

    const actionButtons = (
        <Button 
            type="submit"
            form="photo_caption_modal"
        >
            Update Caption
        </Button>        
    );

    return (
        <Modal
            isOpen={true}
            onClose={() => handleClose()}
            title={<>Update Photo Caption For Photo ID: {photoDetail.AssetID}</>}
            footerContent={actionButtons}
            size='md'
            blurBackdrop
        >
            { renderForm() }
        </Modal>
    );
};

export default PhotoCaptionUpdateModal;