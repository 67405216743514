import * as yup from 'yup';
import { validationHelpers } from '../../../helpers';

const {
    yupNullableStringToNumberPc,
    yupNullableStringToNumberLT100,
    yupNullableStringToNumberLT1000,
    yupStringToNumberLT100,
    yupNullableStringToBool
} = validationHelpers;

const schema = yup.object().shape({
    PediatricMaxAgeRequirement: yupNullableStringToBool,
    PediatricMaxAge: yup.string().when('PediatricMaxAgeRequirement', {
        is: (val: any) => val === true || val === '1' || val === 'Yes',
        then: () => yupStringToNumberLT100.required(''),
        otherwise: () => yupNullableStringToNumberLT100
    }),
    PediatricAvgLongTermStayDuration: yupNullableStringToNumberLT100,
    PediatricAvgLongTermResidentsPc: yupNullableStringToNumberPc,
    PediatricAvgShortStayDuration: yupNullableStringToNumberLT1000,
    PediatricAvgShortTermResidentsPc: yupNullableStringToNumberPc,
    AdmitPediatricResidents: yupNullableStringToBool,

});

export default schema;