import React, { FC } from 'react';
import { ISessionProperty } from '../../../../../../../interfaces/session';
import { FormInput } from '../../../../../../../components/common/form_input';
import { buildPropertyOptions } from '../../helpers';
import { useSessionState } from '../../../../../../../hooks/use_session_state';
import { FormSelect } from '../../../../../../../components/common/form_select';

interface Props {
    properties?: ISessionProperty[];
}

const FormFacilitySelect: FC<Props> = () => {
    const sessionState = useSessionState();
    const properties = sessionState.Properties;
    const isOrg = !!sessionState.Org;

    const isSinglePropertyAccount = !isOrg && properties.length === 1;

    const selector = (
        <FormSelect
            name="propertyId"
            options={buildPropertyOptions(sessionState.Properties)}
            label="Facility"
            maxWidth="460px"
        />
    );

    const hiddenInput = (
        <FormInput name="propertyId" type="hidden" value={properties[0].NHID} />
    );

    // In single facility accounts the only (first) facility is selected in a hidden input
    // otherwise the selector appears for the the user to pick the facility
    return isSinglePropertyAccount ? hiddenInput : selector;
};

export { FormFacilitySelect };
