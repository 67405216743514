import React, { FC, useState } from 'react';
import { Button, ButtonProps } from '../../../../components/common/button';
import { Modal } from '../../../../components/common/modal';
import BookATourForm from '../book_a_tour_form/book_a_tour_form';
import { ITourDefaultValues } from '../types';
import { failedToSaveTourError, prepareDataForForm } from '../helpers';
import { ModalTitle } from '../../../../components/common/modal/modalTitle';
import { useSessionPropertiesMap } from '../../../../hooks/useSessionPropertiesMap';
import { longFormatDate } from '../../../../utils/date';
import { useUpdateTourDetails } from '../hooks/useUpdateTourDetails';
import { usePremiumStatus } from '../../../../hooks/use_premium_status';

interface Props extends ITourDefaultValues {
    styleType?: ButtonProps['styleType'];
    className?: string;
}

const EditTourAvailabilityModal: FC<Props> = (props) => {
    const { styleType = 'solid', className, NHID, UpdatedAt } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggleModal = () => setIsModalOpen((isOpen: boolean) => !isOpen);
    const defaultValues = prepareDataForForm(props);
    const hasExistingTourAvailability = !!defaultValues?.TourAvailabilityID;
    const formId = 'update-team-member';
    const propertyDictionary = useSessionPropertiesMap();
    const propertyName = propertyDictionary?.[NHID]?.Name || '';

    const { isPremium } = usePremiumStatus(NHID);
    const { isPending, mutate } = useUpdateTourDetails(NHID);

    const actionButtons = (
        <Button type="submit" form={formId} isLoading={isPending}>
            Save Changes
        </Button>
    );

    const handleSubmit = (values: any) => {
        mutate(values, { onError: failedToSaveTourError });
        toggleModal();
    };

    const lastUpdatedText = `Last Updated: ${
        UpdatedAt ? longFormatDate(UpdatedAt) : 'No updates made'
    }`;
    const modalTitle = (
        <ModalTitle
            title={`${
                hasExistingTourAvailability ? 'Edit' : 'Add'
            } Tour Availabilty`}
            rightSideText={propertyName}
            subtitle={hasExistingTourAvailability ? lastUpdatedText : ''}
        />
    );

    return (
        <>
            <Modal
                isOpen={isModalOpen}
                onClose={toggleModal}
                title={modalTitle}
                footerContent={actionButtons}
                size="xl"
            >     
                <BookATourForm
                    id={formId}
                    defaultFormValues={defaultValues}
                    handleSubmit={handleSubmit}
                />
            </Modal>
            {isPremium ? (
                <Button
                    styleType={styleType}
                    className="ms-auto"
                    onClick={toggleModal}
                >
                    {hasExistingTourAvailability ? 'Edit' : 'Add'}
                </Button>
            ) : (
                <div className="msg msg-warning">
                    Premium Subscription Required
                </div>
            )}
        </>
    );
};

export { EditTourAvailabilityModal };
