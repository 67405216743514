import React from 'react';

export interface IReviewScoreFullFormattedProps {
    value?: number;
    showAsNotApplicableOnNull?: boolean;
    showAsPlaceholder?: boolean;
}

const ReviewScoreFullFormatted = ({    
    value,
    showAsNotApplicableOnNull = false,
    showAsPlaceholder = false
}: IReviewScoreFullFormattedProps) => {  
    if (isNaN(value)) {
        return <></>;
    }

    if (showAsPlaceholder) {
        return <span>X.X</span>;
    }

    if (!value) {
        if (showAsNotApplicableOnNull) {
            return <span>N/A</span>;
        }

        return <></>;
    }

    const displayValue = value < 10 ? value.toFixed(3) : 10;
        
    return <span>{ displayValue }</span>;
};

export default ReviewScoreFullFormatted;

