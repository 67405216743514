import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { FormRadioGroup } from '../../../../../../components/common/form_radio_group';
import { yesNoOptions } from '../../../helpers';
import { SectionHeading } from '../../components/section_heading';

const InsuranceProvidersAcceptedTab = () => {
    const { fields } = useFieldArray({
        name: 'InsuranceTypes'
    });

    return (
        <>
            <SectionHeading
                title="Insurance Providers Accepted"
            />
            <div className="grid gap-3 lg:gap-5 2xl:gap-6 px-3 sm:px-4 2xl:px-5">
                {fields?.map((field: any, index: number) => (
                    <div key={index}>
                        <FormRadioGroup
                            name={`InsuranceTypes.[${index}].IsActive`}
                            options={yesNoOptions}
                            groupLabel={field.Name}
                            layout="horizontal"
                        />
                    </div>
                ))}
            </div>
        </>
    );
};

export { InsuranceProvidersAcceptedTab };
