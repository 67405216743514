import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';

// DO WE NEED THEMES ?
//import am4themes_animated from '@amcharts/amcharts5/themes/animated';
//import am4themes_material from '@amcharts/amcharts5/themes/material';
// am4core.useTheme(am4themes_animated);
// am4core.useTheme(am4themes_material);

const chartContainer = 'clusteredChartContainer';

interface IProps {
    title?: string;
    chartData: any[];
    cumulative: boolean;
}

const ReviewsRepliesBarChart = ({ chartData, cumulative, title }: IProps) => { 

    const reviewsCountDataFieldName = cumulative ? 'Reviews' : 'ReviewsCount';
    const repliesCountDataFieldName = cumulative ? 'Replies' : 'RepliesCount';

    const labelBullet = (root: any) => {

        const label = am5.Label.new(root, {
            text: '{valueY}',
            fontSize: 14,
            fill: am5.color(0x000000),
            centerX: am5.percent(50),
            centerY: am5.percent(100),
            populateText: true
        });

        return am5.Bullet.new(root, {
            locationX: 0.5,
            locationY: 1,
            sprite: label
        });
    };

    const fitLabels = (series: any) => {

        // Dynamic label positioning disabled for now..
        // series.columns.template.onPrivate('height', (height: number, target: any) => {
        //     am5.array.each(target.dataItem.bullets, (bullet: any) => {
        //         if (height > 30) {
        //             bullet.set('locationY', 0.5);
        //             bullet.get('sprite').set('centerY', am5.p50);
        //             bullet.get('sprite').set('centerX', am5.p50);
        //         } else {
        //             bullet.set('locationY', 2.5);
        //             bullet.get('sprite').set('centerX', am5.percent(100));
        //             bullet.get('sprite').set('centerY', am5.p50);
        //         }           
        //     });
        // });

        series.columns.template.onPrivate('width', (width: any, target: any) => {
            am5.array.each(target.dataItem.bullets, (bullet: any) => {
                if (width > 10) {
                    bullet.get('sprite').show();
                } else {
                    bullet.get('sprite').hide();
                }
            });
        });
    };

    const location = useLocation();
    const path = location.pathname;
    const isDashboard = path === '/';

    useLayoutEffect(() => {

        const root: any = am5.Root.new(chartContainer);

        const customTheme = am5.Theme.new(root);

        customTheme.rule('Label').setAll({
            fill: am5.color(0x262626),
            fontSize: isDashboard ? '0.85rem' : '0.969rem',
            fontFamily: 'Poppins'
        });

        root.setThemes([customTheme]);
        root._logo.dispose();

        const chart = root.container.children.push( 
            am5xy.XYChart.new(root, {
                panY: false,
                layout: root.verticalLayout,
                paddingTop: 30,
                x: am5.percent(50),
                centerX: am5.percent(50)
            })
        );

        if (title) {
            chart.children.unshift(am5.Label.new(root, {
                html: title,
                x: am5.percent(0),
                centerX: am5.percent(0),
                paddingTop: 0,
                paddingBottom: 30,
                paddingLeft: 0
            }));
        }

        // x line
        const xRenderer = am5xy.AxisRendererX.new(root, {
            minGridDistance: 30,
            strokeOpacity: 1,
            strokeWidth: 1,
            stroke: am5.color(0xd0d0d0)
        });

        // x grid
        xRenderer.grid.template.setAll({ location: 1, strokeWidth: 1, stroke: am5.color(0xeaeaea), strokeOpacity: 1 });

        // x labels
        xRenderer.labels.template.setAll({
            textAlign: 'center',
            oversizedBehavior: 'wrap',
            fontSize: isDashboard ? '0.82rem' : '0.9rem',
            breakWords: false,
            paddingTop: isDashboard ? 6 : 12,
            lineHeight: 1
        });

        const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            renderer: xRenderer,
              categoryField: 'displayDate'
            })
        );

        // y line
        const yRenderer = am5xy.AxisRendererY.new(root, {
            strokeOpacity: 1,
            strokeWidth: 1,
            stroke: am5.color(0xd0d0d0),
        });

        // y grid
        yRenderer.grid.template.setAll({ location: 1, strokeWidth: 1, stroke: am5.color(0xeaeaea), strokeOpacity: 1 });

        // y labels
        yRenderer.labels.template.setAll({
            fontSize: isDashboard ? '0.82rem' : '0.9rem',
            paddingRight: isDashboard ? 6 : 12
        });
        
        const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: yRenderer,
            min: 0
        }));

        xAxis.data.setAll(chartData);

        // Create series
        const reviewSeries = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: 'Reviews',
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: reviewsCountDataFieldName,
                categoryXField: 'displayDate'
            })
        );
        
        reviewSeries.set('fill', am5.color(0x007791));
        reviewSeries.set('stroke', am5.color(0x007791));

        reviewSeries.bullets.push(() => labelBullet(root));

        reviewSeries.data.setAll(chartData);
      
        reviewSeries.columns.template.setAll({ 
            cornerRadiusTL: 5, 
            cornerRadiusTR: 5,
           // tooltipText: '{categoryX}: {Reviews.formatNumber(\'#.\')}',
            tooltipY: 0 
        });

        fitLabels(reviewSeries);

        const replySeries = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: 'Replies',
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: repliesCountDataFieldName,
                categoryXField: 'displayDate'
            })
        );

        replySeries.set('fill', am5.color(0xF8D58B));
        replySeries.set('stroke', am5.color(0xF8D58B));

        replySeries.data.setAll(chartData);
      
        replySeries.columns.template.setAll({ 
            cornerRadiusTL: 5, 
            cornerRadiusTR: 5,
            //tooltipText: '{categoryX}: {Replies.formatNumber(\'#.\')}',
            tooltipY: 0 
        });
        
        fitLabels(replySeries);

        replySeries.bullets.push(() => labelBullet(root));

        const legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.p50,
            x: am5.p50,
            y: am5.percent(96),
            //y: isDashboard ? am5.percent(90) : am5.percent(95),
            layout: am5.GridLayout.new(root, {
                maxColumns: 2,
                fixedWidthGrid: false
            }),
            marginTop: isDashboard ? 0 : 20
        }));

        legend.data.setAll(chart.series.values);

        // Add cursor
        //chart.set('cursor', am5xy.XYCursor.new(root, {}));
        
        root.tapToActivate = true;
        root.tapToActivateTimeout = 2000;

        root.current = root;

        return () => {
            root.dispose();
        };
    }, [chartData, cumulative]);

    

    return (
        <div id={chartContainer} className="w-full min-h-96 lg:min-h-[600px]"></div>
    );
};

export default ReviewsRepliesBarChart;
