import React, { useEffect, useState } from 'react';

import PageCommunitySelectWrapper from '../../../components/common/page_community_select_wrapper';
import { LoadingDots } from '../../../components/common/loading_dots';

import { IPropertyVirtualTourRow, IPropertyVirtualTourFormData } from '../../../interfaces/asset'; 
import { useCommunitySelectHistory } from '../../../hooks/use_community_select_history';
import { useUpdatePropertyVirtualTours } from './hooks/useUpdatePropertyVirtualTours';
import { usePropertyVirtualTours } from './hooks/usePropertyVirtualTours';
import PropertyVirtualTourModal from './components/property_virtual_tour_modal';
import PropertyVirtualTourTable from './components/property_virtual_tour_table';
import PropertyVirtualTourForm from './components/property_virtual_tour_form';
import PropertyVirtualTourCard from './components/property_virtual_tour_card';
import { OpType } from '../../../interfaces/common';
import PropertyVirtualTourSaveButton from './components/property_virtual_tour_save_button';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

const PropertyVirtualTour = ({ nhIDs, orgNHID }: IProps) => {

    const formId = 'virtual-tour-form';   
    const cacheKey = 'virtual-tours';

    const [selNHID, setSelNHID] = useState<number>();
    const [showVirtualTourModal, setShowVirtualTourModal] = useState<boolean>(false);
    const [selectedFacility, setSelectedFacility] = useState<IPropertyVirtualTourRow>();

    const formData = (() => {
        if (!selectedFacility) {
            return null;
        }

        return {
            TourURL: selectedFacility.TourURL,
            NHID: selectedFacility.NHID
        };
    })(); 
    
    const handleModalClose = () => {
        setShowVirtualTourModal(false);
        setSelectedFacility(null);
    };
    
    const { data, isLoading,  } = usePropertyVirtualTours([cacheKey]);
    const { mutate } = useUpdatePropertyVirtualTours([cacheKey], selNHID ? null : handleModalClose);

    useEffect(() => {
        const singleNHID = selNHID || (nhIDs?.length === 1 ? nhIDs[0] : null);
        
        if (singleNHID && singleNHID !== selNHID) {
            setSelNHID(singleNHID);
            return;
        }

        if (!selNHID) {
            setSelectedFacility(null);
            return;
        }

        if (!data || isLoading) {
            return;
        }

        const row = data?.rows.find(row => row.NHID === selNHID);

        if (row) {
            setSelectedFacility(row);
        }
    }, [nhIDs, orgNHID, selNHID, data, isLoading]);

    useEffect(() => {
        if (!selectedFacility) {
            return;
        }

        if (!selNHID) {
            setShowVirtualTourModal(true);   
            window.scrollTo(0, 0);
        }
    }, [selectedFacility]); 

    const handleSelect = useCommunitySelectHistory((selNHID: number) => setSelNHID(selNHID));
    const handleSubmit = (data: IPropertyVirtualTourFormData, action: OpType) => mutate({ ...data, Action: action });
    const handleOpenModal = (data: IPropertyVirtualTourRow) => setSelectedFacility(data);
    
    const TourForm = () =>
        <PropertyVirtualTourForm
            handleSubmit={handleSubmit} 
            formData={formData}
            id={formId}
        />;

    return (
        <> 
            <PageCommunitySelectWrapper
                label={'360 Virtual Tour for'}
                handleSelect={handleSelect}
                selNHID={selNHID}
            />

            {isLoading && <LoadingDots show={true} />}
            
            {
                selNHID && selectedFacility ?
                    <PropertyVirtualTourCard selectedFacility={selectedFacility}>
                        <TourForm />
                        <PropertyVirtualTourSaveButton 
                            formId={formId} 
                            className="mt-5 text-end" 
                        />
                    </PropertyVirtualTourCard> :
                    <PropertyVirtualTourTable
                        data={data}
                        handleOpenModal={handleOpenModal}
                    /> 
            }

            <PropertyVirtualTourModal   
                isOpen={showVirtualTourModal}
                handleModalClose={handleModalClose}
                selectedFacility={selectedFacility}
                footerActions={<PropertyVirtualTourSaveButton formId={formId} />}
            >
                <TourForm />
            </PropertyVirtualTourModal>
        </>
    );
};



export default PropertyVirtualTour;