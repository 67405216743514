import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft, faCircle } from '@fortawesome/pro-solid-svg-icons';
import { 
    IPropertyServiceExtras,
    IPropertyServiceExtrasHeader,
    IPropertyExtrasPayloadItem,
    IPropertyService,
    IPropertyServiceCategory,
    IPropertyServiceCategoryType,
    IPropertyServicesPayloadItem,
    IPropertyServiceResource,
    IUpdatePropertyServicesPayload,
    IPropertyServiceCustomExtra,
    IPropertyCustomExtrasPayloadItem
} from '../../interfaces/service';
import { 
    getPropertyServiceCustomExtras,
    getPropertyServiceExtras,
    getPropertyServices,
    updatePropertyServices
} from '../../api/service';

import { ISelectOption } from '../../interfaces/form';
import { LoadingDots } from '../common/loading_dots';
import { Toast, ToastType, showToast } from '../../utils/toast';
import { isDefined } from '../../utils/common';
import { updateProfileCompleteness } from '../../utils/profile_completeness';
import { ProfileCompletenessFilter } from '../../pages/property_details/property_services_and_amenities/services_and_amenities_modal/components/profile_completeness_filter';


interface IProps {
    nhIDs: number[];
    orgNHID: number;
    selNHID: number;
}

const PropertyServiceForm = ({ nhIDs, orgNHID, selNHID }: IProps) => {
    const [
        onlyShowProfileCompletenessFields,
        setOnlyShowProfileCompletenessFields
    ] = useState(null);
    const [ busy, setBusy ] = useState<boolean>(false);
    const [ serviceData, setServiceData ] = useState<IPropertyService[]>();
    const [ serviceCategoryTypes, setServiceCategoryTypes ] = useState<IPropertyServiceCategoryType[]>();
    const [ serviceSelectedValueMap, setServiceSelectedValueMap ] = useState<Map<number, boolean | null>>();
    const [ serviceExtraInitialOptionMap, setServiceExtraInitialOptionMap ] = useState<Map<number, number>>();
    const [ serviceExtraHeaders, setServiceExtraHeaders ] = useState<IPropertyServiceExtrasHeader[]>();
    const [ serviceExtraOptionsMap, setServiceExtraOptionsMap ] = useState<Map<number, ISelectOption[]>>();
    const [ serviceExtraSelectedOptionMap, setServiceExtraSelectedOptionMap ] = useState<Map<number, ISelectOption>>();
    const [ serviceExtrasPayload, setServiceExtrasPayload ] = useState<IPropertyExtrasPayloadItem[]>([]);
    const [ servicePayload, setServicePayload ] = useState<IPropertyServicesPayloadItem[]>([]);

    const [ serviceCustomExtraData, setServiceCustomExtraData ] = useState<IPropertyServiceCustomExtra[]>([]);
    const [ serviceCustomExtraHeaderMap, setServiceCustomExtraHeaderMap ] = useState<Map<number, IPropertyCustomExtrasPayloadItem>>();
    const [ serviceCustomExtraPayload, setServiceCustomExtraPayload ] = useState<IPropertyCustomExtrasPayloadItem[]>([]);

    const [ includedInProfileCompleteness, setIncludedInProfileCompleteness ] = useState<boolean | null>(null);

    useEffect(() => initForm(), [ selNHID ]);

    useEffect(() => {  
        if (!serviceExtraSelectedOptionMap) {
            return;
        }

        buildServiceExtraPayload();
    }, [serviceExtraSelectedOptionMap]);

    useEffect(() => {  
        if (!serviceData) {
            return;
        }

        const modifiedServiceData = isDefined(includedInProfileCompleteness) 
            ? serviceData.filter((service: IPropertyService) => 
                service.IncludedInProfileCompleteness === includedInProfileCompleteness
            )
            : serviceData;

        buildServiceOptions(modifiedServiceData);
    }, [serviceData, includedInProfileCompleteness]);

    useEffect(() => {  
        if (!serviceCustomExtraData) {
            return;
        }

        buildServiceCustomExtraHeaderMap(serviceCustomExtraData);
    }, [serviceCustomExtraData]);

    useEffect(() => {  
        if (!serviceCustomExtraHeaderMap) {
            return;
        }
        
        buildServiceCustomExtraPayload();
    }, [serviceCustomExtraHeaderMap]);

    const handleChange = useCallback(
        (filterProfileCompletnessFields: boolean | null) => {
            setOnlyShowProfileCompletenessFields(
                filterProfileCompletnessFields
            );
        },
        []
    );


    const initForm = () => {
        setServiceExtrasPayload([]);          
        setServicePayload([]);   
        fetchServiceOptionsForProperty();    
    };

    const fetchServiceOptionsForProperty = async () => {
        const [
            services,
            serviceExtras,
            serviceCustomExtras
        ]: [
            IPropertyService[],
            IPropertyServiceExtras[],
            IPropertyServiceCustomExtra[]
        ] = await Promise.all([
            getPropertyServices(selNHID),
            getPropertyServiceExtras(selNHID),
            getPropertyServiceCustomExtras(selNHID)
        ]);
        
        if (services) {
            setServiceData(services);
        }
        if (serviceExtras) {
            buildServiceExtraOptions(serviceExtras);
        }
        if (serviceCustomExtras) {
            setServiceCustomExtraData(serviceCustomExtras);
        }
    };

    const buildServiceCustomExtraHeaderMap = (serviceCustomExtras: IPropertyServiceCustomExtra[]) => {
        const serviceCustomExtraHeadersMap = new Map<number, IPropertyCustomExtrasPayloadItem>;

        serviceCustomExtras
            .filter((item: IPropertyServiceCustomExtra) => item.Description)
            .forEach(({ ResourceCustomExtraHeaderID, Description }: IPropertyServiceCustomExtra) => 
                serviceCustomExtraHeadersMap.set(ResourceCustomExtraHeaderID, {
                    ResourceCustomExtraHeaderID,
                    Description
            })
        );

        setServiceCustomExtraHeaderMap(serviceCustomExtraHeadersMap);
    };

    const buildServiceExtraOptions = async (serviceExtras: IPropertyServiceExtras[]) => {

        const serviceExtraHeadersMap = new Map<number, IPropertyServiceExtrasHeader>;
        const serviceExtrasOptionsMap = new Map<number, ISelectOption[]>;
        const serviceExtraSelectedOptionMap = new Map<number, ISelectOption>;
        const serviceExtraInitialOptionMap = new Map<number, number>;

        serviceExtras.forEach(({
            ResourceExtraHeaderID,
            ResourceExtraHeaderName,
            ResourceExtraOptionID,
            ResourceExtraOptionName,
            IsActive
        }: IPropertyServiceExtras) => {
            if (!serviceExtraHeadersMap.has(ResourceExtraHeaderID)) {
                serviceExtraHeadersMap.set(ResourceExtraHeaderID, {
                    ResourceExtraHeaderID,
                    ResourceExtraHeaderName
                });
            }

            if (!serviceExtrasOptionsMap.has(ResourceExtraHeaderID)) {
                serviceExtrasOptionsMap.set(ResourceExtraHeaderID, []);
            }

            const option: ISelectOption = {
                label: ResourceExtraOptionName,
                value: { ResourceExtraHeaderID, ResourceExtraOptionID }
            };
            
            if (IsActive) {
                serviceExtraSelectedOptionMap.set(ResourceExtraHeaderID, option);
                serviceExtraInitialOptionMap.set(ResourceExtraHeaderID, ResourceExtraOptionID);
            }

            serviceExtrasOptionsMap
                .get(ResourceExtraHeaderID)
                .push({
                    label: ResourceExtraOptionName,
                    value: { ResourceExtraHeaderID, ResourceExtraOptionID }
                });
        });

        const serviceExtraHeaderArr: IPropertyServiceExtrasHeader[] = [...serviceExtraHeadersMap]
            .map(([, ResourceExtraHeader ]): IPropertyServiceExtrasHeader => ResourceExtraHeader
        );

        setServiceExtraHeaders(serviceExtraHeaderArr);
        setServiceExtraOptionsMap(serviceExtrasOptionsMap);
        setServiceExtraSelectedOptionMap(serviceExtraSelectedOptionMap);
        setServiceExtraInitialOptionMap(serviceExtraInitialOptionMap);
    };

    const buildServiceOptions = async (services: IPropertyService[]) => {

        const serviceCategoryTypesMap = new Map<number, IPropertyServiceCategoryType>;
        const serviceSelectedValueMap = new Map<number, boolean | null>;

        services.forEach(({
            ResourceCategoryTypeID,
            ResourceCategoryTypeName,
            ResourceCategoryID,
            ResourceCategoryName,
            ResourceID,
            ResourceName,
            IsActive,
            IncludedInProfileCompleteness
        }: IPropertyService) => {
            if (!serviceCategoryTypesMap.has(ResourceCategoryTypeID)) {
                serviceCategoryTypesMap.set(ResourceCategoryTypeID, {
                    ResourceCategoryTypeID,
                    ResourceCategoryTypeName,
                    CategoryMap: new Map<number, IPropertyServiceCategory>
                });
            }

            const categoryMap = serviceCategoryTypesMap.get(ResourceCategoryTypeID).CategoryMap;

            if (!categoryMap.has(ResourceCategoryID)) {
                categoryMap.set(ResourceCategoryID, {
                    ResourceCategoryID,
                    ResourceCategoryName,
                    Resources: []
                });
            }

            categoryMap.get(ResourceCategoryID).Resources.push({
                ResourceID,
                ResourceName,
                IsActive,
                IncludedInProfileCompleteness
            });

            serviceSelectedValueMap.set(ResourceID, IsActive);
        });

        const serviceCategoryTypesArr: IPropertyServiceCategoryType[] = [...serviceCategoryTypesMap]
            .map(([, ResourceCategoryType]): IPropertyServiceCategoryType => ResourceCategoryType
        );

        setServiceCategoryTypes(serviceCategoryTypesArr);
        setServiceSelectedValueMap(serviceSelectedValueMap);
    };


    const handleServiceExtraChanged = (
        resourceExtraHeaderID: number,
        option: ISelectOption
    ) => {
        const modifiedSelectedOptionMap = new Map(serviceExtraSelectedOptionMap);

        modifiedSelectedOptionMap.set(resourceExtraHeaderID, option);
        setServiceExtraSelectedOptionMap(modifiedSelectedOptionMap);
    };

    const handleServiceExtraRemoved = (resourceExtraHeaderID: number) => {
        const modifiedSelectedOptionMap = new Map(serviceExtraSelectedOptionMap);

        modifiedSelectedOptionMap.delete(resourceExtraHeaderID);
        setServiceExtraSelectedOptionMap(modifiedSelectedOptionMap);
    };

    const handleServiceCustomExtraChanged = (
        resourceCustomExtraHeaderID: number,
        text: string
    ) => {
        const modifiedCustomExtraHeaderMap = new Map(serviceCustomExtraHeaderMap);

        const description = text?.trim().length ? text : null;

        if (!description) {
            const existingService = serviceCustomExtraData.find((item: IPropertyServiceCustomExtra) => 
                item.ResourceCustomExtraHeaderID === resourceCustomExtraHeaderID
            );

            if (!existingService) {
                modifiedCustomExtraHeaderMap.delete(resourceCustomExtraHeaderID);
                setServiceCustomExtraHeaderMap(modifiedCustomExtraHeaderMap);
                
                return;
            }
        }

        modifiedCustomExtraHeaderMap.set(resourceCustomExtraHeaderID, {
            ResourceCustomExtraHeaderID: resourceCustomExtraHeaderID,
            Description: description
        });

        setServiceCustomExtraHeaderMap(modifiedCustomExtraHeaderMap);
    };

    const handleServiceChanged = (
        resourceID: number,
        currentStatus: boolean | null,
        status: boolean | null
    ) => {
        const modifiedServiceSelectedValueMap = new Map(serviceSelectedValueMap);

        modifiedServiceSelectedValueMap.set(resourceID, status);
        setServiceSelectedValueMap(modifiedServiceSelectedValueMap);

        buildServicePayload(resourceID, currentStatus, status);
    };  

    const buildServiceExtraPayload = () => { 
        const serviceExtrasPayload: IPropertyExtrasPayloadItem[] = [];

        serviceExtraSelectedOptionMap.forEach((option: ISelectOption) => {
            serviceExtrasPayload.push(option.value);
        });

        setServiceExtrasPayload(serviceExtrasPayload);
    };

    const buildServiceCustomExtraPayload = () => { 
        const serviceCustomExtraPayload: IPropertyCustomExtrasPayloadItem[] = [];

        serviceCustomExtraHeaderMap.forEach((item: IPropertyCustomExtrasPayloadItem) => {
            serviceCustomExtraPayload.push(item);
        });

        setServiceCustomExtraPayload(serviceCustomExtraPayload);
    };

    const buildServicePayload = (
        resourceID: number,
        currentStatus: boolean | null,
        status: boolean | null
    ) => {
        const modifiedServicePayload = [ ...servicePayload ];

        const existingndex = modifiedServicePayload.findIndex((resource: IPropertyServicesPayloadItem) => 
            resource.ResourceID === resourceID
        );

        if (existingndex > -1) {
            modifiedServicePayload.splice(existingndex, 1);
        }

        if (
            status !== currentStatus &&
            (currentStatus !== null || status !== null)
        ) {
            modifiedServicePayload.push({
                ResourceID: resourceID,
                Status: status
            });
        }

        setServicePayload(modifiedServicePayload);
    };

    const handleSubmit = async () => {
        const payload: IUpdatePropertyServicesPayload = {};

        if (serviceCustomExtrasUpdated) {
            payload.ServiceCustomExtras = serviceCustomExtraPayload;
        }

        if (serviceExtrasUpdated) {
            payload.ServiceExtras = serviceExtrasPayload;
        }

        if (servicePayload.length) {
            payload.Services = servicePayload;
        }

        if ((Object.keys(payload).length)) {
            setBusy(true);

            try {
                await updatePropertyServices(selNHID, payload);
                initForm();
                
                window.scrollTo(0, 0);

                showToast('Services & Amenities updated successfully. Please allow up to 60 minutes for the changes to show on the website.', ToastType.Success);
            } catch(e) {
                showToast('An error occurred saving Services & Amenities', ToastType.Error);
                console.log(e);
            } finally {
                setBusy(false);
                updateProfileCompleteness();
            }
        }
    };

    const serviceExtrasUpdated = ((): boolean => {
        if (!serviceExtraInitialOptionMap) {
            return false;
        }

        if (serviceExtraInitialOptionMap.size !== serviceExtrasPayload.length) {
            return true;
        }

        let dataChanged = false;
        for (const payloadItem of serviceExtrasPayload) {
            const { ResourceExtraHeaderID, ResourceExtraOptionID } = payloadItem;

            if (serviceExtraInitialOptionMap.get(ResourceExtraHeaderID) !== ResourceExtraOptionID) {
                dataChanged = true;
                break;
            }
        }

        return dataChanged;
    })();

    const serviceCustomExtrasUpdated = ((): boolean => {
        if (!serviceCustomExtraPayload) {
            return false;
        }

        let dataChanged = false;
        for (const payloadItem of serviceCustomExtraPayload) {
            const { ResourceCustomExtraHeaderID, Description } = payloadItem;

            const existingService = serviceCustomExtraData.find((item: IPropertyServiceCustomExtra) => 
                item.ResourceCustomExtraHeaderID === ResourceCustomExtraHeaderID
            );

            if (existingService.Description !== Description) {
                dataChanged = true;
                break;
            }
        }

        return dataChanged;
    })();

    const renderServiceExtraSection = ({
        ResourceExtraHeaderID,
        ResourceExtraHeaderName
    }: IPropertyServiceExtrasHeader) => {
        const key = `service-extra-header-${ResourceExtraHeaderID}`;

        return (
            <div className="flex flex-col gap-1 lg:flex-row lg:gap-2 lg:items-center lg:flex-wrap" key={key}>
                <strong className="font-medium lg:min-w-[400px] lg:max-w-[400px] lg:text-lg">
                    { ResourceExtraHeaderName }
                </strong>
                <div className="flex flex-col gap-1.5 sm:flex-row sm:items-center sm:gap-6 lg:gap-10 flex-grow">
                    <div className="flex flex-wrap items-center gap-x-4 gap-y-2 md:gap-x-5 w-full max-w-[600px]">
                        <div className="form-component form-select flex-grow">
                            <Select 
                                id={key}
                                onChange={(option: any) => handleServiceExtraChanged(ResourceExtraHeaderID, option)}
                                options={serviceExtraOptionsMap.get(ResourceExtraHeaderID)}
                                name={key}
                                classNamePrefix="select"
                                value={serviceExtraSelectedOptionMap.get(ResourceExtraHeaderID) || null}
                                unstyled
                                isSearchable={false}
                                /*menuIsOpen={true}*/
                            />
                        </div>
                        <button
                            title="Reset"
                            onClick={() => handleServiceExtraRemoved(ResourceExtraHeaderID)}
                            className="rounded-full bg-brand_grey-light min-h-9 min-w-9"
                        >
                            <FontAwesomeIcon icon={faClockRotateLeft} className="h-4 w-4 link relative top-px" />
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    const renderServiceCustomExtraSection = ({
        ResourceCustomExtraHeaderID,
        ResourceCustomExtraHeaderName
    }: IPropertyServiceCustomExtra) => {
        const key = `service-extra-custom-header-${ResourceCustomExtraHeaderID}`;
        const value = serviceCustomExtraHeaderMap.get(ResourceCustomExtraHeaderID)?.Description || '';

        return (
            <div className="flex flex-col gap-1 lg:flex-row lg:gap-2 lg:items-center lg:flex-wrap" key={key}>
                <strong className="font-medium lg:min-w-[400px] lg:max-w-[400px] lg:text-lg">
                    { ResourceCustomExtraHeaderName }
                </strong>
                <div className="flex flex-col gap-1.5 sm:flex-row sm:items-center sm:gap-6 lg:gap-10 flex-grow">
                    <div className="flex flex-wrap items-center gap-x-4 gap-y-2 md:gap-x-5 w-full max-w-[600px]">
                        <div className="form-component form-textbox flex-grow">
                            <input
                                type="text"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleServiceCustomExtraChanged(
                                    ResourceCustomExtraHeaderID, 
                                    e.target.value
                                )}
                                id={key}
                                name={key}
                                maxLength={500}
                                value={value}
                            />
                        </div>
                        <button
                            title="Reset"
                            onClick={() => handleServiceCustomExtraChanged(ResourceCustomExtraHeaderID, null)}
                            className="rounded-full bg-brand_grey-light min-h-9 min-w-9"
                        >
                            <FontAwesomeIcon icon={faClockRotateLeft} className="h-4 w-4 link relative top-px" />
                        </button>
                    </div>
                </div>
            </div>
        );
    };


    const renderServiceSection = ({
        ResourceID,
        ResourceName,
        IsActive,
        IncludedInProfileCompleteness
    }: IPropertyServiceResource) => {
        const key = `service-${ResourceID}`;

        const showAllFields = onlyShowProfileCompletenessFields === null;
        const showProfileCompletenessFields = IncludedInProfileCompleteness && onlyShowProfileCompletenessFields;
        const showNonProfileCompletenessFields = !IncludedInProfileCompleteness && !onlyShowProfileCompletenessFields;
        
        if (!(showAllFields || showProfileCompletenessFields || showNonProfileCompletenessFields)) {
            return null;
        }

        return (
            <div className="flex flex-col gap-1 lg:flex-row lg:gap-2 lg:items-center lg:flex-wrap" key={key}>
                <strong className="font-medium lg:min-w-[400px] lg:max-w-[400px] lg:text-lg">
                    { ResourceName }
                </strong>
                <div className="flex flex-col gap-1.5 sm:flex-row sm:items-center sm:gap-6 lg:gap-10">
                    <div className="flex flex-wrap items-center gap-x-4 gap-y-2 md:gap-x-5">
                        <div className="form-component form-radio">
                            <label>
                                <input
                                    className="peer"
                                    type="radio" 
                                    name={key} 
                                    checked={serviceSelectedValueMap.get(ResourceID) === true} 
                                    onChange={() => handleServiceChanged(
                                        ResourceID, 
                                        IsActive, 
                                        true
                                    )}
                                /> 
                                <FontAwesomeIcon icon={faCircle} className="peer-checked:block"/>
                                <span>
                                    Yes
                                </span>
                            </label> 
                        </div>
                        <div className="form-component form-radio">
                            <label>
                                <input
                                    className="peer"
                                    type="radio" 
                                    name={key} 
                                    checked={serviceSelectedValueMap.get(ResourceID) === false}
                                    onChange={() => handleServiceChanged(
                                        ResourceID, 
                                        IsActive, 
                                        false
                                    )}
                                /> 
                                <FontAwesomeIcon icon={faCircle} className="peer-checked:block"/>
                                <span>
                                    No
                                </span>
                            </label> 
                        </div>  
                    </div>
                    { IncludedInProfileCompleteness ? <span className="sm:[clip-path:polygon(6%_0%,100%_0%,100%_100%,6%_100%,0%_50%)] bg-brand_secondary rounded-md text-white text-2xs font-medium whitespace-nowrap py-1 px-2 sm:ps-4 w-fit">Included in Profile Completeness</span> : null }
                </div>
            </div>
        );
    };

    const renderServiceCategorySection = ({
        ResourceCategoryID,
        ResourceCategoryName,
        Resources
    }: IPropertyServiceCategory) => {
        const key = `service-category-${ResourceCategoryID}`;

        return (
            <div key={key}>
                <h3 className="font-semibold leading-tight text-md lg:text-lg mb-4 lg:mb-6 2xl:mb-8">
                    { ResourceCategoryName }
                </h3>
                <div key={key} className="grid gap-6 lg:gap-8 2xl:gap-10">
                    {
                        Resources.map((resource: IPropertyServiceResource) => ( 
                            renderServiceSection(resource)
                        ))
                    }
                </div>
            </div>            
        );
    };
    
    const renderServiceCategoryTypeSection = ({
        ResourceCategoryTypeID,
        ResourceCategoryTypeName,
        CategoryMap
    }: IPropertyServiceCategoryType) => {
        const key = `service-category-type-${ResourceCategoryTypeID}`;

        const serviceCategoryArr: IPropertyServiceCategory[] = [...CategoryMap]
            .map(([, ResourceCategory]): IPropertyServiceCategory => ResourceCategory
        );

        return (
            <div className="ring-1 ring-brand_grey rounded-md lg:rounded-lg" key={key}>
                <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 2xl:py-3 min-h-14 flex items-center rounded-t-md lg:rounded-t-lg">
                    <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                        { ResourceCategoryTypeName }
                    </h4>
                </div>
                <div className="p-3 sm:p-4 2xl:p-5 h-full">
                    <div className="grid gap-6 lg:gap-8 2xl:gap-10">
                        {
                            serviceCategoryArr.map((category: IPropertyServiceCategory) => ( 
                                renderServiceCategorySection(category)
                            ))
                        }
                    </div>
                </div>
            </div>   
        );
    };
    
    const renderLoading = () => <LoadingDots show={true} />;

    if (!serviceExtraHeaders || !serviceCategoryTypes || !serviceCustomExtraData) {
        return renderLoading();
    }

    const submitDisabled = (!serviceExtrasUpdated && !servicePayload.length && !serviceCustomExtrasUpdated) || busy;

    return (
        <>  
            <Toast></Toast>
            { busy ? renderLoading() : null }
            <div className="grid gap-3 sm:gap-5 md:gap-6 2xl:gap-7">
                <div className="ring-1 ring-brand_grey rounded-md lg:rounded-lg">
                    <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 2xl:py-3 min-h-14 flex items-center rounded-t-md lg:rounded-t-lg">
                        <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                            Misc Services
                        </h4>
                    </div>
                    <div className="p-3 sm:p-4 2xl:p-5 h-full">
                        <div className="grid gap-6 lg:gap-8 2xl:gap-10">
                            { serviceExtraHeaders.map((serviceExtraHeader: IPropertyServiceExtrasHeader) => 
                                renderServiceExtraSection(serviceExtraHeader))
                            }
                            { serviceCustomExtraData.map((serviceCustomExtraHeader: IPropertyServiceCustomExtra) => 
                                renderServiceCustomExtraSection(serviceCustomExtraHeader))
                            }
                        </div>
                    </div>
                </div>   
                <div className="mt-3 sm:mt-4 2xl:mt-5 flex items-center justify-center gap-3">
                    <ProfileCompletenessFilter onChange={handleChange} value={onlyShowProfileCompletenessFields} />
                </div>
                {
                    serviceCategoryTypes.map((categoryType: IPropertyServiceCategoryType) =>
                        renderServiceCategoryTypeSection(categoryType)
                    )
                }
            </div>
            <button
                disabled={submitDisabled}
                className="btn fixed bottom-6 left-1/2 -translate-x-1/2 shadow-xl ring-4 ring-white"
                type="button"
                onClick={handleSubmit}
            >
                Update
            </button>
        </>      
    );
};

export default PropertyServiceForm;