import React, { useState } from 'react';
import PageCommunitySelectWrapper from '../../../components/common/page_community_select_wrapper';
import PropertyServiceTotalsTable from '../../../components/amenities/property_service_totals_table';
import PropertyServiceForm from '../../../components/amenities/property_service_form';
import ProfileCompletenessHeader from '../../../components/profile_completeness/profile_completeness_header';
import { ProfileScoreType } from '../../../interfaces/property';
import { useCommunitySelectHistory } from '../../../hooks/use_community_select_history';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

const PropertyAmenities = ({ nhIDs, orgNHID }: IProps) => {

    const [ selNHID, setSelNHID ] = useState<number>(null);
    const [ profileCompletenessFilterValue, setProfileCompletenessFilterValue ] = useState<boolean | null>(null);

    const singlePropertySession = nhIDs?.length === 1;
    const singleNHID = singlePropertySession ? nhIDs[0] : selNHID;

    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        setSelNHID(selNHID);
    });
    
    return (
        <>
            <PageCommunitySelectWrapper
                label={'All Services & Amenities for'}
                handleSelect={handleSelect}
                selNHID={selNHID}
            />
            <ProfileCompletenessHeader
                selNHID={selNHID}
                nhIDs={nhIDs}
                orgNHID={orgNHID}
                profileScoreType={ProfileScoreType.ServicesAndAmenities}
                handleProfileCompletenessFilter={setProfileCompletenessFilterValue}
                profileCompletenessFilterValue={profileCompletenessFilterValue}
                title="for Services & Amenities"
            />
            <section>
                <div className="container max-w-none 2xl:container mt-4 sm:mt-6 md:mt-8 2xl:mt-10"> 
                    { 
                        !singlePropertySession && !selNHID ? 
                        <div data-table="Services & Amenities" className="relative [&>div]:scrollbar [&>div]:pb-2 [&>div]:-mx-3 [&>div]:w-[calc(100%+1.5rem)] [&>div]:px-3 sm:[&>div]:-mx-4 sm:[&>div]:w-[calc(100%+2rem)] sm:[&>div]:px-4 xl:[&>div]:mx-0 xl:[&>div]:w-full xl:[&>div]:px-0 before:h-full before:top-0 before:absolute before:z-10 before:bg-gradient-to-r before:from-white before:w-3 before:-left-3 after:h-full after:top-0 after:absolute after:z-10 after:bg-gradient-to-l after:from-white after:-right-3 after:w-3 sm:before:-left-4 sm:before:w-4 sm:after:-right-4 sm:after:w-4 xl:before:content-none xl:after:content-none">
                            <PropertyServiceTotalsTable 
                                selNHID={singleNHID} 
                                nhIDs={nhIDs} 
                                orgNHID={orgNHID} 
                                handleSelect={handleSelect} 
                                profileCompletenessFilterValue={profileCompletenessFilterValue}
                            />
                        </div>
                        : 
                        <PropertyServiceForm 
                            selNHID={singleNHID} 
                            nhIDs={nhIDs} 
                            orgNHID={orgNHID} 
                        />
                    }
                </div>
            </section>
        </>
    );
};

export default PropertyAmenities;