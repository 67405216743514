import React from 'react';

export interface IReviewScoreDisplayFormattedProps {
    value?: number;
    showAsText?: boolean;
    showAsNotApplicableOnNull?: boolean;
    showAsPlaceholder?: boolean;
}

const ReviewScoreDisplayFormatted = ({    
    value,
    showAsText = false,
    showAsNotApplicableOnNull = false,
    showAsPlaceholder = false
}: IReviewScoreDisplayFormattedProps) => {  
    if (isNaN(value)) {
        return <></>;
    }

    if (showAsPlaceholder) {
        return <>X.X</>;
    }

    /*  target this 'div' from elsewhere to scale up:
        [&>div]:text-5xl [&>div]:px-6 [&>div]:py-4 [&>div]:rounded-lg
        [&>div]:text-9xl [&>div]:px-9 [&>div]:py-5 [&>div]:rounded-xl
        [&>div]:text-[3.75rem] [&>div]:px-12 [&>div]:py-7 [&>div]:rounded-2xl
    */

    if (!value) {
        if (showAsNotApplicableOnNull) {
            if (showAsText) {
                return <>N/A</>;
            }

            return <div className="bg-brand_secondary text-white flex justify-center text-base items-center rounded-md w-fit px-3.5 py-2.5 font-bold leading-none"><span className="relative top-px">N/A</span></div>;
        }

        return <></>;
    }

    const displayValue = value < 10 ? value.toFixed(1) : value;
        
    if (showAsText) {
        return <>{ displayValue }</>;
    }

    return (
        <div className="bg-brand_secondary text-white flex justify-center text-base items-center rounded-md w-fit px-3 py-2.5 font-bold leading-none">
            <span className="relative top-px">
                { displayValue }
            </span>
        </div>
    );
};

export default ReviewScoreDisplayFormatted;

