import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingDots } from '../../../components/common/loading_dots';
import { 
    IPropertyCoreDetailInsuranceType,
    IPropertyCoreDetailPayload,
    IPropertyInsuranceTypesPayloadItem,
} from '../../../interfaces/property';
import * as nhAPI from '../../../api/property';
import { updateProfileCompleteness } from '../../../utils/profile_completeness';
import { Toast, ToastType, showToast } from '../../../utils/toast';
import coreDetailsFormSchema from './property_core_details_form.validation';
import * as formCommon from './property_core_detail_cost_funding_form common';
import { useNavigate } from 'react-router-dom';
import { costKeyArray } from  './property_core_detail_cost_funding_form common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft } from '@fortawesome/pro-solid-svg-icons';
import { Button } from '../../../components/common/button';

interface ICoreDetailFormProps {
    nhID: number
}

const PropertyCostFundingForm = ({ nhID }: ICoreDetailFormProps) => {
    const [ isLoading, setIsLoading ] = useState<boolean>();
    const [ isEdit, setIsEdit] = useState<boolean>(false);
    const [ insuranceTypes, setInsuranceTypes ] = useState<IPropertyCoreDetailInsuranceType[]>([]);

    const navigate = useNavigate();

    const onSubmit = async (e: any) => {
        const { ...coreDetails } = e;

        const insuranceTypes: IPropertyInsuranceTypesPayloadItem[] = [];
        Object.keys(coreDetails).forEach((key: string) => {
            if (key.startsWith('InsuranceType')) {
                const propertyInsuranceTypeID = Number(key.split('_')[1]);
                const isActive = coreDetails[key];

                if (isActive) {
                    insuranceTypes.push({
                        PropertyInsuranceTypeID: propertyInsuranceTypeID,
                        IsActive: isActive === '1' ? true : false
                    });                    
                }

                delete coreDetails[key];
            }
        });

        delete coreDetails['LastUpdated'];
        delete coreDetails['CoreDetailsLastUpdated'];
        delete coreDetails['CostFundingLastUpdated'];

        const payload: IPropertyCoreDetailPayload = {
            ...coreDetails,
            InsuranceTypes: insuranceTypes.length ? insuranceTypes : null
        };

        setIsLoading(true);

        try {
            await (nhAPI as any)[`${isEdit ? 'update' : 'create'}PropertyCoreDetailData`](nhID, payload);
            showToast('Cost / Funding details successfully updated. Please allow up to 60 minutes for the changes to show on the website.', ToastType.Success);

            initForm();
                
            window.scrollTo(0, 0);
        } catch(err) {
            showToast('An error occurred saving Core Details.', ToastType.Error);
        } finally {
            if (!isEdit) {
                setIsEdit(true);
            }
            
            setIsLoading(false);
            updateProfileCompleteness();
        }
    };

    const getFormDefaults = async (): Promise<any> => {

        setIsLoading(true); 
        const [formDefaults, insuranceTypes] = await formCommon.getFormDefaults(nhID);

        if (insuranceTypes) {
            setInsuranceTypes(insuranceTypes); 
        }

        setIsLoading(false);

        if (formDefaults.NHID) {
            setIsEdit(true);

        }

        return formDefaults;
    };

    const { 
        register,
        handleSubmit,
        formState: { errors, isDirty },
        watch,
        getValues,
        setValue,
        trigger,
        reset
    } = useForm({
        resolver: yupResolver(coreDetailsFormSchema), 
        defaultValues:  async () => await getFormDefaults(),
    });
    
    const initForm = async () => {
        setIsEdit(false);
        const defaults = await getFormDefaults();
        reset({...defaults});
    };

    useEffect(() => {
       initForm();
    }, [ nhID ]);

    const longTermCareSelected = getValues()['LongTermCare'] === '1';
    const shortTermCareSelected = getValues()['ShortTermCare'] === '1';
    const respiteCareSelected = getValues()['RespiteCare'] === '1';

    const minAgeRequirement = getValues()['MinAgeRequirement'] === '1';
    const pediatricMaxAgeRequirement = getValues()['PediatricMaxAgeRequirement'] === '1';
    const respiteMinStayRequirement = getValues()['RespiteMinStayRequirement'] === '1';

    const roomTypeSharedRoom = getValues()['RoomTypeShared'] === '1';
    const roomTypePrivateRoom = getValues()['RoomTypePrivateRoom'] === '1';
    const roomTypeSemiPrivateRoom = getValues()['RoomTypeSemiPrivate'] === '1';
    const roomTypePrivateSuite = getValues()['RoomTypePrivateSuite'] === '1';

    const costKeyWatch = costKeyArray.reduce((acc: any, val: any) => {
        const naKey = `${val}NA`;
        acc[val] = watch(naKey);
        return acc;
    }, {});

    // This needs to be changed, can't put hooks into loops
    for (const costKey of costKeyArray) {
        // eslint-disable-next-line
        useEffect(() => {
            if (costKeyWatch[costKey]) {
                setValue(costKey, null);
                trigger(costKey);
            }
        }, [costKeyWatch[costKey]]);
    }

    useEffect(() => {
        if (!minAgeRequirement) {
            setValue('MinAge', null);
            trigger('MinAge');
        }

        if (!pediatricMaxAgeRequirement) {
            setValue('PediatricMaxAge', null);
            trigger('PediatricMaxAge');
        }

        if (!respiteMinStayRequirement) {
            setValue('RespiteMinStay', null);
            trigger('RespiteMinStay');
        }
    }, [minAgeRequirement, pediatricMaxAgeRequirement, respiteMinStayRequirement]);


    const setTermData = (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        source: string,
        target: string
    ) => {
        e.preventDefault();

        const sourceKeys = [
            'SharedCostPerDay',
            'PrivateCostPerDay',
            'SemiPrivateCostPerDay'
        ];

        const sourceData = getValues(sourceKeys.map((key: string) => `${source}${key}`));

        sourceKeys.forEach((key: string, index: number) => setValue(`${target}${key}`, sourceData[index], {
            shouldDirty: true
        }));
    };

    const roomTypeLink = () => (
        <>
            <span
                className="link"
                onClick={() => {
                    navigate('/core-details', { 
                        state: { 
                            targetId: 'roomtype',
                            targetNHID: nhID
                        } 
                    });
                }} 
            >
                room type
            </span>
        </>
    );

    return (
        <>
            <Toast></Toast>
            {isLoading ? <LoadingDots /> : null}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid gap-3 sm:gap-5 md:gap-6 2xl:gap-7">
                    <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg">
                        <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-3 min-h-14 grid gap-1">
                            <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                                Long Term Care Cost
                            </h4>
                            <span className="block text-sm text-brand_grey-medium">
                                <p>
                                    To edit the below inputs <a href={'/core-details'} className="link">&lsquo;Long Term Care&rsquo;</a> and the relevant { roomTypeLink() } must be set to &lsquo;Yes&rsquo;.
                                </p>
                            </span>
                        </div>
                        <div className="p-3 sm:p-4 2xl:p-5 h-full">
                            <div className="grid gap-6 lg:gap-8 2xl:gap-10">
                                { 
                                    formCommon.renderCostPerDay(register, errors, [{ 
                                        Label: 'Shared room', 
                                        Key: 'LongTermSharedCostPerDay', 
                                        IncludedInProfileCompleteness: longTermCareSelected && roomTypeSharedRoom && !costKeyWatch.LongTermSharedCostPerDay
                                    }], !longTermCareSelected || !roomTypeSharedRoom, costKeyWatch.LongTermSharedCostPerDay, true)}
                                { 
                                    formCommon.renderCostPerDay(register, errors, [{ 
                                        Label: 'Private room', 
                                        Key: 'LongTermPrivateCostPerDay', 
                                        IncludedInProfileCompleteness: longTermCareSelected && roomTypePrivateRoom  && !costKeyWatch.LongTermPrivateCostPerDay
                                    }], !longTermCareSelected || !roomTypePrivateRoom, costKeyWatch.LongTermPrivateCostPerDay, true)}
                                { 
                                    formCommon.renderCostPerDay(register, errors, [{ 
                                        Label: 'Semi-private room', 
                                        Key: 'LongTermSemiPrivateCostPerDay', 
                                        IncludedInProfileCompleteness: longTermCareSelected && roomTypeSemiPrivateRoom && !costKeyWatch.LongTermSemiPrivateCostPerDay
                                    }], !longTermCareSelected  || !roomTypeSemiPrivateRoom, costKeyWatch.LongTermSemiPrivateCostPerDay, true)} 
                                                                { 
                                    formCommon.renderCostPerDay(register, errors, [{ 
                                        Label: 'Private Suite', 
                                        Key: 'LongTermPrivateSuiteCostPerDay', 
                                        IncludedInProfileCompleteness: false
                                    }], !longTermCareSelected  || !roomTypePrivateSuite, false, false)} 
                                {
                                    formCommon.renderRadioGroup(register, setValue, [
                                        { Label: 'Rates are all-inclusive?', Key: 'LongTermRatesAllInclusive' },
                                        { Label: 'Rate packages/bundles available?', Key: 'LongTermRatePackagesAvailable' }
                                    ], !(longTermCareSelected && (roomTypeSharedRoom || roomTypePrivateRoom || roomTypeSemiPrivateRoom)))
                                } 
                            </div>
                        </div>
                    </div>   
                    <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg">
                        <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-3 min-h-14 flex flex-col gap-1">
                            <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                                Short Term Care Cost
                            </h4>
                            <span className="flex flex-wrap gap-y-0.5 gap-x-2 text-sm text-brand_grey-medium">
                                <p>
                                    To edit the below inputs &lsquo;<a href={'/core-details'} className="">Short Term Care</a>&rsquo; and the relevant { roomTypeLink() } must be set to &lsquo;Yes&rsquo;.
                                </p>
                                <a 
                                    href="#" 
                                    className="inline-block link" 
                                    onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => setTermData(e, 'LongTerm', 'ShortTerm')}
                                >
                                    Use Long Term Care Prices
                                </a>
                            </span>
                        </div>
                        <div className="p-3 sm:p-4 2xl:p-5 h-full">
                            <div className="grid gap-6 lg:gap-8 2xl:gap-10">
                                { 
                                    formCommon.renderCostPerDay(register, errors, [{ 
                                        Label: 'Shared room', 
                                        Key: 'ShortTermSharedCostPerDay', 
                                        IncludedInProfileCompleteness: shortTermCareSelected && roomTypeSharedRoom  && !costKeyWatch.ShortTermSharedCostPerDay
                                    }], !shortTermCareSelected || !roomTypeSharedRoom, costKeyWatch.ShortTermSharedCostPerDay, true)}
                                { 
                                    formCommon.renderCostPerDay(register, errors, [{ 
                                        Label: 'Private room', 
                                        Key: 'ShortTermPrivateCostPerDay', 
                                        IncludedInProfileCompleteness: shortTermCareSelected && roomTypePrivateRoom && !costKeyWatch.ShortTermPrivateCostPerDay
                                    }], !shortTermCareSelected||  !roomTypePrivateRoom, costKeyWatch.ShortTermPrivateCostPerDay, true)}
                                { 
                                    formCommon.renderCostPerDay(register, errors, [{ 
                                        Label: 'Semi-private room', 
                                        Key: 'ShortTermSemiPrivateCostPerDay', 
                                        IncludedInProfileCompleteness: shortTermCareSelected && roomTypeSemiPrivateRoom && !costKeyWatch.ShortTermSemiPrivateCostPerDay
                                    }], !shortTermCareSelected || !roomTypeSemiPrivateRoom, costKeyWatch.ShortTermSemiPrivateCostPerDay, true)}
                                                                    { 
                                    formCommon.renderCostPerDay(register, errors, [{ 
                                        Label: 'Private Suite', 
                                        Key: 'ShortTermPrivateSuiteCostPerDay', 
                                        IncludedInProfileCompleteness: false
                                    }], !shortTermCareSelected  || !roomTypePrivateSuite, false, false)}
                                {
                                    formCommon.renderRadioGroup(register, setValue, [
                                        { Label: 'Rates are all-inclusive?', Key: 'ShortTermRatesAllInclusive' },
                                        { Label: 'Rate packages/bundles available?', Key: 'ShortTermRatePackagesAvailable' }
                                    ], !(shortTermCareSelected && (roomTypeSharedRoom || roomTypePrivateRoom || roomTypeSemiPrivateRoom)))
                                } 
                            </div>
                        </div>
                    </div> 
                    <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg">
                        <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-3 min-h-14 flex flex-col gap-1">
                            <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                                Respite Care Cost
                            </h4>
                            <span className="flex flex-wrap gap-y-0.5 gap-x-2 text-sm text-brand_grey-medium">
                                <p>
                                    To edit the below inputs <a href={'/core-details'} className="link">&lsquo;Respite Care&rsquo;</a> and the relevant { roomTypeLink() } must be set to &lsquo;Yes&rsquo;.
                                </p>
                                <div className="inline-block">
                                    <a 
                                        href="#" 
                                        className="link" 
                                        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => setTermData(e, 'LongTerm', 'Respite')}
                                    >
                                        Use Long Term Care Prices
                                    </a>
                                    &nbsp;or&nbsp;
                                    <a 
                                        href="#" 
                                        className="link" 
                                        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => setTermData(e, 'ShortTerm', 'Respite')}
                                    >
                                        Use Short Term Care Prices
                                    </a>
                                </div>
                            </span>
                        </div>
                        <div className="p-3 sm:p-4 2xl:p-5 h-full">
                            <div className="grid gap-6 lg:gap-8 2xl:gap-10">
                                { 
                                    formCommon.renderCostPerDay(register, errors, [{ 
                                        Label: 'Shared room', 
                                        Key: 'RespiteSharedCostPerDay', 
                                        IncludedInProfileCompleteness: respiteCareSelected && roomTypeSharedRoom && !costKeyWatch.RespiteSharedCostPerDay
                                    }], !respiteCareSelected || !roomTypeSharedRoom, costKeyWatch.RespiteSharedCostPerDay, true)}
                                { 
                                    formCommon.renderCostPerDay(register, errors, [{ 
                                        Label: 'Private room', 
                                        Key: 'RespitePrivateCostPerDay', 
                                        IncludedInProfileCompleteness: respiteCareSelected && roomTypePrivateRoom && !costKeyWatch.RespiteSemiPrivateCostPerDay
                                    }], !respiteCareSelected || !roomTypePrivateRoom, costKeyWatch.RespiteSemiPrivateCostPerDay, true)}
                                { 
                                    formCommon.renderCostPerDay(register, errors, [{ 
                                        Label: 'Semi-private room', 
                                        Key: 'RespiteSemiPrivateCostPerDay', 
                                        IncludedInProfileCompleteness: respiteCareSelected && roomTypeSemiPrivateRoom && !costKeyWatch.RespitePrivateCostPerDay
                                    }], !respiteCareSelected || !roomTypeSemiPrivateRoom, costKeyWatch.RespitePrivateCostPerDay, true)} 
                                    { 
                                    formCommon.renderCostPerDay(register, errors, [{ 
                                        Label: 'Private Suite', 
                                        Key: 'RespitePrivateSuiteCostPerDay', 
                                        IncludedInProfileCompleteness: false
                                    }], !respiteCareSelected  || !roomTypePrivateSuite, false, false)} 
                                { 
                                    formCommon.renderOptionalDuration(register, errors, setValue, watch, { 
                                        Label: 'Minimum stay required?', 
                                        Key: 'RespiteMinStay', 
                                        DurationUnit: 'days' 
                                    },!(respiteCareSelected && (roomTypeSharedRoom || roomTypePrivateRoom || roomTypeSemiPrivateRoom)))
                                }
                                {
                                    formCommon.renderRadioGroup(register, setValue, [
                                        { Label: 'Rates are all-inclusive?', Key: 'RespiteRatesAllInclusive' },
                                        { Label: 'Rate packages/bundles available?', Key: 'RespiteRatePackagesAvailable' }
                                    ], !(respiteCareSelected && (roomTypeSharedRoom || roomTypePrivateRoom || roomTypeSemiPrivateRoom)))
                                } 
                            </div>
                        </div>
                    </div> 
                    <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg">
                        <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 2xl:py-3 min-h-14 flex items-center">
                            <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                                Bed Allocation
                            </h4>
                        </div>
                        <div className="p-3 sm:p-4 2xl:p-5 h-full">
                            <div className="grid gap-6 lg:gap-8 2xl:gap-10">
                                { formCommon.renderRadioGroup(register, setValue, [{ Label: 'All beds dual-licenced for either Medicare or Medicaid?', Key: 'BedsAllDualLicenced', IncludedInProfileCompleteness: true }], false) } 
                                {
                                    formCommon.renderInteger(register, errors, [
                                        { Label: 'Medicaid bed count', Key: 'BedsMedicaidCount', MinIntegerValue: 0, MaxIntegerValue: 999, IncludedInProfileCompleteness: true },
                                        { Label: 'Medicare bed count', Key: 'BedsMedicareCount', MinIntegerValue: 0, MaxIntegerValue: 999, IncludedInProfileCompleteness: true  }
                                    ], false)
                                }                                
                            </div>
                        </div>
                    </div>   
                    <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg">
                        <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 2xl:py-3 min-h-14 flex items-center">
                            <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                                Payment types
                            </h4>
                        </div>
                        <div className="p-3 sm:p-4 2xl:p-5 h-full">
                            <div className="grid gap-6 lg:gap-8 2xl:gap-10">
                                { formCommon.renderPercent(register, errors, [{ Label: '% of residents/patients who use private insurance to pay for care', Key: 'PrivateInsurancePc' }], false) }
                                {
                                    formCommon.renderRadioGroup(register, setValue, [
                                        { Label: 'Managed care contracts accepted as in-network?', Key: 'ManagedCareContractsInNetwork', IncludedInProfileCompleteness: true },
                                        { Label: 'Do you accept Medicare Advantage plans?', Key: 'AcceptMedicareAdvantage', IncludedInProfileCompleteness: true },
                                        { Label: 'Do you accept Medicaid pending patients?', Key: 'AcceptMedicaidPending', IncludedInProfileCompleteness: true }
                                    ], false)
                                }                                 
                            </div>
                        </div>
                    </div>   
                    <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg">
                        <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 2xl:py-3 min-h-14 flex items-center">
                            <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                                Insurance Providers Accepted
                            </h4>
                        </div>
                        <div className="p-3 sm:p-4 2xl:p-5 h-full">
                            <div className="grid gap-6 lg:gap-8 2xl:gap-10">
                                {
                                    (insuranceTypes || []).map(({ PropertyInsuranceTypeID, Name }: IPropertyCoreDetailInsuranceType) =>
                                        <div className="flex flex-col gap-1 lg:flex-row lg:gap-2 lg:items-center lg:flex-wrap" key={`insurance-type-${PropertyInsuranceTypeID}`}>
                                            <strong className="font-medium lg:min-w-[400px] lg:max-w-[400px] lg:text-lg">
                                                { Name }
                                            </strong>
                                            <div className="flex flex-col gap-1.5 sm:flex-row sm:items-center sm:gap-6 lg:gap-10">
                                                <div className="flex flex-wrap items-center gap-x-4 gap-y-2 md:gap-x-5">
                                                    { formCommon.renderRadios(register, `InsuranceType_${PropertyInsuranceTypeID}`, false) }
                                                    <button
                                                        title="Reset"
                                                        onClick={(e) => { 
                                                            e.preventDefault();
                                                            setValue(`InsuranceType_${PropertyInsuranceTypeID}`, null, { shouldDirty: true });    
                                                        }}
                                                        className="rounded-full bg-brand_grey-light min-h-9 min-w-9"
                                                    >
                                                        <FontAwesomeIcon icon={faClockRotateLeft} className="h-4 w-4 link relative top-px" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }                                
                            </div>
                        </div>
                    </div>
                </div>
                <Button 
                    type="submit" 
                    className="fixed bottom-6 left-1/2 -translate-x-1/2 shadow-xl ring-4 ring-white"
                    disabled={!isDirty || isLoading}
                    isLoading={isLoading}
                >
                    Update
                </Button>
            </form>
        </>
    );
};

export default PropertyCostFundingForm;
