import React from 'react';
import { ShowcaseReviewsWidget } from '../dashboard/showcase_reviews_widget';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

const ReviewListPublishedBanner = ({ nhIDs, orgNHID }: IProps) => {
    const showPortletArray = ['postcards-v2','invite-to-review','qr-code-posters','review-score'];

    return (
        <section>
            <div className="container max-w-none 2xl:container mt-4 sm:mt-6 md:mt-8 2xl:mt-10 !p-0">
                <div className="overflow-auto scrollbar scrollbar-sm scrollbar-full">
                    <div className="w-fit flex justify-evenly gap-4 pt-px pb-3 px-3 sm:px-4 sm:gap-5 [&>div]:min-w-[300px] xl:[&>div]:min-w-0 xl:grid xl:grid-cols-4">
                        <ShowcaseReviewsWidget
                            orgNHID={orgNHID}
                            nhIDs={nhIDs}
                            showPortletArray={showPortletArray}
                        />
                    </div>                     
                </div>
            </div>
        </section>
    );
};

export default ReviewListPublishedBanner;
