import React from 'react';

import { IPropertyVirtualTourRow } from '../../../../interfaces/asset';
import { Modal } from '../../../../components/common/modal/modal';
import { ModalTitle } from '../../../../components/common/modal/modalTitle';

interface IProps {
    isOpen: boolean;
    selectedFacility: IPropertyVirtualTourRow;
    handleModalClose: () => void;
    children: React.ReactNode;
    footerActions: React.ReactElement;
}

const PropertyVirtualTourModal: React.FC<IProps> = ({ 
    isOpen,
    selectedFacility,
    handleModalClose, 
    footerActions,
    children
}) => {

    if (!selectedFacility) {
        return null;
    }

    const { Name, TourURL } = selectedFacility;
    const modalTitle = `${TourURL ? 'Update' : 'Add'} 360 Virtual Tour`;
    const title = <ModalTitle title={modalTitle} rightSideText={Name} />;

    return (
        <Modal
            isOpen={isOpen}
            onClose={handleModalClose}
            title={title}
            footerContent={footerActions}
            size="lg"
        >
            {children}
        </Modal>    
    );
};

export default PropertyVirtualTourModal;
