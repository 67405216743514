import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { LeadMetric } from '../../interfaces/leads';

type Props = {
    metric: LeadMetric;
    filters: any;
    data: any[];
};

export default function LeadsInsightsChart(props: Props) {

    const location = useLocation();
    const path = location.pathname;
    const isDashboard = path === '/';

    const chartDiv = 'leads-insights-chart';

    useEffect(() => {
        if (!props.data) {
            return;
        }

        const root = am5.Root.new(chartDiv);

        const customTheme = am5.Theme.new(root);

        customTheme.rule('Label').setAll({
            fill: am5.color(0x262626),
            fontSize: isDashboard ? '0.85rem' : '0.969rem',
            fontFamily: 'Poppins'
        });

        root.setThemes([am5themes_Animated.new(root), customTheme]);
        root._logo.dispose();

        root.dateFormatter.setAll({
            dateFormat: 'yyyy',
            dateFields: ['valueX']
        });

        const chartData = props.data.map((item: any) => {
            let date = new Date();
            date.setHours(0,0,0,0);
            date.setDate(1);

            switch (props.filters.DataPoint) {
                case 'month':
                    date.setFullYear(item.Year);
                    date.setMonth(item.Month - 1);
                    root.dateFormatter.set('dateFormat', 'MMM yyyy');
                    break;
                case 'week':
                    date.setFullYear(item.Year);
                    date.setMonth(0);
                    date.setDate(item.Week * 7);
                    root.dateFormatter.set('dateFormat', 'MMM dt, yyyy');
                    break;
                case 'day':
                    date = new Date(item.EventDate);
                    root.dateFormatter.set('dateFormat', 'MMM dt, yyyy');
                    break;
            }

            const value = item[props.metric];

            return {
                date: date.getTime(),
                value
            };
        });

        const chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: 'panX',
            wheelY: 'zoomX',
            pinchZoomX: true,
            paddingLeft: 0
        }));

        const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {
            behavior: 'none'
        }));

        cursor.lineY.set('visible', false);

        // x line
        const xRenderer = am5xy.AxisRendererX.new(root, {
            minorGridEnabled: true,
            strokeOpacity: 1,
            strokeWidth: 1,
            stroke: am5.color(0xd0d0d0)
        });

        // x grid
        xRenderer.grid.template.setAll({ location: 1, strokeWidth: 1, stroke: am5.color(0xeaeaea), strokeOpacity: 1 });

        // x labels
        xRenderer.labels.template.setAll({
            textAlign: 'center',
            oversizedBehavior: 'wrap',
            fontSize: isDashboard ? '0.82rem' : '0.9rem',
            breakWords: isDashboard ? true : false,
            maxWidth: isDashboard ? 50 : 100,
            paddingTop: isDashboard ? 6 : 12,
            lineHeight: 1
        });

        const xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            maxDeviation: 0.2,
            baseInterval: {
                timeUnit: props.filters.DataPoint,
                count: 1
            },
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {})
        }));

        // y line
        const yRenderer = am5xy.AxisRendererY.new(root, {
            minorGridEnabled: false,
            strokeOpacity: 1,
            strokeWidth: 1,
            stroke: am5.color(0xd0d0d0),
            pan: 'zoom'
        });

        // y grid
        yRenderer.grid.template.setAll({ location: 1, strokeWidth: 1, stroke: am5.color(0xeaeaea), strokeOpacity: 1 });

        // y labels
        yRenderer.labels.template.setAll({
            fontSize: isDashboard ? '0.82rem' : '0.9rem',
            paddingRight: isDashboard ? 6 : 12
        });
        
        const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: yRenderer,
            maxPrecision: 0
        }));

        const series = chart.series.push(am5xy.LineSeries.new(root, {
            name: 'Series',
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'value',
            valueXField: 'date',
            stroke: am5.color('#C3B1E1'),
            tooltip: am5.Tooltip.new(root, {
                labelText: '{valueY}'
            })
        }));

        series.strokes.template.setAll({
            strokeWidth: 2,
            templateField: 'StrokeSettings'
        });

        series.data.setAll(chartData);

        series.appear(1000);
        chart.appear(1000, 100);

        return () => root.dispose();

    }, [props.data, props.metric]);

    return <div id={chartDiv} className="w-full min-h-96"></div>;
}
