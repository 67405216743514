import React from 'react';
import { IPropertyReviewScore } from '../../interfaces/review';
import { getClientFormattedDateShort } from '../../utils/date';
import ReviewScoreBreakdown from './review_score_breakdown';
import ReviewScoreFullFormatted from '../common/review_score_full_formatted';
import { LoadingDots } from '../common/loading_dots';

interface IProps {
    data: IPropertyReviewScore;
}

const ReviewScoreTableExpandableRows = ({ data }: IProps) => {
    const {   
        ReviewScoreFull,
        ReviewScoreTotalReviewCount,
        ReviewScoreQualityScore,
        ReviewScorePositiveReviewCount,
        ReviewScoreQuantityScore,
        MostRecentReviewDate
    } = data;

    const renderContent = () => {
        return (
            <>
                <div className="md:hidden mb-4 grid gap-3">
                    <p>
                        <strong>Review Score Full: <ReviewScoreFullFormatted value={ReviewScoreFull} showAsNotApplicableOnNull={true} /></strong>
                    </p>                
                    <p>
                        <strong># Reviews in the last 24 Months:</strong> { ReviewScoreTotalReviewCount }
                    </p>                
                    <p>
                        <strong>Average Overall Rating of all reviews in the last 24 months (up to 5 points available):</strong> { ReviewScoreQualityScore }
                    </p>                
                    <p>
                        <strong># Positive Reviews (Overall Rating = 4 or 5) in  the last 24 months (15+ reviews required for max points):</strong> { ReviewScorePositiveReviewCount }
                    </p>                
                    <p>
                        <strong>Points from Positive reviews in the  last 24 months (up to 5 points available):</strong> { ReviewScoreQuantityScore }
                    </p>                
                    <p>
                        <strong>Date of Most Recent Review:</strong> { MostRecentReviewDate ? getClientFormattedDateShort(MostRecentReviewDate) : null }
                    </p>              
                </div>
                <ReviewScoreBreakdown data={data} />            
            </>
        );
    };

    return (
        <>
            <div className="p-4">
                { data ? renderContent() : <LoadingDots show={true} />  }
            </div>
        </>
    );
};
export default ReviewScoreTableExpandableRows;
