import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { resetPassword, resetPasswordTokenValid } from '../../api/login';
import { sendActivationEmail } from '../../api/user'; 
import { Toast, showToast, ToastType } from '../../utils/toast';
import { Button } from '../../components/common/button';
import _ from 'lodash';
import { login } from '../../modules/login';

interface FormInputs {
    Password: string;
    ConfirmPassword: string;
}

interface IProps {
    mode: string;
}

interface IModeData {
    title: string;
    passwordLabel: string;
    successTitle: string;
    successText: string;
    btnText: string;
}

const PasswordReset = ({ mode }: IProps) => {

    const { token }  = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [validToken, setValidToken] = useState<boolean>(true);
    const [emailAddress, setEmailAddress] = useState<string | null>(null);
    const [userID, setUserID] = useState<number | null>(null);

    const navigate = useNavigate();

    const {
        register,
        formState: { errors },
        handleSubmit,
        getValues 
    } = useForm<FormInputs>();

    useEffect(() => {

        const testToken = async(token: string) => {
            const resp: any = await resetPasswordTokenValid(token);
            if (!resp?.data) {
                setValidToken(false);
                setEmailAddress(null);
                return;
            }

            const {Token: returnedToken, EmailAddress, UserID} = resp.data;
            setValidToken(!!returnedToken);
            setEmailAddress(EmailAddress);
            setUserID(UserID);
        };

        testToken(token);

    }, [token]);


    const buildModeData = (): IModeData => (

        mode === 'activate' ? {
            title: 'Activate Your Account',
            passwordLabel: 'Password',
            successTitle: 'Account Successfully Activated',
            successText: 'Your Account has been activated',
            btnText: 'Activate Account'


        } : {
            title: 'Reset Your Password',
            passwordLabel: 'New Password',
            successTitle: 'Password Successfully Reset',
            successText: 'Your password has been reset',
            btnText: 'Reset Password'
        }
    );

    if (!['activate', 'password_reset'].includes(mode)) {
        return (
            <h6>Invalid Mode {mode}</h6>
        );
    }

    const modeData = buildModeData();

    const onSubmit = async (data: any) => {

        const { Password, ConfirmPassword } = data;

        if (!Password || !ConfirmPassword || Password !== ConfirmPassword) {
            showToast('Passwords do not match', ToastType.Error);
            return;
        }

        setLoading(true);

        try {
            const result = await resetPassword(mode, token, Password);
            const { Jwt, RefreshJwt } = _.get(result, 'data.data');

            await login(Jwt, RefreshJwt);
            navigate('/');

        } catch (err) {
            const userMsg = _.get(err, 'data.data.UserMsg', 'Set Password Failed');
            showToast(userMsg, ToastType.Error);
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const handleResendActivationEmail = () => {
        if (userID) {
            sendActivationEmail(userID);
        }
    };

    const renderInvalidToken = () => {

        return (
            <form className="p-3 sm:p-4 lg:p-8" onSubmit={handleSubmit(onSubmit)}>
                <div className="grid gap-3">
                    <p>
                        {`${mode === 'password_reset' ? 'Password Reset' : 'Account Activation'} failed as the link has already been used or has expired.`}
                    </p>  
                    <Link 
                        className="btn"
                        onClick={() => handleResendActivationEmail()}
                        to={{
                            pathname: '/login'
                        }}
                    > 
                        Resend Activation Email { emailAddress  ? `to ${emailAddress}` : ''}
                    </Link>
                    <Link 
                        className="link"
                        to={{
                            pathname: '/login'
                        }}
                    >
                        Return to login
                    </Link>
                </div>
            </form>
        );
    };

    const renderForm = () => {

        return (
            <form className="p-3 sm:p-4 lg:p-8" onSubmit={handleSubmit(onSubmit)}>
                <div className="grid gap-3">
                    <p>
                        Passwords must have a minimum length of 8 characters and a maximum length of 32 characters.
                    </p>
                    <div className={`form-component form-inline form-textbox ${errors.Password ? 'form-error' : ''}`}>
                        <label
                            htmlFor="password"
                        >
                            {modeData.passwordLabel}
                        </label>
                        <input
                            {
                                ...register('Password', {
                                    required: 'Please enter your password',
                                })
                            }
                            type="password"
                            id="password"
                        />
                        <ErrorMessage
                            errors={errors}
                            name="Password"
                            render={({ message }: any) => <span>{message}</span>}
                        />
                    </div>
                    <div className={`form-component form-inline form-textbox ${errors.ConfirmPassword ? 'form-error' : ''}`}>
                        <label
                            htmlFor="confirm-password"
                        >
                            Confirm Password
                        </label>
                        <input
                            {
                                ...register('ConfirmPassword', {
                                    required: 'Please enter your password',
                                    validate: value => value === getValues().Password
                                })
                            }
                            type="password"
                            id="confirm-password"
                        />
                        <ErrorMessage
                            errors={errors}
                            name="ConfirmPassword"
                            render={() => <span>Passwords do not match!</span>}
                        />
                    </div>
                    <div className="mt-3 flex flex-wrap gap-3 items-center">
                        <div className="grid text-sm lg:text-md gap-0.5">
                            <Link className="link inline-block" to={{
                                pathname: '/login'
                            }}> 
                                Return to Login
                            </Link>
                        </div>
                        <Button 
                            type="submit" 
                            className="ms-auto"
                        >
                            {modeData.btnText}
                        </Button>
                    </div>
                </div>
            </form>
        );
    };

    return (
        <>
            <Toast></Toast>
            <main>
                <section className="h-screen flex justify-center py-8">
                    <div className="my-auto w-full max-w-[500px] ring-1 ring-brand_grey rounded-md lg:rounded-lg overflow-hidden shadow-md bg-white mx-3 sm:mx-4 lg:mx-8 h-fit"> 
                        <Link to={{pathname: '/'}} className="flex items-end p-3 sm:p-4 lg:px-8 lg:py-6 border-b border-brand_grey bg-brand_faint-blue">
                            <img src="/logo.svg" className="h-9 me-3 lg:h-10 xl:h-11" alt="Portal" />
                            <strong className="text-brand_grey-medium relative top-0.5 font-medium text-lg lg:text-xl lg:top-0">
                                Portal
                            </strong>
                        </Link> 
                        { validToken ? renderForm() : renderInvalidToken() }
                    </div>
                </section>
            </main>
        </>
    );
};

export default PasswordReset;