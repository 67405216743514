import React from 'react';
import { useSelector } from 'react-redux';
import { useQueries } from '@tanstack/react-query';
import { getSingleProperty } from '../../../utils/common';
import { getPropertyCountTotals, getReviewScoreForNHID } from '../../../api/review';
import { RootState } from '../../../store/reducers';
import { 
    IPropertyCountTotalsFilters,
} from '../../../interfaces/review';
import { formatFromDateForAPIRequestUTC } from '../../../utils/date';
import { PropertyMode } from '../../../interfaces/session';

import { IReviewScoreWidgetProps } from './types';
import ReviewScoreWidgetLarge from './review_score_widget_large';
import ReviewScoreWidgetSmall from './review_score_widget_small';
import ReviewScoreWidgetXS from './review_score_widget_xs';

export const ReviewScoreWidget = ({  
    nhIDs, 
    selNHID, 
    propertyMode,
    widgetSize
}: IReviewScoreWidgetProps) => {
    const { Properties, Session } = useSelector(
        ({ SessionState }: RootState) => SessionState
    );

    const { SessionType } = Session;
    const multiMode = [PropertyMode.MultiProperty, PropertyMode.Org].includes(propertyMode);
    const singleProperty = getSingleProperty(SessionType, selNHID, Properties);
    const orgName = Properties.length ? Properties[0].OrgName : '';

    const dateNow = new Date();
    dateNow.setHours(0, 0, 0, 0);
    const todayUTC = formatFromDateForAPIRequestUTC(dateNow);
    const filters: IPropertyCountTotalsFilters = {
        NHID: selNHID,
        TodaysDateUTC: todayUTC
    };

    const reviewScoreNHID = !multiMode 
        ? (selNHID || nhIDs[0]) 
        : Properties.length 
            ? Properties[0].OrgNHID 
            : null;

    const results = useQueries({
        queries: [
            {
                queryKey: ['review', 'totals', 'propertycount', filters], 
                queryFn: async () => await getPropertyCountTotals(filters),
                enabled: widgetSize !== 'xs'
            },
            {
                queryKey: ['review', 'reviewscore', reviewScoreNHID ], 
                queryFn: async () => await getReviewScoreForNHID(reviewScoreNHID),
            }
        ]
    });
    
    const loadingData = widgetSize === 'xs' 
        ?
            (results[1].isLoading || results[1].isFetching || !results[1].data)
        : 
            results.some(result => result.isLoading || result.isFetching || !result.data);

    const { propertyCountData } = results[0].data ?? {};
    const { ReviewScoreDisplay, ReviewScoreFull } = results[1].data ?? {};

    if (widgetSize === 'large') {
        return <ReviewScoreWidgetLarge
            propertyCountData={propertyCountData}
            reviewScoreDisplay={ReviewScoreDisplay}
            reviewScoreFull={ReviewScoreFull}
            orgName={orgName}
            multiMode={multiMode}
            singleProperty={singleProperty}
            loadingData={loadingData}
        />;
    }

    if (widgetSize === 'small') {
        return <ReviewScoreWidgetSmall
            propertyCountData={propertyCountData}
            reviewScoreDisplay={ReviewScoreDisplay}
            reviewScoreFull={ReviewScoreFull}
            orgName={orgName}
            multiMode={multiMode}
            singleProperty={singleProperty}
            loadingData={loadingData}
        />;
    }

    return <ReviewScoreWidgetXS
        reviewScoreDisplay={ReviewScoreDisplay}
        multiMode={multiMode}
        loadingData={loadingData}
    />;

};
