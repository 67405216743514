import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import DataTable from '../../components/common/data_table';
import ReplyModal from '../../components/reviews/reply_modal';
import PublishModal from '../../components/reviews/publish_modal';
import ReviewTableExpandableRows from '../../components/reviews/review_table_expandable_rows';
import ReviewListReplyButton from '../../components/reviews/review_list_reply_button';
import NoReviewData from '../../components/reviews/no_review_data';
import { ISessionState } from '../../interfaces/session';
import { IPortalReplyItem, IReply } from '../../interfaces/reply';
import {
    buildColumns,
    fetchReviewData,
    getReviewDataParams,
    createOrUpdateReply
} from './reviews_list_common';

import { scrollToTop } from '../../utils/common';
import { v4 as uuidv4 } from 'uuid';
import { get } from 'lodash';
import { ReviewPublicationStatusType } from '../../interfaces/review';
import { updateReviewPublicationAuxStatus } from '../../api/review';
import { LoadingDots } from '../../components/common/loading_dots';
import { Button } from '../../components/common/button';
import Swal from 'sweetalert2';

interface IReviewsListProps {
    nhIDs: number[];
    orgNHID: number;
}

const defaultSortOrder = 'DESC';
const defaultSortField = 'LastPublishedDate';
const defaultPageLimit = 100;
const defaultPageOffset = 0;

const ReviewsListPending = ({ nhIDs, orgNHID }: IReviewsListProps) => {
    const navigate = useNavigate();

    const { reviewID: reviewIDParam } = useParams();

    if (reviewIDParam && isNaN(Number(reviewIDParam))) {
        navigate('/reviews');
    }

    const [ busy, setBusy ] = useState<boolean>(false);
    const [ activeReply, setActiveReply ] = useState<any>(null);
    const [ curReview, setCurReview ] = useState<any | null>(null);
    const [ hasReply, setHasReply ] = useState<boolean>(false);
    const [ resetPaginationToggle, setResetPaginationToggle] = useState<boolean>(false);
    const [ reviews, setReviews ] = useState<any[]>([]);
    const [ totalReviewCount, setTotalReviewCount ] = useState<number>(0);
    const [ pageOffset, setPageOffset ] = useState<number>(defaultPageOffset);
    const [ pageLimit, setPageLimit ] = useState<number>(defaultPageLimit);
    const [ sortOrder, setSortOrder ] = useState<string>(defaultSortOrder);
    const [ sortField, setSortField ] = useState<string>(defaultSortField);

    const sessionState: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const canReplyToReview = get(sessionState, 'Session.Perms.CanReplyToReview', false);

    useEffect(() => {
        setPageOffset(defaultPageOffset);
        getReviewDataWithDefaultParams();
        setResetPaginationToggle(!resetPaginationToggle);
    }, [JSON.stringify(nhIDs), orgNHID]);

    const getReviewData = async (
        pageOffset: number,
        pageLimit: number,
        sortField: string,
        sortOrder: string
    ) => {

        setBusy(true);

        const params: any = getReviewDataParams(
            {},
            pageOffset,
            pageLimit,
            sortField,
            sortOrder,
            ReviewPublicationStatusType.PendingPublication,
            false
        );

        const [ tableData, , totalFilteredRows]: 
            [any[], number, number] = await fetchReviewData(params);
        
        if (reviewIDParam && !tableData.length) {
            getReviewData(0, pageLimit, sortField, sortOrder);
            navigate('/reviews');
        }

        setReviews(tableData);
        setTotalReviewCount(totalFilteredRows);
        setBusy(false); 
    };

    const getReviewDataWithDefaultParams = () => getReviewData(
        defaultPageOffset, 
        defaultPageLimit, 
        defaultSortField, 
        defaultSortOrder
    );

    const renderPublishButton = (row: any) => {
        return (
            <Button 
                className="ms-auto"
                disabled={busy}
                onClick={() => setCurReview(row)}
            >
                Publish with no Response
            </Button>
        );
    };

    const renderPublishWithReplyButton = (row: any) => {
        if (canReplyToReview && !row.Reply) {
            return (
                <ReviewListReplyButton
                    data={row}
                    setActiveReply={setActiveReply}
                    setHasReply={setHasReply}
                    updateReplyBtnText={'Publish with a Response'}
                    createReplyBtnText={'Publish with a Response'}
                />
            );
        }
    };

    const columns: any = buildColumns('pending');

    columns.push({
        name: '',
        width: '*',
        cell: (row: any) => (
            <>
                { renderPublishButton(row) }
                { renderPublishWithReplyButton(row)}
            </>
    )});



    const handlePublishReview = async (reviewID: number) => {
        try {
            setBusy(true);
            await publishReview(reviewID);
            setCurReview(null);
        } catch(err) {
            console.error('Failed to publish review');
        } finally {
            setBusy(false);
        }
    };

    const publishReview = async (reviewID: number) => {
        try {
            await updateReviewPublicationAuxStatus(reviewID, ReviewPublicationStatusType.Published);

            const mutatedReviews = reviews
            .filter((review: any) => review.ReviewID !== reviewID)
            .map((review: any) => {
                const reviewCopy = { ...review };
                reviewCopy.uuid = uuidv4();
                return reviewCopy;
            });

            setReviews(mutatedReviews);
            setTotalReviewCount(Math.max(0, totalReviewCount - 1));

            Swal.fire({
                title: 'Successfully Published Review',
                icon: 'success',
                showConfirmButton: false
            });
        } catch(err) {
            console.error('Failed to publish review');
        } 
    };

    const onPageChange = async (pageNumber: number) => {
        const newPageOffset = (pageNumber - 1) * pageLimit;

        if (newPageOffset !== pageOffset) {
            setPageOffset((pageNumber - 1) * pageLimit);
            getReviewData(newPageOffset, pageLimit, sortField, sortOrder);
            scrollToTop();
        }
    };

    const onLimitChange = async (newPerPage: number) => {
        if (newPerPage !== pageLimit) {
            setPageOffset(defaultPageOffset);
            setPageLimit(newPerPage);
            getReviewData(defaultPageOffset, newPerPage, sortField, sortOrder);
            scrollToTop();
        }
    };

    const onSortChange = (column: any, sortOrderParam: any) => {
        if (column?.sortField && sortOrder) {
            sortOrderParam = sortOrderParam.toUpperCase();
            setSortField(column.sortField);
            setSortOrder(sortOrderParam);
            getReviewData(pageOffset, pageLimit, column.sortField, sortOrderParam);
            scrollToTop();
        }
    };
  
    const handleCreateOrUpdateReply = async (reviewID: number, replyData: IReply) => {

        const savedReply = await createOrUpdateReply(reviewID, replyData);
        updateReplyInReviewList(reviewID, savedReply);
        await publishReview(reviewID);
        setActiveReply(null);
    };

    const updateReplyInReviewList = (reviewID: number, replyData: IPortalReplyItem | null) => {

        const review = reviews.find((review: any) => review.ReviewID === reviewID);
        if (review) {
            review.Reply = replyData;

            const mutatedReviews: any[] = reviews.map((review: any) => {
                const reviewCopy = { ...review };
                reviewCopy.uuid = uuidv4();
                return reviewCopy;
            });

            setReviews(mutatedReviews);
        } else {
            console.error(`reviewID: ${reviewID} not found`);
        }
    };

    let totalStr = '';
    switch (totalReviewCount) {
        case 0: 
            totalStr = 'No Reviews Pending Publication';
            break;
        case 1: 
            totalStr = '1 Review Pending Publication';
            break;
        default:
            totalStr = `All ${totalReviewCount} Reviews Pending Publication`;
    }

    return (
        <>
            <section>
                <div className="container max-w-none 2xl:container mt-4 sm:mt-6 md:mt-8 2xl:mt-10">
                    <div className="flex flex-col items-center text-center gap-3 md:flex-row md:text-start">
                        <div>
                            <strong className="text-md md:text-lg lg:text-xl lg:whitespace-nowrap">
                                {totalStr}
                            </strong>
                        </div>
                    </div>
                    <div className="mt-4 sm:mt-6 md:mt-8 lg:mt-10">
                        <div data-table="Pending Publication Reviews" className="relative [&>div]:scrollbar [&>div]:pb-2 [&>div]:-mx-3 [&>div]:w-[calc(100%+1.5rem)] [&>div]:px-3 sm:[&>div]:-mx-4 sm:[&>div]:w-[calc(100%+2rem)] sm:[&>div]:px-4 xl:[&>div]:mx-0 xl:[&>div]:w-full xl:[&>div]:px-0 before:h-full before:top-0 before:absolute before:z-10 before:bg-gradient-to-r before:from-white before:w-3 before:-left-3 after:h-full after:top-0 after:absolute after:z-10 after:bg-gradient-to-l after:from-white after:-right-3 after:w-3 sm:before:-left-4 sm:before:w-4 sm:after:-right-4 sm:after:w-4 xl:before:content-none xl:after:content-none">
                            { busy ? <LoadingDots show={true} /> : null }
                            <DataTable
                                columns={columns}
                                data={reviews}
                                expandableRows={true}
                                expandableRowsComponent={ReviewTableExpandableRows}
                                expandableRowsComponentProps={{published: false, setActiveReply, setHasReply}} 
                                pagination={true}
                                paginationServer={true}
                                paginationTotalRows={totalReviewCount}
                                onChangePage={onPageChange}
                                onChangeRowsPerPage={onLimitChange}
                                onSort={onSortChange}
                                paginationResetDefaultPage={resetPaginationToggle}
                                fixedHeader={false}
                                noDataComponent={<NoReviewData loading={busy} />}
                                defaultExpanded={true}
                                defaultExpandedIDProp={'ReviewID'}
                            /> 
                        </div>
                    </div>
                </div>
            </section>
            {
                activeReply ?
                <ReplyModal
                    reply={activeReply}
                    hasReply={hasReply}
                    handleClose={() => setActiveReply(null)}
                    handleDeleteReply={null}
                    handleCreateOrUpdateReply={handleCreateOrUpdateReply}
                    cancelText={'Publish'}
                    saveText={['Publish', 'Review']}
                /> : null
            }
            {
                curReview ?
                <PublishModal
                    review={curReview}
                    handleClose={() => setCurReview(null)}
                    handlePublishReview={handlePublishReview}
                    cancelText={'Publish'}
                    saveText={['Publish', 'Review']}
                /> : null
            }
        </>
    );
};

export default ReviewsListPending;
