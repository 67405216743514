import React from 'react';
import { useQuery } from '@tanstack/react-query';

import { getReviewCountTotals } from '../../../api/review';
import { formatFromDateForAPIRequestUTC } from '../../../utils/date';
import { ReviewsRequiringActionWidgetXS } from './reviews_requiring_action_widget_xs';
import { IReviewsRequiringActionWidgetProps } from './types';

export const ReviewsRequiringActionWidget = ({
    selNHID,
    widgetSize,
}: IReviewsRequiringActionWidgetProps) => { 
    const dateNow = new Date();
    dateNow.setHours(0, 0, 0, 0);
    const todayUTC = formatFromDateForAPIRequestUTC(dateNow);
    const filters = {
        NHID: selNHID,
        TodaysDateUTC: todayUTC
    };
    
    const { data, isLoading, isFetching } = useQuery({ 
        queryKey: [ 'review', 'totals', 'count',  filters ], 
        queryFn: async () => {
            const data = await getReviewCountTotals(filters.NHID, filters.TodaysDateUTC);    
            
            return {
                reviewCounts: data
            };
        },
    });

    const loadingData = (isLoading || isFetching || !data);

    const { reviewCounts } = data ?? {};

    let totalPendingPublication = 0;
    let totalPublishNoReplyLast90 = 0;

    if (reviewCounts) {
        
        const {
            TotalPendingPublication,
            TotalPublishNoReplyLast90
        } = reviewCounts;

        totalPendingPublication = TotalPendingPublication || 0;
        totalPublishNoReplyLast90 = TotalPublishNoReplyLast90 || 0;
    }
    
    return <ReviewsRequiringActionWidgetXS 
        selNHID={selNHID} 
        totalPendingPublication={totalPendingPublication}
        totalPublishNoReplyLast90={totalPublishNoReplyLast90}
        loadingData={loadingData}
    />;                          
};
