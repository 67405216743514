import React from 'react';

interface IProps {
    loading: boolean;
}

const NoReviewData = ({ loading }: IProps) => {
    return (
        <>
            { loading ? null : <span className="msg msg-warning">No reviews found</span> }
        </>
    );
};

export default NoReviewData;
