import React from 'react';
import PageCommunitySelect from '../../components/common/page_community_select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronLeft} from '@fortawesome/pro-solid-svg-icons';
import { useSessionState } from '../../hooks/use_session_state';
import { PageType } from '../../interfaces/page';

interface IProps {
    label?: string;
    selNHID: number | null;
    handleSelect: (memberID: number | null) => void;
    disabled?: boolean;
    pageType?: PageType;
}

const PageCommunitySelectWrapper = ({
    selNHID, 
    label, 
    handleSelect, 
    disabled,
    pageType
}: IProps) => {
    const sessionState = useSessionState();

    const handleSetAllFacilities = (e: any) => {
        handleSelect(null);
    };

    const renderMultiHeader = () => {
        return (
            <section>
                <div className="container max-w-none 2xl:container mt-4 sm:mt-6 md:mt-8 2xl:mt-10">
                    <div className="flex flex-col whitespace-nowrap items-center justify-center gap-2 md:gap-4 md:flex-row">
                        {
                            selNHID && pageType !== PageType.Dashboard ? 
                            <div className="lg:me-12"><a href="#" className="link" onClick={(e) => handleSetAllFacilities(e)}>
                                <FontAwesomeIcon icon={faChevronLeft} className="me-0.5 h-3 w-3 relative -top-px"/>View all Facilities
                            </a></div>: null
                        }
                        <div>
                            {
                                label ? <label htmlFor="CommunitySelect" className="font-semibold">{label}</label> : null
                            }
                        </div>
                        <div className="w-full lg:max-w-[900px]">
                            <PageCommunitySelect
                                handleSelect={handleSelect}
                                selNHID={selNHID}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                </div>
            </section>
        );
    };

    return sessionState.Org || (sessionState.Properties || []).length > 1 ? renderMultiHeader() : null;
};

export default PageCommunitySelectWrapper;
