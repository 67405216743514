import React from 'react';
import { useNavigate } from 'react-router-dom';

const RoomTypeLink = () => {

    const navigate = useNavigate();

    return (
        <>
            <span
                className="link"
                onClick={() => {
                    navigate('/core-details', { 
                        state: { 
                            targetId: 'roomtype',
                        } 
                    });
                }} 
            >
                room type
            </span>
        </>
    );
};

export { RoomTypeLink };