import React from 'react';
import { FormInput } from '../../../../../../components/common/form_input';
import { FormRadioGroup } from '../../../../../../components/common/form_radio_group';
import { onlyNumeric, onlyUpto100, yesNoOptions } from '../../../helpers';
import { ProfileCompletenessTag } from '../../components/profile_completeness_tag';
import { useFormContext } from 'react-hook-form';
import { SectionHeading } from '../../components/section_heading';

const PediatricResidentsTab = () => {
    const { watch } = useFormContext();

    const inline = true;

    const isAdmitPediatricResidentsSelected =
        watch('AdmitPediatricResidents') === 'Yes';

    const hasMaximumAgeRequirement =
        watch('PediatricMaxAgeRequirement') === 'Yes';

    return (
        <>
            <SectionHeading title="Pediatric Residents" />
            <div className="grid gap-3 lg:gap-5 2xl:gap-6 px-3 sm:px-4 2xl:px-5">
                <FormRadioGroup
                    groupLabel="Do you admit pediatric residents?"
                    info={<ProfileCompletenessTag isVisible={true} />}
                    layout="horizontal"
                    name="AdmitPediatricResidents"
                    options={yesNoOptions}
                    showResetButton={true}
                />
                <FormInput
                    allowedPattern={onlyUpto100}
                    layout="horizontal"
                    disabled={!isAdmitPediatricResidentsSelected}
                    info={
                        <ProfileCompletenessTag
                            isVisible={isAdmitPediatricResidentsSelected}
                        />
                    }
                    inlineLabel={false}
                    label="Average % of short-term pediatric residents per month"
                    maxWidth={'5rem'}
                    name="PediatricAvgShortTermResidentsPc"
                    suffix="%"
                    type="number"
                />
                <FormInput
                    allowedPattern={onlyNumeric}
                    layout="horizontal"
                    disabled={!isAdmitPediatricResidentsSelected}
                    info={<ProfileCompletenessTag isVisible={false} />}
                    inlineLabel={false}
                    label="Average short-term pediatric stay duration"
                    maxWidth={'5rem'}
                    name="PediatricAvgShortStayDuration"
                    suffix="days"
                    type="number"
                />
                <FormInput
                    allowedPattern={onlyUpto100}
                    layout="horizontal"
                    disabled={!isAdmitPediatricResidentsSelected}
                    info={
                        <ProfileCompletenessTag
                            isVisible={isAdmitPediatricResidentsSelected}
                        />
                    }
                    inlineLabel={false}
                    label="Average % of long-term pediatric residents per month"
                    maxWidth={'5rem'}
                    name="PediatricAvgLongTermResidentsPc"
                    suffix="%"
                    type="number"
                />
                <FormInput
                    allowedPattern={onlyNumeric}
                    layout="horizontal"
                    disabled={!isAdmitPediatricResidentsSelected}
                    info={<ProfileCompletenessTag isVisible={false} />}
                    inlineLabel={false}
                    label="Average long-term pediatric stay duration"
                    maxWidth={'5rem'}
                    name="PediatricAvgLongTermStayDuration"
                    suffix="months"
                    type="number"
                />
                <FormRadioGroup
                    disabled={!isAdmitPediatricResidentsSelected}
                    followUpInput={
                        <FormInput
                            name="PediatricMaxAge"
                            inlineLabel={false}
                            suffix="years"
                            allowedPattern={onlyNumeric}
                            maxWidth={'5rem'}
                            type="number"
                            disabled={
                                !hasMaximumAgeRequirement ||
                                !isAdmitPediatricResidentsSelected
                            }
                            info={<ProfileCompletenessTag isVisible={false} />}
                        />
                    }
                    groupLabel="Is there a maximum age requirement for pediatric residents?"
                    layout="horizontal"
                    name="PediatricMaxAgeRequirement"
                    options={yesNoOptions}
                    showResetButton={true}
                />
            </div>
        </>
    );
};

export { PediatricResidentsTab };
