import React, { FC } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { MeetTheTeamMemberType } from '../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';
import {
    DeleteTeamMemberDialogButton,
    ProfileImage,
    EditTeamMemberModal
} from '../../components';

interface Props extends MeetTheTeamMemberType {
    index: number;
}

const DraggableMemberCard: FC<Props> = (props) => {
    const {
        id,
        index,
        sequence,
        photoUrl,
        firstName,
        lastName,
        publishWebsite,
        position,
        unpublishWebsiteComment,
        yearStarted,
        description
    } = props;

    const isPublishedToWebsiteIcon = publishWebsite ? (
        <FontAwesomeIcon icon={faCheck} className="h-5 w-5 text-brand_status-success" />
    ) : (
        <FontAwesomeIcon icon={faXmark} className="h-5 w-5 text-brand_status-error" />
    );

    const details = (
        <>
            <div className="min-w-14 max-w-14 text-center">{sequence}</div>
            <div className="min-w-44 max-w-44 [&>img]:rounded-md">
                <ProfileImage
                    imgUrl={photoUrl}
                />
            </div>
            <div className="min-w-44 max-w-44">{firstName} {lastName}</div>
            <div className="min-w-44 max-w-44">{position}</div>
            <div className="min-w-20 max-w-20">{yearStarted}</div>
            <div className="w-full min-w-56">{description}</div>
            <div className="min-w-20 max-w-20 text-center">
                    {isPublishedToWebsiteIcon}
                    { !publishWebsite && <span className="block text-brand_status-error text-sm">{unpublishWebsiteComment}</span> }
                
            </div>
            <div className="ms-auto min-w-28 max-w-28">
                <div className="flex flex-col gap-2">
                    <DeleteTeamMemberDialogButton
                        memberDetails={props}
                        isInSinglePropertyContext={true}
                    />
                    <EditTeamMemberModal
                        memberDetails={props}
                        isInSinglePropertyContext={true}
                    />
                </div>
            </div>
        </>
    );

    return (
        <Draggable key={id} draggableId={String(id)} index={index}>
            {(provided) => (
                <li
                    className="ring-1 ring-brand_grey bg-white p-3 flex items-center gap-4 rounded-md text-sm lg:text-md"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    {details}
                </li>
            )}
        </Draggable>
    );
};

export { DraggableMemberCard };
