import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import FilterMatrix from '../../../modules/filter_matrix';

interface IProps {
    filterMatrix: FilterMatrix;
    applyFilter(key: string, value: any, refreshData?: boolean): void;
    reviewScheme: any
}

const ReviewExperienceTypeFilter = ({ filterMatrix, applyFilter, reviewScheme }: IProps) => {

    const [ experienceTypeOptions, setExperienceTypeOptions ] = useState<any[]>([]);

    useEffect(() => {

        if (!reviewScheme) {
            return;
        }

        const options = reviewScheme.ExperienceTypes.map((item: any) => ({
            label: item.Name,
            value: item.ExperienceTypeID
        }));
        
        setExperienceTypeOptions(options);

    }, [reviewScheme]);

    return (
        <div className="form-component form-inline form-select">
            <label 
                htmlFor="experienceTypeSelect"
            >
                Reviewer Experience Type
            </label>
            <Select
                id="experienceTypeSelect"
                onChange={(option: any) => applyFilter('ReviewExperience', option)}
                options={experienceTypeOptions}
                isMulti
                name="experienceType"
                classNamePrefix="select"
                value={filterMatrix.getFormFieldValue('ReviewExperience')}
                unstyled
            />
        </div>
    );
};

export default ReviewExperienceTypeFilter;
