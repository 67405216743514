import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const PasswordResetConfirm = () => {

    const  location = useLocation();
    const state: any = location?.state;
    const { EmailAddress } = state || {};

    if (!EmailAddress) {
        console.log('EmailAddress is not defined');
        return;
    }

    return (
        <main>
            <section className="h-screen flex justify-center py-8">
                <div className="my-auto w-full max-w-[500px] ring-1 ring-brand_grey rounded-md lg:rounded-lg overflow-hidden shadow-md bg-white mx-3 sm:mx-4 lg:mx-8 h-fit"> 
                    <Link to={{pathname: '/'}} className="flex items-end p-3 sm:p-4 lg:px-8 lg:py-6 border-b border-brand_grey bg-brand_faint-blue">
                        <img src="/logo.svg" className="h-9 me-3 lg:h-10 xl:h-11" alt="Portal" />
                        <strong className="text-brand_grey-medium relative top-0.5 font-medium text-lg lg:text-xl lg:top-0">
                            Portal
                        </strong>
                    </Link>
                    <div className="p-3 sm:p-4 lg:p-8 grid gap-3">
                        <p>
                            If <strong>{EmailAddress}</strong> has a registered account you will receive an email shortly with instructions on how to reset your password.
                        </p>
                        <p>
                            If you do not receive an email within 5 minutes (and you have checked your junk folder), you likely do not have an existing account. To create an account please <Link to={{pathname: '/requestaccess'}} className="link">request access here</Link>.
                        </p>
                        <Link 
                            className="link"
                            to={{
                                pathname: '/login'
                            }}
                        > 
                            Return to login
                        </Link>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default PasswordResetConfirm;