import React, { useState } from 'react';
import { Modal } from '../../components/common/modal';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import RatingStars from '../../components/common/rating_stars';
import { IReply  } from '../../interfaces/reply';
import { LoadingDots } from '../common/loading_dots';
import { ISessionState } from '../../interfaces/session';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { Button } from '../common/button';
import cx from 'classnames';

interface IProps {
    reply: any;
    hasReply: boolean;
    handleClose(): void;
    handleDeleteReply(reviewID: number): Promise<void> | null;
    handleCreateOrUpdateReply(reviewID: number, replyData: IReply ): Promise<void>;
    cancelText: string;
    saveText: string[];
}

interface FormInputs {
  ReplierFirstName: string;
  ReplierLastName: string;
  ReplierPosition: string;
  ReplierEmail: string;
  ReplyContent: string;
}

const ReplyModal = ({
    reply, 
    hasReply, 
    handleClose, 
    handleDeleteReply,
    handleCreateOrUpdateReply,
    cancelText,
    saveText
}: IProps) => {

    const sessionState: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const userFirstName = get(sessionState, 'User.FirstName', '');
    const userLastName = get(sessionState, 'User.LastName', '');
    const userJobTitle = get(sessionState, 'User.JobTitle', '');
    const userEmail = get(sessionState, 'Session.EmailAddress', '');

    const defaultReplierFirstName = reply.ReplyID ? reply.ReplierFirstName || '' : userFirstName;
    const defaultReplierLastName = reply.ReplyID ? reply.ReplierLastName || ''  : userLastName;
    const defaultReplierPosition = reply.ReplyID ? reply.ReplierPosition || ''  : userJobTitle;
    const defaultReplierEmail = reply.ReplyID ? reply.ReplierEmail || '' : userEmail;

    const [ busy, setBusy ] = useState<boolean>(false);
    const [ confirmDelete, setConfirmDelete ] = useState<boolean>(false);

    const { register, formState: { errors }, handleSubmit } = useForm<FormInputs>();

    const onSubmit = async (data: any) => {
        setBusy(true);

        const { 
            ReplierEmail, 
            ReplierFirstName, 
            ReplierLastName, 
            ReplierPosition, 
            ReplyContent
        } = data;

        const replyData: IReply = {
            ReplierEmail,
            ReplierFirstName,
            ReplierLastName,
            ReplierPosition,
            ReplyContent
        };

        if (reply.ReplyID) {
            replyData.ReplyID = reply.ReplyID;
        }

        await handleCreateOrUpdateReply(reply.ReviewID, replyData);
        setBusy(false);
    };

    const handleDelete = async () => {

        if (!handleDeleteReply) {
            return;
        }

        setBusy(true);
        await handleDeleteReply(reply.ReviewID);
        setBusy(false);
        setConfirmDelete(false);
    };

    const footerContent = (
        <>
            { hasReply && !confirmDelete &&
                <Button
                    type="button"
                    onClick={() => setConfirmDelete(true)}
                >
                    Delete this reply
                </Button>
            }
            { hasReply && confirmDelete && handleDeleteReply &&
                <Button
                    type="button"
                    variant="warning"
                    onClick={() => handleDelete()}
                >
                    Confirm Delete
                </Button>
            }        
            <Button
                type="button"
                styleType="outline"
                onClick={() => handleClose()}
            >
                Close &amp; Do Not {cancelText}
            </Button>
            <Button 
                type="submit" 
                form="reply_modal"
            >
                {saveText[0]} {saveText[1]} 
            </Button>
        </>
    );

    return (
        <Modal
            isOpen={true}
            onClose={handleClose}
            title={<>Reply to Review ID: {reply.ReviewID} { reply.ReplierFirstName ? `by ${reply.ReplierFirstName}` : '' } { reply.ReplierLastName } for { reply.PropertyName }</>}
            size='xl'
            footerContent={footerContent}
            footerContentClose={false}
        >
            <>
                <div className="flex flex-wrap gap-x-6 gap-y-3 bg-brand_faint-grey border border-brand_grey rounded-md lg:rounded-lg p-3 sm:p-4 2xl:p-5 mb-8 sm:mb-9 2xl:mb-10">  
                    { 
                        reply.OverallRating && 
                        <div className="flex items-center gap-2 [&>div]:relative [&>div]:-top-px">
                            <strong className="font-semibold lg:text-lg">Overall Rating:</strong> 
                            <RatingStars
                                keyProp={reply.ReviewID}
                                value={reply.OverallRating}
                            />
                        </div>
                    }
                    { reply.ExperienceTypeName && 
                        <div className="flex items-center gap-2">
                            <strong className="font-semibold lg:text-lg">Reviewer Experience Type:</strong> {reply.ExperienceTypeName}
                        </div>
                    }
                    { reply.ConnectionName && 
                        <div className="flex items-center gap-2">
                            <strong className="font-semibold lg:text-lg">Relationship to the resident or client:</strong> {reply.ConnectionName}
                        </div>
                    }
                    <div className="flex flex-col lg:flex-row lg:items-start gap-2 overflow-auto scrollbar max-h-24 pe-9">
                        <strong className="font-semibold lg:text-lg">Review:</strong> { reply.ReviewContent }
                    </div>
                </div>
                { busy ?
                    <LoadingDots show={true} showInPlace={true} /> :
                    <form onSubmit={handleSubmit(onSubmit)} id="reply_modal">
                        <div className="grid gap-3 lg:gap-5 2xl:gap-6 md:grid-cols-2">
                            <div>
                                <div className={cx('form-component form-inline form-textbox',{ 'form-error' : errors.ReplierFirstName })}>
                                    <label htmlFor="ReplierFirstName">
                                        <strong>Replier First Name * <span className="font-medium text-xs">(published)</span></strong> 
                                    </label>
                                    <input
                                        { ...register('ReplierFirstName', {
                                                required: 'Please enter a Replier First Name'
                                            })
                                        }
                                        type="text"
                                        id="ReplierFirstName"
                                        defaultValue={defaultReplierFirstName}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="ReplierFirstName"
                                        render={({ message }: any) => <span>{message}</span>}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className={cx('form-component form-inline form-textbox',{ 'form-error' : errors.ReplierLastName })}>
                                    <label htmlFor="ReplierLastName">
                                        Replier Last Name * <span className="font-medium text-xs">(published)</span>
                                    </label>
                                    <input
                                        {
                                            ...register('ReplierLastName', {
                                                required: 'Please enter a Replier Last Name'
                                            })
                                        }
                                        type="text"
                                        id="ReplierLastName"
                                        defaultValue={defaultReplierLastName}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="ReplierLastName"
                                        render={({ message }: any) => <span>{message}</span>}
                                    />                                    
                                </div>
                            </div>
                            <div>
                                <div className={cx('form-component form-inline form-textbox',{ 'form-error' : errors.ReplierPosition })}>
                                    <label htmlFor="ReplierPosition">
                                        Replier Job Title * <span className="font-medium text-xs">(published)</span>
                                    </label>
                                    <input
                                        {
                                            ...register('ReplierPosition', {
                                                required: 'Please enter a Replier Job Title'
                                            })
                                        }
                                        type="text"
                                        id="ReplierPosition"
                                        defaultValue={defaultReplierPosition}
                                    />
                                    <div className="form-note">
                                        eg. Marketing Director
                                    </div>
                                    <ErrorMessage
                                        errors={errors}
                                        name="ReplierPosition"
                                        render={({ message }) => <span>{message}</span>}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className={cx('form-component form-inline form-textbox',{ 'form-error' : errors.ReplierEmail })}>
                                    <label htmlFor="ReplierEmail">
                                        Replier Email * <span className="font-medium text-xs">(not published)</span>
                                    </label>
                                    <input
                                        {
                                            ...register('ReplierEmail', {
                                                required: 'Please enter a Replier Email Address'
                                            })
                                        }
                                        type="email"
                                        id="ReplierEmail"
                                        defaultValue={defaultReplierEmail}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="ReplierEmail"
                                        render={({ message }: any) => <span>{message}</span>}
                                    />
                                </div>
                            </div>
                            <div className="md:col-span-2">
                                <div className={cx('form-component form-inline form-textarea',{ 'form-error' : errors.ReplyContent })}>
                                    <label htmlFor="ReplyContent">
                                        Reply Text * <span className="font-medium text-xs">(published<span className="hidden md:inline-block">&nbsp;- on website alongside the review</span>)</span>
                                    </label>
                                    <textarea
                                        {
                                            ...register('ReplyContent', {
                                                required: 'Please enter some Reply Text'
                                            })
                                        }
                                        id="ReplyContent"
                                        rows={5}
                                        defaultValue={reply.ReplyContent || ''}
                                    ></textarea>
                                    <div className="form-note">
                                        You will be notified when your Reply has been Verified
                                    </div>
                                    <ErrorMessage
                                        errors={errors}
                                        name="ReplyContent"
                                        render={({ message }) => <span>{message}</span>}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                }
            </>
        </Modal>
    );
};

export default ReplyModal;
