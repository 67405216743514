import { sortBy } from 'lodash';
import { ITourDefaultValues, ITourFormData, TourType } from './types';
import Swal from 'sweetalert2';
import { showToast, ToastType } from '../../../utils/toast';

type WeekdayMap = typeof weekDays;

const weekDays = {
    2: 'Monday',
    3: 'Tuesday',
    4: 'Wednesday',
    5: 'Thursday',
    6: 'Friday',
    7: 'Saturday',
    1: 'Sunday'
} as const;


const mapToDaysOfWeek = (daysString: string) => {
    const days =
        daysString
            ?.split(',')
            .sort()
            .map((digit) => {
                const dayIndex = Number(digit) as keyof WeekdayMap;
                return weekDays[dayIndex];
            }) || [];

    return days;
};


const daysOptions = (() => {
    return Object.entries(weekDays).map(([weekDayNumber, dayText]) => ({
        label: dayText,
        value: Number(weekDayNumber)
    }));
})();

const timeOptions = (() => {
    const times = [];
    for (let i = 600; i <= 2200; i+= 100) {
        const time = `${i < 1000 ? `0${i}` : i}`;
        const timeWithSeparator = time.replace(/(..)(..)/, '$1:$2');
        times.push(timeWithSeparator);
    }
    return times.map(time => ({
        value: time,
        label: time
    }));
    
})();


const prepareDataForForm = (data: ITourDefaultValues): ITourFormData => {
    return {
        ...data,
        TourTypeID: data.TourTypeID ? String(data.TourTypeID) as TourType : null,
        PhysicalTourDays: data?.PhysicalTourDays?.split(',').map(Number) || [],
        VirtualTourDays: data?.VirtualTourDays?.split(',').map(Number) || []
    };
};

const prepareFormValuesForSubmission = (nhID: number, formValues: ITourFormData): ITourDefaultValues => {
    const {
        IsVerified = false,
        IsOrganization,
        PhysicalDescription,
        PhysicalTourDays,
        PhysicalTourEndTime,
        PhysicalTourStartTime,
        TourAvailabilityID,
        TourTypeID,
        VirtualDescription,
        VirtualTourDays,
        VirtualTourEndTime,
        VirtualTourStartTime,
    } = formValues;

    const tourPayLoad: any = {
        IsVerified,
        IsOrganization,
        NHID: nhID,
        PhysicalDescription: null,
        PhysicalTourDays: [],
        PhysicalTourEndTime: null,
        PhysicalTourStartTime: null,
        TourAvailabilityID,
        TourTypeID,
        VirtualDescription: null,
        VirtualTourDays: [],
        VirtualTourEndTime: null,
        VirtualTourStartTime: null,
    };

    switch (formValues.TourTypeID) {
        case TourType.Both_onsite_remote:
            return {
                ...tourPayLoad,
                PhysicalDescription,
                PhysicalTourDays,
                PhysicalTourEndTime,
                PhysicalTourStartTime,
                VirtualDescription,
                VirtualTourDays,
                VirtualTourEndTime,
                VirtualTourStartTime,
            };
        case TourType.Onsite:
            return {
                ...tourPayLoad,
                PhysicalDescription,
                PhysicalTourDays,
                PhysicalTourEndTime,
                PhysicalTourStartTime,
            };
        case TourType.Remote:
            return {
                ...tourPayLoad,
                VirtualDescription,
                VirtualTourDays,
                VirtualTourEndTime,
                VirtualTourStartTime,
            };
        default:
            return {
                ...tourPayLoad
            };
    }
};

const moveOrgToTopRow = (rows: ITourDefaultValues[]) => {
    return sortBy(rows, ({ IsOrganization }) => IsOrganization ? 0 : 1);
};

const failedToSaveTourError = () => Swal.fire({
    title: 'Failed to save tour updates',
    text: 'Please try again',
    icon: 'error'
});

const displaySuccessToast = () => {
    showToast('Tour Availability details successfully updated. Please allow up to 60 minutes for the changes to show on the website.', ToastType.Success);
};

export {
    daysOptions,
    displaySuccessToast,
    failedToSaveTourError,
    mapToDaysOfWeek,
    moveOrgToTopRow,
    prepareDataForForm,
    prepareFormValuesForSubmission,
    timeOptions,
    weekDays
};
