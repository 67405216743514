import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark, faCircle } from '@fortawesome/pro-solid-svg-icons';
interface IProps {
    handleProfileCompletenessFilter: (profileCompletenessFilterValue: boolean | null) => void;
    handleNoVideoFilter: (showWithNoVideo: boolean) => void;
    showWithNoVideo: boolean;
    profileCompletenessFilterValue: boolean | null;
}

const ProfileCompletenessVideoFilter = ({ 
    showWithNoVideo, 
    handleProfileCompletenessFilter, 
    handleNoVideoFilter,
    profileCompletenessFilterValue
}: IProps) => {

    return (
        <div className="bg-brand_faint-blue rounded-xl lg:rounded-3xl">
            <div className="py-4 px-6 lg:py-6 text-center flex flex-col gap-2 xl:flex-row xl:justify-center xl:gap-12 xl:text-start xl:items-center">
                <div>
                    <strong className="text-lg block leading-tight">
                        Profile Complete
                    </strong>
                    <span className="block text-sm leading-tight md:text-md whitespace-nowrap">
                        for Videos
                    </span>
                </div>
                <div className="flex gap-4 justify-center xl:gap-6">
                    <div className="form-component form-radio">
                        <label>
                            <input
                                className="peer"
                                id="filter"
                                type="radio"
                                onChange={() => handleProfileCompletenessFilter(null)}
                                checked={profileCompletenessFilterValue === null}
                                name="complete"
                            /> 
                            <FontAwesomeIcon icon={faCircle} className="peer-checked:block"/>
                            <span className="text-lg">
                                All
                            </span>
                        </label>         
                    </div>
                    <div className="form-component form-radio">
                        <label>
                            <input
                                className="peer"
                                id="filter"
                                type="radio"
                                onChange={() => handleProfileCompletenessFilter(true)}
                                checked={profileCompletenessFilterValue === true}
                                name="complete"
                            /> 
                            <FontAwesomeIcon icon={faCircle} className="peer-checked:block"/>
                            <span>
                                <FontAwesomeIcon icon={faCheck} className="text-3xl text-brand_status-success"/>
                            </span>
                        </label>         
                    </div>
                    <div className="form-component form-radio">
                        <label>
                            <input
                                className="peer"
                                id="filter"
                                type="radio"
                                onChange={() => handleProfileCompletenessFilter(false)}
                                checked={profileCompletenessFilterValue === false}
                                name="complete"
                            /> 
                            <FontAwesomeIcon icon={faCircle} className="peer-checked:block"/>
                            <span>
                                <FontAwesomeIcon icon={faXmark} className="text-3xl text-brand_status-error"/>
                            </span>
                        </label>         
                    </div>
                </div> 
            </div>
            {/*<div className="border-t border-brand_grey py-4 px-4 lg:py-6 xl:px-12">
                <div className="form-component form-checkbox">
                    <label>
                        <input
                            className="peer"
                            type="checkbox"
                            name="lowres"
                            checked={showWithNoVideo}
                            onChange={() => handleNoVideoFilter(!showWithNoVideo)}
                        />
                        <FontAwesomeIcon icon={faCheck} className="peer-checked:block"/>
                        <span>
                            <strong className="text-lg block leading-tight">
                                Show Facilities with no Video
                            </strong>
                            <span className="block text-sm leading-tight md:text-md">
                                (excluding Provider Video)
                            </span>
                        </span>
                    </label>         
                </div>
            </div>*/}
        </div>
    );
};

export default ProfileCompletenessVideoFilter;
