import React, { useState, FormEvent } from 'react';
import { Button } from '../../components/common/button';

interface IPortalUserEmailCheckerFormProps {
    handleEmailExistsCheck(emailToCheck: string) : void;
}

function EmailExistsForm(props : IPortalUserEmailCheckerFormProps) {
    const [emailToCheck, setEmailToCheck] = useState('');
  
    const handleEmailToCheckValue = (e: any) => {
        setEmailToCheck(e.target.value);
    };
    
    const handleOnSubmit = (e: FormEvent) => {
        e.preventDefault();
        props.handleEmailExistsCheck(emailToCheck);
    };

    return (
            <form id="emailChecker" onSubmit={handleOnSubmit}>
                <fieldset>
                    <div className="flex flex-wrap items-center gap-3">
                        <div className="form-component form-inline form-textbox w-full max-w-96">
                            <label
                                htmlFor="emailCheck"
                            >
                                Email
                            </label>
                            <input
                                type="email"
                                id="emailToCheck"
                                max="120"
                                onChange={handleEmailToCheckValue}
                                placeholder="Enter email"
                                value={emailToCheck || ''}
                                required
                            />
                        </div>
                        <Button
                            type="submit"
                        >
                            Next
                        </Button>
                    </div>
                </fieldset>
            </form>          
    );
}

export default EmailExistsForm;